






















































import { Component, Prop, Watch, Vue } from 'vue-property-decorator'

import { loadWhile } from '@/generic/helpers'
import { SelectOption } from '@/generic/models/SelectOption'

import MissionService from '@/services/Mission.service'
import AdminConsoleService from '../../services/AdminConsoleService'
import MissionStatistics from '../../models/console/MissionStatistics.model'
import { AdminDashboardMissionStatisticsCard } from '../../translations/AdminDashboard.translations'

@Component
export default class AdminEmployersAggregateStatsComponent extends Vue {
  @Prop() public total!: number
  @Prop() public translations!: AdminDashboardMissionStatisticsCard

  private missionId: number | null = null
  private missions: SelectOption[] = []
  private missionStats: MissionStatistics = {
    missionApplicationsCount: 0,
    missionConvocationsCount: 0,
    missionEmployersCount: 0,
    missionJobOffersCount: 0,
    missionUniqueCandidatesCount: 0
  }

  created() {
    this.loadMissions()
  }

  private loadMissions(): void {
    loadWhile(this, 'Loading', async () => {
      const result = await MissionService.getAllMissions()

      this.missions = result.data
    })
  }

  @Watch('missionId')
  private onMissionChange(mission: SelectOption) {
    loadWhile(this, 'Loading', async () => {
      const result = await AdminConsoleService.getMissionStatistics(mission.value as number)

      this.missionStats = result.data
    })
  }
}
