
















































import { Component, Prop, Vue } from 'vue-property-decorator'
import { MissionCardTranslations } from './MissionList.translations'
import { Culture } from '@/generic/models/Culture'
import MissionCompanyModel from './Mission.model'
import { CultureService } from '@/services/CultureService'

@Component
export default class MissionCardComponent extends Vue {
  @Prop() translations !: MissionCardTranslations
  @Prop() value !: MissionCompanyModel

  private get missionUrl (): string {
    if (this.value.url) {
      return `/${CultureService.getCulture()}${this.value.url}`
    }
    return this.value.url
  }

  private get location (): string {
    const city = this.value.multipleCity && !this.value.multipleCountry ? this.translations.multipleCity : this.value.city
    const country = this.value.multipleCountry ? this.translations.multipleCountry : this.value.country
    return (city || '') +
            ((city && country) ? ', ' : '') +
            (country || '')
  }

  private get oneDayMission (): boolean {
    const endDate = new Date(this.value.endDate)
    const startDate = new Date(this.value.startDate)
    return startDate.getDate() === endDate.getDate() &&
      startDate.getMonth() === endDate.getMonth() &&
      startDate.getFullYear() === endDate.getFullYear()
  }
}
