












































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { SiteMenu, MenuSection } from '@/navigation/model/SiteMenu.model'
import SignOutService from '@/services/SignOutService'

@Component
export default class MobileUserMenuComponent extends Vue {
  @Prop() menu!: SiteMenu

  private navigate (link: string) {
    window.location.href = link
  }

  private signOut () {
    SignOutService.signOut()
      .then(() => {
        location.reload()
      })
  }
}
