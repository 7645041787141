import Candidate from '@/candidate/Candidate.model'

let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'

function getRandomInt (min : number, max : number) {
  return Math.floor(Math.random() * (max - min)) + min
}

function randomTextArray (min: number, max: number) : string[] {
  var result = []
  for (var x = 0; x < getRandomInt(min, max); x++) {
    result.push(randomText(5, 10))
  }

  return result
}

function randomText (min: number, max: number) : string {
  var name: string = ''
  for (var x = 0; x < getRandomInt(min, max); x++) {
    name += characters.charAt(getRandomInt(0, characters.length))
  }

  return name
}

export function generateCandidates (amount: number) : Candidate[] {
  var results: Candidate[] = []

  for (var x = 0; x < amount; x++) {
    results.push({
      id: 0,
      firstName: randomText(4, 10),
      lastName: randomText(4, 10),
      title: randomText(5, 16),
      industry: '',
      new: true,
      booked: false,
      hidden: false,
      consultedByRecruiter: false,
      tags: randomTextArray(2, 5).map(function (text: string, index: number) {
        return { id: index, name: text }
      }),
      // percentage: getRandomInt(70, 100),
      liked: false,
      rating: 0,
      email: `${randomText(4, 7)}@${randomText(6, 10)}.com`,
      candidateId: 0,
      created: new Date(),
      isSelectedForCVDownload: false,
      languageProficiencyLevelEn: '',
      languageProficiencyLevelFr: '',
      degree: ''
    })
  }

  return results
}
