





























































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { CreateProfileTranslations } from '../CreateProfile.translations'
import { CreateProfileModel } from '../CreateProfile.model'
import { SelectOption } from '@/generic/models/SelectOption'
import CityService from '@/services/CityService'
import SubdivisionService from '@/services/SubdivisionService'
import MITMAutocompleteComponent from '@/generic/components/MITMAutocomplete.vue'
import { RequiredRule } from '../../generic/rules/Required'
import { IsAscii } from '@/generic/rules/IsAscii'

@Component({
  components: {
    'mitm-autocomplete': MITMAutocompleteComponent
  }
})
export default class MoreInfosComponent extends Vue {
  @Prop() private translations!: CreateProfileTranslations
  @Prop() private value !: CreateProfileModel
  @Prop() private immigrationProcess !: Array<SelectOption>
  @Prop() private countriesList !: Array<SelectOption>

  private isFirstLoad : boolean = true
  private rules: { [key: string]: Array<(value: any) => boolean | string> } = {
    required: [
      new RequiredRule().getValidator(this.translations.common.message.required)
    ],
    ascii: [
      new IsAscii().getValidator(this.translations.common.message.invalidCharacter)
    ]
  }
  private cityOptions: Array<string> = []
  private subdivisionOptions: Array<string> = []

  @Watch('value.countryId')
  private onCountry() {
    if (this.isFirstLoad) {
      this.updateSubdivisionOptions(this.value.countryId)
      this.isFirstLoad = false
    }
  }

  private updateSubdivisionOptions (selectedCountry: number | null) {
    if (selectedCountry !== null) {
      SubdivisionService.getSubdivisions(selectedCountry)
        .then(response => {
          this.subdivisionOptions.splice(0)
          this.subdivisionOptions.push(...response.data)
        })
        .catch(() => {
          this.subdivisionOptions.splice(0)
        })
    } else {
      this.subdivisionOptions.splice(0)
    }
  }

  private countrySubdivisionInput (value: string) {
    this.value.countrySubdivision = value
  }

  /**
   * This function is currently not used
   */
  private updateCityOptions (value: string) {
    if (value.length > 2) {
      CityService.getCities(value)
        .then(response => {
          this.cityOptions.splice(0)
          this.cityOptions.push(...response.data)
        })
        .catch(() => {
          this.cityOptions.splice(0)
        })
    } else {
      this.cityOptions.splice(0)
    }
  }

  /**
   * This function is currently not used
   */
  private cityInput (value: string) {
    this.value.city = value
  }
}
