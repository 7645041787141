import { Culture } from '@/generic/models/Culture'

export class CultureService {
  public static getCulture (): Culture {
    const parts = window.location.pathname.split('/')
    if (parts.length < 2 || parts[1] === '') {
      return Culture.French
    }
    return parts[1] as Culture
  }

  public static getCultureCode (): string {
    if (this.getCulture() === Culture.English) {
      return 'en-US'
    }
    return 'fr-CA'
  }
}
