































import { Component, Prop, Vue } from 'vue-property-decorator'
import { AuthentificationTitleTranslations } from '@/authentication/title/AuthentificationTitle.translations'
import { AuthentcationErrorTranslations } from '@/authentication/AuthentcationError.translations'
import ForgotPasswordTranslations from '@/forgot-password/ForgotPassword.translations'
import { SignIn } from './sign-in/SignIn.model'
import { SignInTranslations } from './sign-in/SignIn.translations'
import { SignUp } from './sign-up/SignUp.model'
import { SignUpTranslations } from './sign-up/SignUp.translations'
import { fullURL, getUrlParameter } from '../generic/helpers'

@Component
export default class AuthentificationCandidateComponent extends Vue {
  @Prop() private signIn!: { initModel: SignIn, translations: SignInTranslations }
  @Prop() private register!: { initModel: SignUp, translations: SignUpTranslations }
  @Prop() private culture!: string
  @Prop() private returnUrl!: string
  @Prop() private title!: AuthentificationTitleTranslations
  @Prop() private passwordTranslations!: ForgotPasswordTranslations
  @Prop() private errors!: AuthentcationErrorTranslations
  private displaySignUp = true

  mounted () {
    this.displaySignUp = getUrlParameter('su') !== 'false'
    const url = fullURL()
    if (!url.searchParams.get('ReturnUrl') && this.returnUrl) {
      url.searchParams.set('ReturnUrl', this.returnUrl)
      window.history.pushState(null, '', url.href)
    }
  }

  onsignupchange () {
    this.displaySignUp = !this.displaySignUp
    const url = fullURL()
    url.searchParams.set('su', `${this.displaySignUp}`)
    window.history.pushState(null, '', url.href)
  }
}
