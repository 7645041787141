





































import { Component, Prop, Vue } from 'vue-property-decorator'
import { MissionScheduleTranslations } from '@/dashboard/missions/MissionSchedule.translations'
import { AllCompaniesSchedule } from '@/dashboard/missions/RecruiterSchedule'
import { loadWhile } from '@/generic/helpers'
import { MeetingService } from '@/services/MeetingService'
import { SelectOption } from '@/generic/models/SelectOption'
import MissionService from '@/services/Mission.service'

@Component
export default class AllCompaniesMissionScheduleComponent extends Vue {
  @Prop() private translations!: MissionScheduleTranslations;
  @Prop() private adminKey!: string;
  private missionId: number | null = null;
  private loaded = false;
  private allCompaniesSchedules: AllCompaniesSchedule | null = null;
  private missions: SelectOption[] = [];

  private mounted (): void {
    this.loadMissions()
  }

  private loadMissions (): void {
    loadWhile(this, 'Loading', () => MissionService.getAllActiveMissions().then(result => {
      this.missions = result.data
    }))
  }

  private onMissionIdChange (newValue: number | null): void {
    this.missionId = newValue
    if (this.missionId !== null) {
      this.fetchSchedules(this.missionId)
    } else {
      this.allCompaniesSchedules = null
    }
  }

  private fetchSchedules (missionId: number): void {
    loadWhile(this, 'Loading', () => MeetingService.getAllCompaniesMeetings(missionId, this.adminKey).then((response) => {
      this.allCompaniesSchedules = response.data
    })).finally(() => {
      this.loaded = true
    })
  }

  private print (): void {
    window.print()
  }
}
