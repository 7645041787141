










































import { Component, Prop, Vue } from 'vue-property-decorator'
import CandidateCardTranslations from '@/candidate/candidates-list/CandidateCard.translations'
import { FavoriteCandidatesForJobOffer } from './FavoriteCandidates.model'

@Component
export default class FavoritesForJobOfferComponent extends Vue {
  @Prop() private translations!: CandidateCardTranslations
  @Prop() private favorites!: FavoriteCandidatesForJobOffer
  @Prop() companyId !: number
  @Prop() showHidden !: boolean

  private get favoriteCandidates () {
    return this.favorites.favorites.sort((c1, c2) => {
      return c2.rating - c1.rating
    })
  }

  private getCandidatesText () {
    return this.favorites.favorites.length > 1 || this.translations.candidateCard.candidates === undefined ? this.translations.candidateCard.candidates : this.removeLastCharacter(this.translations.candidateCard.candidates)
  }

  private removeLastCharacter (text: string) {
    return text.slice(0, -1)
  }
}
