



















































import { Component, Prop, Vue } from 'vue-property-decorator'
import { AxiosResponse } from 'axios'
import { CompanyCoordinate } from '@/company/models/CompanyCoordinate'
import { CompanyService } from '@/services/CompanyService'
import { CultureService } from '@/services/CultureService'
import { MapPoint } from '@/generic/models/MapPoint'
import MITMAzureMapComponent from '@/generic/components/MITMAzureMap.vue'
import EmployerPopUpComponent from './EmployerPopUp.vue'
import { EmployerMapTranslations } from './EmployerMap.translations'

@Component({
  components: {
    'azure-map': MITMAzureMapComponent,
    'employer-popup': EmployerPopUpComponent
  }
})
export default class EmployerMapComponent extends Vue {
  @Prop() activityArea!: number
  @Prop() missionId!: string
  @Prop() mapCenter!: number[]
  @Prop() private translations!: EmployerMapTranslations
  private resultsCount = 0
  private searchTerms = ''
  private animation = false
  private dataSourceIsSet = false

  private map!: MITMAzureMapComponent
  private companyInfo: CompanyCoordinate = {
    name: '',
    streetAddress: '',
    city: '',
    postalCode: '',
    logo: '',
    primaryArea: '',
    secondaryArea: '',
    latitude: 0,
    longitude: 0
  }

  private get culture () {
    return CultureService.getCultureCode()
  }

  private handleResponse (response: AxiosResponse<CompanyCoordinate[]>) {
    this.resultsCount = response.data.length
    if (this.map) {
      const points = response.data.map((company: CompanyCoordinate) : MapPoint => {
        return {
          data: company,
          longitude: company.longitude,
          latitude: company.latitude
        }
      })
      if (this.dataSourceIsSet) {
        this.map.replacePoints(points)
      } else {
        this.map.setDataSource(points, (this.$refs['popup-template'] as Vue).$el as HTMLElement)
        this.dataSourceIsSet = true
      }
    }
  }

  private onMapReady (map : MITMAzureMapComponent) {
    this.map = map
    this.search()
  }

  private search (): void {
    if (this.activityArea) {
      CompanyService.getCompanyCoordinatesForArea(this.activityArea, this.culture, this.searchTerms)
        .then(this.handleResponse)
    } else {
      CompanyService.getCompanyCoordinatesForMission(this.missionId, this.culture, this.searchTerms)
        .then(this.handleResponse)
    }
  }

  private onOpenPopup (popupData : any) {
    this.companyInfo = popupData as CompanyCoordinate
  }

  private onMouseOver (): void {
    this.animation = true
  }

  private onMouseLeave (): void {
    this.animation = false
  }
}
