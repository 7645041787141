









































import { Component, Prop, Vue } from 'vue-property-decorator'
import { MenuLink } from '@/navigation/model/Menu.model'
import MobileMenuComponent from './mobile/MobileMenu.vue'
import { SiteMenu } from '@/navigation/model/SiteMenu.model'
import { FooterTranslations } from '@/generic/translations/Footer.translations'
import MobileHeaderComponent from './mobile/MobileHeader.vue'
import { BreadcrumbLink } from '@/content/breadcrumb/BreadcrumbLink.model'

@Component
export default class ContentLayoutComponent extends Vue {
  @Prop() private switchLanguage!: MenuLink
  @Prop() private menu!: SiteMenu
  @Prop() private footerTranslations!: FooterTranslations
  @Prop() private about!: string
  @Prop() private contactUs!: string
  @Prop() private faq!: string
  @Prop() private breadcrumbLinks!: Array<BreadcrumbLink>
  @Prop() private newsletterPage!: string

  private onToggleMenu (): void {
    (this.$refs.menu as MobileMenuComponent).toggleDrawer()
  }

  private onMenuClosed () : void {
    (this.$refs.header as MobileHeaderComponent).closeMenu()
  }
}
