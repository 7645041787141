




















































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { PreviewOfferTranslations } from './PreviewOffer.translations'
import { ITranslatable } from '@/generic/models/ITranslatable'
import { Company } from '@/company/Company.model'
import { CultureService } from '@/services/CultureService'
import { Culture } from '@/generic/models/Culture'

@Component
export default class PreviewOfferCompanyComponent extends Vue {
  @Prop() private translations!: PreviewOfferTranslations
  @Prop() private company!: Company
  @Prop({ default: null }) private displayEnglish!: boolean | null
  private useEnglish = false

  mounted () {
    if (this.displayEnglish === null) {
      this.useEnglish = CultureService.getCulture() === Culture.English
    } else {
      this.useEnglish = this.displayEnglish
    }
  }

  private translate (translatable: ITranslatable): string {
    return this.useEnglish ? translatable.en : translatable.fr
  }

  private getTranslation (key: string): string {
    const [key1, key2] = key.split('.')
    return (this.useEnglish ? this.translations.offerEn : this.translations.offerFr)[key1][key2]
  }
}
