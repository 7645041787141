import { BASE_API } from './api-common'
import { AxiosPromise } from 'axios'
import { InterestArea } from '@/generic/models/InterestArea.model'
import qs from 'qs'

export default class InterestAreasService {
  public static getInterestAreas (input: string, culture: string, selectedInterestAreas: number[]) : AxiosPromise<Array<InterestArea>> {
    return BASE_API.get(`interestAreas/getInterestAreas`, {
      params: {
        value: input,
        culture: culture,
        selectedInterestAreas: selectedInterestAreas
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    })
  }

  public static getInterestAreasByCnpCode (cnpCode: number, culture: string, selectedInterestAreas: number[]) : AxiosPromise<Array<InterestArea>> {
    return BASE_API.get(`interestAreas/getInterestAreasByCNPCode`, {
      params: {
        value: cnpCode,
        culture: culture,
        selectedInterestAreas: selectedInterestAreas
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    })
  }
}
