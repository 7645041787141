























































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { CommonErrorTranslations } from '@/generic/models/CommonError.translations'
import { EmployerPackageTranslations } from './models/EmployerPackage.translations'
import ProductListComponent from './Product/ProductList.vue'
import { loadWhile, notify } from '@/generic/helpers'
import { ProductService } from '@/services/ProductService'
import Product from './models/Product'
import { ShoppingCart } from './models/ShoppingCart'
import { ShoppingCartService } from '@/services/ShoppingCartService'
import { IndustryTranslations } from '@/profile/Industry.translations'
import { SelectOption } from '@/generic/models/SelectOption'
import { getActivityAreasMifi } from '@/generic/activity-area-helper'

@Component({
  components: {
    'mitm-product-list': ProductListComponent
  }
}
)
export default class EmployerPackageComponent extends Vue {
  @Prop() translations!: EmployerPackageTranslations
  @Prop() industryTranslations!: IndustryTranslations
  @Prop() errors!: CommonErrorTranslations
  @Prop() companyId!: number
  @Prop() culture!: string
  private shoppingCart!: ShoppingCart
  private loaded = false
  private hasError = false
  private productsCohorts!:Array<Product>
  private productsJobOffers!:Array<Product>
  private productsPermits!:Array<Product>
  private canBuy!:boolean
  private isShoppingCartEmpty:boolean = true
  private numberOfEmployees!: number
  private industriesList: Array<SelectOption> = getActivityAreasMifi(this.industryTranslations)

  mounted () {
    loadWhile(this, this.translations.loading, () => Promise.all([
      ProductService.getCohortsProducts()
        .then(response => {
          this.productsCohorts = response.data
        })
        .catch(() => {
          this.hasError = true
        }),
      ProductService.getJobOffersProducts()
        .then(response => {
          this.productsJobOffers = response.data
        })
        .catch(() => {
          this.hasError = true
        }),
      ProductService.getPermitsProducts()
        .then(response => {
          this.productsPermits = response.data
        })
        .catch(() => {
          this.hasError = true
        }),
      ProductService.getEmployeesNumber(this.companyId)
        .then(response => {
          this.canBuy = response.data > 0
          this.numberOfEmployees = response.data
        })
        .catch(() => {
          this.hasError = true
        }),
      ShoppingCartService.getShoppingCart(this.culture)
        .then(response => {
          this.shoppingCart = response.data
          this.isShoppingCartEmpty = this.shoppingCart.cartItems.length === 0
        })
        .catch(() => {
          this.hasError = true
        })
    ]).finally(() => {
      this.loaded = true
    }))
  }

  private itemAdded () {
    this.isShoppingCartEmpty = false
  }

  private goToCart () {
    if (this.isShoppingCartEmpty) {
      notify(this, this.translations.message.emptyCart)
      return
    }
    if (this.culture === 'en-US') {
      window.location.href = `/en/employer/cart`
    } else {
      window.location.href = `/fr/employeur/panier`
    }
  }
}
