























































































































































































































































































































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { InteractiveMapTranslations } from './translations/InteractiveMap.translations'
import { MapRegion } from './MapRegion'
import InteractiveMapListComponent from './InteractiveMapList.vue'
import InteractiveMapInfoCardComponent from './InteractiveMapInfoCard.vue'

@Component({
  components: {
    'mitm-interactive-map-list': InteractiveMapListComponent,
    'mitm-interactive-map-info-card': InteractiveMapInfoCardComponent
  }
})
export default class InteractiveMapComponent extends Vue {
  @Prop() private translations!: InteractiveMapTranslations
  @Prop() private regions!: MapRegion[]
  private showMontreal: boolean = false
  private selectedRegion: MapRegion | undefined = undefined
  private train: Boolean = false
  private metro: boolean = false
  private infoBox: boolean = false
  private currentRegion!: MapRegion
  private map!: HTMLElement | null
  private paths!: any
  private openDialog: boolean = false

  private get ZonesGDM () {
    return this.regions.filter(region => region.map === 'MAP_GRANDMTL')
  }

  private get ZonesIDM () {
    return this.regions.filter(region => region.map === 'ARRONDISSEMENTS')
  }

  mounted () {
    this.map = document.querySelector('.InteractiveMap') as HTMLElement
  }

  private handleClicked (region: MapRegion) {
    this.infoBox = false
    this.currentRegion = region
    if (region.zoneId === 'ile') {
      this.showMontreal = true
    } else {
      this.listSelectActions(region, 'clicked')
      this.infoBox = true
    }
  }

  private handleHovered (region: MapRegion) {
    this.listSelectActions(region, 'hover')
  }

  private handleMapClick (e: MouseEvent) {
    this.mapSelectActions(e, 'clicked')

    if (this.$q.screen.lt.md) {
      this.mapMobileSelectActions(e)
    }
  }

  private handleMapHover (e: MouseEvent) {
    this.mapSelectActions(e, 'hover')
  }

  private handleSelect (value: MapRegion) {
    this.infoBox = false
    this.selectedRegion = value
    this.currentRegion = this.selectedRegion
    this.resetSelected('hover')
    if (this.selectedRegion.zoneId === 'ile') {
      this.showMontreal = true
    } else {
      this.selectActions(this.selectedRegion, 'clicked')
      this.openDialog = true
      this.infoBox = true
    }
  }

  private selectActions (region: MapRegion, mouseAction: string) {
    this.resetSelected(mouseAction)
    const currentMapZoneCollection = document.querySelectorAll<HTMLElement>(`#region-${region.zoneId} .st0`)
    this.activateMapZone(currentMapZoneCollection, mouseAction)
  }

  private listSelectActions (region: MapRegion, mouseAction: string) {
    const listItem = document.querySelector(`#list-${region.zoneId}`) as HTMLElement
    const MapItems = document.querySelectorAll<HTMLElement>(`#region-${region.zoneId} .st0`)
    this.selectArea(listItem, MapItems, mouseAction)
  }

  private mapSelectActions (e: any, mouseAction: string) {
    const currentMapZoneId = e.currentTarget.id
    const currentRegionIdName = currentMapZoneId.replace(`region-`, '')
    const currentListZoneItem = document.querySelector<HTMLElement>(`#list-${currentRegionIdName}`)
    const currentMapZoneCollection = document.querySelectorAll<HTMLElement>(`#region-${currentRegionIdName} .st0`)

    this.resetSelected(mouseAction)
    this.activateMapZone(currentMapZoneCollection, mouseAction)
    this.activateListItem(currentListZoneItem, mouseAction)
  }

  private mapMobileSelectActions (e: any) {
    const currentRegionIdName = e.currentTarget.id.replace(`region-`, '')
    const regionList = this.showMontreal ? this.ZonesIDM : this.ZonesGDM
    let index
    this.infoBox = false

    if (currentRegionIdName === 'ile') {
      this.showMontreal = true
    } else {
      const ids = regionList.map((region: any) => {
        return region['zoneId']
      })
      index = ids.indexOf(currentRegionIdName)
      if (index >= 0) {
        this.currentRegion = regionList[index]
        this.selectedRegion = regionList[index]
        this.openDialog = true
        this.infoBox = true
      }
    }
  }

  private selectArea (listEl: HTMLElement, mapEl: NodeListOf<HTMLElement>, mouseAction: string) {
    this.resetSelected(mouseAction)
    listEl.classList.add(`_is-${mouseAction}`)
    mapEl.forEach((region: HTMLElement) => {
      region.classList.add(`_is-${mouseAction}`)
      this.SVGToTop(region)
    })
  }

  private resetSelected (mouseAction: string) {
    if (this.map !== null) {
      this.map.querySelectorAll(`._is-${mouseAction}`).forEach((areas) => {
        areas.classList.remove(`_is-${mouseAction}`)
      })
    }
  }

  private activateMapZone (currentMapRegionCollection: NodeListOf<HTMLElement>, mouseAction: string) {
    if (currentMapRegionCollection !== null) {
      currentMapRegionCollection.forEach((region: HTMLElement) => {
        region.classList.add(`_is-${mouseAction}`)
        this.SVGToTop(region)
      })
    }
  }

  private activateListItem (currentListRegionItem: HTMLElement|null, mouseAction: string) {
    if (currentListRegionItem) {
      currentListRegionItem.classList.add(`_is-${mouseAction}`)
      if (mouseAction === 'clicked') {
        currentListRegionItem.click()
      }
    }
  }

  private getTitleById (zoneId: string) {
    const zone = this.regions.filter(function (region) {
      return region.zoneId === zoneId
    })
    return zone[0].name
  }

  private SVGToTop (region: any) {
    const map = document.querySelector('.InteractiveMap svg #MAP') as HTMLElement
    const mapFirstChild = map.firstChild

    if (region.parentElement !== null && mapFirstChild !== null) {
      const regionsParent = mapFirstChild.firstChild as HTMLElement
      const currentElement = region.closest('a') as HTMLElement
      const searchedElement = regionsParent.querySelectorAll('._is-clicked')
      regionsParent.appendChild(currentElement as HTMLElement)
      for (let i = 0; i < searchedElement.length; i++) {
        regionsParent.appendChild(searchedElement[i].closest('a') as HTMLElement)
      }
    }
  }
}
