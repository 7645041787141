





















































import { Component, Prop, Vue } from 'vue-property-decorator'
import ForgotPasswordTranslations from './ForgotPassword.translations'
import { AuthenticationService } from '../services/Authentication.service'
import { AuthentcationErrorTranslations } from '@/authentication/AuthentcationError.translations'

@Component
export default class ForgotPasswordComponent extends Vue {
  @Prop() private translations !: ForgotPasswordTranslations
  @Prop() private email !: string
  @Prop() private culture !: string
  @Prop() errors!: AuthentcationErrorTranslations

  private openDialog : boolean = false
  private hasError : boolean = false
  private errorMessage : string = ''
  private loading : boolean = false

  submitReset () {
    this.hasError = false
    this.loading = true
    AuthenticationService.resetPassword({ email: this.email, culture: this.culture })
      .then(() => {
        (this.$refs.forgotPasswordDialog as any).hide()
        this.loading = false
      })
      .catch((error : any) => {
        this.errorMessage = this.errors[error.response.data.message]
        this.loading = false
        this.hasError = true
      })
  }
}
