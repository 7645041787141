import { Language } from '@/generic/models/Language.model'

export class LanguageHelper {
  public static getAllLanguage (forOffer: boolean) {
    if (forOffer) {
      return ['addOtherLanguage', Language.arabic, Language.spanish, Language.hindi, Language.mandarin, Language.portuguese, Language.other]
    }
    return [Language.arabic, Language.spanish, Language.hindi, Language.mandarin, Language.portuguese, Language.other]
  }
}
