import { BASE_API } from './api-common'
import MissionCompanyModel from '@/dashboard/missions/Mission.model'
import { AxiosPromise } from 'axios'
import { CultureService } from './CultureService'
import { TimeSlot } from '@/job-application/JobApplication.model'
import { SelectOption } from '@/generic/models/SelectOption'
import { MissionRequestInformationForm } from '@/content/missions/MissionRequestInformationForm'
import { MissionEvent } from '@/content/models/MissionEvent'
import MissionInfo from '@/candidate/candidate-details/meeting-details/MissionInfo'

export default class MissionService {
  public static missionCompany(
    companyId: number,
    cultureCode: string,
    ignoreDate: boolean = false,
    onlyActive: boolean = false
  ): AxiosPromise<Array<SelectOption>> {
    return BASE_API.get('mission/company', {
      params: {
        companyId: companyId,
        cultureCode: cultureCode,
        ignoreDate: ignoreDate,
        onlyActive: onlyActive
      }
    })
  }

  public static availableMissions(
    companyId: number,
    cultureCode: string
  ): AxiosPromise<Array<MissionCompanyModel>> {
    return BASE_API.get('mission/company', {
      params: {
        companyId: companyId,
        cultureCode: cultureCode,
        ignoreDate: false,
        onlyActive: true,
        includeAll: true
      }
    })
  }

  public static activeMissionCompany(companyId: number): AxiosPromise<Array<MissionCompanyModel>> {
    return BASE_API.get('mission/companyActive', {
      params: {
        companyId: companyId,
        cultureCode: CultureService.getCultureCode()
      }
    })
  }

  public static requestInformation(missionId: string): AxiosPromise<any> {
    return BASE_API.post('mission/requestInformation', {
      missionId: missionId
    })
  }

  public static getMissionTimeSlots(missionId: number): AxiosPromise<Array<TimeSlot>> {
    return BASE_API.get('mission/timeSlots', { params: { missionId: missionId } })
  }

  public static saveAvailability(
    companyId: number,
    missionId: number,
    selected: any
  ): AxiosPromise {
    return BASE_API.post('mission/availability', {
      companyId: companyId,
      missionId: missionId,
      selected: selected
    })
  }

  public static getMissionInfo(missionId: number): AxiosPromise<MissionInfo> {
    return BASE_API.get('mission/info', { params: { missionId: missionId } })
  }

  public static getAllActiveMissions(): AxiosPromise<SelectOption[]> {
    return BASE_API.get<SelectOption[]>('mission/allActive')
  }

  public static getAllMissions(): AxiosPromise<SelectOption[]> {
    return BASE_API.get<SelectOption[]>('mission')
  }

  public static guestRequestInformation(
    missionId: string,
    requestInformationForm: MissionRequestInformationForm
  ): AxiosPromise<void> {
    return BASE_API.post('mission/guestRequestInformation', {
      missionId: missionId,
      guestRequestInformationForm: requestInformationForm
    })
  }

  public static getEvents(ignoreEventId: number): AxiosPromise<MissionEvent[]> {
    return BASE_API.get('mission/events', {
      params: {
        ignoreEventId: ignoreEventId,
        cultureCode: CultureService.getCultureCode()
      }
    })
  }

  public static isVirtual(missionId: number): AxiosPromise<boolean> {
    return BASE_API.get('mission/virtual', { params: { missionId: missionId } })
  }
}
