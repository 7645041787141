


















































































































































































import { loadWhile, notify } from '@/generic/helpers'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { AccountTranslations } from './Account.translations'
import { ChangeAccountModel } from '@/authentication/account/ChangeAccount.model'
import { RequiredRule } from '@/generic/rules/Required'
import MatchField from '@/generic/rules/Match'
import ValidatePasswordDialog from '@/authentication/password/ValidatePassword.vue'
import { ValidatePasswordModel } from '@/authentication/password/ValidatePassword.model'
import { AuthenticationService } from '@/services/Authentication.service'
import { AccountService } from '@/services/Account.service'
import { SelectOption } from '@/generic/models/SelectOption'
import MITMPromptComponent from '../../generic/components/MITMPrompt.vue'
import { CompanyService } from '../../services/CompanyService'
import { DialogResult } from '../../generic/models/DialogResult'
import { AxiosPromise } from 'axios'
import AdminConsoleCandidateService from '@/admin/console/services/AdminConsoleCandidateService'

@Component
export default class AccountFormComponent extends Vue {
  @Prop() private translations!: AccountTranslations
  @Prop() private model !: ChangeAccountModel
  @Prop() private culture !: string
  @Prop() private signInUrl !: string
  @Prop() private emailNotificationField !: Array<SelectOption>
  @Prop() private lastRecruiter!: boolean
  @Prop() private confirmed!: boolean
  @Prop({ default: false }) private isAdmin!: boolean
  @Prop() private adminId!: number
  private allowChangePassword : boolean = false
  private allowChangeEmail : boolean = false
  private hasError : boolean = false
  private errorMessage : string = ''
  private rules: { [key: string]: Array<(value: any) => boolean | string> } = {
    email: [new RequiredRule().getValidator(this.translations.common.message.required)],
    firstName: [new RequiredRule().getValidator(this.translations.common.message.required)],
    lastName: [new RequiredRule().getValidator(this.translations.common.message.required)],
    confirmPassword: [new RequiredRule().getValidator(this.translations.common.message.required), new MatchField('newPassword', this.get, this.getField).getValidator(this.translations.auth.passwords.message.mustMatch)],
    password: [new RequiredRule().getValidator(this.translations.common.message.required), new MatchField('confirmPassword', this.get, this.getField).getValidator(this.translations.auth.passwords.message.mustMatch)]
  }
  get (prop: string): any {
    return this.model[prop]
  }

  getField (prop: string) : any {
    return this.$refs[prop]
  }

  private get promptMessage () {
    if (this.model.isRecruiter) {
      return this.translations.auth.account.prompt.changeEmail
    } else {
      return this.translations.auth.account.prompt.candidateChangeEmail
    }
  }

  private enableEmail () {
    this.validatePassword()
      .then(result => {
        if (result) {
          this.allowChangeEmail = result.valid
          this.$nextTick(() => {
            (this.$refs['emailField'] as any).focus()
          })
        }
      })
  }

  private validatePassword () : Promise<ValidatePasswordModel | undefined> {
    return (this.$refs.validatePasswordDialog as ValidatePasswordDialog).open()
  }

  private confirmPassword () {
    this.validatePassword()
      .then(result => {
        if (result) {
          this.allowChangePassword = result.valid
          this.model.changePassword = true
          this.model.currentPassword = result.password
          this.$nextTick(() => {
            (this.$refs.newPassword as any).focus()
          })
        }
      })
  }

  private validate () : Promise<boolean> {
    const form = this.$refs['accountForm'] as any
    return form.validate()
  }

  private reconfirm () {
    this.hasError = false
    loadWhile(this, this.translations.common.message.loading, () => AuthenticationService.reconfirmAccount(this.culture)
      .then(() => {
        notify(this, this.translations.auth.account.message.confirmationSent)
      })
      .catch((error) => {
        this.hasError = true
        this.errorMessage = this.translations.error[error.response.data.message]
      })
    )
  }

  private reconfirmByAdmin () {
    this.hasError = false
    let candidateId = 0
    if (this.model.id !== undefined) {
      candidateId = this.model.id
    }
    loadWhile(this, this.translations.common.message.loading, () => AdminConsoleCandidateService.reconfirmAccount(candidateId, this.culture)
      .then(() => {
        notify(this, this.translations.auth.account.message.confirmationSent)
      })
      .catch((error) => {
        this.hasError = true
        this.errorMessage = this.translations.error[error.response.data.message]
      })
    )
  }

  private saveAccount () {
    this.hasError = false
    this.model.preferredCulture = this.culture
    this.validate()
      .then(isValid => {
        if (isValid) {
          loadWhile(this, this.translations.common.message.loading, () => this.updateAccount()
            .then(response => {
              this.model = response.data
              notify(this, this.translations.auth.account.message.saved)
              if (this.model.relogin) {
                window.location.href = this.signInUrl
              }
            })
            .catch(error => {
              this.hasError = true
              this.errorMessage = this.translations.error[error.response.data.message]
            })
            .finally(() => {
              this.model.newPassword = ''
              this.model.confirmPassword = ''
              this.allowChangePassword = false
            })
          )
        }
      })
  }

  private updateAccount (): AxiosPromise<ChangeAccountModel> {
    if (this.isAdmin) {
      return AccountService.updateAccountFromAdmin(this.model)
    }
    return AccountService.updateAccount(this.model)
  }

  private deleteAccount (): void {
    if (this.lastRecruiter) {
      (this.$refs.deleteCompanyPrompt as MITMPromptComponent).open()
    } else {
      this.validatePassword().then((event: ValidatePasswordModel | undefined) => {
        if (event) {
          loadWhile(this, this.translations.common.message.loading, () => AccountService.deleteAccount(event.password).then((response) => {
            notify(this, this.translations.auth.account.message.deleted)
            setTimeout(() => {
              window.location.href = '/'
            }, 3000)
          }))
        }
      })
    }
  }

  private deleteAccountByAdmin (): void {
    if (this.lastRecruiter) {
      (this.$refs.deleteCompanyPrompt as MITMPromptComponent).open()
    } else {
      loadWhile(this, this.translations.common.message.loading, () => AdminConsoleCandidateService.deleteAccount(this.model.email).then((response) => {
        notify(this, this.translations.auth.account.message.deleted)
        setTimeout(() => {
          if (this.culture === 'en-US') {
            window.location.href = `/en/site-admin/console-candidates-list`
          } else {
            window.location.href = `/fr/admin/console-liste-candidats`
          }
        }, 3000)
      }))
    }
  }

  private deleteCompany (result: DialogResult): void {
    if (result === DialogResult.Ok) {
      this.validatePassword().then((event: ValidatePasswordModel | undefined) => {
        if (event) {
          loadWhile(this, this.translations.common.message.loading, () => CompanyService.deleteCompany(event.password).then(response => {
            notify(this, this.translations.companyDeleted)
            setTimeout(() => {
              window.location.href = '/'
            }, 3000)
          }))
        }
      })
    }
  }
}
