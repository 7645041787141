import { ShoppingCart } from '@/dashboard/employer-package/models/ShoppingCart'
import { ShoppingCartItem } from '@/dashboard/employer-package/models/ShoppingCartItem'
import { AxiosPromise } from 'axios'
import { BASE_API } from './api-common'

export class PaymentService {
  public static createOrUpdatePaymentIntent (shoppingCart: ShoppingCart) : AxiosPromise<ShoppingCart> {
    return BASE_API.post('payment/createOrUpdatePaymentIntent', shoppingCart)
  }
}
