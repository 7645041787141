import { AxiosPromise } from 'axios'
import { BASE_API } from '@/services/api-common'
import { AdminBillingResponse } from '../models/billing/AdminOrder'

export default class AdminConsoleBillingService {
  public static getBilling(pageNumber: number,
    searchTerms: string,
    searchTransactionId: string,
    eventId: string,
    startDate: string,
    endDate: string): AxiosPromise<AdminBillingResponse> {
    let data = []
    data = eventId.split('-')
    return BASE_API.get('adminconsolebilling/getbilling', {
      params: {
        pageNumber: pageNumber,
        searchTerms: searchTerms,
        searchTransactionId: searchTransactionId,
        eventId: data[0],
        eventType: data[1] === undefined ? '' : data[1],
        startDate: startDate,
        endDate: endDate
      }
    })
  }

  public static downloadInvoice (orderId: number) : AxiosPromise<any> {
    return BASE_API.get(`adminconsolebilling/downloadorderinvoice?orderId=${orderId}`,
      {
        responseType: 'blob'
      })
  }

  public static downloadContracts (orderId: number) : AxiosPromise<any> {
    return BASE_API.get(`adminconsolebilling/downloadordercontracts?orderId=${orderId}`,
      {
        responseType: 'blob'
      })
  }
}
