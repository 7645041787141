














































































































































import { Component, Prop, Vue, Mixins } from 'vue-property-decorator'
import { PreviewOfferTranslations } from './PreviewOffer.translations'
import { OfferService } from '@/services/OfferService'
import { JobOffer } from '../JobOffer.model'
import { loadWhile, notify, notifyNegative } from '@/generic/helpers'
import { PublicationLanguage } from '@/generic/models/PublicationLanguage'
import { CompanyService } from '@/services/CompanyService'
import { Company } from '@/company/Company.model'
import { SelectOption } from '@/generic/models/SelectOption'
import { dateFilter } from '@/generic/filters/Date'
import { Culture } from '@/generic/models/Culture'
import { Status } from '@/generic/models/Status'
import GtmEmployerMixin from '@/gtm/GtmEmployer'
import { RecruiterService } from '@/services/RecruiterService'
import { CultureService } from '@/services/CultureService'

@Component
export default class PreviewOfferContainerComponent extends Mixins(GtmEmployerMixin) {
  @Prop() private translations!: PreviewOfferTranslations
  @Prop() private offerId!: number
  @Prop() private experienceTexts!: { fr: Array<SelectOption>, en: Array<SelectOption> }
  @Prop() private editUrl!: string
  @Prop() private userCanPublish!: boolean
  @Prop({ default: false }) private companyCanPublish!: boolean
  @Prop() private model !: JobOffer
  @Prop() private displayEnglish = false
  @Prop() private loaded = false
  @Prop() private firstOffer = false
  @Prop() private hasAnyAvailableOrder!: boolean
  @Prop() private employerPackageUrl!: string
  private company = new Company()
  private hasError = false
  private errorMessage = ''

  private handleError (error: any) {
    this.hasError = true
    if (error.response.status === 400 && error.response.data.message === 'accountNotConfirmed') {
      this.errorMessage = this.translations.errors.auth.accountNotConfirmed
    } else if (error.response.status === 404) {
      this.errorMessage = this.translations.errors.company.notFound
    } else if (error.response.status === 403) {
      this.errorMessage = this.translations.errors.common.forbidden
    } else {
      this.errorMessage = this.translations.errors.offer[error.response.data.message]
    }
  }

  mounted (): void {
    console.log(this.model)
    loadWhile(this, this.translations.common.message.loading, () => {
      this.displayEnglish = this.model.language.language === PublicationLanguage.English
      return CompanyService.getCompany(this.model.companyId)
        .then((response) => {
          this.company = response.data
        }).catch(error => {
          if (error.response.data.message === undefined) {
            this.handleError(error)
          } else {
            this.hasError = true
            this.errorMessage = this.translations.errors.company[error.response.data.message]
          }
        }).finally(() => {
          this.loaded = true
        })
    })
  }

  private isBilingualOffer (): boolean {
    return this.model.language.language === PublicationLanguage.FrenchAndEnglish
  }

  private getCurrentDate (): string {
    return this.translations.previewOffer.header.prompt.replace(/{{now}}/gi, dateFilter(new Date()))
  }

  private getExpiryDate (): Date {
    if (this.model.expiryDate) {
      return this.model.expiryDate
    }
    const date = new Date()
    date.setMonth(date.getMonth() + 1)
    return date
  }

  private gtmPublish (activeJobs : number) {
    if (this.firstOffer) {
      let confirmed = this.$userContext ? this.$userContext.confirmed : false
      this.onPublishFirstOffer(this.translations.previewOffer.footer.publish, confirmed)
    } else {
      this.onPublishJob(activeJobs)
    }
  }

  private publish (): void {
    loadWhile(this, this.translations.common.message.loading, () => {
      return OfferService.publishOffer(this.offerId)
        .then(response => {
          this.gtmPublish(response.data.offerCount)
          setTimeout(() => {
            window.location.href = this.editUrl
          }, 500)
        })
        .catch((error) => {
          if (error.response.data.message === undefined) {
            this.handleError(error)
          } else if (error.response.status === 400 && error.response.data.message === 'notEnoughOrderItems') {
            this.hasError = true
            this.errorMessage = this.translations.previewOffer.footer.notEnoughOrderItems
          } else {
            this.hasError = true
            this.errorMessage = this.translations.errors.auth[error.response.data.message]
          }
        })
    })
  }

  private getPublishedDate (): string {
    const culture = this.displayEnglish ? Culture.English : Culture.French
    return dateFilter(this.model.publishedDate || new Date(), culture)
  }

  private get isReadyToBePublish() : boolean {
    return this.model.status === Status.ReadyToBePublished
  }

  private get isDraft() : boolean {
    return this.model.status === Status.Draft
  }

  private sendRequestForMoreJobOffers () {
    return loadWhile(this, this.translations.common.message.loading, () => RecruiterService.requestMoreAllowedJobOffers(this.company.id, CultureService.getCultureCode()).then(response => {
      notify(this, this.translations.common.companyCanPublish.message)
    }).catch(() => {
      notifyNegative(this, this.translations.common.companyCanPublish.error)
    }))
  }

  private navigateToEmployerPackagePage () {
    window.location.href = this.employerPackageUrl
  }
}
