






























import { Component, Prop, Vue } from 'vue-property-decorator'
import { CreateProfileTranslations } from '../CreateProfile.translations'
import { CreateProfileModel } from '../CreateProfile.model'
import { RequiredRule } from '@/generic/rules/Required'
import { IsURL } from '@/generic/rules/IsURL'
import { IsLinkedinURL } from '@/generic/rules/IsLinkedinURL'

@Component
export default class LinksSectionComponent extends Vue {
@Prop() private translations!: CreateProfileTranslations
@Prop() private value !: CreateProfileModel

 private rules: { [key: string]: Array<(value: any) => boolean | string> } = {
   validURL: [
     new IsURL().getValidator(this.translations.errors.common.urlNotFound)
   ],
   validLinkedin: [
     new IsLinkedinURL().getValidator(this.translations.errors.common.urlNotFound)
   ]
 };
}
