






































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { MissionRequestInformationForm } from './MissionRequestInformationForm'
import { RequiredRule } from '@/generic/rules/Required'
import { IsPhone } from '@/generic/rules/IsPhone'
import { MissionRequestInformationFormTranslations } from './translations/MissionRequestInformationForm.translations'

@Component
export default class MissionRequestInformationFormComponent extends Vue {
  @Prop() private translations!: MissionRequestInformationFormTranslations
  @Prop({ default: '' }) private customClass !: string
  private openDialog = false
  private result: MissionRequestInformationForm = {
    companyName: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: ''
  }
  private rules: { [key: string]: Array<(value: any) => boolean | string> } = {
    companyName: [new RequiredRule().getValidator(this.translations.requiredCompanyName)],
    firstName: [new RequiredRule().getValidator(this.translations.requiredFirstName)],
    lastName: [new RequiredRule().getValidator(this.translations.requiredLastName)],
    email: [new RequiredRule().getValidator(this.translations.requiredEmailAddress)],
    phone: [
      new IsPhone().getValidator(this.translations.invalidPhone),
      new RequiredRule().getValidator(this.translations.requiredPhoneNumber)
    ]
  }

  private hide () {
    this.openDialog = false
  }

  private onConfirm () {
    this.validate().then(isValid => {
      if (isValid) {
        this.$emit('confirmed', this.result)
        this.hide()
      }
    })
  }

  private validate (): Promise<boolean> {
    const form = this.$refs.form as any
    return form.validate()
  }

  public open (): void {
    this.openDialog = true
  }
}
