
















































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class AnimatedCircleWithArrowIconComponent extends Vue {
  @Prop() private animation!: boolean
  @Prop({ default: '' }) private size!: string
}
