import { Colleague } from '@/company/colleagues/Colleague.model'
import { Company } from '@/company/Company.model'
import { BASE_API } from '@/services/api-common'
import { AxiosPromise } from 'axios'
import Companies from '../models/companies/Companies.model'
import CompaniesFilter from '../models/companies/CompaniesFilter.model'
import { SelectOption } from '@/generic/models/SelectOption'

export default class AdminConsoleCompanyService {
  public static getCompanies(filter: CompaniesFilter): AxiosPromise<Companies> {
    let data = []
    data = filter.selectedEvent.split('-')
    filter.selectedEvent = data[0]
    filter.selectedEventType = data[1]
    return BASE_API.post('adminconsolecompany/getcompanies', filter)
  }

  public static getCompaniesByActivityAreas(activityareaIds: Array<number>): AxiosPromise<SelectOption[]> {
    return BASE_API.get('adminconsolecompany/getcompaniesbyactivityareas', {
      params: {
        activityareaIds: activityareaIds
      }
    })
  }

  public static exportCompanies(filter: CompaniesFilter): AxiosPromise<Companies> {
    return BASE_API.post('adminconsolecompany/exportcompanies', filter, { responseType: 'blob' })
  }

  public static inviteColleagues(id: number, culture: string, model: Colleague): AxiosPromise<any> {
    return BASE_API.post(`adminconsolecompany/invite/${id}`, model, {
      params: {
        culture: culture
      }
    })
  }

  public static reInviteColleagues(id: number, culture: string, email: string): AxiosPromise<any> {
    return BASE_API.post(
      `adminconsolecompany/reinvite/${id}`,
      {},
      {
        params: {
          culture,
          email
        }
      }
    )
  }

  public static disableRecruiter(
    companyId: number,
    id: number,
    deleteRecruiter: boolean
  ): AxiosPromise<any> {
    return BASE_API.delete(`adminconsolecompany/disable/${id}`, {
      params: {
        companyId: companyId,
        deleteRecruiter: deleteRecruiter
      }
    })
  }

  public static saveCompany(model: Company): AxiosPromise<Company> {
    return BASE_API.put(`adminconsolecompany/save/${model.id}`, model)
  }

  public static deleteCompany(
    companyId: number,
    password: string,
    culture: string
  ): AxiosPromise<string> {
    return BASE_API.delete('adminconsolecompany/deleteCompany', {
      params: {
        companyId: companyId,
        password: password,
        culture: culture
      }
    })
  }
}
