import { BASE_API } from './api-common'
import { AxiosPromise } from 'axios'
import CandidateInfo from '@/dashboard/employer/models/CandidateInfo.model'
import { JobApplicationModel } from '@/job-application/JobApplication.model'
import { JobOfferSummary } from '@/offer/summary/JobOfferSummary.model'
import { CultureService } from './CultureService'
import DetailResult from '@/candidate/candidate-details/DetailResult'
import { CandidateDetails } from '@/candidate/candidate-details/CandidateDetails.model'
import { SelectedIds } from '@/generic/models/MITMAvailabilityExpansion.models'
import { CandidatureType } from '@/candidate/Candidate.model'
import moment from 'moment'
import { dateTimeFilter } from '@/generic/filters/Date'
import qs from 'qs'
import { SelectOption } from '@/generic/models/SelectOption'
import { CandidateDownloadDto } from '@/candidate/CandidateDownload.model'
import MeetingPreferences from '@/admin/console/models/meetings/MeetingPreferences.model'
import { CandidateSimpleDetails } from '@/candidate/candidate-details/CandidateSimpleDetails.model'

export default class CandidateService {
  public static getCandidatesForEmployer (companyId : number) {
    return BASE_API.get('candidate', { params: { companyId: companyId } })
  }

  public static getAllCandidates (companyId : number, since : Date) : AxiosPromise<CandidateInfo> {
    return BASE_API.get('candidate/all', { params: {
      companyId: companyId,
      since: since
    } })
  }

  public static applyToJobOffer (model: JobApplicationModel) {
    return BASE_API.post('candidature/applyToJobOffer', { ...model, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone })
  }

  private static timezoneOffset () {
    let now = moment(new Date())

    if (now.isDST()) {
      return moment(new Date()).utcOffset() - 1
    }

    return moment(new Date()).utcOffset()
  }

  public static getDetails (candidateId: number, jobId: number) : AxiosPromise<DetailResult> {
    return BASE_API.get('candidate/details', { params: {
      candidateId: candidateId,
      jobId: jobId,
      culture: CultureService.getCultureCode()
    } })
  }

  public static getMyAppliedOffers (candidateId: number) : AxiosPromise<JobOfferSummary[]> {
    return BASE_API.get('candidate/appliedOffers', { params: { candidateId: candidateId, culture: CultureService.getCultureCode() } })
  }

  public static getSuggestedJobByCandidate (candidateId: number) : AxiosPromise<Array<JobOfferSummary>> {
    return BASE_API.get('candidate/getSuggestedJobByCandidate', { params: {
      candidateId: candidateId, culture: CultureService.getCultureCode()
    } })
  }

  public static getSimpleDetails (candidateId: number): AxiosPromise<CandidateDetails> {
    return BASE_API.get('candidate/simpleDetails', {
      params: {
        candidateId: candidateId,
        culture: CultureService.getCultureCode()
      }
    })
  }

  public static getSimpleDetailsForMeeting (candidateId: number): AxiosPromise<CandidateSimpleDetails> {
    return BASE_API.get('candidate/simpleDetails', {
      params: {
        candidateId: candidateId,
        culture: CultureService.getCultureCode()
      }
    })
  }

  public static getCandidate (candidateId: number): AxiosPromise<JobApplicationModel> {
    return BASE_API.get('candidate/getCandidate', {
      params: {
        candidateId: candidateId
      }
    })
  }

  public static getAvailabilities (candidateId: number, mission: number) : AxiosPromise<SelectedIds> {
    return BASE_API.get(`candidate/availabilities/${candidateId}`, {
      params: {
        mission: mission
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    })
  }

  public static getCandidateAvailabilityPreferences (candidateId : number): AxiosPromise<MeetingPreferences> {
    return BASE_API.get(`candidate/getcandidateavailabilitypreferences/${candidateId}`)
  }

  public static hideCandidate (candidateId: number) : AxiosPromise<number> {
    return BASE_API.put(`candidature/hide/${candidateId}`)
  }

  public static setCansultedByRecuiter (candidateId: number) : AxiosPromise<number> {
    return BASE_API.put(`candidature/setCansultedByRecuiter/${candidateId}`)
  }

  public static showCandidate (candidateId: number) : AxiosPromise<number> {
    return BASE_API.put(`candidature/show/${candidateId}`)
  }

  public static getDocuments (candidateId: number) : AxiosPromise<any> {
    return BASE_API.get(`candidate/documents/${candidateId}`, {
      responseType: 'blob'
    })
  }

  public static downloadCvs (type: CandidatureType, jobId: number, culture: string, showHidden: boolean, city: string, missions: Array<number>,
    countries: Array<number>, languagesProficiencyLevelFr: Array<number>, languagesProficiencyLevelEn: Array<number>, interestAreaGroup: number | null,
    industries: Array<string>, levelsOfStudy: Array<string>, candidatesIds: string) : AxiosPromise<any> {
    if (type === CandidatureType.Applicant) {
      return BASE_API.post('candidature/applicantCVPdf', new CandidateDownloadDto(
        jobId,
        culture,
        showHidden,
        city,
        missions,
        countries,
        languagesProficiencyLevelFr,
        languagesProficiencyLevelEn,
        interestAreaGroup,
        industries,
        levelsOfStudy,
        candidatesIds),
      {
        responseType: 'blob'
      })
    } else {
      return BASE_API.get('candidature/suggestionCVPdf', {
        params: {
          jobId: jobId,
          culture: culture,
          showHidden: showHidden,
          city: city,
          candidatesIds: candidatesIds
        },
        responseType: 'blob'
      })
    }
  }

  public static downloadCandidatesCsv (type: CandidatureType, jobId: number, culture: string, showHidden: boolean, city: string, missions: Array<number>,
    selectedCountries: Array<number>, languagesProficiencyLevelFr: Array<number>, languagesProficiencyLevelEn: Array<number>, interestAreaGroup: number | null,
    industries: Array<string>, levelsOfStudy: Array<string>, candidatesIds: string) : AxiosPromise<any> {
    if (type === CandidatureType.Applicant) {
      return BASE_API.post('candidature/applicantCsv', new CandidateDownloadDto(
        jobId,
        culture,
        showHidden,
        city,
        missions,
        selectedCountries,
        languagesProficiencyLevelFr,
        languagesProficiencyLevelEn,
        interestAreaGroup,
        industries,
        levelsOfStudy,
        candidatesIds),
      {
        responseType: 'blob'
      })
    } else {
      return BASE_API.get('candidature/suggestionCsv', {
        params: {
          jobId: jobId,
          culture: culture,
          showHidden: showHidden,
          city: city,
          candidatesIds: candidatesIds
        },
        responseType: 'blob'
      })
    }
  }
}
