import { AxiosPromise } from 'axios'
import { BASE_API } from './api-common'
import { SelectOption } from '@/generic/models/SelectOption'
import { ExistingMeeting } from '@/candidate/candidate-details/CandidateDetails.model'
import { CompanySchedule, AllCompaniesSchedule } from '@/dashboard/missions/RecruiterSchedule'
import { MeetingValueOption } from '@/generic/models/MeetingValueOption'
import { MeetingsListFilter } from '@/dashboard/meeting-list/models/MeetingsListFilter.model'
import MeetingsList from '@/dashboard/meeting-list/models/MeetingsList.models'
import MeetingFollowUpDetails from '@/dashboard/meeting-list/models/MeetingFollowUpDetails.model'
import AddFollowUp from '@/dashboard/meeting-list/models/AddFollowUp.model'
import MeetingsCount from '@/dashboard/meeting-list/models/MeetingsCount.models'

export class MeetingService {
  public static getExisting (missionId: number, candidateId: number, companyId: number): AxiosPromise<ExistingMeeting> {
    return BASE_API.get('meeting/existing', { params: { missionId: missionId, companyId: companyId, candidateId: candidateId } })
  }
  public static getAvailableRecruiters (missionId: number, companyId: number): AxiosPromise<SelectOption[]> {
    return BASE_API.get('meeting/availableRecruiter', { params: { missionId: missionId, companyId: companyId } })
  }

  public static getPossibleMeetings (missionId: number, candidateId: number, recruiterId: number, companyId: number): AxiosPromise<MeetingValueOption[]> {
    return BASE_API.get('meeting/possibleMeetings', { params: { missionId: missionId, companyId: companyId, candidateId: candidateId, recruiterId: recruiterId } })
  }

  public static setupMeeting (timeslotId: number, missionId: number, candidateId: number, recruiterId: number, companyId: number, startTime: Date, endTime: Date, jobId: number): AxiosPromise<SelectOption[]> {
    return BASE_API.post('meeting/setupMeeting', { timeslotId: timeslotId, missionId: missionId, companyId: companyId, candidateId: candidateId, recruiterId: recruiterId, localStartTime: startTime, localEndTime: endTime, jobId })
  }

  public static setupRecMeeting (companyId: number, candidateId: number, jobOfferId: number, date: Date, period: number, recruiterCandidateTimezoneDif: number): AxiosPromise<void> {
    return BASE_API.post('meeting/setupRecMeeting', { companyId, candidateId, jobOfferId, date, period, recruiterCandidateTimezoneDif })
  }

  public static rebookMeeting (timeslotId: number, missionId: number, candidateId: number, recruiterId: number, companyId: number, jobId: number): AxiosPromise<SelectOption[]> {
    return BASE_API.post('meeting/rebook', { timeslotId: timeslotId, missionId: missionId, companyId: companyId, candidateId: candidateId, recruiterId: recruiterId, jobId })
  }

  public static rebookRecMeeting (meetingId: number, companyId: number, jobOfferId: number, date: Date, period: number, recruiterCandidateTimezoneDif: number): AxiosPromise<void> {
    return BASE_API.post('meeting/rebookRecMeeting', { meetingId, companyId, jobOfferId, date, period, recruiterCandidateTimezoneDif })
  }

  public static getCompanyMeetings (missionId: number, companyId: number): AxiosPromise<CompanySchedule> {
    return BASE_API.get<CompanySchedule>('meeting/getCompanyMeetings', {
      params: {
        missionId,
        companyId
      }
    })
  }

  public static getAllCompaniesMeetings (missionId: number, adminKey: string): AxiosPromise<AllCompaniesSchedule> {
    return BASE_API.get<AllCompaniesSchedule>('meeting/getAllCompaniesMeetings', {
      params: {
        missionId,
        adminKey
      }
    })
  }

  public static removeMeeting (timeslotId: number, missionId: number, candidateId: number, recruiterId: number, companyId: number): AxiosPromise<void> {
    return BASE_API.post('meeting/remove', { timeslotId: timeslotId, missionId: missionId, companyId: companyId, candidateId: candidateId, recruiterId: recruiterId })
  }

  public static removeRecMeeting (meetingId: number, companyId: number, date: Date, period: number): AxiosPromise<void> {
    return BASE_API.post('meeting/removeRecMeeting', { meetingId, companyId, date, period })
  }

  public static getMeetings(filter: MeetingsListFilter): AxiosPromise<MeetingsList> {
    let data = []
    data = filter.selectedEvent.split('-')
    filter.selectedEvent = data[0]
    filter.selectedEventType = data[1]
    return BASE_API.post('meeting/getMeetings', filter)
  }

  public static getFollowUpMeetingCount (companyId : number): AxiosPromise<MeetingsCount> {
    return BASE_API.get('meeting/getMeetingAwaitingFollowUpCount', {
      params: {
        companyId: companyId
      }
    })
  }

  public static getMeetingFollowUpDetails (companyId : number, meetingId : number) : AxiosPromise<MeetingFollowUpDetails> {
    return BASE_API.get('meeting/getMeetingFollowUpDetails', {
      params: {
        companyId: companyId,
        meetingId: meetingId
      }
    })
  }

  public static saveFollowUp (followUp: AddFollowUp) : AxiosPromise<void> {
    return BASE_API.post('meeting/addFollowUp', followUp)
  }
}
