


























































































































































































































































































































































































































































































































































































import { Component, Prop, Vue, Mixins } from 'vue-property-decorator'
import { CandidateDetails } from './CandidateDetails.model'
import CandidateCardTranslations from '@/candidate/candidates-list/CandidateCard.translations'
import Candidate from '@/candidate/Candidate.model'
import CandidateService from '@/services/CandidateService'
import { FavoriteService } from '@/services/FavoriteService'
import GtmEmployerMixin from '@/gtm/GtmEmployer'
import { downloadFile, loadWhile } from '@/generic/helpers'
import { dateFilter } from '@/generic/filters/Date'
import { CultureService } from '@/services/CultureService'

@Component
export default class CandidateDetailsComponent extends Mixins(GtmEmployerMixin) {
  @Prop() private seeProfile!: boolean
  @Prop() private candidate!: Candidate
  @Prop() private showEmail!: boolean
  @Prop() private jobId!: number
  @Prop() private translations !: CandidateCardTranslations
  @Prop() private companyId!: number
  @Prop() private showMission!: boolean
  @Prop({ default: true }) showRating!: boolean
  @Prop({ default: false }) private isSuggested !: boolean

  private loaded = false
  private hasError = false
  private errorMessage = ''
  private rating = 0

  private toggleOptions = [
    { label: this.translations.candidateDetails.yes, value: 0 },
    { label: this.translations.candidateDetails.no, value: 1 }
  ]

  private immigrationOptions = this.translations.immigrationProcess
  private candidateInternal!: CandidateDetails

  beforeMount () {
    this.candidateInternal = {
      id: this.candidate.candidateId,
      // percentage: this.candidate.percentage,
      firstName: this.candidate.firstName,
      lastName: this.candidate.lastName,
      title: this.candidate.title,
      industry: this.candidate.industry,
      applied: true,
      new: this.candidate.new,
      consultedByRecruiter: this.candidate.consultedByRecruiter,
      liked: this.candidate.liked,
      email: this.candidate.email,
      urlSite: '',
      urlLinkedin: '',
      hasDocuments: false,
      skills: null,
      workExperiences: null,
      educationEntries: null,
      licenses: null,
      city: '',
      country: '',
      immigrationProcess: null,
      questionAnswer: null,
      missionsParticipating: [],
      created: this.candidate.created.toString().split('T')[0],
      files: [],
      languageProficiencyLevelDescriptionFr: '',
      languageProficiencyLevelDescriptionEn: '',
      showRecConvocation: false,
      availabilityPreferences: {
        days: []
      },
      selectedMeetingRecId: null,
      selectedRecMeetingPeriod: null,
      selectedRecMeetingDate: new Date(),
      candidateTimezone: ''
    }
    this.rating = this.candidate.rating
  }

  mounted () {
    CandidateService.getDetails(this.candidateInternal.id, this.jobId)
      .then((response) => {
        const result = response.data
        this.candidateInternal.urlLinkedin = result.urlLinkedin
        this.candidateInternal.urlSite = result.urlSite
        this.candidateInternal.hasDocuments = result.hasDocuments
        this.candidateInternal.country = result.country
        this.candidateInternal.city = result.city
        this.candidateInternal.immigrationProcess = result.immigrationProcess
        this.candidateInternal.skills = result.skills
        this.candidateInternal.workExperiences = result.workExperiences
        this.candidateInternal.educationEntries = result.educationEntries
        this.candidateInternal.licenses = result.licenses
        this.candidateInternal.questionAnswer = result.questionAnswer
        this.candidateInternal.missionsParticipating = result.missionsParticipating
        this.candidateInternal.applied = result.applied
        this.candidateInternal.files = result.files
        this.candidateInternal.languageProficiencyLevelDescriptionFr = result.languageProficiencyLevelDescriptionFr
        this.candidateInternal.languageProficiencyLevelDescriptionEn = result.languageProficiencyLevelDescriptionEn
        this.candidateInternal.showRecConvocation = result.showRecConvocation
        this.candidateInternal.availabilityPreferences = result.availabilityPreferences
        this.candidateInternal.selectedMeetingRecId = result.selectedMeetingRecId
        this.candidateInternal.selectedRecMeetingPeriod = result.selectedRecMeetingPeriod
        this.candidateInternal.selectedRecMeetingDate = result.selectedRecMeetingDate
        this.candidateInternal.candidateTimezone = result.candidateTimezone
      })
      .catch((error) => this.handleError(error))
      .finally(() => {
        this.loaded = true
      })
  }

  private handleError (error: any): void {
    this.hasError = true
    if (error.response.status === 404) {
      this.errorMessage = this.translations.candidateError.detailsNotFound
    }
  }

  // This is commented for now since the calculation is not done well, link to the PR will provide info on all commented code when its time to fix
  // https://dev.azure.com/patricioaraya/Talent%20Montr%C3%A9al/_git/Talent%20Montr%C3%A9al/pullrequest/47?_a=files
  // private progressionColor () {
  //  if (!this.candidateInternal || this.candidateInternal.percentage < 60) {
  //    return 'mitm-red'
  //  }
  //  if (this.candidateInternal.percentage >= 60 && this.candidateInternal.percentage < 100) {
  //    return 'mitm-orange'
  //  }
  //  if (this.candidateInternal.percentage === 100) {
  //    return 'mitm-dark-green'
  //  }
  // }

  private updateRating () {
    this.loaded = false
    FavoriteService.updateRating(this.jobId, this.candidate.candidateId, this.rating)
      .then(result => {
        this.rating = result.data.rating
        if (this.rating > 0) {
          this.onAddFavorite(this.isSuggested)
        }
      })
      .catch(_ => { this.rating = 0 })
      .finally(() => {
        this.candidate.rating = this.rating
        this.loaded = true
      })
  }

  private isColumnEmpty () {
    return this.candidateInternal.skills === null ||
        (this.candidateInternal.city === '' &&
        this.candidateInternal.country === '' &&
        this.candidateInternal.immigrationProcess === null &&
        this.candidateInternal.questionAnswer === null)
  }

  private onDownloadDocuments (id: number) {
    loadWhile(this, this.translations.common.message.loading, () => CandidateService.getDocuments(id)
      .then((response) => {
        downloadFile(response)
      }))
  }

  private formatDateMonthDayOnly (date: string): string {
    const culture = CultureService.getCulture()
    return dateFilter(date || new Date(), culture, { year: 'numeric', month: 'long' })
  }

  private splitLanguageProficiency (str: string) {
    return str.split(',', 1)[0]
  }
}
