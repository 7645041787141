



















import { Component, Prop, Vue } from 'vue-property-decorator'
import { MapRegion } from './MapRegion'

@Component
export default class InteractiveMapListComponent extends Vue {
  @Prop() private list!: MapRegion[]

  clicked (region: {}) {
    this.$emit('clicked', region)
  }

  hovered (region: {}) {
    this.$emit('hovered', region)
  }
}
