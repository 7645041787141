

































import { Component, Prop, Vue } from 'vue-property-decorator'

import AdminCandidatesAggregateStatsComponent from './console/AdminCandidatesAggregateStats.vue'
import AdminEmployersAggregateStatsComponent from './console/AdminEmployersAggregateStats.vue'
import AdminMissionAggregateStatsComponent from './console/AdminMissionAggregateStats.vue'

import { loadWhile } from '@/generic/helpers'

import AdminConsoleService from '../services/AdminConsoleService'
import AdminConsoleStatistics from '../models/console/PlatformStatistics.model'
import { AdminDashboardTranslations } from '../translations/AdminDashboard.translations'

@Component({
  components: {
    AdminCandidatesAggregateStatsComponent,
    AdminEmployersAggregateStatsComponent,
    AdminMissionAggregateStatsComponent
  }
})
export default class AdminConsoleComponent extends Vue {
  @Prop() private translations!: AdminDashboardTranslations

  private platformStatistics!: AdminConsoleStatistics

  data() {
    return {
      platformStatistics: null
    }
  }

  created() {
    this.loadPlatformStatistics()
  }

  private loadPlatformStatistics(): void {
    loadWhile(this, 'Loading', async () => {
      const response = await AdminConsoleService.getPlatformStatistics()
      this.platformStatistics = response.data
    })
  }
}
