







































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Company } from '@/company/Company.model'
import { CultureService } from '@/services/CultureService'
import { Culture } from '@/generic/models/Culture'

@Component
export default class AdminCompanyCardComponent extends Vue {
  @Prop() private company!: Company
  private primaryActivityArea = ''
  private secondaryActivityArea = ''
  private hasError = false
  private errorMessage: string = ''
  private href: string = ''

  mounted () {
    if (this.company.activityArea !== null) {
      const preferredCulture = CultureService.getCulture()
      if (preferredCulture === Culture.English) {
        this.primaryActivityArea = this.company.activityArea.primaryActivity?.en !== undefined
          ? this.company.activityArea.primaryActivity?.en
          : ''
        this.secondaryActivityArea = this.company.activityArea.secondaryActivity?.en !== undefined
          ? this.company.activityArea.secondaryActivity?.en
          : ''
      } else {
        this.primaryActivityArea = this.company.activityArea.primaryActivity?.fr !== undefined
          ? this.company.activityArea.primaryActivity?.fr
          : ''
        this.secondaryActivityArea = this.company.activityArea.secondaryActivity?.fr !== undefined
          ? this.company.activityArea.secondaryActivity?.fr
          : ''
      }
    }

    this.href = window.location.origin + this.getHref()
  }

  private handleError (error: any) {
    this.hasError = true
    if (error.response.status === 404) {
      this.errorMessage = 'notFound'
    } else if (error.response.status === 403) {
      this.errorMessage = 'forbidden'
    } else {
      this.errorMessage = 'unknown'
    }
  }

  private getHref () : string {
    let href: string

    if (CultureService.getCulture() === Culture.English) {
      href = `/en/site-admin/console-company-modification/${this.company.id}`
    } else {
      href = `/fr/admin/console-modification-entreprise/${this.company.id}`
    }

    return href
  }
}

