import { ChangeAccountModel } from '@/authentication/account/ChangeAccount.model'
import { InterestArea } from '@/generic/models/InterestArea.model'
import { CreateProfileModel } from '@/profile/CreateProfile.model'
import { BASE_API } from '@/services/api-common'
import { AxiosPromise } from 'axios'
import MeetingPreferences from '../models/meetings/MeetingPreferences.model'

export default class AdminConsoleCandidateService {
  public static getProfile (candidateId : number): AxiosPromise<CreateProfileModel> {
    return BASE_API.get(`adminconsolecandidate/getProfile/${candidateId}`)
  }

  public static getAccount (candidateId : number): AxiosPromise<ChangeAccountModel> {
    return BASE_API.get(`adminconsolecandidate/getaccount/${candidateId}`)
  }

  public static saveProfile (model: CreateProfileModel): AxiosPromise<CreateProfileModel> {
    return BASE_API.put(`adminconsolecandidate/save`, model)
  }

  public static reconfirmAccount (candidateId: number, culture : string) {
    return BASE_API.put('adminconsolecandidate/reconfirm', null, {
      params: {
        candidateId: candidateId,
        culture: culture
      }
    })
  }

  public static deleteAccount (candidateEmail: string): AxiosPromise<void> {
    return BASE_API.delete('adminconsolecandidate/delete', {
      params: {
        candidateEmail: candidateEmail
      }
    })
  }

  public static getInterestArea (culture : string): AxiosPromise<Array<InterestArea>> {
    return BASE_API.get('adminconsolecandidate/getinterestarea', {
      params: {
        culture: culture
      }
    })
  }

  public static getCandidateAvailabilityPreferences (candidateId : number, jobOfferId : number): AxiosPromise<MeetingPreferences> {
    return BASE_API.get(`adminconsolecandidate/getcandidateavailabilitypreferences/${candidateId}`, {
      params: {
        jobOfferId: jobOfferId
      }
    })
  }
}
