import { ITranslatable } from '@/generic/models/ITranslatable'
import { Filter } from '../Filter'

export default class CompaniesFilter {
    pageNumber: number
    pageSize: number
    totalCount: number
    name: string
    numberOfEmployees: number
    activityArea: string
    socialLink: string
    address: string
    filterValue: Filter
    crmId: string
    culture: string
    jobId: number | null
    recruiterEmailAddress: string
    selectedEvent: string
    selectedEventType: string
    selectedActivityArea: number
    codeCnp: number

    constructor () {
      this.pageNumber = 1
      this.pageSize = 20
      this.totalCount = 0
      this.name = ''
      this.numberOfEmployees = 0
      this.activityArea = ''
      this.socialLink = ''
      this.address = ''
      this.filterValue = 0
      this.crmId = ''
      this.culture = ''
      this.jobId = 0
      this.recruiterEmailAddress = ''
      this.selectedEvent = ''
      this.selectedEventType = ''
      this.selectedActivityArea = 0
      this.codeCnp = 0
    }
}
