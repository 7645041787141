










































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class RichTextEditorComponent extends Vue {
  @Prop() private value !: string
  @Prop({ default: 1 }) private minHeight!: number
  @Prop() private customToolbar !: string
  @Prop() private buttonDefinitions !: string

  private get definitions () {
    if (this.buttonDefinitions) {
      return JSON.parse(this.buttonDefinitions)
    } else {
      return {
        linkMedia: {
          icon: 'attach_file',
          handler: this.createMediaLink
        }
      }
    }
  }

  private createLink (url: string) {
    (this.$refs.editor as any).runCmd('createLink', url, true)
  }

  private createMediaLink () {
    this.$emit('media-callback', this)
  }

  private get toolbar () {
    if (this.customToolbar) {
      return JSON.parse(this.customToolbar)
    } else {
      return [
        [ 'bold', 'italic', 'underline' ],
        [ 'hr', 'link', 'linkMedia' ],
        [
          {
            label: this.$q.lang.editor.fontSize,
            icon: this.$q.iconSet.editor.fontSize,
            fixedLabel: true,
            fixedIcon: true,
            list: 'no-icons',
            options: [
              'size-1',
              'size-2',
              'size-3',
              'size-4',
              'size-5',
              'size-6',
              'size-7'
            ]
          },
          'fontColor',
          'removeFormat'
        ],
        [ 'unordered', 'ordered', 'undo', 'redo' ]
      ]
    }
  }

  private colors = [
    '#004A7C',
    '#da1b66',
    '#004a7c',
    '#7a2f6f',
    '#00b7ce',
    '#000000',
    '#ffffff'
  ]

  private onInput (event: any) {
    this.$emit('input', event)
    this.$emit('dispatch', event)
  }

  private changeColor (color : string) {
    const edit = this.$refs.editor as any
    edit.caret.restore()
    edit.runCmd('foreColor', color)
  }
}
