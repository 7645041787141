






































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { SelectOption } from '@/generic/models/SelectOption'
import { MeetingValueOption } from '@/generic/models/MeetingValueOption'
import { MeetingService } from '@/services/MeetingService'
import MissionService from '@/services/Mission.service'
import { fullURL, notifyNegative } from '@/generic/helpers'
import { dateFilter, dateTimeFilter, hourFilter } from '@/generic/filters/Date'
import CandidateCardTranslations from '@/candidate/candidates-list/CandidateCard.translations'
import MITMPromptComponent from '@/generic/components/MITMPrompt.vue'
import { DialogResult } from '@/generic/models/DialogResult'
import { CultureService } from '../../../services/CultureService'

@Component
export default class MeetingDetailsComponent extends Vue {
  @Prop() missionId!: number;
  @Prop() candidateId!: number;
  @Prop() jobId!: number;
  @Prop() translations!: CandidateCardTranslations;
  @Prop() companyId!: number;

  private loaded = false;
  private possibleRecruiters: SelectOption[] = [];
  private selectedRecruiter: SelectOption | null = null;
  private dataPossibleMeetings: MeetingValueOption[] = []
  private possibleMeetings: SelectOption[] | null = null;
  private selectedMeeting: SelectOption | null = null;
  private loading = false;
  private submitLoading = false;
  private missionName = '';
  private editMode = false;
  private isVirtual: boolean = false

  private timeOptions = {
    hour: 'numeric',
    minute: 'numeric',
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
  }

  mounted () {
    Promise.all([
      MissionService.getMissionInfo(this.missionId).then(
        result => {
          this.missionName = result.data.name
          this.isVirtual = result.data.type === 1
        }
      ),
      MeetingService.getAvailableRecruiters(
        this.missionId,
        this.companyId
      ).then(result => (this.possibleRecruiters = result.data))
    ])
      .then(() =>
        MeetingService.getExisting(
          this.missionId,
          this.candidateId,
          this.companyId
        ).then(result => {
          if (result.data) {
            this.recruiterSelected(result.data.selectedRecruiter)
            this.selectedMeeting = this.mapDate(result.data.selectedMeeting)
            this.editMode = true
          }
        })
      )
      .finally(() => {
        this.loaded = true
      })
  }

  private recruiterSelected (value: SelectOption) {
    this.possibleMeetings = null
    this.selectedRecruiter = value
    this.selectedMeeting = null
    if (this.selectedRecruiter) {
      this.loading = true
      MeetingService.getPossibleMeetings(
        this.missionId,
        this.candidateId,
        this.selectedRecruiter ? (this.selectedRecruiter.value as number) : 0,
        this.companyId
      )
        .then(result => {
          this.dataPossibleMeetings = result.data
          this.possibleMeetings = this.mapDateArray(result.data)
        })
        .finally(() => (this.loading = false))
    }
  }

  private submit () {
    if (this.selectedMeeting && this.selectedRecruiter) {
      if (this.editMode) {
        this.submitLoading = true
        MeetingService.rebookMeeting(
          this.selectedMeeting.value as number,
          this.missionId,
          this.candidateId,
          this.selectedRecruiter.value as number,
          this.companyId,
          this.jobId
        ).then(() => {
          this.redirect()
        }).catch(() => {
          notifyNegative(this, this.translations.candidateDetails.booking.error.rebook)
        }).finally(() => {
          this.submitLoading = false
        })
      } else {
        this.submitLoading = true
        const selected = this.dataPossibleMeetings.find(d => d.value === ((this.selectedMeeting as any).value as number))

        MeetingService.setupMeeting(
          this.selectedMeeting.value as number,
          this.missionId,
          this.candidateId,
          this.selectedRecruiter.value as number,
          this.companyId,
          dateTimeFilter((selected as any).startTime),
          dateTimeFilter((selected as any).endTime),
          this.jobId
        ).then(() => {
          this.redirect()
        }).catch(() => {
          notifyNegative(this, this.translations.candidateDetails.booking.error.rebook)
        }).finally(() => {
          this.submitLoading = false
        })
      }
    }
  }
  private cancelMeeting (result: DialogResult) {
    if (result === DialogResult.Ok) {
      MeetingService.getExisting(
        this.missionId,
        this.candidateId,
        this.companyId
      ).then(result => {
        MeetingService.removeMeeting(
        result.data.selectedMeeting.value as number,
        this.missionId,
        this.candidateId,
        result.data.selectedRecruiter.value as number,
        this.companyId
        )
      }
      ).finally(() => {
        this.redirect()
      })
    }
  }

  private cancelConfirmation () {
    (this.$refs.confirmPrompt as MITMPromptComponent).open()
  }

  private redirect () {
    const url = fullURL()
    url.searchParams.append('op', `${this.candidateId}`)
    window.location.href = url.href
  }

  private mapDate (toMap: MeetingValueOption) {
    let culture = CultureService.getCulture()
    let label = ''
    if (this.isVirtual) {
      label = dateFilter(toMap.startTime.replace(/ /g, 'T'), culture) + ' : ' + hourFilter(toMap.startTime.replace(/ /g, 'T'), culture, this.timeOptions) + ' - ' + hourFilter(toMap.endTime.replace(/ /g, 'T'), culture, this.timeOptions)
    } else {
      label = dateFilter(toMap.startTime.replace(/ /g, 'T')) + ' : ' + hourFilter(toMap.startTime.replace(/ /g, 'T')) + ' - ' + hourFilter(toMap.endTime.replace(/ /g, 'T'))
    }

    return {
      label: label,
      value: toMap.value
    }
  }

  private mapDateArray (toMap: MeetingValueOption[]) {
    return toMap.map(tm => this.mapDate(tm))
  }
}
