import MissionCompanyModel from '@/dashboard/missions/Mission.model'
import { InterestArea } from '@/generic/models/InterestArea.model'
import { EditOffer } from '@/offer/edit/EditOffer.model'
import { BASE_API } from '@/services/api-common'
import { CultureService } from '@/services/CultureService'
import { AxiosPromise } from 'axios'

export default class AdminConsoleJobOfferService {
  public static deleteOffer (offerId: number): AxiosPromise<void> {
    return BASE_API.delete(`adminconsolejoboffer/deleteOffer/${offerId}`)
  }

  public static editOffer (offerId: number, model: EditOffer, isDraftEditing: boolean): AxiosPromise<any> {
    return BASE_API.put(`adminconsolejoboffer/updateoffer/${offerId}`, model, {
      params: {
        isDraftEditing: isDraftEditing
      }
    })
  }

  public static activeMissionCompany (companyId: number): AxiosPromise<Array<MissionCompanyModel>> {
    return BASE_API.get('adminconsolejoboffer/companyActive',
      {
        params: {
          companyId: companyId,
          cultureCode: CultureService.getCultureCode()
        }
      })
  }

  public static getInterestArea (culture : string): AxiosPromise<Array<InterestArea>> {
    return BASE_API.get('adminconsolejoboffer/getinterestarea', {
      params: {
        culture: culture
      }
    })
  }
}
