


































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { CreateProfileTranslations } from '../CreateProfile.translations'
import { CreateProfileModel } from '../CreateProfile.model'
import { FileType } from '@/generic/models/FileType'
import { FileInfo } from '@/generic/models/File.model'
import { filter } from 'cypress/types/bluebird'
import CandidateService from '../../services/CandidateService'
import { downloadFile, loadWhile } from '@/generic/helpers'
import { MinimumRule } from '@/generic/rules/Minimum'
import { SpecificValueRule } from '@/generic/rules/SpecificValue'

@Component
export default class FileListComponent extends Vue {
  @Prop() private translations!: CreateProfileTranslations
  @Prop() private value !: Array<FileInfo>
  @Prop() private candidateId!: number
  @Prop() private maxNbFiles!: number
  @Prop() private isNewProfile!: boolean

  private fileSizeLimit = 5242880; // 5 MB
  private totalFileLimit = 15728640; // 15 MB
  private acceptedType = 'application/pdf'
  private displayFileTooBigMessage = false
  private displayWrongFormatMessage = false
  private displayTotalSizeTooBig = false
  private isDragDrop = false
  private isFileChanged = this.isNewProfile

  private rules: { [key: string]: Array<(value: any) => boolean | string> } = {
    required: [
      new SpecificValueRule(this.maxNbFiles).getValidator(this.translations.profileCreate.cv.fileInput.required)
    ]
  };

  private dragOverHandler (event: DragEvent): void {
    event.preventDefault()
  }

  dropHandler (event: DragEvent): void {
    event.preventDefault()
    this.isDragDrop = false
    if (!this.hasMaxNbFiles && event.dataTransfer) {
      this.onNewFile(event.dataTransfer.files)
    }
  }

  private get fileInput (): HTMLInputElement {
    return this.$refs.fileInput as HTMLInputElement
  }

  private get hasFiles () : boolean {
    return this.value && this.value.length > 0
  }

  private get isDownloadButtonVisible () : boolean {
    return !this.isFileChanged && this.hasFiles
  }

  private get hasMaxNbFiles () : boolean {
    return this.value && this.value.length >= this.maxNbFiles
  }

  private chooseFile (): void {
    this.fileInput.click()
  }

  private onChange (): void {
    this.onNewFile(this.fileInput.files && this.fileInput.files)
    this.fileInput.value = ''
  }

  private onNewFile (files: FileList|null): void {
    this.onFileChosen()
    if (files) {
      let totalSize = this.value.reduce((previous, current) => previous + current.size, 0)
      for (var x = 0; x < files.length; x++) {
        let file = files[x]
        totalSize += file.size
        if (totalSize > this.totalFileLimit) {
          this.displayTotalSizeTooBig = true
        }
        if (this.hasSameName(file)) {
          continue
        }
        if (!this.hasValidSize(file)) {
          this.onBigFileChosen()
          return
        } else if (!this.hasValidType(file)) {
          this.onInvalidFileChosen()
          return
        } else {
          this.onValidFileChosen(file)
        }
      }
    }
  }

  private onFileChosen (): void {
    this.displayFileTooBigMessage = false
    this.displayWrongFormatMessage = false
    this.displayTotalSizeTooBig = false
  }

  private hasValidSize (file: File): boolean {
    return file.size < this.fileSizeLimit
  }

  private hasValidType (file: File): boolean {
    return file.type === this.acceptedType
  }

  private hasSameName (newFile: File): boolean {
    return this.value.find((file) => file.fileName === newFile.name) !== undefined
  }

  private onValidFileChosen (file: File): void {
    this.updateValue(file)
  }

  private onBigFileChosen (): void {
    this.displayFileTooBigMessage = true
  }

  private onInvalidFileChosen (): void {
    this.displayWrongFormatMessage = true
  }

  private updateValue (file : File): void {
    const reader = new FileReader()
    reader.addEventListener('loadend', () => {
      const document: FileInfo = {
        id: 0,
        fileContent: (reader.result as string).split(',')[1],
        fileType: this.mimeTypeToFileType(file.type),
        fileName: file.name,
        size: file.size
      }
      this.value.push(document)
      this.isFileChanged = true
    })
    reader.readAsDataURL(file)
  }

  private mimeTypeToFileType (mimeType: string): FileType {
    if (mimeType === 'application/pdf') {
      return FileType.Pdf
    }
    return FileType.Unknown
  }

  private deleteFile (index: number) {
    let fileInfo = this.value.splice(index, 1)
    let totalSize = this.value.reduce((previous, current) => previous + current.size, 0)
    if (totalSize < this.totalFileLimit) {
      this.displayTotalSizeTooBig = false
    }
    fileInfo.forEach((file) => {
      if (file.id > 0) {
        this.$emit('file-deleted', file)
      }
    })
    this.$forceUpdate()
  }

  private downloadAll (candidateId: number) {
    loadWhile(this, this.translations.common.message.loading, () => CandidateService.getDocuments(candidateId)
      .then((response) => {
        downloadFile(response)
      }))
  }
}
