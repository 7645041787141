

























































































































































































































































































































































































































































































































import { Component, Prop, Vue, Mixins, Model } from 'vue-property-decorator'
import moment from 'moment'
import { EditOffer } from './EditOffer.model'
import { EditOfferTranslations } from './EditOffer.translations'
import { OfferService } from '@/services/OfferService'
import { loadWhile, notify, notifyNegative } from '@/generic/helpers'
import { OfferPrerequisiteData } from '../prerequisite/models/OfferPrerequisiteData.model'
import { OfferPrerequisiteDto } from '@/offer/prerequisite/models/OfferPrerequisiteDto.model'
import { SelectOption } from '@/generic/models/SelectOption'
import { CompanyOrder } from '@/company/models/CompanyOrder'
import { CompanyService } from '@/services/CompanyService'
import { PublicationLanguage } from '@/generic/models/PublicationLanguage'
import { QuestionType } from '@/generic/models/QuestionType'
import { Status } from '@/generic/models/Status'
import { dateFilter } from '@/generic/filters/Date'
import { Culture } from '@/generic/models/Culture'
import { CultureService } from '@/services/CultureService'
import MissionService from '@/services/Mission.service'
import MissionCompanyModel from '@/dashboard/missions/Mission.model'
import GtmEmployerMixin from '@/gtm/GtmEmployer'
import SkillHelpComponent from '../skill/SkillHelp.vue'
import { ProductType } from '@/dashboard/employer-package/models/ProductType'
import { DurationOptionType } from '@/dashboard/employer-package/models/DurationOptionType'
import { WorkInfoData } from '@/offer/jobOfferWorkInfos/WorkInfoData.model'
import { RequirementData } from '@/offer/jobOfferRequirement/RequirementData.model'

@Component({
  components: {
    'mitm-skill-help': SkillHelpComponent
  }
})
export default class EditOfferComponent extends Mixins(GtmEmployerMixin) {
  @Prop() private translations!: EditOfferTranslations
  @Prop() private prerequisiteData!: OfferPrerequisiteData
  @Prop() private workInfoData!: WorkInfoData
  @Prop() private requirementData!: RequirementData
  @Prop() private experienceData!: Array<SelectOption>
  @Prop() private companyId!: number
  @Prop() private offerId!: number
  @Prop({ default: false }) private createOffer!: boolean
  @Prop({ default: false }) private userCanPublish!: boolean
  @Prop({ default: false }) private companyCanPublish!: boolean
  @Prop() private previewUrl!: string
  @Prop() private availableOrders!: CompanyOrder[]
  @Prop() private employerPackageUrl!: string
  @Prop({ default: false }) private displayExtendOfferButton!: boolean;
  @Prop() private hasAnyAvailableOrder!: boolean

  private selectedSoftSkills : Array<SelectOption> = []
  private maxSoftSkillsItem : number = 2
  private minSoftSkillsItem : number = 1
  private optionIsDisabled : Array<boolean> = [false, false]
  private missions: MissionCompanyModel[] = []
  private currentStep = 1
  model = new EditOffer()
  private disableNextStepButton = false
  private disableStep2Button = false
  private disableStep3Button = false
  private hasError = false
  private errorMessage = ''
  private loaded = false
  private selectedMissionIds: number[] = []
  private availableOrdersOptions: SelectOption[] = []
  private availableOrdersOptionsShort: SelectOption[] = []
  private optionLabel: string = ''
  private softSkillsList : Array<SelectOption> = [
    { value: '', label: this.translations.offer.skillsAndTitle.choose },
    { value: 'Autonomy', label: this.translations.offer.skillsAndTitle.autonomy },
    { value: 'AbilityToFederate', label: this.translations.offer.skillsAndTitle.abilityToFederate },
    { value: 'Adaptability', label: this.translations.offer.skillsAndTitle.adaptability },
    { value: 'CommunicationSkills', label: this.translations.offer.skillsAndTitle.communicationSkills },
    { value: 'Curiosity', label: this.translations.offer.skillsAndTitle.curiosity },
    { value: 'DecisionMakingSkills', label: this.translations.offer.skillsAndTitle.decisionMakingSkills },
    { value: 'OrganizationalSkills', label: this.translations.offer.skillsAndTitle.organizationalSkills },
    { value: 'Perseverance', label: this.translations.offer.skillsAndTitle.perseverance },
    { value: 'Reactivity', label: this.translations.offer.skillsAndTitle.reactivity },
    { value: 'Rigor', label: this.translations.offer.skillsAndTitle.rigor },
    { value: 'StepBackSkills', label: this.translations.offer.skillsAndTitle.stepBackSkills },
    { value: 'StrengthOfProposal', label: this.translations.offer.skillsAndTitle.strengthOfProposal },
    { value: 'StressManagement', label: this.translations.offer.skillsAndTitle.stressManagement },
    { value: 'Teamwork', label: this.translations.offer.skillsAndTitle.teamwork }
  ]

  expiryDate (selectedId: number, availableOptions: SelectOption[]) {
    if (this.model.offer.expiryDate) {
      return this.model.offer.expiryDate
    }

    let date = new Date()
    date.setMonth(date.getMonth() + 1)

    let index = 0
    availableOptions.forEach(o => {
      if (o.value !== -900) {
        if (o.value === selectedId) {
          let orderMission = this.availableOrders.find(o => o.orderItemId === selectedId &&
          o.productType === ProductType.Mission)
          if (orderMission !== undefined && this.availableOrders[index].expiry != null) {
            date = new Date(moment(this.availableOrders[index].expiry).format())
          }
        }
      }
      index++
    })

    return date
  }

  isMission(selectedId: number) {
    let isMission = false
    let selectedOption = this.availableOrdersOptions.find(o => o.value === selectedId)
    if (selectedOption !== null) {
      let order = this.availableOrders.find(o => o.orderItemId === selectedOption?.value)
      if (order?.productType === ProductType.Mission) {
        isMission = true
      }
    }
    return isMission
  }

  isCohort(selectedId: number) {
    let isCohort = false
    let selectedOption = this.availableOrdersOptions.find(o => o.value === selectedId)
    if (selectedOption !== null) {
      let order = this.availableOrders.find(o => o.orderItemId === selectedOption?.value)
      if (order?.productType === ProductType.Cohort) {
        isCohort = true
      }
    }
    return isCohort
  }

  private get canExtend () {
    return this.userCanPublish && this.companyCanPublish && this.isPublished && this.displayExtendOfferButton
  }

  private get canPublish () {
    return this.userCanPublish && this.companyCanPublish && this.isReadyToBePublish && !this.createOffer
  }

  private get canRepublish () {
    return this.userCanPublish && this.companyCanPublish && this.isExpired
  }

  private get expiryMessage () {
    if (this.isExpired) {
      return this.translations.offer.edit.expired
    }
    if (this.isClosed) {
      return this.translations.offer.edit.closed
    }
    if (this.isPublished) {
      return this.translations.offer.edit.expires
    }
    const nowRegex = /{{now}}/gi
    return this.translations.offer.edit.prompt.replace(nowRegex, dateFilter(new Date()))
  }

  private get isDraft () : boolean {
    return this.model.offer.status === Status.Draft
  }

  private get isExpired () : boolean {
    return this.model.offer.status === Status.Expired
  }

  private get isPublished () : boolean {
    return this.model.offer.status === Status.Published
  }

  private get isReadyToBePublish () : boolean {
    return this.model.offer.status === Status.ReadyToBePublished
  }

  private get isReviewed () : boolean {
    return this.model.offer.status === Status.Reviewed
  }

  private get isAwaitingReview () : boolean {
    return this.model.offer.status === Status.AwaitingReview
  }

  private get isClosed () : boolean {
    return this.model.offer.status === Status.Closed ||
           this.model.offer.status === Status.Expired
  }

  private handleError (error: any) {
    this.hasError = true
    if (error.response.status === 400 && error.response.data.message === 'accountNotConfirmed') {
      this.errorMessage = this.translations.errors.auth.accountNotConfirmed
    } else if (error.response.status === 404) {
      this.errorMessage = this.translations.errors.company.notFound
    } else if (error.response.status === 403) {
      this.errorMessage = this.translations.errors.common.forbidden
    } else if (error.response.status === 409) {
      this.errorMessage = this.translations.errors.offer.cannotRepublish
    } else {
      this.errorMessage = this.translations.errors.offer[error.response.data.message]
    }
  }

  private mounted() {
    this.availableOrders.forEach(o => {
      if (o.productType === ProductType.JobOffer) {
        let name = o.name.split('(')[0]
        let duration = o.productDurationType === DurationOptionType.oneMonth
          ? this.translations.orderDurations.one
          : o.productDurationType === DurationOptionType.oneYear
            ? this.translations.orderDurations.two
            : ''
        o.name = name + ' (' + duration + ')'
      } else {
        o.name = o.name.split('(')[0]
      }
    })
    this.createAvailableOrdersOptionsShort(this.availableOrders)
    this.createAvailableOrdersOptions(this.availableOrders)

    loadWhile(this, this.translations.common.message.loading, () => Promise.all([CompanyService.getCompany(this.companyId)
      .then(response => {
        this.model.company.id = this.companyId
        this.model.company.companyInfo = response.data.basicInfo
        if (!this.createOffer) {
          OfferService.getOffer(this.offerId, CultureService.getCultureCode())
            .then(response => {
              this.model.offer = response.data
              if (this.model.offer.asset == null) {
                this.model.offer.asset = {
                  en: '',
                  fr: ''
                }
              }
              if (this.model.offer.taskDescription == null) {
                this.model.offer.taskDescription = {
                  en: '',
                  fr: ''
                }
              }
              // this.adjustPrerequisites(this.model.offer.prerequisites)
              this.ajustMissionSelect()
              this.adjustJobOfferWorkInfos()
              this.adjustJobOfferRequirements()
              for (let i = 0; i < this.model.offer.softSkills.length; i++) {
                let skill = this.softSkillsList.find(s => s.value === this.model.offer.softSkills[i])
                if (skill !== null) {
                  this.selectedSoftSkills.push(skill as SelectOption)
                  this.optionIsDisabled[i] = true
                }
              }
              if (this.model.offer.softSkills.length === 0) {
                this.selectedSoftSkills.push(this.softSkillsList[0])
                this.model.offer.softSkills.push(this.softSkillsList[0].value.toString())
              }
            })
            .catch(error => {
              this.handleError(error)
            })
            .finally(() => {
              this.loaded = true
            })
        } else {
          this.onCreateJobOffer()
          this.selectedSoftSkills.push(this.softSkillsList[0])
          this.model.offer.softSkills.push(this.softSkillsList[0].value.toString())
          this.model.offer.addressInfo.useCompanyAddress = true
          this.loaded = true
        }
      }), MissionService.activeMissionCompany(this.companyId)
      .then(response => {
        this.missions = response.data
        this.ajustMissionSelect()
      })])
      .catch(error => {
        if (error.response.data.message === undefined) {
          this.handleError(error)
        } else {
          this.hasError = true
          this.errorMessage = this.translations.errors.company[error.response.data.message]
        }
      }))
  }

  private adjustPrerequisites(prerequisites: OfferPrerequisiteDto) {
    // Not use anymore
    while (prerequisites.questions.length < 2) {
      prerequisites.questions.push({
        responseType: QuestionType.Scale,
        questionEn: '',
        questionFr: '',
        viewAllCandidates: true,
        viewOnlyResponse: 0
      })
    }
  }

  private adjustJobOfferWorkInfos() {
    if (this.model.offer.jobOfferWorkInfos == null) {
      this.model.offer.jobOfferWorkInfos = {
        hoursPerWeek: undefined,
        salaryIsAnnually: 1,
        unionize: 0,
        hourlywage: undefined,
        annualSalary: undefined,
        offerID: 0
      }
    }
  }

  private adjustJobOfferRequirements() {
    if (this.model.offer.jobOfferRequirements == null) {
      this.model.offer.jobOfferRequirements = {
        yearExperience: undefined,
        levelOfStudy: undefined,
        studyDiploma: undefined,
        otherLanguage: undefined,
        languageProficiencyFrId: undefined,
        languageProficiencyEnId: undefined,
        languageProficiencyOtherId: undefined,
        offerID: 0
      }
    }
  }

  private saveDraftClick (): void {
    this.saveDraft(false, this.isDraft).catch(() => {})
  }

  private gtmSave () {
    let confirmed = this.$userContext ? this.$userContext.confirmed : false
    if (this.isPublished) {
      this.onSaveOffer(this.translations.offer.button.save, confirmed)
    } else {
      this.onSaveDraft(this.translations.offer.button.saveAsDraft, confirmed)
    }
  }

  private saveDraft (noRedirect: boolean, isDraftEditing: boolean): Promise<any> {
    this.hasError = false
    return this.validate().then(isValid => {
      if (isValid) {
        if (!this.HasValidInterestAreas()) {
          notifyNegative(this, this.translations.common.interestArea.error.empty)
        } else {
          this.model.offer.missionIds = []
          if (this.isOrderSelectedTypeOfMission()) {
            this.setMissionId()
          }
          if (this.createOffer) {
            return loadWhile(this, this.translations.common.message.loading, () => OfferService.createOffer(this.model, isDraftEditing).then(response => {
              notify(this, this.translations.offer.edit.created)
              this.gtmSave()
              const culture = CultureService.getCulture()
              let redirect = response.data.redirect.fr
              if (culture === Culture.English) {
                redirect = response.data.redirect.en
              }
              if (noRedirect) {
                const parts = redirect.split('/')
                const id = parts[parts.length - 1]
                return `${this.previewUrl}/${id}`
              } else {
                window.location.href = redirect
              }
            }).catch((error) => {
              if (error.response.data.message === undefined) {
                this.handleError(error)
              } else {
                this.hasError = true
                this.errorMessage = this.translations.errors.offer[error.response.data.message]
              }
            }))
          } else {
            return loadWhile(this, this.translations.common.message.loading, () => OfferService.editOffer(this.model.offer.id, this.model, isDraftEditing)
              .then(response => {
                this.gtmSave()
                notify(this, this.translations.offer.edit.saved)
              }).then(() => {
                return CompanyService.getAvailableOrdersForJob(CultureService.getCultureCode(), this.model.offer).then(responseOrders => {
                  this.createAvailableOrdersOptions(responseOrders.data)
                })
              })
              .catch((error) => {
                if (error.response.data.message === undefined) {
                  this.handleError(error)
                } else {
                  this.hasError = true
                  this.errorMessage = this.translations.errors.offer[error.response.data.message]
                }
              }))
          }
        }
      } else {
        return Promise.reject(isValid)
      }
    })
  }

  private setMissionId () {
    let selectedOrder = this.availableOrders.find(o => o.orderItemId === this.model.offer.orderItemId)
    if (selectedOrder !== undefined) {
      let mission = this.missions.find(m => m.crmId === selectedOrder?.orderItemGuid)
      if (mission !== undefined) {
        this.model.offer.missionIds.push(mission.id)
      }
    }
  }

  private isOrderSelectedTypeOfMission () : boolean {
    return this.availableOrders.find(o => o.orderItemId === this.model.offer.orderItemId)?.productType === ProductType.Mission
  }

  sendToReview () : void {
    this.hasError = false
    if (this.isDraft) {
      this.saveDraft(true, false).then(() => {
        if (!this.hasError) {
          loadWhile(this, this.translations.common.message.loading, () => {
            return OfferService.updateStatus(this.model.offer.id, Status.AwaitingReview).then(() => {
              window.location.reload()
              notify(this, this.translations.offer.edit.sendToReview)
            }).catch((error) => {
              if (error.response.data.message === undefined) {
                this.handleError(error)
              } else {
                this.hasError = true
                this.errorMessage = this.translations.errors.auth[error.response.data.message]
              }
            })
          })
        }
      })
    }
  }

  private publish (): void {
    this.hasError = false
    this.validate().then(isValid => {
      if (!this.HasValidInterestAreas()) {
        notifyNegative(this, this.translations.common.interestArea.error.empty)
      } else {
        if (isValid && !this.isPublished) {
          // Make sure to set mission id for mission order
          this.model.offer.missionIds = []
          if (this.isOrderSelectedTypeOfMission()) {
            this.setMissionId()
          }
          loadWhile(this, this.translations.common.message.loading, () => {
            return OfferService.editOffer(this.model.offer.id, this.model, false).then((response) => {
              return OfferService.publishOffer(this.model.offer.id).then((response) => {
                this.onPublishJob(response.data.offerCount)
                window.location.reload()
                notify(this, this.translations.offer.edit.published)
              }).catch((error) => {
                if (error.response.data.message === undefined) {
                  this.handleError(error)
                } else if (error.response.status === 400 && error.response.data.message === 'notEnoughOrderItems') {
                  this.hasError = true
                  this.errorMessage = this.translations.errors.offer.notEnoughOrderItems
                } else {
                  this.hasError = true
                  this.errorMessage = this.translations.errors.auth[error.response.data.message]
                  if (this.errorMessage === undefined) {
                    this.errorMessage = this.translations.errors.offer[error.response.data.message]
                  }
                }
              })
            }).catch((error) => {
              if (error.response.data.message === undefined) {
                this.handleError(error)
              } else {
                this.hasError = true
                this.errorMessage = this.translations.errors.offer[error.response.data.message]
              }
            })
          })
        }
      }
    })
  }

  private republish (): void {
    this.hasError = false
    this.validate().then(isValid => {
      if (!this.HasValidInterestAreas()) {
        notifyNegative(this, this.translations.common.interestArea.error.empty)
      } else {
        if (isValid && this.isExpired) {
          loadWhile(this, this.translations.common.message.loading, () => {
            return OfferService.editOffer(this.model.offer.id, this.model, false).then((response) => {
              return OfferService.republishOffer(this.model.offer.id, CultureService.getCultureCode())
                .then(response => {
                  notify(this, this.translations.offer.edit.expires)
                  this.model.offer.status = response.data.status
                  this.model.offer.expiryDate = response.data.expiryDate
                  this.model.offer.publishedDate = response.data.publishedDate
                })
                .catch((error) => {
                  if (error.response.data.message === undefined) {
                    this.handleError(error)
                  } else if (error.response.status === 400 && error.response.data.message === 'notEnoughOrderItems') {
                    this.hasError = true
                    this.errorMessage = this.translations.errors.offer.notEnoughOrderItems
                  } else {
                    this.hasError = true
                    this.errorMessage = this.translations.errors.auth[error.response.data.message]
                  }
                })
            })
              .catch((error) => {
                if (error.response.data.message === undefined) {
                  this.handleError(error)
                } else {
                  this.hasError = true
                  this.errorMessage = this.translations.errors.offer[error.response.data.message]
                }
              })
          })
        }
      }
    })
  }

  private extend (): void {
    this.hasError = false
    this.validate().then(isValid => {
      if (isValid && this.isPublished) {
        loadWhile(this, this.translations.common.message.loading, () => {
          return OfferService.extendOffer(this.model.offer.id).then(response => {
            this.model.offer.expiryDate = response.data.expiryDate
          }).catch((error) => {
            if (error.response.data.message === undefined) {
              this.handleError(error)
            } else {
              this.hasError = true
              this.errorMessage = this.translations.errors.auth[error.response.data.message]
            }
          })
        })
      }
    })
  }

  private changeLanguage (language : PublicationLanguage): void {
    this.model.offer.language.language = language
  }

  private HasValidInterestAreas (): boolean {
    return this.model.offer.interestAreaGroups !== null && this.model.offer.interestAreaGroups.length > 0
  }

  private validate (): Promise<boolean> {
    const form = this.$refs['editOfferForm'] as any
    return form.validate()
  }

  private preview (): void {
    this.saveDraft(true, false).then((redirect) => {
      if (!this.hasError) {
        this.onPreviewOffer(this.translations.offer.button.previewThisOffer, this.$userContext ? this.$userContext.confirmed : false)
        setTimeout(() => {
          window.location.href = redirect || this.previewUrl
        }, 1000)
      }
    })
  }

  private ajustMissionSelect () {
    this.selectedMissionIds = this.model.offer.missionIds
  }

  private navigateToEmployerPackagePage () {
    window.location.href = this.employerPackageUrl
  }

  private createAvailableOrdersOptions (availableOrders: CompanyOrder[]) {
    this.availableOrdersOptions = []
    this.extractLabel(availableOrders, this.availableOrdersOptions)
  }

  private createAvailableOrdersOptionsShort (availableOrders: CompanyOrder[]) {
    this.availableOrdersOptionsShort = []
    this.extractLabel(availableOrders, this.availableOrdersOptionsShort, true)
  }

  private extractLabel(availableOrders: CompanyOrder[], extractedArray: SelectOption[], isShort = false) {
    availableOrders.forEach(o => {
      let label = o.isCrmOrder ? o.name
        : o.productType === ProductType.Mission ? this.getAvailableOrdersMissionName(o, isShort)
          : this.getAvailableOrdersName(o, isShort)
      let option: SelectOption = {
        label: label,
        value: o.orderItemId
      }
      return extractedArray.push(option)
    })
  }

  private getAvailableOrdersName(order: CompanyOrder, isShort: boolean): string {
    if (isShort) {
      return order.name
    } else {
      return order.name + ' - ' +
      order.remainingQuantity + ' ' +
      this.translations.offer.select.remainingOrders + ' - ' + this.getFormattedDate(order.expiry)
    }
  }

  private getAvailableOrdersMissionName(order: CompanyOrder, isShort: boolean): string {
    if (isShort) {
      return order.name.split('(')[0]
    } else {
      return order.name.split('(')[0] + ' - ' + this.getFormattedDate(order.expiry)
    }
  }

  private getFormattedDate(d: Date): string {
    let date = new Date(d)
    return [
      date.getFullYear(),
      ('0' + (date.getMonth() + 1)).slice(-2),
      ('0' + date.getDate()).slice(-2)
    ].join('-')
  }

  displayOfferName(selectedId: number, availableOptions: SelectOption[]) {
    let index = 0
    availableOptions.forEach(o => {
      if (o.value !== -900) {
        if (o.value === selectedId) {
          let splittedOption = o.label.split('-')
          let order = this.availableOrders.find(o => o.orderItemId === selectedId && o.productType === ProductType.Mission) || this.availableOrders.find(o => o.orderItemId === selectedId && o.productType === ProductType.Cohort)
          if (order !== undefined) {
            this.optionLabel = splittedOption[0] + '- ' + this.translations.offer.order.cohort.options.part.one + ' ' +
            moment(order.candidateStartDate).format('DD/MM/YYYY') + ' ' + this.translations.offer.order.cohort.options.part.two +
            ' ' + moment(order.candidateEndDate).format('DD/MM/YYYY')
          } else {
            this.optionLabel = splittedOption[0] + '- ' + splittedOption[1] + '- ' +
            this.translations.offer.select.expiry + ' ' + splittedOption[2] + '-' + splittedOption[3] + '-' + splittedOption[4]
          }
        } else if (selectedId === null) {
          this.optionLabel = ''
        }
      }
      index++
    })
    return this.optionLabel
  }

  private addSoftSkillRow() {
    if (this.selectedSoftSkills.length === this.minSoftSkillsItem) {
      this.selectedSoftSkills.push(this.softSkillsList[0])
      this.model.offer.softSkills.push(this.softSkillsList[0].value.toString())
    }
  }

  private deleteSoftSkillRow() {
    if (this.selectedSoftSkills.length === this.maxSoftSkillsItem) {
      this.optionIsDisabled[1] = false
      this.selectedSoftSkills.pop()
      this.model.offer.softSkills.pop()
    }
  }

  private setModelSoftSkill(index: number) {
    let selectInput = (document.getElementsByClassName('soft-skill-select')[index] as HTMLSelectElement)
    let selectedOption = this.softSkillsList.find(s => s.value === selectInput.value) as SelectOption
    this.selectedSoftSkills[index] = selectedOption
    this.model.offer.softSkills[index] = selectedOption.value.toString()
    this.disableFirstOption(index)
  }

  private disableFirstOption(index: number) {
    if (!this.optionIsDisabled[index]) {
      let selectInput = (document.getElementsByClassName('soft-skill-select')[index] as HTMLSelectElement)
      selectInput.options[0].disabled = true
      this.optionIsDisabled[index] = true
    }
  }
}
