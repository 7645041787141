
























import { Component, Prop, Vue } from 'vue-property-decorator'
import MissionCompanyModel from './Mission.model'
import MissionsListTranslations from './MissionList.translations'
import { loadWhile } from '@/generic/helpers'
import MissionService from '@/services/Mission.service'
import { CultureService } from '@/services/CultureService'

@Component
export default class MissionsListComponent extends Vue {
  @Prop() translations !: MissionsListTranslations
  @Prop() companyId !: number
  private models !: MissionCompanyModel[]
  private loaded = false
  private hasError = false
  private errorMessage = ''

  mounted () {
    loadWhile(this, this.translations.loading, () => this.loadMissionList())
  }

  loadMissionList () : Promise<MissionCompanyModel[] | void> {
    return MissionService.availableMissions(this.companyId, CultureService.getCultureCode())
      .then((result) => { this.models = result.data })
      .catch((e) => { this.handleError(e) })
      .finally(() => { this.loaded = true })
  }

  private handleError (error: any) {
    this.hasError = true
    if (error.response.status === 404) {
      this.errorMessage = this.translations.errors.unknown
    }
  }
}
