export enum Status {
    Draft,
    Published,
    Closed,
    Expired,
    Archived,
    AwaitingReview, // TODO
    Reviewed, // TODO
    ReadyToBePublished // TODO
}
