























import { Component, Prop, Vue } from 'vue-property-decorator'
import { MeetingService } from '@/services/MeetingService'
import { loadWhile } from '@/generic/helpers'
import { CompanySchedule } from './RecruiterSchedule'
import { MissionScheduleTranslations } from './MissionSchedule.translations'

@Component
export default class CompanyMissionScheduleComponent extends Vue {
  @Prop() private missionId!: number;
  @Prop() private companyId!: number;
  @Prop() private translations!: MissionScheduleTranslations;
  private loaded = false;
  private companySchedules: CompanySchedule | null = null;

  private mounted (): void {
    loadWhile(this, this.translations.common.message.loading, () => MeetingService.getCompanyMeetings(this.missionId, this.companyId).then((response) => {
      this.companySchedules = response.data
    })).finally(() => {
      this.loaded = true
    })
  }

  private reloadMeetings () : void {
    loadWhile(this, this.translations.common.message.loading, () => MeetingService.getCompanyMeetings(this.missionId, this.companyId).then((response) => {
      this.companySchedules = response.data
    }))
  }

  private print (): void {
    window.print()
  }
}
