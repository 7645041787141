












import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class YoutubeVideoComponent extends Vue {
  @Prop() videoId!: string;

  private get fullUrlVideo (): string {
    return 'https://www.youtube.com/embed/' + this.videoId
  }
}
