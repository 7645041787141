




















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class MITMCopyrightComponent extends Vue {
  private showCopyright: boolean = false
}
