




















import { Component, Prop, Vue } from 'vue-property-decorator'
@Component
export default class BulletPointBlockComponent extends Vue {
  @Prop() private title!: string
  @Prop() private primaryColor!: string
  @Prop() private secondaryColor!: string

  private get cssVariables () {
    return {
      '--primary-color': this.primaryColor,
      '--secondary-color': this.secondaryColor
    }
  }
}
