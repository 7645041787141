
















































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { CultureService } from '@/services/CultureService'
import { Culture } from '@/generic/models/Culture'
import MissionService from '@/services/Mission.service'
import { dateFilter, hourFilter } from '@/generic/filters/Date'
import {
  TimeSlot,
  TimeSlotsPerDay
} from '@/job-application/JobApplication.model'
import { MITMAvailabilityExpansionTranslations } from '../translations/MITMAvailabilityExpansion.translations'
import { SelectedTimeSlotsByDate } from '../models/MITMAvailabilityExpansion.models'

@Component
export default class MITMAvailabilityExpansionComponent extends Vue {
  @Prop() translations!: MITMAvailabilityExpansionTranslations;
  @Prop() id!: number;
  @Prop() selected!: Array<number>;
  @Prop() missionId!: number;
  @Prop() missionLink!: string;
  @Prop({ default: false }) isOpen!: boolean;

  private isVirtual!: boolean
  private timeSlots!: TimeSlot[];
  private loading = true;
  private timeSlotsPerDay!: TimeSlotsPerDay[]
  private pagination = { rowsPerPage: 0 };
  private localSelected: SelectedTimeSlotsByDate = {};
  private visibleColumns = ['startTime', 'endTime', 'location'];
  private columns = [{}] ;

  private localValue = !!this.selected;

  mounted () {
    this.isVirtualMission(this.missionId)

    if (this.localValue) {
      this.fetchTimeSlotsPerDay().then(result => {
        this.timeSlotsPerDay = result
        this.timeSlotsPerDay.forEach(tspd => {
          this.localSelected[tspd.date as string] = tspd.timeSlots.filter(ts =>
            this.selected.some(s => s === ts.id)
          )
        })
        this.emitSelection()
        this.loading = false
      })
    }
  }

  select (index: string, event: any) {
    if (event.added) {
      Vue.set(this.localSelected, index, [
        ...this.localSelected[index],
        ...event.rows
      ])
    } else {
      if (event.keys) {
        Vue.set(
          this.localSelected,
          index,
          this.localSelected[index].filter((s: TimeSlot) => {
            return !event.keys.some((k: number) => k === s.id)
          })
        )
      }
    }
    // i dont see how to do otherwise...
    this.$forceUpdate()
    this.emitSelection()
  }

  public selectAll () {
    this.setLocalValue(true)
  }

  private flattenSelected () {
    const flatten = []
    for (const key in this.localSelected) {
      if (this.localSelected[key]) {
        flatten.push(...this.localSelected[key].flatMap((s: TimeSlot) => s.id))
      }
    }
    return flatten
  }

  private isVirtualMission (missionId: number) {
    MissionService.isVirtual(missionId).then(result => {
      this.isVirtual = result.data
      this.columns = [
        {
          name: 'id',
          label: '',
          field: (row: TimeSlot) => row.id,
          format: (val: any) => `${val}`
        },
        {
          name: 'startTime',
          required: true,
          label: this.translations.starts,
          field: (row: any) => row.startTime,
          format: (val: any) => this.isVirtual ? hourFilter(val, CultureService.getCulture(), {
            hour: 'numeric',
            minute: 'numeric',
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
          }) : hourFilter(val),
          style: 'width: 45px'
        },
        {
          name: 'endTime',
          label: this.translations.ends,
          field: (row: any) => row.endTime,
          format: (val: any) => this.isVirtual ? hourFilter(val, CultureService.getCulture(), {
            hour: 'numeric',
            minute: 'numeric',
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
          }) : hourFilter(val),
          style: 'width: 45px'
        },
        {
          name: 'location',
          label: this.translations.location,
          field: this.setLocation
        }
      ]
    })
  }

  private setLocation (timeslot : TimeSlot) : string {
    if (this.isVirtual) {
      return CultureService.getCulture() === Culture.English ? 'Virtual' : 'Virtuel'
    } else {
      return (timeslot.location || '') +
        (timeslot.location && timeslot.cityName ? ', ' : '') +
        (timeslot.cityName || '')
    }
  }

  private setLocalValue (value: boolean) {
    if (value && !this.timeSlotsPerDay) {
      this.loading = true
      this.fetchTimeSlotsPerDay()
        .then(timeSlotsPerDay => {
          this.timeSlotsPerDay = timeSlotsPerDay
          this.timeSlotsPerDay.forEach(element => {
            this.localSelected[element.date as string] = element.timeSlots
          })
          this.emitSelection()
        })
        .finally(() => {
          this.loading = false
        })
    } else if (value && Object.keys(this.localSelected).length === 0) {
      this.timeSlotsPerDay.forEach(tspd => {
        this.localSelected[tspd.date as string] = tspd.timeSlots
      })
      this.emitSelection()
    }
    this.localValue = value
    this.$emit('input', value)
  }

  emitSelection () {
    this.$emit('selection', { id: this.id, selected: this.flattenSelected() })
  }

  public show () {
    (this.$refs.innerQexpansionItem as any).show()
  }

  private fetchTimeSlotsPerDay (): Promise<Array<TimeSlotsPerDay>> {
    return MissionService.getMissionTimeSlots(this.missionId).then(result => {
      const timeSlots = result.data
      const map = new Map<number, Array<TimeSlot>>()
      timeSlots.forEach(ts => {
        if (this.isVirtual) {
          ts.startTime = this.changeTimeZone(ts.startTime)
          ts.endTime = this.changeTimeZone(ts.endTime)
        } else {
          ts.startTime = new Date(ts.startTime + 'Z')
          ts.endTime = new Date(ts.endTime + 'Z')
        }
        const date = new Date(ts.startTime.toDateString()).valueOf()
        if (map.has(date)) {
          const dateTimeSlots = map.get(date)
          if (dateTimeSlots) {
            dateTimeSlots.push(ts)
          }
        } else {
          map.set(date, [ts])
        }
      })
      const timeSlotsPerDay = []
      for (let date of map.keys()) {
        timeSlotsPerDay.push({
          date: new Date(date),
          city: '',
          timeSlots: map.get(date) || []
        })
      }
      return timeSlotsPerDay
    })
  }

  private changeTimeZone (date: Date): Date {
    return new Date(new Date(date + 'Z').toLocaleString('en', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }))
  }
}
