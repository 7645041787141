


















































import { Component, Vue, Prop } from 'vue-property-decorator'
import { MissionMoreInfoBlockTranslations } from './translations/MissionMoreInfoBlock.translations'
import MissionService from '@/services/Mission.service'
import { DialogResult } from '@/generic/models/DialogResult'
import MITMPromptComponent from '@/generic/components/MITMPrompt.vue'
import { loadWhile, notify } from '@/generic/helpers'
import MissionRequestInformationFormComponent from './MissionRequestInformationForm.vue'
import { MissionRequestInformationForm } from './MissionRequestInformationForm'

@Component
export default class MissionMoreInfoBlockComponent extends Vue {
  @Prop() private translations!: MissionMoreInfoBlockTranslations
  @Prop() private missionId!: string
  @Prop() private displayButton!: boolean
  @Prop() private blockText!: string
  @Prop() private additionalText!: string
  @Prop() private useRequestInformationForm!: boolean
  private customClass: string = 'U--z-index--6003 U--absolute'
  private animation = false

  private onMouseOver () {
    this.animation = true
  }

  private onMouseLeave () {
    this.animation = false
  }

  private requestInformation (): void {
    (this.$refs.confirmPrompt as MITMPromptComponent | MissionRequestInformationFormComponent).open()
  }

  private onConfirmation (result: DialogResult | MissionRequestInformationForm): void {
    if (this.useRequestInformationForm) {
      loadWhile(this, this.translations.common.message.loading, () => MissionService.guestRequestInformation(this.missionId, result as MissionRequestInformationForm).then(() => {
        notify(this, this.translations.missionMoreInfoBlock.successful)
      }))
    } else {
      if (result === DialogResult.Ok) {
        loadWhile(this, this.translations.common.message.loading, () => MissionService.requestInformation(this.missionId).then(() => {
          notify(this, this.translations.missionMoreInfoBlock.successful)
        }))
      }
    }
  }
}
