













































































import { Component, Prop, Vue, Mixins } from 'vue-property-decorator'
import { NewsletterForm } from './NewsletterForm'
import { RequiredRule } from '@/generic/rules/Required'
import { SpecificValueRule } from '@/generic/rules/SpecificValue'
import { NewsletterTranslations } from '@/generic/translations/Newsletter.translations'
import { CultureService } from '../services/CultureService'
import { NewsletterService } from '../services/Newsletter.service'
import { notify } from '../generic/helpers'
import GtmNewsletterMixin from '@/gtm/GtmNewsletter'
import { IsEmail } from '@/generic/rules/IsEmail'

@Component
export default class NewsletterFormComponent extends Mixins(GtmNewsletterMixin) {
  @Prop() private translations!: NewsletterTranslations
  @Prop() private privacyPolicyLink!: string
  @Prop({ default: true }) private showCancel: boolean = false

  private result: NewsletterForm = {
    firstName: '',
    lastName: '',
    email: '',
    consent: false,
    culture: CultureService.getCultureCode(),
    origin: location.hostname
  }

  private rules: { [key: string]: Array<(value: any) => boolean | string> } = {
    firstName: [new RequiredRule().getValidator(this.translations.firstNameRequired)],
    lastName: [new RequiredRule().getValidator(this.translations.lastNameRequired)],
    email: [new RequiredRule().getValidator(this.translations.emailAddressRequired), new IsEmail().getValidator(this.translations.emailAddressRequired)],
    consent: [new SpecificValueRule(true).getValidator(this.translations.consentRequired)]
  }

  private onCancel () {
    this.$emit('cancel')
  }

  private onConfirm () {
    this.validate().then(isValid => {
      if (isValid) {
        NewsletterService.subscribe(this.result)
          .then((response) => {
            this.OnSubscribeNewsletterFromPopup()
            notify(this, this.translations.success)
            this.$emit('confirmed', this.result)
          })
      }
    })
  }

  private validate (): Promise<boolean> {
    const form = this.$refs.form as any
    return form.validate()
  }

  private onPrivacyPolicyClick (e: Event): void {
    e.stopPropagation()
  }
}
