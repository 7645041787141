

















































































































































































































































































































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { getUrlParameter, loadWhile, fullURL, debounce, notify, downloadFile, downloadFileCsv, notifyNegative } from '@/generic/helpers'
import Candidate from '../Candidate.model'
import CandidateCardTranslations from './CandidateCard.translations'
import { CultureService } from '@/services/CultureService'
import { Culture } from '@/generic/models/Culture'
import { SelectOption } from '@/generic/models/SelectOption'
import CandidateService from '@/services/CandidateService'
import { CompanyService } from '@/services/CompanyService'
import { CandidatureType } from '@/candidate/Candidate.model'
import { AxiosError } from 'axios'
import { generateCandidates } from '@/generic/data'
import { Status } from '@/generic/models/Status'
import { LanguageProficiency } from '@/generic/models/LanguageProficiency.model'
import { CandidateLanguageProficiency } from '@/generic/models/CandidateLanguageProficiency.model'
import { getActivityAreasMifi } from '@/generic/activity-area-helper'
import { TagsHelper } from '@/search/TagsHelper.model'
import { filter } from 'cypress/types/bluebird'

@Component
export default class CandidatesListComponent extends Vue {
  @Prop() jobTitle !: string
  @Prop() translations !: CandidateCardTranslations
  @Prop() jobId !: number
  @Prop() companyId !: number
  @Prop() maximumNumberOfSuggestedProfiles !: number
  @Prop() private countriesList !: Array<SelectOption>
  @Prop() private languageProficienciesListFr !: Array<LanguageProficiency>
  @Prop() private languageProficienciesListEn !: Array<LanguageProficiency>
  private selectedCountries: SelectOption[] = []
  private selectedIndustries: SelectOption[] = []
  private selectedLevelsOfStudy: SelectOption[] = []
  private languagesProficiencyLevelFr: LanguageProficiency[] = []
  private languagesProficiencyLevelEn: LanguageProficiency[] = []
  private filters: TagsHelper[] = []
  private candidates: Array<Candidate> = []
  private totalNumberOfCandidates: number = 0
  private suggestedCandidates: Array<Candidate> = []
  private totalNumberOfSuggestedCandidates: number = 0
  private lastSuggestedCandidates: Array<Candidate> = []
  private hasError = false
  private tab = '0'
  private cultureCode !: string
  private pageNumberApplicant = 1
  private pageNumberSuggested = 1
  private applicantCount : number = 0
  private suggestedProfileCount : number = 0
  private candidateNewApplicant = 0
  private candidateNewSuggested = 0
  private loaded = false
  private searchTerms = ''
  private interestAreaGroup : number | null = null
  private showHidden: boolean = false
  private sort: string = 'date'
  private openedProfile = ''
  private loadingList: boolean = false
  private loadingFiles: boolean = false
  private loadingFilesCSV: boolean = false
  private hideSuggestions: boolean = false
  private numberOfHidedProfile: number = 0
  private selectedSuggestedCandidatesIds: string = ''
  private selectedApplicantsCandidatesIds: string = ''
  private animation = false
  private industries: Array<SelectOption> = getActivityAreasMifi(this.translations.industries)
  private pageSizeDropDown : Array<SelectOption> = [
    { value: 20, label: '20' },
    { value: 30, label: '30' },
    { value: 50, label: '50' },
    { value: 100, label: '100' }
  ]
  private maxPage: number = 5
  private selectedPageSize = this.pageSizeDropDown[0].value as number
  private cnpSearchTimer: any
  private regularSearchTimer: any

  private mounted (): void {
    this.tab = getUrlParameter('tab') || '0'
    this.openedProfile = getUrlParameter('op') || ''
    const preferredCulture = CultureService.getCulture()
    if (preferredCulture === Culture.English) {
      this.cultureCode = 'en-US'
    } else {
      this.cultureCode = 'fr-CA'
    }
    this.load()
  }

  private get missionFilterOutClass () {
    return 'animated ' + (this.$q.screen.lt.md ? 'slideOutRight' : 'fadeOut')
  }

  private removeLastCharacter (text: string) {
    return text.slice(0, -1)
  }

  private onTabChange (tab: string) {
    this.tab = tab
    // Reset filters
    this.pageNumberApplicant = 1
    this.pageNumberSuggested = 1
    this.selectedPageSize = this.pageSizeDropDown[0].value as number
    this.languagesProficiencyLevelFr = []
    this.languagesProficiencyLevelEn = []
    this.interestAreaGroup = null
    this.searchTerms = ''
    this.selectedCountries = []
    this.selectedIndustries = []
    this.filters = []

    const url = fullURL()
    url.searchParams.set('tab', `${tab}`)
    window.history.pushState(null, '', url.href)

    if (this.tab === '0') {
      this.loadApplicantCandidates(1, (a: boolean | void) => {})
    } else if (this.tab === '1') {
      this.loadSuggestedCandidates(1, (a: boolean | void) => {})
    }
  }

  private load (): void {
    this.loaded = false
    CompanyService.getNumberCandidateForEachJob(this.jobId)
      .then(result => {
        this.applicantCount = result.data
      })

    CompanyService.getNumberSuggestedCandidateForEachJob(this.jobId)
      .then(result => {
        this.suggestedProfileCount = result.data
      })
    CompanyService.getNumberOfNewCandidateForEachJob(this.jobId)
      .then(result => {
        this.candidateNewApplicant = result.data
      })
    CompanyService.getNumberOfNewSuggestedCandidateForEachJob(this.jobId).then(result => {
      this.candidateNewSuggested = result.data
    })

    if (this.tab === '0') {
      this.loadApplicantCandidates(1, (a: boolean | void) => {})
    } else if (this.tab === '1') {
      this.loadSuggestedCandidates(1, (a: boolean | void) => {})
    }

    this.loaded = true
  }

  private get showExportTools () {
    let currentTab = 0
    if (typeof this.tab === 'string') {
      currentTab = parseInt(this.tab)
    } else {
      currentTab = this.tab
    }

    if (currentTab === 0) {
      return this.candidates.length > 0
    } else {
      return this.suggestedCandidates.length > 0 && !this.hideSuggestions
    }
  }

  private downloadCV (tab: number) {
    let culture = CultureService.getCultureCode()
    if (typeof tab === 'string') {
      tab = parseInt(tab)
    }

    let promise = null
    if (tab === 0) {
      this.selectedApplicantsCandidatesIds = ''
      this.candidates.forEach(candidate => { candidate.isSelectedForCVDownload ? this.selectedApplicantsCandidatesIds += candidate.candidateId + '|' : this.selectedApplicantsCandidatesIds += '' })
      promise = CandidateService.downloadCvs(
        CandidatureType.Applicant,
        this.jobId,
        culture,
        this.showHidden,
        this.searchTerms,
        [],
        this.selectedCountries !== null && this.selectedCountries.length > 0 ? this.selectedCountries.map((x) => x.value as number) : [],
        this.languagesProficiencyLevelFr != null && this.languagesProficiencyLevelFr.length > 0 ? this.languagesProficiencyLevelFr.map((x) => x.id as number) : [],
        this.languagesProficiencyLevelEn != null && this.languagesProficiencyLevelEn.length > 0 ? this.languagesProficiencyLevelEn.map((x) => x.id as number) : [],
        this.interestAreaGroup ? this.interestAreaGroup as number : 0,
        this.selectedIndustries !== null && this.selectedIndustries.length > 0 ? this.selectedIndustries.map((x) => x.value as string) : [],
        this.selectedLevelsOfStudy !== null && this.selectedLevelsOfStudy.length > 0 ? this.selectedLevelsOfStudy.map((x) => x.value as string) : [],
        this.selectedApplicantsCandidatesIds)
    } else {
      this.selectedSuggestedCandidatesIds = ''
      this.suggestedCandidates.forEach(candidate => { candidate.isSelectedForCVDownload ? this.selectedSuggestedCandidatesIds += candidate.candidateId + '|' : this.selectedSuggestedCandidatesIds += '' })
      promise = CandidateService.downloadCvs(
        CandidatureType.SuggestedProfile,
        this.jobId,
        culture,
        this.showHidden,
        this.searchTerms,
        [],
        [],
        [],
        [],
        0,
        [],
        [],
        this.selectedSuggestedCandidatesIds)
    }

    if (promise) {
      this.loadingFiles = true
      promise
        .then(response => {
          if (response.status === 204) {
            notify(this, this.translations.candidateCard.noCvAvailable)
          } else {
            downloadFile(response)
          }
        })
        .catch(() => {
          notify(this, this.translations.candidateCard.noCvAvailable)
        })
        .finally(() => {
          this.loadingFiles = false
        })
    }
  }

  private downloadDocumentsCsv (type: CandidatureType, jobId: number, culture: string, showHidden: boolean, city: string, mission: Array<number>,
    countryIds: Array<number>, languagesProficiencyLevelFr: Array<number>, languagesProficiencyLevelEn: Array<number>, interestAreaGroup: number | null,
    industries: Array<string>, levelsOfStudy: Array<string>, candidatesIds: string) {
    this.loadingFilesCSV = true
    CandidateService.downloadCandidatesCsv(
      type,
      jobId,
      culture,
      showHidden,
      city,
      mission,
      countryIds,
      languagesProficiencyLevelFr,
      languagesProficiencyLevelEn,
      interestAreaGroup,
      industries,
      levelsOfStudy,
      candidatesIds
    )
      .then(response => {
        if (response.status === 204) {
          notify(this, this.translations.candidateCard.noCvAvailable)
        } else {
          downloadFileCsv(response)
        }
      })
      .catch(() => {
        notify(this, this.translations.candidateCard.noCvAvailable)
      })
      // eslint-disable-next-line no-return-assign
      .finally(() => this.loadingFilesCSV = false)
  }

  private csvUrl (tab: number) {
    let culture = CultureService.getCultureCode()
    if (typeof tab === 'string') {
      tab = parseInt(tab)
    }

    if (tab === 0) {
      this.selectedApplicantsCandidatesIds = ''
      this.candidates.forEach(candidate => { candidate.isSelectedForCVDownload ? this.selectedApplicantsCandidatesIds += candidate.candidateId + '|' : this.selectedApplicantsCandidatesIds += '' })
      this.downloadDocumentsCsv(
        CandidatureType.Applicant,
        this.jobId,
        culture,
        this.showHidden,
        this.searchTerms,
        [],
        this.selectedCountries !== null && this.selectedCountries.length > 0 ? this.selectedCountries.map((x) => x.value as number) : [],
        this.languagesProficiencyLevelFr !== null && this.languagesProficiencyLevelFr.length > 0 ? this.languagesProficiencyLevelFr.map((x) => x.id as number) : [],
        this.languagesProficiencyLevelEn !== null && this.languagesProficiencyLevelEn.length > 0 ? this.languagesProficiencyLevelEn.map((x) => x.id as number) : [],
        this.interestAreaGroup ? this.interestAreaGroup as number : 0,
        this.selectedIndustries !== null && this.selectedIndustries.length > 0 ? this.selectedIndustries.map((x) => x.value as string) : [],
        this.selectedLevelsOfStudy !== null && this.selectedLevelsOfStudy.length > 0 ? this.selectedLevelsOfStudy.map((x) => x.value as string) : [],
        this.selectedApplicantsCandidatesIds)
    } else {
      this.selectedSuggestedCandidatesIds = ''
      this.suggestedCandidates.forEach(candidate => { candidate.isSelectedForCVDownload ? this.selectedSuggestedCandidatesIds += candidate.candidateId + '|' : this.selectedSuggestedCandidatesIds += '' })
      this.downloadDocumentsCsv(CandidatureType.SuggestedProfile,
        this.jobId,
        culture,
        this.showHidden,
        this.searchTerms,
        [],
        [],
        [],
        [],
        0,
        [],
        [],
        this.selectedSuggestedCandidatesIds)
    }
  }

  private loadSuggestedCandidates (index : number, done: Function): Promise<void> {
    return CompanyService.getSuggestedCandidatesByJob(this.jobId,
      this.cultureCode,
      this.pageNumberSuggested,
      this.selectedPageSize,
      this.searchTerms,
      [],
      this.selectedCountries !== null && this.selectedCountries.length > 0 ? this.selectedCountries.map((x) => x.value as number) : [],
      this.languagesProficiencyLevelFr !== null && this.languagesProficiencyLevelFr.length > 0 ? this.languagesProficiencyLevelFr.map((x) => x.id as number) : [],
      this.languagesProficiencyLevelEn !== null && this.languagesProficiencyLevelEn.length > 0 ? this.languagesProficiencyLevelEn.map((x) => x.id as number) : [],
      this.interestAreaGroup ? this.interestAreaGroup as number : 0,
      this.selectedIndustries !== null && this.selectedIndustries.length > 0 ? this.selectedIndustries.map((x) => x.value as string) : [],
      this.selectedLevelsOfStudy !== null && this.selectedLevelsOfStudy.length > 0 ? this.selectedLevelsOfStudy.map((x) => x.value as string) : [],
      this.sort).then(response => {
      // eslint-disable-next-line no-return-assign
      response.data.candidates.forEach((candidate) => candidate.isSelectedForCVDownload = false)
      this.suggestedCandidates = response.data.candidates
      this.totalNumberOfSuggestedCandidates = response.data.total
      this.refreshSuggestions()
      done()
    }).catch((error : AxiosError<Array<Candidate>>) => {
      if (error && error.response) {
        if (error.response.status === 401) {
          this.suggestedCandidates = generateCandidates(Math.min(this.suggestedProfileCount, 20))
          this.hideSuggestions = true
        } else {
          this.hasError = true
        }
      }
      done(true)
    })
  }

  private loadApplicantCandidates (index : number, done: Function): Promise<void> {
    return CompanyService.getApplicantCandidatesByJob(this.jobId,
      this.cultureCode,
      this.pageNumberApplicant,
      this.selectedPageSize,
      this.searchTerms,
      [],
      this.selectedCountries !== null && this.selectedCountries.length > 0 ? this.selectedCountries.map((x) => x.value as number) : [],
      this.languagesProficiencyLevelFr !== null && this.languagesProficiencyLevelFr.length > 0 ? this.languagesProficiencyLevelFr.map((x) => x.id as number) : [],
      this.languagesProficiencyLevelEn !== null && this.languagesProficiencyLevelEn.length > 0 ? this.languagesProficiencyLevelEn.map((x) => x.id as number) : [],
      this.interestAreaGroup ? this.interestAreaGroup as number : 0,
      this.selectedIndustries !== null && this.selectedIndustries.length > 0 ? this.selectedIndustries.map((x) => x.value as string) : [],
      this.selectedLevelsOfStudy !== null && this.selectedLevelsOfStudy.length > 0 ? this.selectedLevelsOfStudy.map((x) => x.value as string) : [],
      this.sort)
      .then(response => {
        // eslint-disable-next-line no-return-assign
        response.data.candidates.forEach((candidate) => candidate.isSelectedForCVDownload = false)
        this.candidates = response.data.candidates
        this.totalNumberOfCandidates = response.data.total
        this.refreshCandidates()
        done()
      })
      .catch(error => {
        if (error) {
          this.hasError = true
        }
        done(true)
      })
  }

  private requestMoreSuggestedProfiles () : Promise<any> {
    return CompanyService.requestMoreSuggestedProfiles(this.jobId, this.companyId, this.cultureCode)
      .then(response => {
        notify(this, this.translations.requestMoreSuggestedProfiles.responseOk)
      })
      .catch(() => {
        notifyNegative(this, this.translations.requestMoreSuggestedProfiles.responseKo)
      })
  }

  private onInterestAreaGroupChange (interestAreaGroupSearch: number): void {
    this.interestAreaGroup = interestAreaGroupSearch
    clearTimeout(this.cnpSearchTimer)
    this.cnpSearchTimer = setTimeout(() => {
      this.loadApplicantCandidates(1, (a: boolean | void) => {})
    }, 1500)
  }

  private onSearchTermsChange (newSearchTerms: string): void {
    this.searchTerms = newSearchTerms
    clearTimeout(this.regularSearchTimer)
    if (this.tab === '0') {
      this.regularSearchTimer = setTimeout(() => {
        this.loadApplicantCandidates(1, (a: boolean | void) => {})
      }, 1500)
    } else if (this.tab === '1') {
      this.regularSearchTimer = setTimeout(() => {
        this.loadSuggestedCandidates(1, (a: boolean | void) => {})
      }, 1500)
    }
  }

  private onMissionSelectedChange (missionsSelected: SelectOption[]) {
    this.filters = this.filters.filter((x) => x.filterType !== 'mission')
    if (missionsSelected !== null && missionsSelected.length > 0) {
      missionsSelected.forEach((x) => this.filters.push({ value: x.label, filterType: 'mission' }))
    }
    this.loadApplicantCandidates(1, (a: boolean | void) => {})
  }

  private onCountrySelectedChange (countriesSelected: SelectOption[]) {
    this.filters = this.filters.filter((x) => x.filterType !== 'country')
    if (countriesSelected !== null && countriesSelected.length > 0) {
      countriesSelected.forEach((x) => this.filters.push({ value: x.label, filterType: 'country' }))
    }
    this.loadApplicantCandidates(1, (a: boolean | void) => {})
  }

  private onIndustrySelectedChange (industriesSelected: SelectOption[]) {
    this.filters = this.filters.filter((x) => x.filterType !== 'industry')
    if (industriesSelected !== null && industriesSelected.length > 0) {
      industriesSelected.forEach((x) => this.filters.push({ value: x.label, filterType: 'industry' }))
    }
    this.loadApplicantCandidates(1, (a: boolean | void) => {})
  }

  private onLevelOfStudySelectedChange (levelsOfStudySelected: SelectOption[]) {
    this.filters = this.filters.filter((x) => x.filterType !== 'study')
    if (levelsOfStudySelected !== null && levelsOfStudySelected.length > 0) {
      levelsOfStudySelected.forEach((x) => this.filters.push({ value: x.label, filterType: 'study' }))
    }
    this.loadApplicantCandidates(1, (a: boolean | void) => {})
  }

  private onFrenchLevelChangedChange (frLevelSelected: LanguageProficiency[]) {
    this.filters = this.filters.filter((x) => x.filterType !== 'frLevel')
    if (frLevelSelected !== null && frLevelSelected.length > 0) {
      frLevelSelected.forEach((x) => this.filters.push({ value: x.label + ' (fr)', filterType: 'frLevel' }))
    }
    if (this.tab === '0') {
      this.loadApplicantCandidates(1, (a: boolean | void) => {})
    } else if (this.tab === '1') {
      this.loadSuggestedCandidates(1, (a: boolean | void) => {})
    }
  }

  private onEnglishLevelChangedChange (enLevelSelected: LanguageProficiency[]) {
    this.filters = this.filters.filter((x) => x.filterType !== 'enLevel')
    if (enLevelSelected !== null && enLevelSelected.length > 0) {
      enLevelSelected.forEach((x) => this.filters.push({ value: x.label + ' (en)', filterType: 'enLevel' }))
    }
    if (this.tab === '0') {
      this.loadApplicantCandidates(1, (a: boolean | void) => {})
    } else if (this.tab === '1') {
      this.loadSuggestedCandidates(1, (a: boolean | void) => {})
    }
  }

  private openDetails (candidateId: number, tab: string) {
    return (candidateId + '') === this.openedProfile && this.tab === tab
  }

  private refreshCandidates () {
    this.$forceUpdate()
  }

  private decreaseApplicantNumbers () {
    if (this.candidateNewApplicant > 0) {
      this.candidateNewApplicant--
    }
  }

  private decreaseSuggestedNumbers () {
    if (this.candidateNewSuggested > 0) {
      this.candidateNewSuggested--
    }
  }

  private refreshSuggestions () {
    this.numberOfHidedProfile = this.suggestedCandidates.filter(c => c.hidden).length
    this.$forceUpdate()
  }

  private onMouseOver () {
    this.animation = true
  }

  private onMouseLeave () {
    this.animation = false
  }

  private onChangePageSizeCandidate() {
    let selectInput = (document.getElementsByClassName('pageSize-dropDown-candidate')[0] as HTMLSelectElement)
    this.selectedPageSize = parseInt(selectInput.value)
    this.pageNumberApplicant = 1
    this.loadApplicantCandidates(1, (a: boolean | void) => {})
  }

  private onPageChangeCandidate (newPage: number) {
    if (newPage !== this.pageNumberApplicant) {
      this.pageNumberApplicant = newPage
      this.loadApplicantCandidates(1, (a: boolean | void) => {})
    }
  }

  private onChangePageSizeSuggestedCandidate() {
    let selectInput = (document.getElementsByClassName('pageSize-dropDown-suggested-candidate')[0] as HTMLSelectElement)
    this.selectedPageSize = parseInt(selectInput.value)
    this.pageNumberSuggested = 1
    this.loadSuggestedCandidates(1, (a: boolean | void) => {})
  }

  private onPageChangeSuggestedCandidate (newPage: number) {
    if (newPage !== this.pageNumberSuggested) {
      this.pageNumberSuggested = newPage
      this.loadSuggestedCandidates(1, (a: boolean | void) => {})
    }
  }

  private resetFilters() {
    this.selectedCountries = []
    this.selectedIndustries = []
    this.selectedLevelsOfStudy = []
    this.languagesProficiencyLevelFr = []
    this.languagesProficiencyLevelEn = []
    this.interestAreaGroup = null
    this.searchTerms = ''
    this.filters = []
    if (this.tab === '0') {
      this.loadApplicantCandidates(1, (a: boolean | void) => {})
    } else if (this.tab === '1') {
      this.loadSuggestedCandidates(1, (a: boolean | void) => {})
    }
  }

  private deleteTag (index: number) {
    let deletedFilter = this.filters[index]
    switch (deletedFilter.filterType) {
      case 'country':
        this.selectedCountries = this.selectedCountries.filter((x) => x.label !== deletedFilter.value)
        this.filters.splice(index, 1)
        break
      case 'study':
        this.selectedLevelsOfStudy = this.selectedLevelsOfStudy.filter((x) => x.label !== deletedFilter.value)
        this.filters.splice(index, 1)
        break
      case 'industry':
        this.selectedIndustries = this.selectedIndustries.filter((x) => x.label !== deletedFilter.value)
        this.filters.splice(index, 1)
        break
      case 'frLevel':
        this.languagesProficiencyLevelFr = this.languagesProficiencyLevelFr.filter((x) => x.label + ' (fr)' !== deletedFilter.value)
        this.filters.splice(index, 1)
        break
      case 'enLevel':
        this.languagesProficiencyLevelEn = this.languagesProficiencyLevelEn.filter((x) => x.label + ' (en)' !== deletedFilter.value)
        this.filters.splice(index, 1)
        break
      default:
        this.filters.splice(index, 1)
        break
    }
    this.loadApplicantCandidates(1, (a: boolean | void) => {})
  }
}
