

































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { ExpertisePage } from './Expertise'
import { ExpertiseBlockTranslations } from './ExpertiseBlock.translations'

import AnimatedCircleWithArrowIconComponent from '@/generic/components/AnimatedCircleWithArrowIcon.vue'
import AnimatedBackgroundComponent from '@/generic/components/AnimatedBackground.vue'

@Component({
  components: {
    'mitm-animated-circle-arrow-icon': AnimatedCircleWithArrowIconComponent,
    'mitm-animated-background': AnimatedBackgroundComponent
  }
})
export default class ExpertiseBlockComponent extends Vue {
  @Prop() private expertises!: Array<ExpertisePage>
  @Prop() private allExpertises!: string
  @Prop() private translations!: ExpertiseBlockTranslations
  @Prop() private primaryColor!: string
  @Prop() private secondaryColor!: string

  private animations = this.expertises.map(() => false)
  private animationBtn = false

  private onMouseEnter (index: number) {
    this.$set(this.animations, index, true)
  }

  private onMouseLeave (index: number) {
    this.$set(this.animations, index, false)
  }
}
