





































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { SelectOption } from '@/generic/models/SelectOption'
import { WorkInfoData } from '@/offer/jobOfferWorkInfos/WorkInfoData.model'
import { RequirementData } from '@/offer/jobOfferRequirement/RequirementData.model'
import { LanguageProficiency } from '@/generic/models/LanguageProficiency.model'
import { TagsHelper } from '@/search/TagsHelper.model'
import MITMSelectnputComponent from '@/generic/components/MITMSelectInput.vue'
import SearchTranslations from '@/search/search.translations'
import MITMInputComponent from '../generic/components/MITMInput.vue'

@Component
export default class SearchComponent extends Vue {
  @Prop() private events!: []
  @Prop() private jobRequirements!: RequirementData
  @Prop() private languageProficienciesListFr !: Array<SelectOption>
  @Prop() private languageProficienciesListEn !: Array<SelectOption>
  @Prop() private experience !: Array<SelectOption>
  @Prop() private salaryInfo!: WorkInfoData
  @Prop() private searchTranslations!: SearchTranslations
  @Prop({ default: false }) private hideTitle!: boolean
  private selectedEvent: SelectOption|null = null
  private selectedStudy: SelectOption|null = null
  private selectedExperience: SelectOption|null = null
  private selectedProficiencyFr: SelectOption|null = null
  private selectedProficiencyEn: SelectOption|null = null
  private selectedInterviewType: SelectOption|null = null
  private selectedSalaryRange: SelectOption|null = null
  private hasFiltersApplied: boolean = false
  private numberOfFiltersApplied: number = 0
  private filtersApplied: TagsHelper [] = []
  private value = ''
  private animation = false
  private interviewType: Array<SelectOption> = [
    { value: 'true', label: this.searchTranslations.interview.virtual },
    { value: 'false', label: this.searchTranslations.interview.faceToFace }
  ]
  private searchTimeout: any
  private skipFirstLoadCounter: number = 0

  private onMouseOver () {
    this.animation = true
  }

  private onMouseLeave () {
    this.animation = false
  }

  private toggleFilters () {
    document.querySelector('.select-container')?.classList.toggle('open')
    document.querySelector('.filter-button')?.classList.toggle('open')
  }

  public setSearch (keywords: string, selectedEventId: string, selectedStudy: string, selectedExperience: string, selectedProficiencyFr: string, selectedProficiencyEn: string, selectedSalaryRange: string, selectedInterviewType: boolean|null) {
    const selectInputEvent = (this.$refs['searchSelectEvent'] as MITMSelectnputComponent)
    const eventIndex = this.events.findIndex((element : any) => element.value.toString() === selectedEventId)
    const selectInputStudy = (this.$refs['searchSelectStudy'] as MITMSelectnputComponent)
    const studyIndex = this.jobRequirements.levelOfStudy.findIndex((element : any) => element.value.toString() === selectedStudy)
    const selectInputExperience = (this.$refs['searchSelectExperience'] as MITMSelectnputComponent)
    const experienceIndex = this.experience.findIndex((element : any) => element.value.toString() === selectedExperience)
    const selectInputProficiencyFr = (this.$refs['searchSelectProficiencyFr'] as MITMSelectnputComponent)
    const proficiencyFrIndex = this.languageProficienciesListFr.findIndex((element : any) => element.value.toString() === selectedProficiencyFr)
    const selectInputProficiencyEn = (this.$refs['searchSelectProficiencyEn'] as MITMSelectnputComponent)
    const proficiencyEnIndex = this.languageProficienciesListEn.findIndex((element : any) => element.value.toString() === selectedProficiencyEn)
    const selectInputSalaryRange = (this.$refs['searchSelectSalary'] as MITMSelectnputComponent)
    const salaryRangeIndex = this.salaryInfo.annualSalary.findIndex((element : any) => element.value.toString() === selectedSalaryRange)
    const selectInputInterview = (this.$refs['searchSelectInterview'] as MITMSelectnputComponent)
    this.value = keywords
    this.numberOfFiltersApplied = 0
    this.filtersApplied = []

    if (eventIndex >= 0) {
      selectInputEvent.selectIndexForSelectOption(eventIndex)
      this.selectedEvent = this.events[eventIndex]
      this.hasFiltersApplied = true
      this.numberOfFiltersApplied++
      this.filtersApplied.push({ value: selectInputEvent.getLabelByIndex(eventIndex), filterType: 'event' })
    } else {
      selectInputEvent.clear()
    }

    if (studyIndex >= 0) {
      selectInputStudy.selectIndexForSelectOption(studyIndex)
      this.selectedStudy = this.jobRequirements.levelOfStudy[studyIndex]
      this.hasFiltersApplied = true
      this.numberOfFiltersApplied++
      this.filtersApplied.push({ value: selectInputStudy.getLabelByIndex(studyIndex), filterType: 'study' })
    } else {
      selectInputStudy.clear()
    }

    if (experienceIndex >= 0) {
      selectInputExperience.selectIndexForSelectOption(experienceIndex)
      this.selectedExperience = this.experience[experienceIndex]
      this.hasFiltersApplied = true
      this.numberOfFiltersApplied++
      this.filtersApplied.push({ value: selectInputExperience.getLabelByIndex(experienceIndex), filterType: 'experience' })
    } else {
      selectInputExperience.clear()
    }

    if (proficiencyFrIndex >= 0) {
      selectInputProficiencyFr.selectIndexForSelectOption(proficiencyFrIndex)
      this.selectedProficiencyFr = this.languageProficienciesListFr[proficiencyFrIndex]
      this.hasFiltersApplied = true
      this.numberOfFiltersApplied++
      this.filtersApplied.push({ value: selectInputProficiencyFr.getLabelByIndex(proficiencyFrIndex), filterType: 'proficiencyFr' })
    } else {
      selectInputProficiencyFr.clear()
    }

    if (proficiencyEnIndex >= 0) {
      selectInputProficiencyEn.selectIndexForSelectOption(proficiencyEnIndex)
      this.selectedProficiencyEn = this.languageProficienciesListEn[proficiencyEnIndex]
      this.hasFiltersApplied = true
      this.numberOfFiltersApplied++
      this.filtersApplied.push({ value: selectInputProficiencyEn.getLabelByIndex(proficiencyEnIndex), filterType: 'proficiencyEn' })
    } else {
      selectInputProficiencyEn.clear()
    }

    if (salaryRangeIndex >= 0) {
      selectInputSalaryRange.selectIndexForSelectOption(salaryRangeIndex)
      this.selectedSalaryRange = this.salaryInfo.annualSalary[salaryRangeIndex]
      this.hasFiltersApplied = true
      this.numberOfFiltersApplied++
      this.filtersApplied.push({ value: selectInputSalaryRange.getLabelByIndex(salaryRangeIndex), filterType: 'salary' })
    } else {
      selectInputSalaryRange.clear()
    }

    if (selectedInterviewType === null) {
      selectInputInterview.clear()
    } else if (selectedInterviewType) {
      selectInputInterview.selectIndexForSelectOption(0)
      this.selectedInterviewType = this.interviewType[0]
      this.hasFiltersApplied = true
      this.numberOfFiltersApplied++
      this.filtersApplied.push({ value: selectInputInterview.getLabelByIndex(0), filterType: 'interview' })
    } else {
      selectInputInterview.selectIndexForSelectOption(1)
      this.selectedInterviewType = this.interviewType[1]
      this.hasFiltersApplied = true
      this.numberOfFiltersApplied++
      this.filtersApplied.push({ value: selectInputInterview.getLabelByIndex(1), filterType: 'interview' })
    }

    if (this.hasFiltersApplied) {
      let select = document.querySelector('.select-container')
      if (!select?.classList.contains('open')) {
        select?.classList.add('open')
        document.querySelector('.filter-button')?.classList.add('open')
      }
    }
  }

  private goSearch (): void {
    this.numberOfFiltersApplied = 0
    this.filtersApplied = []
    this.emitSearch()
  }

  private resetFilters () {
    const selectInputEvent = (this.$refs['searchSelectEvent'] as MITMSelectnputComponent)
    selectInputEvent.clear()
    const selectInputStudy = (this.$refs['searchSelectStudy'] as MITMSelectnputComponent)
    selectInputStudy.clear()
    const selectInputExperience = (this.$refs['searchSelectExperience'] as MITMSelectnputComponent)
    selectInputExperience.clear()
    const selectInputProficiencyFr = (this.$refs['searchSelectProficiencyFr'] as MITMSelectnputComponent)
    selectInputProficiencyFr.clear()
    const selectInputProficiencyEn = (this.$refs['searchSelectProficiencyEn'] as MITMSelectnputComponent)
    selectInputProficiencyEn.clear()
    const selectInputSalaryRange = (this.$refs['searchSelectSalary'] as MITMSelectnputComponent)
    selectInputSalaryRange.clear()
    const selectInputInterview = (this.$refs['searchSelectInterview'] as MITMSelectnputComponent)
    selectInputInterview.clear()
  }

  private deleteTag (index: number) {
    switch (this.filtersApplied[index].filterType) {
      case 'event':
        const selectInputEvent = (this.$refs['searchSelectEvent'] as MITMSelectnputComponent)
        selectInputEvent.clear()
        break
      case 'study':
        const selectInputStudy = (this.$refs['searchSelectStudy'] as MITMSelectnputComponent)
        selectInputStudy.clear()
        break
      case 'experience':
        const selectInputExperience = (this.$refs['searchSelectExperience'] as MITMSelectnputComponent)
        selectInputExperience.clear()
        break
      case 'proficiencyFr':
        const selectInputProficiencyFr = (this.$refs['searchSelectProficiencyFr'] as MITMSelectnputComponent)
        selectInputProficiencyFr.clear()
        break
      case 'proficiencyEn':
        const selectInputProficiencyEn = (this.$refs['searchSelectProficiencyEn'] as MITMSelectnputComponent)
        selectInputProficiencyEn.clear()
        break
      case 'salary':
        const selectInputSalaryRange = (this.$refs['searchSelectSalary'] as MITMSelectnputComponent)
        selectInputSalaryRange.clear()
        break
      default:
        const selectInputInterview = (this.$refs['searchSelectInterview'] as MITMSelectnputComponent)
        selectInputInterview.clear()
        break
    }
  }

  public onFilterChange() {
    if (this.skipFirstLoadCounter < 7) {
      // This is done to prevent call to backend since parent component (JobOfferList) has already called it
      // We use 7 for counter because there is 7 filters
      // TODO to fix before next release ??
      this.skipFirstLoadCounter++
      return
    }
    this.numberOfFiltersApplied = 0
    this.filtersApplied = []
    this.emitSearch()
  }

  public emitSearch() {
    clearTimeout(this.searchTimeout)
    this.searchTimeout = setTimeout(() => {
      // Done like this to remove typescript error
      let eventValue = null
      let eventType = null
      if (this.selectedEvent !== null) {
        let eventData = this.selectedEvent.value.toString().split('-')
        eventValue = eventData[0]
        eventType = eventData[1]
        this.numberOfFiltersApplied++
        this.filtersApplied.push({ value: this.selectedEvent.label, filterType: 'event' })
      }
      let studyValue = null
      if (this.selectedStudy !== null) {
        studyValue = this.selectedStudy.value
        this.numberOfFiltersApplied++
        this.filtersApplied.push({ value: this.selectedStudy.label, filterType: 'study' })
      }
      let experienceValue = null
      if (this.selectedExperience !== null) {
        experienceValue = this.selectedExperience.value
        this.numberOfFiltersApplied++
        this.filtersApplied.push({ value: this.selectedExperience.label, filterType: 'experience' })
      }
      let proficiencyFrValue = null
      if (this.selectedProficiencyFr !== null) {
        proficiencyFrValue = this.selectedProficiencyFr.value
        this.numberOfFiltersApplied++
        this.filtersApplied.push({ value: this.selectedProficiencyFr.label, filterType: 'proficiencyFr' })
      }
      let proficiencyEnValue = null
      if (this.selectedProficiencyEn !== null) {
        proficiencyEnValue = this.selectedProficiencyEn.value
        this.numberOfFiltersApplied++
        this.filtersApplied.push({ value: this.selectedProficiencyEn.label, filterType: 'proficiencyEn' })
      }
      let salaryValue = null
      if (this.selectedSalaryRange !== null) {
        salaryValue = this.selectedSalaryRange.value
        this.numberOfFiltersApplied++
        this.filtersApplied.push({ value: this.selectedSalaryRange.label, filterType: 'salary' })
      }
      let interviewTypeValue = null
      if (this.selectedInterviewType !== null) {
        interviewTypeValue = this.selectedInterviewType.value
        this.numberOfFiltersApplied++
        this.filtersApplied.push({ value: this.selectedInterviewType.label, filterType: 'interview' })
      }

      this.$emit('search', {
        value: this.value,
        selectedEventId: eventValue,
        selectedEventType: eventType,
        selectedStudy: studyValue,
        selectedExperience: experienceValue,
        selectedProficiencyFr: proficiencyFrValue,
        selectedProficiencyEn: proficiencyEnValue,
        selectedSalaryRange: salaryValue,
        selectedInterviewType: interviewTypeValue
      })
    }, 500)
  }
}
