import { BASE_API } from './api-common'
import { AxiosPromise } from 'axios'
import { SelectOption } from '@/generic/models/SelectOption'
import { CultureService } from './CultureService'

export default class CityService {
  public static getCities (prefix: string) : AxiosPromise<Array<string>> {
    const lang = CultureService.getCulture()
    return BASE_API.get(`city/getCities?prefix=${prefix}&language=${lang}`)
  }
}
