import { render, staticRenderFns } from "./SkillHelp.vue?vue&type=template&id=3c612bcc&scoped=true&"
import script from "./SkillHelp.vue?vue&type=script&lang=ts&"
export * from "./SkillHelp.vue?vue&type=script&lang=ts&"
import style0 from "./SkillHelp.vue?vue&type=style&index=0&id=3c612bcc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c612bcc",
  null
  
)

export default component.exports