






































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Expertise } from './Expertise'
import { ExpertiseListTranslations } from './ExpertiseList.translations'
import { CultureService } from '@/services/CultureService'

@Component
export default class ExpertiseListComponent extends Vue {
  @Prop() private expertises!: Array<Expertise>
  @Prop() private translations!: ExpertiseListTranslations
  private culture: string = CultureService.getCulture()
}
