




































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { SiteMenu, MenuSection } from '@/navigation/model/SiteMenu.model'
import { getInitials } from '@/generic/helpers'

export enum SubMenu {
  User,
  Section,
  None
}
@Component
export default class MobileMenuComponent extends Vue {
  @Prop() menu!: SiteMenu
  @Prop() private switchLanguageLabel!: string
  @Prop() private switchLanguageLink!: string
  private drawer: boolean = false
  private initials = getInitials(this.menu.name)

  private selectedSection!: MenuSection
  private sectionShown: SubMenu = SubMenu.None

  private get displaySection () {
    return this.sectionShown === SubMenu.Section
  }

  private get displayUser () {
    return this.sectionShown === SubMenu.User
  }

  private get screenWidth () {
    return this.$q.screen.width
  }

  private closeSection () {
    this.sectionShown = SubMenu.None
  }

  private navigate (link: string) {
    window.location.href = link
  }

  private showUser () {
    this.sectionShown = SubMenu.User
  }

  private showSection (section : MenuSection) {
    this.selectedSection = section
    this.sectionShown = SubMenu.Section
  }

  public toggleDrawer (): void {
    this.drawer = !this.drawer
  }
}
