



























































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { CultureService } from '@/services/CultureService'
import { MissionPageTranslations } from './translations/MissionPage.translations'
import { oneDayMission, missionLocation } from '@/generic/helpers'
import { MissionEvent } from '../models/MissionEvent'

@Component
export default class MissionEventCardComponent extends Vue {
   @Prop() private event!: MissionEvent
   @Prop() private translations!: MissionPageTranslations

  private options = { month: 'short' }
  private startDate = new Date(this.event.startDate)
  private startDay = this.startDate.getDate()
  private startMonth = new Intl.DateTimeFormat(this.culture, this.options).format(this.startDate)
  private endDate = new Date(this.event.endDate)
  private endDay = this.endDate.getDate()
  private endMonth = new Intl.DateTimeFormat(this.culture, this.options).format(this.endDate)

  private get month () {
    const month = (this.startMonth === this.endMonth) ? this.startMonth : `${this.startMonth} - ${this.endMonth}`
    return month
  }

  private get oneDayMission (): boolean {
    return oneDayMission(this.startDate, this.endDate)
  }

  private get location (): string {
    return missionLocation(this.event.multipleCity, this.event.city, this.event.multipleCountry, this.event.country, this.translations.multipleCity, this.translations.multipleCountry)
  }

  private get activityArea (): string {
    if (this.event.hasMultipleActivityArea) {
      return this.translations.multipleActivityArea
    } else {
      return this.event.primaryActivityArea + (this.event.secondaryActivityArea ? this.event.secondaryActivityArea : '')
    }
  }

  private get culture () {
    return CultureService.getCultureCode()
  }

  private goTo () {
    window.location.href = this.event.url
  }
}
