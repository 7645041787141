




































































































































































import { Component, Prop, Vue, Mixins } from 'vue-property-decorator'
import CandidateCardTranslations from '@/candidate/candidates-list/CandidateCard.translations'
import CandidateResponse from '../../models/candidats/CandidateResponse.model'
import { CultureService } from '@/services/CultureService'
import { Culture } from '@/generic/models/Culture'

@Component
export default class AdminCandidateCardComponent extends Vue {
  @Prop() private candidate !: CandidateResponse
  @Prop() private translations !: CandidateCardTranslations
  @Prop({ default: false }) private hidden!: boolean
  private href: string = ''

  mounted () {
    this.href = window.location.origin + this.getHref()
  }

  tagsCharacterCount () {
    let count = 0
    this.candidate.tags.forEach(tag => {
      if (tag.name !== null) {
        count = count + tag.name.length
      }
    })
    return count
  }

  sortByCharacterCount (tags: any) {
    tags.sort(function (a: any, b: any) {
      return a.name?.length - b.name?.length
    })
  }

  maxTags (tags: any) {
    let maxTags = [] as any
    for (let i = 0; i < 4; i++) {
      if (tags[i]) {
        maxTags.push(tags[i])
      }
      this.sortByCharacterCount(maxTags)
    }
    if (this.tagsCharacterCount() > 75) {
      maxTags.pop()
    }
    return maxTags
  }
  private getHref () : string {
    let href: string

    if (CultureService.getCulture() === Culture.English) {
      href = `/en/site-admin/console-edit-candidate/${this.candidate.id}`
    } else {
      href = `/fr/admin/console-modification-candidat/${this.candidate.id}`
    }

    return href
  }
}

