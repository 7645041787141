




















































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ActivityAreaCarouselComponent extends Vue {
  @Prop() activityAreaBlocks!: []
  @Prop() private slidesToShow!: number
  @Prop() private spacing!: boolean
  @Prop() private title!: string
  @Prop() private buttonText!: string
  @Prop() private primaryColor!: string
  @Prop() private secondaryColor!: string
  @Prop() private allPages!: string
  @Prop() private switchNav!: boolean
  @Prop() private deactivateCarousel!: boolean
}
