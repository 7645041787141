





























import { Component, Prop, Vue, Mixins } from 'vue-property-decorator'
import { CalendarEvent } from './Event.model'

@Component
export default class EventCalendarComponent extends Vue {
  @Prop() events!: CalendarEvent[]
  @Prop() selectedDate!: Date
  @Prop() private colors!: string[]
  @Prop() private cityColors!: Map<string, string>
  @Prop() forRecMeeting!: boolean
  private ready : boolean = false
  private dateCities: Map<string, string> = new Map<string, string>()

  private get date () {
    return this.formatDate(this.selectedDate)
  }

  private mounted () {
    this.mapDateToCity()

    this.updateEventOrderInDom()
    this.ready = true
  }

  private get eventDates () {
    return this.events.map((event) => {
      return this.formatDate(event.date)
    })
  }

  private get cities () : string[] {
    return Array.from(this.cityColors.keys())
  }

  private chooseColor (date: string) : string {
    const city = this.dateCities.get(date)
    if (city) {
      return this.cityColors.get(city) || ''
    }
    return ''
  }

  private mapDateToCity () {
    this.events.forEach((event) => {
      this.dateCities.set(this.formatDate(event.date), event.city)
    })
  }

  private onSelectDate (value: string, reason: string, details: {year: number, month: number, day: number}) {
    this.$emit('select-date', new Date(details.year, details.month - 1, details.day))
  }

  private updateEventOrderInDom () {
    this.$nextTick(() => {
      var calendar = (this.$refs['calendar'] as Vue)
      var dates = calendar.$el.getElementsByClassName('q-date__event')
      Array.from(dates).forEach((el: Element) => {
        let sibling = el.previousSibling
        if (el.parentNode !== null) {
          el.parentNode.insertBefore(el, sibling)
        }
      })
    })
  }

  private formatDate (date : Date) : string {
    var month = '' + (date.getMonth() + 1)
    var day = '' + date.getDate()
    var year = date.getFullYear()

    if (month.length < 2) {
      month = '0' + month
    }

    if (day.length < 2) {
      day = '0' + day
    }

    return [year, month, day].join('/')
  }

  private getAvailableDateForCalendar(dateInCalendarFormat: Date) {
    if (!this.forRecMeeting) {
      return true
    }
    let now = new Date()
    let date = new Date(dateInCalendarFormat)
    date.setHours(23)
    date.setMinutes(59)
    let dateInFuture = new Date()
    // Done like this to block date after the calculated preferences range
    dateInFuture.setDate(dateInFuture.getDate() + (84 - now.getDay()))
    dateInFuture.setHours(23)
    dateInFuture.setMinutes(59)
    if (date >= now && date < dateInFuture) {
      return true
    }
    return false
  }
}
