import { AxiosPromise } from 'axios'
import { BASE_API } from '@/services/api-common'
import { MeetingsListFilter } from '../models/meetings/MeetingsListFilter.model'
import MeetingsList from '../models/meetings/MeetingsList.models'
import { Meeting } from '@/dashboard/meeting-list/models/Meeting.model'
import AddFollowUp from '@/dashboard/meeting-list/models/AddFollowUp.model'
import MeetingFollowUpDetails from '@/dashboard/meeting-list/models/MeetingFollowUpDetails.model'
import { ExternalListFilter } from '../models/meetings/external/ExternalListFilter.model'
import ExternalList from '../models/meetings/external/ExternalList.models'
import { FileInfo } from '@/generic/models/File.model'
import ExternalDetails from '../models/meetings/external/ExternalDetails.models'

export default class AdminConsoleService {
  public static getExternalDocuments(filter: ExternalListFilter): AxiosPromise<ExternalList> {
    return BASE_API.post('adminconsolemeeting/getExternalMeetingDocuments', filter)
  }

  public static uploadExternalDocuments(file: FileInfo, documentName: string): AxiosPromise<ExternalList> {
    return BASE_API.post('adminconsolemeeting/addExternalMeetingDocument', {
      name: documentName,
      file: file
    })
  }

  public static getExternalDocumentMeetings(documentName: string): AxiosPromise<ExternalDetails> {
    return BASE_API.get('adminconsolemeeting/getExternalMeetingDocumentDetails', {
      params: {
        name: documentName
      }
    })
  }

  public static deleteExternalMeetingDocument(documentName: string): AxiosPromise {
    return BASE_API.delete('adminconsolemeeting/deleteExternalMeetingDocument', {
      params: {
        name: documentName
      }
    })
  }

  public static getMeetings(filter: MeetingsListFilter): AxiosPromise<MeetingsList> {
    let data = []
    data = filter.selectedEvent.split('-')
    filter.selectedEvent = data[0]
    filter.selectedEventType = data[1]
    return BASE_API.post('adminconsolemeeting/getMeetings', filter)
  }

  public static saveMeeting(meeting: Meeting): AxiosPromise {
    return BASE_API.post('adminconsolemeeting/saveMeeting', meeting)
  }

  public static deleteMeeting(meetingId: number, companyId: number, date: Date, period: number): AxiosPromise {
    return BASE_API.post('adminconsolemeeting/deleteMeeting', { meetingId, companyId, date, period })
  }

  public static exportMeetings(filter: MeetingsListFilter): AxiosPromise<MeetingsList> {
    return BASE_API.post('adminconsolemeeting/exportMeetings', filter, { responseType: 'blob' })
  }

  public static getMeetingFollowUpDetails (meetingId : number) : AxiosPromise<MeetingFollowUpDetails> {
    return BASE_API.get('adminconsolemeeting/getMeetingFollowUpDetails', {
      params: {
        meetingId: meetingId
      }
    })
  }

  public static saveFollowUp (followUp: AddFollowUp) : AxiosPromise<void> {
    return BASE_API.post('adminconsolemeeting/addFollowUp', followUp)
  }
}
