








































import { Component, Prop, Vue } from 'vue-property-decorator'
import { CompanyService } from '@/services/CompanyService'
import { CompanyOrder } from '@/company/models/CompanyOrder'
import { ProductType } from './models/ProductType'
import RemainingPackage from './models/RemainingPackage.translations'

@Component
export default class RemainingPackageComponent extends Vue {
    @Prop() companyId !: number
    @Prop() currentCulture !: string
    @Prop() translations !: RemainingPackage

    private loaded = false
    private totalJobOfferOrders = 0
    private totalMissionOrders = 0
    private firstExpiryCompanyOrder!: CompanyOrder | undefined
    private totalJobOfferOrdersWillExpireSoon = 0

    mounted () {
      CompanyService.getCompanyActiveOffers(this.companyId, this.currentCulture)
        .then(result => {
          if (result.data !== null) {
            let jobMissionOrders = result.data.filter(order => order.productType === ProductType.Mission)
            if (jobMissionOrders !== undefined) {
              this.totalMissionOrders = jobMissionOrders.length
            }
            let jobOfferOrders = result.data.filter(order => order.productType === ProductType.JobOffer)
            if (jobOfferOrders !== undefined) {
              this.firstExpiryCompanyOrder = jobOfferOrders[0]
              jobOfferOrders.forEach(order => {
                this.totalJobOfferOrders += order.remainingQuantity
              })
              if (this.firstExpiryCompanyOrder !== undefined) {
                jobOfferOrders.forEach(order => {
                  if (order.expiry === this.firstExpiryCompanyOrder?.expiry) {
                    this.totalJobOfferOrdersWillExpireSoon += order.remainingQuantity
                  }
                })
              }
            }
          }
        }).finally(() => {
          this.loaded = true
        })
    }
}

