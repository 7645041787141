





























































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { AdminOrder, OrderStatus } from '../../models/billing/AdminOrder'
import AdminBillingPageTranslations from '../../models/billing/Billing.translations'
import OrderDetailsComponent from './OrderDetails.vue'

@Component({
  components: {
    'mitm-order-details': OrderDetailsComponent
  }
})
export default class AdminOrderCardComponent extends Vue {
  @Prop() private order !: AdminOrder
  @Prop() translations!: AdminBillingPageTranslations
  @Prop({ default: false }) private isNew!: boolean
  private seeOrder = false
  private loading = false

  private showOrder () {
    this.seeOrder = true
  }

  getStatusLabel () : string {
    if (this.order.orderStatus === OrderStatus.PaymentReceived) {
      return this.translations.billingPage.order.status.paymentReceived
    } else if (this.order.orderStatus === OrderStatus.PaymentFailed) {
      return this.translations.billingPage.order.status.failed
    } else if (this.order.orderStatus === OrderStatus.InProgress) {
      return this.translations.billingPage.order.status.inProgress
    } else {
      return this.translations.billingPage.order.status.expired
    }
  }

  getColor() : string {
    if (this.order.orderStatus === OrderStatus.PaymentReceived) {
      return 'green'
    } else if (this.order.orderStatus === OrderStatus.InProgress) {
      return 'azure'
    } else {
      return 'red'
    }
  }
}

