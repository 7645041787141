















































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { OfferService } from '@/services/OfferService'
import { JobOfferSummary } from '@/offer/summary/JobOfferSummary.model'
import { dateFilter } from '@/generic/filters/Date'
import { Culture } from '@/generic/models/Culture'
import { ITranslatable } from '@/generic/models/ITranslatable'
import { getUrlParameter, loadWhile } from '@/generic/helpers'
import JobOfferCardTranslations from './jobOfferCard.translations'
import { CultureService } from '@/services/CultureService'
import { FavoriteService } from '@/services/FavoriteService'

@Component
export default class jobOfferCardComponent extends Vue {
  @Prop() private jobId!: number
  @Prop() private jobOffer!: JobOfferSummary
  @Prop({ default: '' }) private missionId!: string
  @Prop() private translations!: JobOfferCardTranslations
  @Prop({ default: false }) private userIsCandidate !: boolean

  private liked = 0
  private hasError = false
  private errorMessage: string = ''
  private href:string = ''

  mounted () {
    if (this.jobOffer.isFavorite) {
      this.liked = 1
    }

    this.href = window.location.origin + this.getHref()
  }
  private handleError (error: any) {
    this.hasError = true
    if (error.response.status === 404) {
      this.errorMessage = this.translations.error.notFound
    } else if (error.response.status === 403) {
      this.errorMessage = this.translations.error.forbidden
    } else {
      this.errorMessage = this.translations.error.unknown
    }
  }
  private getPublishedDate (): string {
    const culture = CultureService.getCulture()
    return dateFilter(this.jobOffer.publishedDate || new Date(), culture)
  }

  private likedOffer () {
    if (!this.jobOffer.isFavorite) {
      loadWhile(this, this.translations.loading, () =>
        FavoriteService.addOfferToFavorite(this.jobId)
          .then(result => {
            this.jobOffer.isFavorite = true
            this.liked = 1
          })
          .catch(error => {
            this.handleError(error)
          })
      )
    } else {
      loadWhile(this, this.translations.loading, () =>
        FavoriteService.deleteOfferFromFavorite(this.jobId)
          .then(result => {
            this.jobOffer.isFavorite = false
            this.liked = 0
          })
          .catch(error => {
            this.handleError(error)
          })
      )
    }
  }

  private getHref () : string {
    const url = `${this.jobOffer.titleSlug}/${this.jobId}`
    let href: string
    if (CultureService.getCulture() === Culture.English) {
      href = `/en/jobs/job/${url}`
    } else {
      href = `/fr/emplois/emploi/${url}`
    }

    if (this.missionId !== '') {
      href += `?mission=${this.missionId}`
    }

    return href
  }
}

