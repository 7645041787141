import { AxiosPromise } from 'axios'
import { BASE_API } from './api-common'
import { Company } from '@/company/Company.model'
import { CompanyCoordinate } from '@/company/models/CompanyCoordinate'
import Candidate from '@/candidate/Candidate.model'
import CandidateList from '@/candidate/CandidateList.model'
import { base64StringToBlob } from 'cypress/types/blob-util'
import { CompanyOrder } from '@/company/models/CompanyOrder'
import { JobOffer } from '@/offer/JobOffer.model'
import { SelectOption } from '@/generic/models/SelectOption'
import { CandidateSearchDto } from '@/candidate/CandidateSearch.model'
import { SuggestedCandidate } from '@/candidate/SuggestedCandidate.model'

export class CompanyService {
  public static getCompany (id: number): AxiosPromise<Company> {
    return BASE_API.get(`company/company/${id}`)
  }

  public static saveCompany (model: Company) : AxiosPromise<Company> {
    return BASE_API.put(`company/save/${model.id}`, model)
  }

  public static getNumberCandidateForEachJob (jobId: number) : AxiosPromise<number> {
    return BASE_API.get('candidature/getNumberCandidateForEachJob', {
      params: {
        jobId: jobId
      }
    })
  }

  public static getNumberOfNewCandidateForEachJob (jobId: number) : AxiosPromise<number> {
    return BASE_API.get('candidature/GetNumberOfNewCandidateForEachJob', {
      params: {
        jobId: jobId
      }
    })
  }

  public static getNumberSuggestedCandidateForEachJob (jobId: number) {
    return BASE_API.get('candidature/getNumberSuggestedCandidateForEachJob', {
      params: {
        jobId: jobId
      }
    })
  }

  public static getNumberOfNewSuggestedCandidateForEachJob (jobId: number) {
    return BASE_API.get('candidature/getNumberOfnewSuggestedCandidateForEachJob', {
      params: {
        jobId: jobId
      }
    })
  }

  public static getApplicantCandidatesByJob (jobId: number, culture: string, pageNumber: number, pageSize: number, cityOrCountry: string, missions: Array<number>,
    countries: Array<number>, languagesProficiencyLevelFr: Array<number>, languagesProficiencyLevelEn: Array<number>, interestAreaGroup: number | null,
    industries: Array<string>, levelsOfStudy: Array<string>, sort: string) : AxiosPromise<CandidateList> {
    return BASE_API.post('candidate/getApplicantCandidatesByJob', new CandidateSearchDto(
      jobId,
      culture,
      pageNumber,
      pageSize,
      cityOrCountry,
      missions,
      countries,
      languagesProficiencyLevelFr,
      languagesProficiencyLevelEn,
      interestAreaGroup,
      industries,
      levelsOfStudy,
      sort)
    )
  }

  public static getSuggestedCandidatesByJob (jobId: number, culture: string, pageNumber: number, pageSize: number, cityOrCountry: string, missions: Array<number>,
    countries: Array<number>, languagesProficiencyLevelFr: Array<number>, languagesProficiencyLevelEn: Array<number>, interestAreaGroup: number | null,
    industries: Array<string>, levelsOfStudy: Array<string>, sort: string) : AxiosPromise<CandidateList> {
    return BASE_API.post('candidate/getSuggestedCandidatesByJob', new SuggestedCandidate(
      jobId,
      culture,
      pageNumber,
      pageSize,
      cityOrCountry,
      missions,
      countries,
      languagesProficiencyLevelFr,
      languagesProficiencyLevelEn,
      interestAreaGroup,
      industries,
      levelsOfStudy,
      sort)
    )
  }

  public static getCompanyCoordinatesForArea (areaId: number, culture: string, searchTerms: string) : AxiosPromise<CompanyCoordinate[]> {
    return BASE_API.get(`company/activityArea/${areaId}`, {
      params: {
        culture: culture,
        searchTerms: searchTerms
      }
    })
  }

  public static getCompanyCoordinatesForMission (missionId: string, culture: string, searchTerms: string) : AxiosPromise<CompanyCoordinate[]> {
    return BASE_API.get(`company/mission/${missionId}`, {
      params: {
        culture: culture,
        searchTerms: searchTerms
      }
    })
  }

  public static getRecruitersMission (companyId: number, missionId: number) : AxiosPromise<{recruiters: any[], selected: any}> {
    return BASE_API.get('company/recruitersMission', { params: { companyId: companyId, missionId: missionId } })
  }

  public static deleteCompany (password: string): AxiosPromise<void> {
    return BASE_API.delete('company/deleteCompany', {
      params: {
        password: password
      }
    })
  }

  public static requestMoreSuggestedProfiles (jobId: number, companyId: number, culture: string) : AxiosPromise<any> {
    return BASE_API.get('offer/requestMoreSuggestedProfiles', {
      params: {
        jobId: jobId,
        companyId: companyId,
        culture: culture
      }
    })
  }

  public static getCompanyActiveOffers (companyId: number, culture: string): AxiosPromise<CompanyOrder[]> {
    return BASE_API.get('company/getCompanyActiveOffers', {
      params: {
        companyId: companyId,
        culture: culture
      }
    })
  }

  public static getAvailableOrdersForJob (currentCulture: string, model: JobOffer): AxiosPromise<CompanyOrder[]> {
    return BASE_API.post('company/GetAvailableOrdersForJob', model, {
      params: {
        currentCulture: currentCulture
      }
    })
  }
}
