import { DirectiveOptions } from 'vue'

export const ClickWhenNoDragDirective: DirectiveOptions = {
  inserted (el, node) {
    if (node.value) {
      let mouseStart: number | null = null
      el.onmousedown = (e: MouseEvent): void => {
        mouseStart = e.clientX
      }
      el.onmouseup = (e: MouseEvent): void => {
        if (mouseStart !== null) {
          const mouseEnd = e.clientX
          if (Math.abs(mouseStart - mouseEnd) <= 20) {
            node.value()
          }
          mouseStart = null
        }
      }
    }
  }
}
