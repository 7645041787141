










































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { SkillTranslations } from './Skill.translations'
import { CommonTranslations } from '@/generic/models/Common.translations'
import { Skill } from './Skill.model'
import { mount } from '@vue/test-utils'
import { PublicationLanguage } from '@/generic/models/PublicationLanguage'
import { SelectOption } from '@/generic/models/SelectOption'
import { RequiredRule } from '@/generic/rules/Required'
import { Experience } from '../../generic/models/Experience'
import MITMAutocompleteComponent from '@/generic/components/MITMAutocomplete.vue'
import SkillSuggestionService from '@/services/SkillSuggestionService'
import { ITranslatable } from '@/generic/models/ITranslatable'
import { IsAscii } from '@/generic/rules/IsAscii'

@Component({
  components: {
    'mitm-autocomplete': MITMAutocompleteComponent
  }
})
export default class SkillComponent extends Vue {
  @Prop() private translations!: SkillTranslations
  @Prop() private commonTranslations!: CommonTranslations
  @Prop() private experienceData !: Array<SelectOption>
  @Prop() private value!: Array<Skill>
  @Prop() private sectionNumber!: number
  @Prop() private language!: PublicationLanguage
  @Prop({ default: false }) private isCandidateProfil!: boolean
  @Prop({ default: true }) private required!: boolean

  private maxLength : number = 60
  private skillOptionsFr : Array<string> = []
  private skillOptionsEn : Array<string> = []
  private autoCompleteOptions :ITranslatable[] = []
  private skillLookupTimeout: number = 0

  mounted () {
    if (this.value[0]) {
      if (!this.value[0].fr) {
        for (const ele of this.value) {
          if (!ele.fr) {
            ele.fr = ''
          }
        }
      }
      if (!this.value[0].en) {
        for (const ele of this.value) {
          if (!ele.en) {
            ele.en = ''
          }
        }
      }
    } else {
      this.addRow()
    }
  }

  private deleteRow (pos: number) {
    this.value.splice(pos, 1)
  }

  private addRow () {
    this.value.push({
      fr: '',
      en: '',
      experienceYears: Experience.OneToTwo
    })
    this.autoCompleteOptions.splice(0)
  }

  private updateOptions (index: number, lang: string, value: string) {
    let options: Array<string>
    let culture: string
    if (lang === 'Fr') {
      options = this.skillOptionsFr
      culture = 'fr-CA'
    } else {
      options = this.skillOptionsEn
      culture = 'en-US'
    }

    if (value.length > 0) {
      SkillSuggestionService.getSkillSuggestions(value, culture)
        .then(response => {
          if (this.autoCompleteOptions.length === 0) {
            this.autoCompleteOptions = response.data
          }
          options.splice(0)
          options.push(...response.data.map((item) => lang === 'Fr' ? item.fr : item.en))
        })
        .catch(() => {
          options.splice(0)
        })
      let autocomplete = (this.$refs['skill' + lang + index] as any)[0]
      autocomplete.refresh()
    } else {
      options.splice(0)
    }
  }

  private inputChanged (index: number, lang: string, value: string) {
    if (value.length === 0) {
      this.autoCompleteOptions.splice(0)
    }
    if (lang === 'Fr') {
      this.value[index].fr = value
      let opt = this.getOptions(lang, value)
      if (opt !== undefined) {
        this.value[index].en = opt
      }
    } else {
      this.value[index].en = value
      let opt = this.getOptions(lang, value)
      if (opt !== undefined) {
        this.value[index].fr = opt
      }
    }
  }

  private getOptions (lang:string, value:string) {
    if (this.autoCompleteOptions.length > 0) {
      if (lang === 'Fr') {
        return this.autoCompleteOptions.find(item => item.fr === value)?.en
      } else {
        return this.autoCompleteOptions.find(item => item.en === value)?.fr
      }
    } else {
      return undefined
    }
  }

  private rules: { [key: string]: Array<(value: any) => boolean | string> } = {
    fr: [
      new RequiredRule().getValidator(this.commonTranslations.message.required, () => this.publicationLanguageFrench(this.language))
    ],
    en: [
      new RequiredRule().getValidator(this.commonTranslations.message.required, () => this.publicationLanguageEnglish(this.language))
    ],
    ascii: [
      new IsAscii().getValidator(this.commonTranslations.message.invalidCharacter)
    ]
  };

  private publicationLanguageFrench (language: PublicationLanguage) {
    return language === PublicationLanguage.FrenchAndEnglish || language === PublicationLanguage.French
  }

  private publicationLanguageEnglish (language: PublicationLanguage) {
    return language === PublicationLanguage.FrenchAndEnglish || language === PublicationLanguage.English
  }

  private publicationLanguageFrenchAndEnglish (language: PublicationLanguage) {
    return language === PublicationLanguage.FrenchAndEnglish
  }

  private publicationLanguageEnglishOnly (language: PublicationLanguage) {
    return language === PublicationLanguage.English
  }

  private validateSkills (): boolean {
    if (this.value.length < 2) {
      return false
    }

    let numberOfInvalidRow = 0
    for (let i = 0; i < this.value.length; i++) {
      if (this.isEmptyOrSpaces(this.value[i].en) || this.isEmptyOrSpaces(this.value[i].fr)) {
        numberOfInvalidRow++
      }
    }

    return this.value.length - numberOfInvalidRow >= 2
  }

  private isEmptyOrSpaces (str: string): boolean {
    return str === null || str.match(/^ *$/) !== null
  }
}
