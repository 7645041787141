

















import { Component, Prop, Vue } from 'vue-property-decorator'
import { HtmlSanitizerService } from '@/services/HtmlSanitizer.service'
import { MITMEditorTranslations } from '../models/MITMEditor.translations'

@Component
export default class MITMEditorComponent extends Vue {
  @Prop() private value !: string
  @Prop({ default: '' }) private dataTest!: string
  @Prop() private translations!: MITMEditorTranslations
  private toolbar = [
    [
      {
        icon: `img:${process.env.VUE_APP_ASSET_URL}assets/icons/ICON_WYSIWYG_textsize.svg`,
        fixedLabel: true,
        fixedIcon: true,
        list: 'no-icons',
        options: [
          'title',
          'subtitle',
          'paragraph'
        ]
      },
      'bold',
      'italic',
      'unordered',
      'ordered',
      'link'
    ]
  ]

  private definitions = {
    title: {
      cmd: 'fontSize',
      param: '5',
      tip: this.translations.title
    },
    subtitle: {
      cmd: 'fontSize',
      param: '4',
      tip: this.translations.subtitle
    },
    paragraph: {
      cmd: 'fontSize',
      param: '3',
      tip: this.translations.paragraph
    }
  }

  private onInput (event: any) {
    this.$emit('input', event)
  }

  private onPaste (event: ClipboardEvent): void {
    if (event && event.clipboardData) {
      event.preventDefault()
      let paste = ''
      if (event.clipboardData.types.includes('text/html')) {
        paste = event.clipboardData.getData('text/html')
      } else if (event.clipboardData.types.includes('text/plain')) {
        paste = event.clipboardData.getData('text/plain')
      }
      HtmlSanitizerService.sanitize(paste).then((response) => {
        (this.$refs.editor as any).runCmd('insertHTML', response.data)
      })
    }
  }

  mounted () {
    // This makes using the keyboard to switch fields more useful.
    setTimeout(() => {
      const buttons = this.$el.firstElementChild!.firstElementChild!.firstElementChild!.firstElementChild!.children
      for (let i = 0; i < buttons.length; ++i) {
        buttons[i].setAttribute('tabindex', '-1')
      }
    }, 10)
  }
}
