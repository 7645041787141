export enum MediaType {
  Image,
  Video,
  Audio
}

export default interface MediaObject
{
  url : string
  altText : string
  copyright: string
  type : MediaType
}
