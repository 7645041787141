import { ShoppingCart } from '@/dashboard/employer-package/models/ShoppingCart'
import { AxiosPromise } from 'axios'
import { BASE_API } from './api-common'

export class OrderService {
  public static createOrder (shoppingCart: ShoppingCart, companyId: number, cultureCode:string) : AxiosPromise<any> {
    return BASE_API.post('order/createOrder', shoppingCart, {
      params: {
        companyId: companyId,
        cultureCode: cultureCode
      } })
  }
}
