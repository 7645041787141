






































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { WorkExperienceTranslations } from './WorkExperience.translations'
import { CommonTranslations } from '@/generic/models/Common.translations'
import { WorkExperience } from './WorkExperience.model'
import { SelectOption } from '@/generic/models/SelectOption'
import { RequiredRule } from '@/generic/rules/Required'
import { ITranslatable } from '@/generic/models/ITranslatable'

@Component
export default class WorkExperienceComponent extends Vue {
  @Prop() private translations!: WorkExperienceTranslations
  @Prop() private commonTranslations!: CommonTranslations
  @Prop() private value!: Array<WorkExperience>
  @Prop() private sectionNumber!: number
  @Prop({ default: false }) private isCandidateProfil!: boolean
  @Prop() private countriesList !: Array<SelectOption>
  @Prop() private industries !: Array<SelectOption>

  private rules: { [key: string]: Array<(value: any) => boolean | string> } = {
    required: [
      new RequiredRule().getValidator(this.commonTranslations.message.required)
    ]
  }

  private maxItem : number = 5
  private minItem : number = 1
  private maxLength : number = 60

  private isCurrentJob (index: number) : boolean {
    return this.value && this.value[index].isCurrentJob
  }

  private onIsCurrentJobClick (val: any, event: any, index: number) {
    if (val) {
      this.value[index].endDate = null
    }
  }

  private onStartDate (value: any, reason: any, details: any, index: number) {
    if (reason === 'month') {
      (this.$refs.qStartDateProxy as any)[index].hide()
    }
  }

  private onEndDate (value: any, reason: any, details: any, index: number) {
    if (reason === 'month') {
      (this.$refs.qEndDateProxy as any)[index].hide()
    }
  }

  private dateOptions (date : Date) {
    return new Date(date) <= new Date()
  }

  private deleteRow (pos: number) {
    this.value.splice(pos, 1)
  }

  private addRow () {
    if (this.value.length < 5) {
      this.value.push({
        title: '',
        companyName: '',
        country: '',
        countryId: null,
        industry: '',
        isCurrentJob: false,
        startDate: null,
        endDate: null
      })
    }
  }

  private validateMaxItem (): boolean {
    if (this.value?.length < this.minItem) {
      return false
    }

    if (this.value?.length > this.maxItem) {
      return false
    }
    return true
  }

  private get hasMaxEntries () : boolean {
    return this.value && this.value.length >= 5
  }

  private isEmptyOrSpaces (str: string): boolean {
    return str === null || str.match(/^ *$/) !== null
  }
}
