import { render, staticRenderFns } from "./AdminProductForm.vue?vue&type=template&id=5552dc7a&scoped=true&"
import script from "./AdminProductForm.vue?vue&type=script&lang=ts&"
export * from "./AdminProductForm.vue?vue&type=script&lang=ts&"
import style0 from "./style/adminProductForm.scss?vue&type=style&index=0&id=5552dc7a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5552dc7a",
  null
  
)

export default component.exports