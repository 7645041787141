






import * as Atlas from 'azure-maps-control'
import { Component, Prop, Vue } from 'vue-property-decorator'
import AzureMapService from '@/services/AzureMap.service'
import { MapPoint } from '../models/MapPoint'

@Component
export default class MITMAzureMapComponent extends Vue {
  @Prop() zoom!: number
  @Prop() maxZoom!: number
  @Prop() minZoom!: number
  @Prop() center!: number[]
  @Prop() language!: string

  private map!: Atlas.Map
  private popupTemplate !: HTMLElement
  private popup = new Atlas.Popup({
    pixelOffset: [0, -10]
  })

  private mapReady () {
    this.$emit('map-ready', this)
  }

  private mounted () {
    AzureMapService.getSubscriptionKey()
      .then(response => {
        Atlas.setSubscriptionKey(response.data)
        this.map = new Atlas.Map((this.$refs.map as HTMLElement),
          {
            center: this.center,
            zoom: this.zoom,
            language: this.language,
            maxZoom: !this.maxZoom ? 20 : this.maxZoom,
            minZoom: !this.minZoom ? 0 : this.minZoom
          })

        this.map.events.add('ready', this.mapReady)
      })
  }

  public replacePoints (points: MapPoint[]): void {
    const symbols = points.map((point : MapPoint) => {
      return new Atlas.data.Feature(new Atlas.data.Point([point.longitude, point.latitude]), point.data)
    });
    (this.map.sources.getById('source') as Atlas.source.DataSource).setShapes(symbols)
  }

  public setDataSource (points : MapPoint[], template: HTMLElement) : void {
    this.popupTemplate = template
    const ds = new Atlas.source.DataSource('source', {
      cluster: true,
      clusterRadius: 45,
      clusterMaxZoom: 15
    })
    this.map.sources.add(ds)
    this.replacePoints(points)

    this.map.layers.add(new Atlas.layer.SymbolLayer(ds, undefined, {
      iconOptions: {
        image: 'marker-blue'
      },
      textOptions: {
        textField: ['get', 'point_count_abbreviated'],
        offset: [0, -0.75],
        color: '#ffffff'
      },
      filter: ['has', 'point_count']
    }))

    const detailSymbols = new Atlas.layer.SymbolLayer(ds, undefined, {
      iconOptions: {
        image: 'pin-blue'
      },
      filter: ['!', ['has', 'point_count']]
    })
    this.map.layers.add(detailSymbols)

    this.map.events.add('click', detailSymbols, this.showPopup)
  }

  private showPopup (event: Atlas.MapMouseEvent) {
    if (event.shapes && event.shapes.length > 0) {
      const shape = event.shapes[0] as Atlas.Shape
      const properties = shape.getProperties()
      const coordinates = shape.getCoordinates() as Atlas.data.Position
      this.popup.setOptions({
        content: this.popupTemplate,
        position: coordinates
      })
      this.$emit('open-popup', properties)
      this.popup.open(this.map)
      this.map.events.addOnce('click', this.hidePopup)
    }
  }

  private hidePopup () {
    this.popup.close()
  }
}
