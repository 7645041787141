export interface CalendarEvent {
  date: Date
  city: string
}

export class SelectedEvent {
  id: number | null
  date: Date
  periodIndex: number|null

  constructor () {
    this.id = null
    this.date = new Date()
    this.periodIndex = null
  }
}
