export class DoughnutChart {
  labels: string[] = []
  datasets: DoughnutDataSet[] = []
}

export class DoughnutDataSet {
  backgroundColor: string[] = []
  borderColor?: string[]
  borderAlign?: string[]
  borderWidth?: number
  hoverBackgroundColor?: string[] = []
  hoverBorderColor?: string[]
  hoverBorderWidth?: number
  weight?: number
  data: number[] = []
}
