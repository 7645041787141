
























































































































import MITMCarouselComponent from '@/generic/components/MITMCarousel.vue'
import { notifyNegative } from '@/generic/helpers'
import { ShoppingCartService } from '@/services/ShoppingCartService'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { EmployerPackageTranslations, HeaderTranslations } from '../models/EmployerPackage.translations'
import Product from '../models/Product'
import { ProductJobOfferType } from '../models/ProductJobOfferType'
import { ShoppingCart } from '../models/ShoppingCart'
import { ShoppingCartItem } from '../models/ShoppingCartItem'
import ProductCardComponent from './ProductCard.vue'
import StaticProductCardComponent from './StaticProductCard.vue'
import { SelectOption } from '@/generic/models/SelectOption'

@Component({
  components: {
    'mitm-carousel': MITMCarouselComponent,
    'mitm-product-card': ProductCardComponent,
    'mitm-static-product-card': StaticProductCardComponent
  }
})
export default class ProductListComponent extends Vue {
  @Prop() headerTranslations!:HeaderTranslations
  @Prop() translations!:EmployerPackageTranslations
  @Prop() products!:Array<Product>
  @Prop({ default: false }) private isCohort!: boolean
  @Prop({ default: false }) private isJobOffer!: boolean
  @Prop({ default: false }) private isPermit!: boolean
  @Prop() shoppingCart!: ShoppingCart
  @Prop({ default: false }) canBuy!: boolean
  @Prop() culture!: string
  @Prop() companyId!: number
  @Prop() industriesList!: Array<SelectOption>

  private slide: number = 0
  private windowWidth = window.innerWidth

  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
  }

  get hasContent () : boolean {
    return this.products.length > 0
  }

  get isMobile () : boolean {
    return this.windowWidth <= 1220
  }

  productAdded ({ product, quantity } : { product: Product, quantity: number }) {
    if (!this.canBuy) {
      notifyNegative(this, this.translations.message.error.cantBuy)
    } else {
      if (product?.isJobOfferDepartment) {
        var item = this.shoppingCart.cartItems.find(i => i.skuId === product?.id)
        if (item !== undefined && item.productJobOfferType === ProductJobOfferType.OneOffer && item.cartItemUnits === 4) {
          this.mergeCurrentItem(item, ProductJobOfferType.FiveOffer)
        } else if (item !== undefined && item.productJobOfferType === ProductJobOfferType.FiveOffer && item.cartItemUnits === 1) {
          this.mergeCurrentItem(item, ProductJobOfferType.TenOffer)
        } else {
          this.addItem(product.skuGuid, quantity)
        }
      } else {
        this.addItem(product.skuGuid, quantity)
      }
    }
  }

  mergeCurrentItem (item: ShoppingCartItem, offerType: ProductJobOfferType) {
    var productResult = this.products.find(p => p.isJobOfferDepartment && p.productJobOfferType === offerType)
    if (productResult !== undefined) {
      let variant = productResult.variants.find(v => v.productDurationType === item?.productDurationType)
      if (variant !== undefined) {
        this.mergeItem(item.cartItemId, variant.skuGuid)
      }
    }
  }

  addItem (skuGuid: string, quantity: number) {
    Promise.all([
      ShoppingCartService.addItem(this.companyId, skuGuid, this.culture, false, quantity)
        .then((response) => {
          this.shoppingCart = response.data
        }).finally(() => {
          this.$emit('item-added')
          this.$forceUpdate()
        })
    ])
  }

  mergeItem (cartItemId: number, variantSkuGuid: string) {
    Promise.all([
      ShoppingCartService.mergeItem(cartItemId, variantSkuGuid, this.culture)
        .then((response) => {
          this.shoppingCart = response.data
        }).finally(() => {
          this.$forceUpdate()
        })
    ])
  }
}

