
































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { LogoTranslations } from './Logo.translations'
import { Logo } from './Logo.model'
import { FileType } from '@/generic/models/FileType'

@Component
export default class LogoComponent extends Vue {
  @Prop() private translations!: LogoTranslations
  @Prop() private value!: Logo
  private filename = ''
  private mimeType = ''
  private croppa: any = null
  private displayFileTooBigMessage = false
  private displayWrongFormatMessage = false

  mounted (): void {
    this.croppa = this.$refs.croppa
  }

  private get localValue (): Logo {
    return this.value
  }

  private set localValue (value: Logo) {
    this.$emit('input', value)
  }

  private clear (): void {
    this.filename = ''
    this.croppa.remove()
    this.$emit('input', null)
  }

  private chooseFile (): void {
    this.croppa.chooseFile()
  }

  private onFileChosen (): void {
    this.clear()
    this.displayFileTooBigMessage = false
    this.displayWrongFormatMessage = false
  }

  private onValidFileChosen (): void {
    const file = this.croppa.getChosenFile() as File
    if (file) {
      this.filename = file.name
      this.mimeType = file.type
      this.updateValue()
    }
  }

  private onBigFileChosen (): void {
    this.displayFileTooBigMessage = true
  }

  private onInvalidFileChosen (): void {
    this.displayWrongFormatMessage = true
  }

  private updateValue (): void {
    this.croppa.promisedBlob(this.mimeType).then((blob: Blob) => {
      const reader = new FileReader()
      reader.addEventListener('loadend', () => {
        const logo: Logo = {
          fileContent: (reader.result as string).split(',')[1],
          fileType: this.mimeTypeToFileType(blob.type)
        }
        this.localValue = logo
      })
      reader.readAsDataURL(blob)
    })
  }

  private mimeTypeToFileType (mimeType: string): FileType {
    if (mimeType === 'image/png') {
      return FileType.Png
    } else if (mimeType === 'image/jpeg') {
      return FileType.Jpeg
    }
    return FileType.Unknown
  }

  private onDragAndDrop (file: File): void {
    this.croppa._onNewFileIn(file)
  }
}
