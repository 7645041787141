import Cohort from './Cohort.models'
import { FileInfo } from '@/generic/models/File.model'

export interface ProductPriceRange {
  max: number
  price: number
}

export interface Company {
  id: number
  name: string
}

export interface ActivityArea {
  id: number
  en: string
  fr: string
}

export default class ProductDetails extends Cohort {
  nameFr: string
  nameEn: string
  prices: Array<ProductPriceRange>
  activityAreaIds: Array<number>
  companieIds: Array<number>
  companies: Array<Company>
  activityAreas: Array<ActivityArea>
  contractFr: Array<FileInfo>
  contractEn: Array<FileInfo>

  constructor () {
    super()
    this.nameFr = ''
    this.nameEn = ''
    this.prices = [{
      max: 4,
      price: 0
    }, {
      max: 9,
      price: 0
    }, {
      max: 24,
      price: 0
    }, {
      max: 99,
      price: 0
    }]
    this.activityAreaIds = []
    this.companieIds = []
    this.contractFr = []
    this.contractEn = []
    this.companies = []
    this.activityAreas = []
  }
}
