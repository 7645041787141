import { render, staticRenderFns } from "./CarouselSlideColumnImage.vue?vue&type=template&id=bd94f150&scoped=true&"
import script from "./CarouselSlideColumnImage.vue?vue&type=script&lang=ts&"
export * from "./CarouselSlideColumnImage.vue?vue&type=script&lang=ts&"
import style0 from "./CarouselSlideColumnImage.vue?vue&type=style&index=0&id=bd94f150&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd94f150",
  null
  
)

export default component.exports