import { AxiosPromise } from 'axios'
import { BASE_API } from '@/services/api-common'
import { SelectOption } from '@/generic/models/SelectOption'
import AdminAuthenticateModel from '../models/AdminAuthenticate.model'
import PlatformStatistics from '../models/console/PlatformStatistics.model'
import MissionStatistics from '../models/console/MissionStatistics.model'
import { CandidatsListFilter } from '../models/candidats/CandidatsListFilter.model'
import CandidatsList from '../models/candidats/CandidatsList.models'
import { JobOffersListFilter } from '../models/jobOffers/JobOffersListFilter.model'
import { SearchResults } from '@/offer/job-offer-list/SearchResults.model'

export default class AdminConsoleService {
  public static Login(model: AdminAuthenticateModel): AxiosPromise {
    return BASE_API.post('adminconsole/login', model)
  }

  public static getDropdownEvents(cultureCode: string): AxiosPromise<SelectOption[]> {
    return BASE_API.get<SelectOption[]>('adminconsole/getDropdownEvents', {
      params: {
        cultureCode: cultureCode
      }
    })
  }

  public static getApplicantsByOffer(offerId: number): AxiosPromise<SelectOption[]> {
    return BASE_API.get<SelectOption[]>('adminconsole/getapplicantsbyoffer', {
      params: {
        offerId: offerId
      }
    })
  }

  public static getAllJobs(culture: string, eventId: string, companyId: number = 0, onlyRec: boolean = false): AxiosPromise<SelectOption[]> {
    let data = []
    data = eventId.split('-')
    return BASE_API.get('adminconsole/getjobs', {
      params: {
        culture: culture,
        eventId: data[0],
        eventType: data[1] === undefined ? '' : data[1],
        companyId: companyId,
        onlyRec: onlyRec
      }
    })
  }

  public static getJobOffers(filter: JobOffersListFilter): AxiosPromise<SearchResults> {
    let data = []
    data = filter.selectedEvent.split('-')
    filter.selectedEvent = data[0]
    filter.selectedEventType = data[1]
    return BASE_API.post('adminconsole/getjobOffers', filter)
  }

  public static getAllCompanies(): AxiosPromise<SelectOption> {
    return BASE_API.post('adminconsole/getAllCompanies')
  }

  public static getCandidates(filter: CandidatsListFilter): AxiosPromise<CandidatsList> {
    let data = []
    data = filter.selectedEvent.split('-')
    filter.selectedEvent = data[0]
    filter.selectedEventType = data[1]
    return BASE_API.post('adminconsole/getcandidates', filter)
  }

  public static getPlatformStatistics(): AxiosPromise<PlatformStatistics> {
    return BASE_API.get('adminconsolestatistics/platform')
  }

  public static getMissionStatistics(missionId: number): AxiosPromise<MissionStatistics> {
    return BASE_API.get(`adminconsolestatistics/mission?id=${missionId}`)
  }

  public static exportCandidates(filter: CandidatsListFilter): AxiosPromise<CandidatsList> {
    return BASE_API.post('adminconsole/exportcandidates', filter, { responseType: 'blob' })
  }

  public static exportJobOffers(filter: JobOffersListFilter): AxiosPromise<any> {
    return BASE_API.post('adminconsole/exportjoboffers', filter, { responseType: 'blob' })
  }

  public static GetActivityAreas(culture: string): AxiosPromise<SelectOption[]> {
    return BASE_API.get('adminconsole/getactivityareas', {
      params: {
        culture: culture
      }
    })
  }
}
