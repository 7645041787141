




























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class HeaderBlockComponent extends Vue {
  @Prop() private title!: string
  @Prop() private primaryColor!: string
  @Prop() private secondaryColor!: string
  @Prop() private headerClass !: string
  @Prop() private actionLink!: string
  @Prop() private linkText!: string
}
