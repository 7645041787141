

















































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { ActivityAreaTranslations } from '@/activity-area/ActivityArea.translations'
import { BasicCompanyInfo } from '@/company/basic-info/BasicInfo.model'
import { JobInfoTranslations } from '@/offer/info/JobInfo.translations'
import { OfferAddressTranslations } from '@/offer/address/OfferAddress.translations'
import { CommonErrorTranslations } from '@/generic/models/CommonError.translations'
import { AddressInfo } from '../address/AddressInfo.model'
import { InterestAreaTranslations } from '@/interest-area/InterestArea.translations'
import { InterestArea, InterestAreaGroup } from '@/generic/models/InterestArea.model'
import { CultureService } from '@/services/CultureService'
import { notifyNegative } from '@/generic/helpers'
import InterestAreasService from '@/services/InterestAreas.service'
import MITMAutocompleteComponent from '@/generic/components/MITMAutocomplete.vue'
import { SelectOption } from '@/generic/models/SelectOption'
import { RequiredRule } from '@/generic/rules/Required'
import { IsAscii } from '@/generic/rules/IsAscii'
import { getActivityAreasMifi } from '@/generic/activity-area-helper'
import { CreateProfileTranslations } from '../../profile/CreateProfile.translations'
import { CommonTranslations } from '@/generic/models/Common.translations'
import { IndustryTranslations } from '@/profile/Industry.translations'
import { JobOffer } from '@/offer/JobOffer.model'

@Component({
  components: {
    'mitm-autocomplete': MITMAutocompleteComponent
  }
})
export default class JobInfoFormComponent extends Vue {
  @Prop() private addressInfoModel!: AddressInfo
  @Prop() private jobCount!: { count?: number }
  @Prop() private basicInfoModel!: BasicCompanyInfo
  @Prop() private translations!: OfferAddressTranslations
  @Prop() private commonTranslations!: CommonTranslations
  @Prop() private industryTranslations!: IndustryTranslations
  @Prop() private jobInfoTranslations!: JobInfoTranslations
  @Prop() private activityAreaTranslations!: ActivityAreaTranslations
  @Prop() private interestAreaTranslations!: InterestAreaTranslations
  @Prop() private interestAreaModel!: InterestAreaGroup[]
  @Prop() private errorTranslations !: CommonErrorTranslations
  @Prop() private jobOffer!: JobOffer
  private industriesList: Array<SelectOption> = getActivityAreasMifi(this.industryTranslations)
  private interestAreas!: Array<InterestArea>;
  private autoCompleteOptions : Array<InterestArea> = []
  private interestAreaOptions : Array<string> = []
  private interestArea : any
  private culture !: string

  private rules: { [key: string]: Array<(value: any) => boolean | string> } = {
    required: [
      new RequiredRule().getValidator(this.commonTranslations.message.required)
    ],
    ascii: [
      new IsAscii().getValidator(this.commonTranslations.message.invalidCharacter)
    ]
  };

  mounted () {
    this.culture = CultureService.getCultureCode()
  }

  private inputChanged (value: string) {
    if (this.autoCompleteOptions.length > 0) {
      let selectedInterestArea = this.autoCompleteOptions.find(x => x.title === value)
      if (selectedInterestArea !== undefined) {
        this.addInterestAreaGroup(selectedInterestArea)
      }
    }
  }

  private updateOptions (value: string) {
    let options: Array<string> = this.interestAreaOptions
    if (value.length >= 1) {
      let selectedInterestAreas = this.interestAreaModel !== undefined && this.interestAreaModel !== null && this.interestAreaModel.length > 0
        ? this.interestAreaModel.map((item) => item.id)
        : []
      if (!isNaN(+value)) {
        this.getInterestAreasByCnpCode(+value, options, this.culture, selectedInterestAreas)
      } else if (value.length > 2) {
        this.getInterestAreasByTitle(value, options, this.culture, selectedInterestAreas)
      }
    }
  }

  private getInterestAreasByTitle (value: string, options: Array<string>, culture: string, selectedInterestAreas: number []) {
    InterestAreasService.getInterestAreas(value, culture, selectedInterestAreas)
      .then(response => {
        if (response.data.length !== 0) {
          this.interestAreas = response.data
          this.autoCompleteOptions = this.interestAreas
          options.splice(0)
          options.push(...this.interestAreas.map((item) => item.title))
        }
      })
      .catch(() => {
        this.interestAreas.splice(0)
      })
  }

  private getInterestAreasByCnpCode (value: number, options: Array<string>, culture: string, selectedInterestAreas: number []) {
    InterestAreasService.getInterestAreasByCnpCode(value, culture, selectedInterestAreas)
      .then(response => {
        if (response.data.length !== 0) {
          this.interestAreas = response.data
          this.autoCompleteOptions = this.interestAreas
          options.splice(0)
          options.push(...this.interestAreas.map((item) => item.title))
        }
      })
      .catch(() => {
        this.interestAreas.splice(0)
      })
  }

  private addInterestAreaGroup (selectedInterestArea : InterestArea) {
    if (this.interestAreaModel === undefined || this.interestAreaModel === null) {
      this.interestAreaModel = []
    }

    if (this.interestAreaModel.some(i => i.id === selectedInterestArea.interestAreaGroup.id)) {
      notifyNegative(this, this.interestAreaTranslations.error.familyNameExist)
      return
    }
    if (this.interestAreaModel.length === 3) {
      notifyNegative(this, this.interestAreaTranslations.error.maximumValues)
      return
    }

    this.interestAreaModel.push(selectedInterestArea.interestAreaGroup)
  }

  private getInterestAreaGroupNameTranslated (selectedGroup : InterestAreaGroup) : string {
    let culture : string = CultureService.getCultureCode()
    let translatedValue : string = selectedGroup.translations.find(t => t.cultureName === culture)?.name!

    return translatedValue
  }

  private deleteAreaGroup (selectedGroup : InterestAreaGroup) {
    let position = this.interestAreaModel.indexOf(selectedGroup)
    this.interestAreaModel.splice(position, 1)
  }
}
