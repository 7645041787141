























import { Component, Prop, Vue } from 'vue-property-decorator'
import { MissionEvent } from '../models/MissionEvent'
import { loadWhile } from '@/generic/helpers'
import MissionService from '@/services/Mission.service'
import { MissionsLandingPageTranslations } from './translations/MissionsLandingPage.translations'

@Component
export default class MissionsLandingPageComponent extends Vue {
  @Prop() private translations!: MissionsLandingPageTranslations
  private events: MissionEvent[] = []
  private hasError = false
  private errorMessage = ''

  private mounted (): void {
    loadWhile(this, 'Loading', () => MissionService.getEvents(0).then((response) => {
      this.events = response.data
    }).catch((error) => {
      this.handleError(error)
    }))
  }

  private handleError (error: any): void {
    this.hasError = true
    if (error.response.status === 404) {
      this.errorMessage = this.translations.error.notFound
    } else if (error.response.status === 403) {
      this.errorMessage = this.translations.error.forbidden
    } else {
      this.errorMessage = this.translations.error.unknown
    }
  }
}
