import { Filter } from '../Filter'

export class CandidatsListFilter {
    total: number
    pageNumber: number
    pageSize: number
    candidatesType: string
    firstName: string
    lastName: string
    email: string
    selectedEvent: string
    selectedEventType: string
    selectedJobOffer: number
    city: string
    country: number
    candidatesIds: string
    searchTerms: string
    cultureName: string
    onlyValidProfiles:boolean
    onlyParticipants:boolean
    languageProficiencyLevelFr: string
    levelOfStudy: string
    educationCountry: number
    countryOfCitizenship: number
    languageProficiencyLevelEn: string
    filterValue: Filter
    codeCnp: number
    industry: string

    constructor () {
      this.total = 0
      this.pageNumber = 1
      this.pageSize = 20
      this.candidatesType = 'All'
      this.firstName = ''
      this.lastName = ''
      this.email = ''
      this.selectedEvent = ''
      this.selectedEventType = ''
      this.selectedJobOffer = 0
      this.city = ''
      this.country = 0
      this.candidatesIds = ''
      this.searchTerms = ''
      this.cultureName = ''
      this.onlyValidProfiles = false
      this.onlyParticipants = false
      this.languageProficiencyLevelFr = ''
      this.levelOfStudy = ''
      this.educationCountry = 0
      this.countryOfCitizenship = 0
      this.languageProficiencyLevelEn = ''
      this.filterValue = 0
      this.codeCnp = 0
      this.industry = ''
    }
}
