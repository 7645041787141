





















import { Component, Prop, Vue } from 'vue-property-decorator'
import LineChartComponent from '@/generic/components/charts/LineChart.vue'
import CandidateService from '@/services/CandidateService'
import LineChart from '@/generic/components/charts/LineChart.model'
import CandidateChartTranslations from './CandidateChart.translations'
import { CommonErrorTranslations } from '@/generic/models/CommonError.translations'

@Component({
  components: {
    'line-chart': LineChartComponent
  }
})
export default class CandidateChartComponent extends Vue {
  @Prop() height !: number
  @Prop() translations !: CandidateChartTranslations
  @Prop() graphLocale !: string
  @Prop() companyId !: number
  @Prop() errors!: CommonErrorTranslations
  loaded : boolean = false
  chartData : LineChart = new LineChart()
  error : any
  hasError : boolean = false
  service : CandidateService
  options : any = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    scales: {
      xAxes: [{
        ticks: {
          fontColor: '#35383f',
          opacity: 0.83,
          fontFamily: 'Radnika',
          fontWeight: 600,
          fontSize: 13
        },
        gridLines: {
          display: false,
          drawBorder: false
        }
      }],
      yAxes: [{
        ticks: {
          min: 0,
          max: 50,
          stepSize: 10,
          fontColor: '#35383f',
          opacity: 0.83,
          fontWeight: 600,
          fontFamily: 'Radnika',
          fontSize: 13
        },
        gridLines: {
          display: true,
          borderDash: [15, 5],
          drawBorder: false
        }
      }]

    },
    tooltips: {
      displayColors: false,
      xPadding: 44,
      yPadding: 16,
      callbacks: {
        title: function (tooltipItem: any, data: LineChart) {
          return ''
        },
        label: (tooltipItem: any, data: LineChart) => {
          return data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] + ' ' + this.translations.tooltip
        }
      }
    }
  }

  constructor () {
    super()
    this.service = new CandidateService()
  }

  get myStyles () {
    return {
      position: 'relative'
    }
  }

  setGraphMax (keys: string[], data: any) {
    const dataArray : number[] = keys.map(key => data[key])
    const max = Math.ceil(Math.max(...dataArray) / 10) * 10
    const stepSize = Math.ceil(Math.ceil(max / 20) / 10) * 10
    this.options.scales.yAxes[0].ticks.stepSize = stepSize
    this.options.scales.yAxes[0].ticks.max = Math.ceil(max / stepSize / 10) * 10 * stepSize
  }

  async mounted () {
    try {
      CandidateService.getCandidatesForEmployer(this.companyId)
        .then(response => {
          let keys = Object.keys(response.data).reverse()
          let labels = keys.map(val => {
            return new Date(val).toLocaleDateString(this.graphLocale, { month: 'short', day: 'numeric' })
          })

          this.setGraphMax(keys, response.data)

          this.chartData.labels = labels
          var color = 'rgba(247,84,135,1)'
          this.chartData.datasets.push({
            backgroundColor: 'rgba(247, 84, 135, 0.12)',
            borderColor: color,
            pointBorderColor: color,
            pointBackgroundColor: 'white',
            pointRadius: 4,
            pointBorderWidth: 2,
            pointStyle: 'circle',
            pointHoverBackgroundColor: 'white',
            pointHoverBorderColor: color,
            pointHoverBorderWidth: 2,
            pointHoverRadius: 4,
            lineTension: 0,
            data: keys.map(key => response.data[key])
          })
          this.loaded = true
        })
        .catch(error => {
          this.hasError = true
          if (error.response.status === 404) {
            this.error = this.errors.notFound
          } else {
            this.error = this.errors.unknown
          }
        })
    } catch (e) {
      this.error = this.errors.unknown
    }
  }
}
