











































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { EmployerCheckoutTranslations } from '../models/EmployerCheckout.translations'
import { Address, ShoppingCart } from '../models/ShoppingCart'
import { loadWhile } from '@/generic/helpers'
import { ShoppingCartService } from '@/services/ShoppingCartService'
import { PaymentService } from '@/services/PaymentService'
import { RequiredRule } from '@/generic/rules/Required'
import { PostalCodeRule } from '@/generic/rules/PostalCode'
import ProductContract from '../models/ProductContract'
import { ProductType } from '../models/ProductType'
import ShoppingCartSummaryComponent from '../ShoppingCart/ShoppingCartSummary.vue'

@Component({
  components: {
    'mitm-shopping-summary': ShoppingCartSummaryComponent
  }
})
export default class EmployerCheckoutComponent extends Vue {
    @Prop() translations!: EmployerCheckoutTranslations
    @Prop() companyId!: number
    @Prop() companyName!: string
    @Prop() culture!: string
    @Prop() publishKey!: string
    @Prop() recruiterEmail!: string
    private shoppingCart!: ShoppingCart
    private contracts!: ProductContract[]
    private currentStep = 1
    private stepRefs = ['step1', 'step2', 'step3']
    private hasError: boolean=false
    private loaded: boolean =false
    private address: Address = { address: '', city: '', postalCode: '' }
    private rules: { [key: string]: Array<(value: any) => boolean | string> } = {
      contract: [
        new RequiredRule().getValidator(
          this.translations.common.message.required
        )
      ],
      address: [
        new RequiredRule().getValidator(
          this.translations.common.address.message.addressRequired
        )
      ],
      postalCode: [
        new RequiredRule().getValidator(
          this.translations.common.address.message.postalCodeRequired
        ),
        new PostalCodeRule().getValidator(
          this.translations.common.address.message.invalidPostalCode
        )
      ],
      city: [
        new RequiredRule().getValidator(
          this.translations.common.address.message.cityRequired
        )
      ]
    };

    mounted () {
      loadWhile(this, this.translations.common.message.loading, () => Promise.all([
        ShoppingCartService.getShoppingCart(this.culture, true)
          .then(response => {
            this.shoppingCart = response.data
            this.shoppingCart.address = this.address
            this.shoppingCart.receiptEmail = this.recruiterEmail
          }).catch(() => {
            this.hasError = true
          }),
        ShoppingCartService.getProductsContracts(this.culture)
          .then(response => {
            this.contracts = response.data
            this.contracts.forEach(x => { x.url = ShoppingCartService.getDownloadProductContractUrl(x.attachmentGuid) })
          }).catch(() => {
            this.hasError = true
          })
          .finally(() => {
            this.loaded = true
          })
      ])
      )
    }

    get getTodayDate() : string {
      return new Date().toLocaleDateString(this.culture)
    }

    private validate (): Promise<boolean> {
      const form = this.$refs[this.stepRefs[this.currentStep - 1]] as any
      return form.validate()
    }

    private pageButtonLabel (page: number): string | null {
      if (page < this.currentStep) {
        return null
      }
      return page.toString()
    }

    private changePageTo (page: number): void {
      if (page > this.currentStep) {
        if (this.currentStep === 1 && this.isAllContactsSigned()) {
          this.shoppingCart.contracts = this.contracts
          this.currentStep = page
        } else if (this.currentStep === 2 && this.validAddress()) {
          this.createOrUpdatePaymentIntent(page)
        }
      } else {
        this.currentStep = page
      }
    }

    isAllContactsSigned () : boolean {
      return this.contracts.find(c => c.isSigned === false || c.signatoryName === '' || c.signatoryTitle === '') === undefined
    }
    private validAddress () : boolean {
      return this.address.address !== '' && this.address.city !== '' && this.address.postalCode !== ''
    }

    private pageButtonIcon (page: number): boolean {
      return page < this.currentStep
    }

    private pageButtonColor (page: number): string {
      if (page === this.currentStep) {
        return 'active-page'
      }
      if (page < this.currentStep) {
        return 'previous-page'
      }
      return 'next-page'
    }

    private goToNextPage (): void {
      this.changePageTo(this.currentStep + 1)
    }

    private goToPreviousPage (): void {
      this.changePageTo(this.currentStep - 1)
    }

    private createOrUpdatePaymentIntent (page: number) {
      this.shoppingCart.address = this.address
      PaymentService.createOrUpdatePaymentIntent(this.shoppingCart)
        .then(response => {
          this.shoppingCart = response.data
          this.currentStep = page
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error.response.data.message)
        })
    }

    private signed (val: ProductContract) {
      this.$forceUpdate()
    }

    private getContractTranslation (contract: ProductContract) : string {
      return contract.productType === ProductType.JobOffer
        ? this.translations.checkout.section.one.button.jobOffer
        : contract.productType === ProductType.Mission
          ? this.translations.checkout.section.one.button.mission
          : this.translations.checkout.section.one.button.permit
    }
}
