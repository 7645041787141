import { BASE_API } from './api-common'
import { AxiosPromise } from 'axios'
import { CultureService } from './CultureService'

export default class SubdivisionService {
  public static getSubdivisions (countryId: number) : AxiosPromise<string> {
    const lang = CultureService.getCulture()
    return BASE_API.get(`country/getSubdivisions?countryId=${countryId}&language=${lang}`)
  }
}
