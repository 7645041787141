
























































































































































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { downloadFileCsv, loadWhile } from '@/generic/helpers'
import Candidate from '@/candidate/Candidate.model'
import CandidateCardTranslations from '@/candidate/candidates-list/CandidateCard.translations'
import { CultureService } from '@/services/CultureService'
import { Culture } from '@/generic/models/Culture'
import { SelectOption } from '@/generic/models/SelectOption'
import AdminConsoleService from '../../services/AdminConsoleService'
import { CandidatsListFilter } from '../../models/candidats/CandidatsListFilter.model'
import { getActivityAreasMifi } from '@/generic/activity-area-helper'
import { LanguageProficiency } from '@/generic/models/LanguageProficiency.model'

@Component
export default class AdminCandidatesListComponent extends Vue {
  @Prop() translations!: CandidateCardTranslations
  @Prop() private filterData!: Array<SelectOption>
  @Prop() private levelOfStudyOptions!: Array<string>
  @Prop() private countryOptions!: Array<string>
  @Prop() private languageProficienciesOptions!: Array<LanguageProficiency>
  private industries: Array<SelectOption> = getActivityAreasMifi(this.translations.industries)
  private filters = new CandidatsListFilter()
  private events: SelectOption[] = []
  private jobsList: SelectOption[] = []
  private selectedEvent!: SelectOption | null
  private selectedJobOffer!: SelectOption | null
  private candidates: Array<Candidate> = []
  private hasError = false
  private loaded = true
  private eventsLoaded = false
  private jobsOffersLoaded = false
  private searchTerms = ''
  private animation = false
  private pageSizeOptions = [20, 50, 100]
  private pageSizeOldValue = 20
  private candidatesTypes = [
    this.translations.common.option.all,
    this.translations.candidatesPage.applicant,
    this.translations.candidatesPage.suggested
  ]
  private candidatesTypeOldValue = this.translations.common.option.all
  private languageProficiencyLevelFr!: LanguageProficiency | null
  private languageProficiencyLevelEn!: LanguageProficiency | null
  private industry!: SelectOption | null
  private levelOfStudy!: SelectOption | null
  private country!: SelectOption | null
  private educationCountry!: SelectOption | null
  private countryOfCitizenship!: SelectOption | null
  private selectedFilterValue: SelectOption = { value: 0, label: '' }

  private mounted (): void {
    this.filters.candidatesType = this.translations.common.option.all
    const preferredCulture = CultureService.getCulture()
    if (preferredCulture === Culture.English) {
      this.filters.cultureName = 'en-US'
    } else {
      this.filters.cultureName = 'fr-CA'
    }

    this.loadEvents((a: boolean | void) => {})
    this.loadJobs((a: boolean | void) => {})
    this.loadcandidates()
  }

  private get animationOutClass () {
    return 'animated ' + (this.$q.screen.lt.md ? 'slideOutRight' : 'fadeOut')
  }

  private loadcandidates (): void {
    this.loaded = false
    loadWhile(this, this.translations.common.message.loading, () =>
      this.loadApplicantCandidates((a: boolean | void) => {}).then(() => {
        this.loaded = true
      })
    )
  }

  private loadEvents (done: Function): Promise<void> {
    this.eventsLoaded = false
    this.hasError = false
    return AdminConsoleService.getDropdownEvents(this.filters.cultureName)
      .then((result) => {
        this.events = [{ label: this.translations.common.option.all, value: '' }, { label: this.translations.common.option.rec, value: '0-0' }]
        this.events = this.events.concat(result.data)
        this.eventsLoaded = true
        done(true)
      })
      .catch((error) => {
        if (error) {
          this.hasError = true
        }
        done(true)
      })
  }

  private loadJobs (done: Function): Promise<void> {
    this.jobsOffersLoaded = false
    this.hasError = false
    return AdminConsoleService.getAllJobs(this.filters.cultureName, this.filters.selectedEvent)
      .then((result) => {
        this.jobsList = [{ label: this.translations.common.option.all, value: '' }]
        this.jobsList = this.jobsList.concat(result.data)
        this.jobsOffersLoaded = true
        done(true)
      })
      .catch((error) => {
        if (error) {
          this.hasError = true
        }
        this.jobsOffersLoaded = true
        done(true)
      })
  }

  private loadApplicantCandidates (done: Function): Promise<void> {
    this.hasError = false
    return AdminConsoleService.getCandidates(this.filters)
      .then((response) => {
        this.filters = response.data.filters
        this.candidates = []
        if (response.data.candidatures.length > 0) {
          Array.prototype.push.apply(this.candidates, response.data.candidatures)
          done()
        } else {
          done(true)
        }
      })
      .catch((error) => {
        if (error) {
          this.hasError = true
        }
        done(true)
      })
  }

  private onPageChange (newPage: number) {
    if (newPage !== this.filters.pageNumber) {
      this.filters.pageNumber = newPage
      this.onSearch()
    }
  }

  private onSearch () {
    this.loadcandidates()
  }

  private resetFilters () {
    this.filters = new CandidatsListFilter()
    this.selectedEvent = null
    this.selectedJobOffer = null
    this.languageProficiencyLevelEn = null
    this.languageProficiencyLevelFr = null
    this.industry = null
    this.country = null
    this.educationCountry = null
    this.countryOfCitizenship = null
    this.levelOfStudy = null
  }

  private advancedSearch () {
    this.resetPageNumber()
    this.loadcandidates()
  }

  private exportSearch () {
    this.loaded = false

    loadWhile(this, this.translations.common.message.loading, async () => {
      this.hasError = false

      try {
        const response = await AdminConsoleService.exportCandidates(this.filters)

        downloadFileCsv(response)
      } catch (error) {
        if (error) {
          this.hasError = true
        }
      }

      this.loaded = true
    })
  }

  private onSearchTermsChange (newSearchTerms: string): void {
    this.filters.searchTerms = newSearchTerms
    if (this.filters.searchTerms.length > 4) {
      this.resetPageNumber()
    }
  }

  private eventSelected (eventSelected: SelectOption) {
    if (eventSelected && eventSelected.value !== '') {
      this.selectedEvent = eventSelected
      this.filters.selectedEvent = eventSelected.value.toString()
    } else {
      this.filters.selectedEvent = ''
      this.selectedEvent = null
    }
    this.$forceUpdate()
  }

  private jobOfferSelected (jobSelected: SelectOption) {
    if (jobSelected && jobSelected.value !== '') {
      this.selectedJobOffer = jobSelected
      this.filters.selectedJobOffer = +jobSelected.value
    } else {
      this.filters.selectedJobOffer = 0
      this.selectedJobOffer = null
    }
    this.$forceUpdate()
  }

  private pageSizeChanged () {
    if (this.filters.pageSize !== this.pageSizeOldValue) {
      this.pageSizeOldValue = this.filters.pageSize
      this.resetPageNumber()
    }
  }

  private candidatesTypeChanged () {
    if (this.filters.candidatesType !== this.candidatesTypeOldValue) {
      this.candidatesTypeOldValue = this.filters.candidatesType
      this.resetPageNumber()
    }
  }

  private industryChanged (industry: SelectOption) {
    if (industry && industry.value !== '') {
      this.industry = industry
      this.filters.industry = industry.value + ''
    } else {
      this.filters.industry = ''
      this.industry = null
    }
    this.$forceUpdate()
  }

  private levelOfStudyChanged (levelOfStudy: SelectOption) {
    if (levelOfStudy && levelOfStudy.value !== '') {
      this.levelOfStudy = levelOfStudy
      this.filters.levelOfStudy = levelOfStudy.value + ''
    } else {
      this.filters.levelOfStudy = ''
      this.levelOfStudy = null
    }
    this.$forceUpdate()
  }

  private countryChanged (country: SelectOption) {
    if (country && country.value !== '') {
      this.country = country
      this.filters.country = +country.value
    } else {
      this.filters.country = 0
      this.country = null
    }
    this.$forceUpdate()
  }

  private educationCountryChanged (educationCountry: SelectOption) {
    if (educationCountry && educationCountry.value !== '') {
      this.educationCountry = educationCountry
      this.filters.educationCountry = +educationCountry.value
    } else {
      this.filters.educationCountry = 0
      this.educationCountry = null
    }
    this.$forceUpdate()
  }

  private countryOfCitizenshipChanged (countryOfCitizenship: SelectOption) {
    if (countryOfCitizenship && countryOfCitizenship.value !== '') {
      this.countryOfCitizenship = countryOfCitizenship
      this.filters.countryOfCitizenship = +countryOfCitizenship.value
    } else {
      this.filters.countryOfCitizenship = 0
      this.countryOfCitizenship = null
    }
    this.$forceUpdate()
  }

  private frenchLevelChanged (languageProficiencyLevelFr: LanguageProficiency) {
    if (languageProficiencyLevelFr && languageProficiencyLevelFr.level !== '') {
      this.languageProficiencyLevelFr = languageProficiencyLevelFr
      this.filters.languageProficiencyLevelFr = languageProficiencyLevelFr.proficiencyLevel + ''
    } else {
      this.filters.languageProficiencyLevelFr = ''
      this.languageProficiencyLevelFr = null
    }
    this.$forceUpdate()
  }

  private englishLevelChanged (languageProficiencyLevelEn: LanguageProficiency) {
    if (languageProficiencyLevelEn && languageProficiencyLevelEn.level !== '') {
      this.languageProficiencyLevelEn = languageProficiencyLevelEn
      this.filters.languageProficiencyLevelEn = languageProficiencyLevelEn.proficiencyLevel + ''
    } else {
      this.filters.languageProficiencyLevelEn = ''
      this.languageProficiencyLevelEn = null
    }
    this.$forceUpdate()
  }

  private filterDataChanged () {
    if (this.filters.filterValue !== this.selectedFilterValue.value) {
      this.filters.filterValue = +this.selectedFilterValue.value
      this.resetPageNumber()
    }
  }

  private resetPageNumber () {
    this.filters.pageNumber = 1
  }
}
