


















































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class MITMCarouselComponent extends Vue {
  @Prop() private numberOfSlides!: number
  @Prop({ default: false }) private spacing!: boolean
  @Prop({ default: 4 }) private slidesToShow!: number
  @Prop({ default: false }) private shadow!: boolean
  @Prop({ default: false }) private switchNav!: boolean
  @Prop({ default: '' }) private primaryColor!: string
  @Prop({ default: false }) private deactivateCarousel!: boolean
  @Prop({ default: false }) private isBig!: boolean
  @Prop({ default: true }) private infinite!: boolean

  private get responsiveOptions () {
    return {
      slidesToShow: 1,
      navButtons: false,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: Math.min(2, this.slidesToShow)
          }
        },
        {
          breakpoint: 960,
          settings: {
            slidesToShow: Math.min(3, this.slidesToShow)
          }
        },
        {
          breakpoint: 1150,
          settings: {
            slidesToShow: Math.min(4, this.slidesToShow)
          }
        }
      ]
    }
  }
}
