import { render, staticRenderFns } from "./AdminCompanyCard.vue?vue&type=template&id=381c2a75&scoped=true&"
import script from "./AdminCompanyCard.vue?vue&type=script&lang=ts&"
export * from "./AdminCompanyCard.vue?vue&type=script&lang=ts&"
import style0 from "./AdminCompanyCard.vue?vue&type=style&index=0&id=381c2a75&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "381c2a75",
  null
  
)

export default component.exports