import GtmMixin, { GtmEvent } from './GtmMixin'
import Component from 'vue-class-component'

@Component
export default class GtmNewsletterMixin extends GtmMixin {
  protected OnSubscribeNewsletterFromPopup () {
    this.callGtm(GtmEvent.Newsletter, 'newsletter subscription', 'pop up')
  }

  protected OnSubscribeNewsletterFromSignup () {
    this.callGtm(GtmEvent.Newsletter, 'newsletter subscription', 'accpount flow')
  }
}
