

import { Component, Prop } from 'vue-property-decorator'
import MeetingCardDetailsComponent from '@/dashboard/meeting-list/MeetingCardDetails.vue'
import { loadWhile, notify } from '@/generic/helpers'
import AdminConsoleMeetingService from '../../services/AdminConsoleMeetingService'

@Component
export default class AdminMeetingCardDetailsComponent extends MeetingCardDetailsComponent {
  @Prop() candidateHref!: string

  showProfile () {
    window.open(this.candidateHref + this.candidateId, '_blank')
  }

  getMeetingDetails() : Promise<void> {
    return loadWhile(this, ' ', () => AdminConsoleMeetingService.getMeetingFollowUpDetails(this.meetingId)
      .then(response => {
        this.followUpDetails = response.data
      })
      .catch(error => {
        this.handleError(error)
      })
    )
  }

  saveFollowUp() : Promise<void> {
    return loadWhile(this, ' ', () => AdminConsoleMeetingService.saveFollowUp(this.followUp = {
      meetingId: this.meetingId,
      companyId: this.companyId,
      salary: this.selectedFollowUpSalary ? +this.selectedFollowUpSalary.value : 0,
      status: this.selectedFollowUpStatus ? +this.selectedFollowUpStatus.value : 0,
      note: this.followUpStatusNote
    })
      .then(response => {
        notify(this, this.translations.meetingsPage.saveNotify || 'Sauvegarder')
        this.followUpNeeded = false
        this.$emit('follow-up-save')
        this.loadMeetingDetails()
      })
      .catch(error => {
        this.handleError(error)
      })
    )
  }
}
