























import { Component, Prop, Vue } from 'vue-property-decorator'
import { JobOffer } from '@/offer/JobOffer.model'
import IJobCardTranslations from '@/offer/summary/JobCard.translations'
import MissionCompanyModel from '@/dashboard/missions/Mission.model'

@Component
export default class OfferGrid extends Vue {
  @Prop() cardTranslation !: IJobCardTranslations
  @Prop() jobsList!: JobOffer[]
  @Prop() companyId!: number
}
