import { IRule } from './IRule'

export class MinimumRule implements IRule {
  private minimum: number

  constructor (minimum: number) {
    this.minimum = minimum
  }

  getValidator (errorMessage: string, activate?: () => boolean): (value: any) => boolean|string {
    return (value: any): boolean|string => {
      if (activate === undefined || (activate && activate())) {
        return value >= this.minimum || errorMessage
      }
      return true
    }
  }
}
