








































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { downloadFileCsv, loadWhile } from '@/generic/helpers'
import CompaniesFilter from '../../models/companies/CompaniesFilter.model'
import CompaniesTranslations from '../../models/companies/Companies.translations'
import { CultureService } from '@/services/CultureService'
import { Culture } from '@/generic/models/Culture'
import AdminConsoleService from '../../services/AdminConsoleService'
import { Company } from '@/company/Company.model'
import { SelectOption } from '@/generic/models/SelectOption'
import AdminConsoleCompanyService from '../../services/AdminConsoleCompanyService'

@Component
export default class AdminCompaniesListComponent extends Vue {
  @Prop() private translations!: CompaniesTranslations
  @Prop() private filterData!: Array<SelectOption>
  private filters = new CompaniesFilter()
  private comapnies: Array<Company> = []
  private loaded = false
  private hasError = false
  private pageSizeOptions = [20, 50, 100]
  private pageSizeOldValue = 20
  private selectedFilterValue: SelectOption = { value: 0, label: '' }
  private events: SelectOption[] = []
  private eventsLoaded = false
  private selectedEvent!: SelectOption | null
  private activityAreas: SelectOption[] = []
  private activityAreasLoaded = false
  private selectedActivityArea!: SelectOption | null

  private mounted(): void {
    const preferredCulture = CultureService.getCulture()
    if (preferredCulture === Culture.English) {
      this.filters.culture = 'en-US'
    } else {
      this.filters.culture = 'fr-CA'
    }
    this.loadEvents((a: boolean | void) => {})
    this.loadActivityAreas((a: boolean | void) => {})
    this.load()
  }

  private load(): void {
    this.loaded = false
    loadWhile(this, 'Loading...', () =>
      this.loadCompanies((a: boolean | void) => {}).then(() => {
        this.loaded = true
      })
    )
  }

  private loadEvents(done: Function): Promise<void> {
    this.eventsLoaded = false
    return AdminConsoleService.getDropdownEvents(this.filters.culture)
      .then((result) => {
        this.events = [{ value: '', label: this.translations.common.option.all }, { label: this.translations.common.option.rec, value: '0-0' }]
        this.events = this.events.concat(result.data)
        this.eventsLoaded = true
        done(true)
      })
      .catch((error) => {
        if (error) {
          this.hasError = true
        }
        done(true)
      })
  }

  private loadActivityAreas(done: Function): Promise<void> {
    this.activityAreasLoaded = false
    return AdminConsoleService.GetActivityAreas(this.filters.culture)
      .then((result) => {
        this.activityAreas = [{ value: '', label: this.translations.common.option.all }]
        this.activityAreas = this.activityAreas.concat(result.data)
        this.activityAreasLoaded = true
        done(true)
      })
      .catch((error) => {
        if (error) {
          this.hasError = true
        }
        done(true)
      })
  }

  private loadCompanies(done: Function): Promise<void> {
    if (this.filters.jobId === null) {
      this.filters.jobId = 0
    }
    return AdminConsoleCompanyService.getCompanies(this.filters)
      .then((response) => {
        this.filters = response.data.filters
        if (this.filters.jobId === 0) {
          this.filters.jobId = null
        }
        this.comapnies = []
        if (response.data.companies.length > 0) {
          Array.prototype.push.apply(this.comapnies, response.data.companies)
          done()
        } else {
          done(true)
        }
      })
      .catch((error) => {
        if (error) {
          this.hasError = true
        }
        done(true)
      })
  }

  private onPageChange(newPage: number) {
    if (newPage !== this.filters.pageNumber) {
      this.filters.pageNumber = newPage
      this.load()
    }
  }

  private pageSizeChanged() {
    if (this.filters.pageSize !== this.pageSizeOldValue) {
      this.pageSizeOldValue = this.filters.pageSize
      this.resetPageNumber()
    }
  }

  private filterDataChanged() {
    if (this.filters.filterValue !== this.selectedFilterValue.value) {
      this.filters.filterValue = +this.selectedFilterValue.value
      this.resetPageNumber()
    }
  }

  private advancedSearch() {
    this.resetPageNumber()
    this.load()
  }

  private exportSearch() {
    if (this.filters.jobId === null) {
      this.filters.jobId = 0
    }

    loadWhile(this, this.translations.common.message.loading, async () => {
      this.hasError = false

      try {
        const response = await AdminConsoleCompanyService.exportCompanies(this.filters)

        downloadFileCsv(response)
      } catch (error) {
        if (error) {
          this.hasError = true
        }
      }
    })
  }

  private eventSelected(eventSelected: SelectOption) {
    if (eventSelected) {
      this.selectedEvent = eventSelected
      this.filters.selectedEvent = eventSelected.value.toString()
    } else {
      this.filters.selectedEvent = ''
      this.selectedEvent = null
    }
    this.$forceUpdate()
  }

  private activityAreaSelected(activityAreaSelected: SelectOption) {
    if (activityAreaSelected) {
      this.selectedActivityArea = activityAreaSelected
      this.filters.selectedActivityArea = +activityAreaSelected.value
    } else {
      this.filters.selectedActivityArea = 0
      this.selectedActivityArea = null
    }
    this.$forceUpdate()
  }

  private resetFilters() {
    this.filters = new CompaniesFilter()
    this.filters.jobId = null
    this.selectedEvent = null
    this.selectedActivityArea = null
  }

  private get animationOutClass() {
    return 'animated ' + (this.$q.screen.lt.md ? 'slideOutRight' : 'fadeOut')
  }

  private resetPageNumber() {
    this.filters.pageNumber = 1
  }
}
