






























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class CreateFirstOfferLayoutComponent extends Vue {
  @Prop() private otherLanguageLabel!: string
  @Prop() private otherLanguageLink!: string
}
