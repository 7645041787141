



























































import { Component, Prop, Vue } from 'vue-property-decorator'
import { LanguageSelectorTranslations } from './LanguageSelector.translations'
import { LanguageSelector } from './LanguageSelector.model'
import { PublicationLanguage } from '@/generic/models/PublicationLanguage'

@Component
export default class LanguageSelectorComponent extends Vue {
  @Prop() private translations!: LanguageSelectorTranslations;
  @Prop() private value!: LanguageSelector
  @Prop({ default: false }) private disable!: boolean

  private publicationLanguage = PublicationLanguage

  private onInput (lang: PublicationLanguage): void {
    this.$emit('input', lang)
  }
}
