



























































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { CreateProfileTranslations } from './CreateProfile.translations'
import { CreateProfileModel } from './CreateProfile.model'
import { SelectOption } from '@/generic/models/SelectOption'
import { RequiredRule } from '@/generic/rules/Required'
import HelpIconComponent from '../generic/components/MITMHelpIcon.vue'
import SkillHelpComponent from '../offer/skill/SkillHelp.vue'
import { LanguageProficiency } from '@/generic/models/LanguageProficiency.model'
import { Language } from '@/generic/models/Language.model'
import { LanguageHelper } from '@/generic/models/LanguageHelper.model'
import { CandidateLanguageProficiency } from '@/generic/models/CandidateLanguageProficiency.model'
import { ProficiencyTranslations } from './proficiency.translations'

@Component
export default class LanguageProficiencyComponent extends Vue {
  @Prop() private translations!: ProficiencyTranslations
  @Prop() private languageProficienciesListFr !: Array<LanguageProficiency>
  @Prop() private languageProficienciesListEn !: Array<LanguageProficiency>
  @Prop() private LanguageProficiencyFr !: number
  @Prop() private LanguageProficiencyEn !: number
  @Prop() private LanguageProficiencies !: Array<CandidateLanguageProficiency>
  private languageList: string[] = []
  private nbOtherLanguageMax: number = 2
  private otherLanguageTextboxValue: string[] = []

  private rules: { [key: string]: Array<(value: any) => boolean | string> } = {
    required: [
      new RequiredRule().getValidator(this.translations.name.required)
    ]
  }

  private mounted (): void {
    this.languageList = LanguageHelper.getAllLanguage(false)
    for (let i = 0; i < this.LanguageProficiencies.length; i++) {
      if (this.languageList.includes(this.LanguageProficiencies[i].name)) {
        this.otherLanguageTextboxValue[i] = ''
      } else {
        (document.getElementsByClassName('language-proficiency-textbox')[i] as HTMLInputElement).value = this.LanguageProficiencies[i].name
        this.otherLanguageTextboxValue[i] = this.LanguageProficiencies[i].name
      }
    }
  }

  public translate (language: string) {
    if (language === Language.arabic) {
      return this.translations.field.arabic
    }
    if (language === Language.spanish) {
      return this.translations.field.spanish
    }
    if (language === Language.hindi) {
      return this.translations.field.hindi
    }
    if (language === Language.mandarin) {
      return this.translations.field.mandarin
    }
    if (language === Language.portuguese) {
      return this.translations.field.portuguese
    }
    if (language === Language.other) {
      return this.translations.field.other
    }
  }

  private selectedLanguageProficiencyFr (id: number) {
    this.LanguageProficiencyFr = id
    this.$emit('selected-language-proficiency-fr', id)
  }

  private selectedLanguageProficiencyEn (id: number) {
    this.LanguageProficiencyEn = id
    this.$emit('selected-language-proficiency-en', id)
  }

  private selectedLanguageProficiency(id: number, index: number) {
    this.LanguageProficiencies[index].languageProficiencyID = id
    this.$emit('selected-language-proficiency', this.LanguageProficiencies)
  }

  private showTextBox(index: number, value: string) {
    let select = document.getElementsByClassName('language-proficiency-select')[index]
    let container = document.getElementsByClassName('language-proficiency-textbox-container')[index]
    if (value === 'other') {
      select?.classList.add('hidden')
      container?.classList.remove('hidden')
    } else {
      select?.classList.remove('hidden')
      container?.classList.add('hidden')
      this.otherLanguageTextboxValue[index] = ''
    }
  }

  private setOtherLanguage(index: number) {
    this.otherLanguageTextboxValue[index] = (document.getElementsByClassName('language-proficiency-textbox')[index] as HTMLInputElement).value
    this.LanguageProficiencies[index].name = this.otherLanguageTextboxValue[index]
  }

  private removeTextBox(index: number) {
    document.getElementsByClassName('language-proficiency-select')[index].classList.remove('hidden')
    document.getElementsByClassName('language-proficiency-textbox-container')[index].classList.add('hidden')
    this.LanguageProficiencies[index].name = this.languageList[0]
    this.otherLanguageTextboxValue[index] = ''
  }

  private canAddOtherLanguage() {
    return this.LanguageProficiencies !== null && this.LanguageProficiencies.length < this.nbOtherLanguageMax
  }

  private addRow() {
    if (this.canAddOtherLanguage()) {
      this.LanguageProficiencies.push({
        languageProficiencyID: null,
        name: this.languageList[0]
      })
      this.otherLanguageTextboxValue.push('')
    }
  }

  private deleteRow(index: number) {
    if (this.LanguageProficiencies !== null && this.LanguageProficiencies.length > 0) {
      this.LanguageProficiencies.splice(index, 1)
      this.$emit('selected-language-proficiency', this.LanguageProficiencies)
    }
  }
}
