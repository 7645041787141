









































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Menu, MenuItem } from '../model/Menu.model'
import SignOutService from '@/services/SignOutService'

@Component
export default class AppMenuComponent extends Vue {
  @Prop() private menu!: Menu
  @Prop() private signOutRedirect!: string
  @Prop() private open!: boolean
  @Prop() private otherLanguageLabel!: string
  @Prop() private otherLanguageLink!: string
  @Prop() private homepageUrl!: string

  private isMini = !this.$q.screen.lt.md
  private drawer = !this.$q.screen.lt.md
  private localStorageKey = 'viewedAppMenu'
  private firstViewOpenDrawerTrigger = 1000 // milliseconds
  private firstViewOpenDrawerDuration = 3000 // milliseconds

  mounted (): void {
    if (!this.$q.screen.lt.md) {
      const isInLocalStorage = localStorage.getItem(this.localStorageKey)
      if (!isInLocalStorage) {
        localStorage.setItem(this.localStorageKey, '1')
        setTimeout(() => {
          this.toggleMini()
        }, this.firstViewOpenDrawerTrigger)
        setTimeout(() => {
          this.toggleMini()
        }, this.firstViewOpenDrawerDuration)
      }
    }
  }

  public openDrawer (): void {
    this.drawer = true
  }

  public isMenuClosed () : boolean {
    return this.isMini
  }

  public toggleMini (): void {
    this.isMini = !this.isMini
    if (!this.$q.screen.lt.md) {
      if (this.isMini) {
        this.$emit('shrinked')
      } else {
        this.$emit('expanded')
      }
    }
  }

  public toggleDrawer (): void {
    this.drawer = !this.drawer
  }

  private navigateTo (link: string): void {
    window.location.href = link
  }

  private isActive (menuItem: MenuItem): boolean {
    return window.location.pathname === menuItem.link || (menuItem.alsoActiveFor !== undefined && menuItem.alsoActiveFor.includes(this.pathNameWithoutId))
  }

  private get pathNameWithoutId (): string {
    return window.location.pathname.replace(/\/\d+$/, '')
  }
}
