





























































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { SelectOption } from '@/generic/models/SelectOption'
import { OfferPrerequisiteTranslations } from './JobApplication.translations'
import { RequiredRule } from '@/generic/rules/Required'
import PreSelectionQuestionsHelp from './PreSelectionQuestionsHelp.vue'

@Component({
  components: {
    'mitm-PreSelectionQuestions-help': PreSelectionQuestionsHelp
  }
})
export default class PreSelectionQuestionsComponent extends Vue {
  @Prop() private translations !: OfferPrerequisiteTranslations
  @Prop() private questionOne!: string|null
  @Prop() private questionTwo!: string|null
  @Prop() private questionOneOptions!: SelectOption[]|null
  @Prop() private questionTwoOptions!: SelectOption[]|null
  @Prop() private responseOne!: number
  @Prop() private responseTwo!: number
  @Prop() private readOnly!: boolean
  @Prop() private isScaleType!: boolean
  private useInput = false

  private onQuestion1ResponseChange (value: number) {
    this.$emit('question-one-response-change', value)
  }

  private onQuestion2ResponseChange (value: number) {
    this.$emit('question-two-response-change', value)
  }

  private rules: { [key: string]: Array<(value: any) => boolean | string> } = {
    questionOneOptions: [new RequiredRule().getValidator(this.translations.preselection.answerRequired)],
    questionTwoOptions: [new RequiredRule().getValidator(this.translations.preselection.answerRequired)]
  }
}
