























import { Component, Prop, Vue } from 'vue-property-decorator'
import SignOutService from '@/services/SignOutService'

@Component
export default class MITMSignOutComponent extends Vue {
  @Prop({ default: true }) iconOnly!: boolean
  @Prop() label!: string
  @Prop() redirectUrl!: string
  @Prop() light!: boolean

  private signOut () {
    SignOutService.signOut()
      .then(() => { window.location.href = this.redirectUrl })
  }
}
