









































import { Component, Prop, Vue } from 'vue-property-decorator'
import { OfferService } from '@/services/OfferService'
import OfferCountTranslations from './OfferCount.translations'

@Component
export default class OfferCountComponent extends Vue {
  @Prop() companyId !: number
  @Prop() translations !: OfferCountTranslations
  @Prop() link !: string

  private jobCount : number = 0

  mounted () {
    OfferService.getActiveJobCount(this.companyId)
      .then(result => {
        this.jobCount = result.data.count
      })
  }
}
