












































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { RecruiterSchedule } from './RecruiterSchedule'
import { dateFilter, hourFilter } from '@/generic/filters/Date'
import { CultureService } from '@/services/CultureService'
import { MissionScheduleTranslations } from './MissionSchedule.translations'
import CandidateService from '@/services/CandidateService'
import { loadWhile } from '@/generic/helpers'
import { CandidateDetails } from '@/candidate/candidate-details/CandidateDetails.model'
import { JobApplicationModel } from '@/job-application/JobApplication.model'
import { MeetingService } from '@/services/MeetingService'
import MITMPromptComponent from '@/generic/components/MITMPrompt.vue'
import { DialogResult } from '@/generic/models/DialogResult'
import { Culture } from '../../generic/models/Culture'

@Component
export default class RecruiterMissionScheduleComponent extends Vue {
  @Prop() private schedule!: RecruiterSchedule
  @Prop() private companyName!: string
  @Prop() private translations!: MissionScheduleTranslations
  @Prop() private clickableRows!: boolean
  @Prop() private isVirtual!: boolean
  private details: CandidateDetails | null = null
  private candidate: JobApplicationModel | null = null
  private seeDetails = false
  private timeSlotId: number = 0
  private candidateId: number | null = null

  private columns = [{
    name: 'time',
    label: this.translations.missionSchedule.date,
    align: 'left',
    field: 'time'
  }, {
    name: 'jobOffer',
    label: this.translations.missionSchedule.offer,
    align: 'left',
    field: 'jobOffer'
  }, {
    name: 'candidateName',
    label: this.translations.missionSchedule.candidate,
    align: 'left',
    field: 'candidateName'
  }, {
    name: 'candidateTitle',
    label: this.translations.missionSchedule.title,
    align: 'left',
    field: 'candidateTitle'
  }]
  private pagination = {
    rowsPerPage: 0
  }

  private get meetings (): { candidateId: number | null, jobOffer: string, candidateName: string, candidateTitle: string, time: string, timeSlotId: number }[] {
    return this.schedule.meetings.map((meeting) => {
      let start = this.adjustTime(meeting.startTime)
      let end = this.adjustTime(meeting.endTime)
      let date = this.isVirtual ? dateFilter(meeting.startTime + 'Z', CultureService.getCulture(), {
        day: 'numeric',
        month: 'short',
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      }) : dateFilter(meeting.startTime, CultureService.getCulture(), { day: 'numeric', month: 'short' })
      return {
        candidateId: meeting.candidateId,
        jobOffer: (CultureService.getCulture() === Culture.French ? (meeting.offerFr || meeting.offerEn) : (meeting.offerEn || meeting.offerFr)) || '',
        candidateName: meeting.candidateName || '',
        candidateTitle: meeting.candidateTitle || '',
        timeSlotId: meeting.timeSlotId,
        time: `${date} ${start} - ${end}`
      }
    })
  }

  private get candidateDetails (): CandidateDetails | null {
    if (this.details === null || this.candidate === null) {
      return null
    }
    return { ...this.details, ...this.candidate }
  }

  private openProfile (candidateId: number | null): void {
    if (this.clickableRows && candidateId !== null) {
      loadWhile(this, this.translations.common.message.loading, () => {
        return Promise.all([
          CandidateService.getSimpleDetails(candidateId).then((response) => {
            this.details = response.data
          }),
          CandidateService.getCandidate(candidateId).then((response) => {
            this.candidate = response.data
          })
        ]).then(() => {
          this.seeDetails = true
        })
      })
    }
  }
  private cancelMeeting (result: DialogResult) {
    if (this.candidateId != null && result === DialogResult.Ok) {
      MeetingService.removeMeeting(
        this.timeSlotId,
        this.schedule.missionId,
        this.candidateId,
        this.schedule.recruiterId,
        this.schedule.companyId
      )
        .then(() => {
          this.$emit('refresh')
        })
    }
  }

  private createBtn (candidateId: number | null): any {
    if (this.clickableRows && candidateId !== null) {
      return true
    }
  }
  private cancelConfirmation (candidateId: number | null, timeSlotId: number) {
    (this.$refs.confirmPrompt as MITMPromptComponent).open()
    this.candidateId = candidateId
    this.timeSlotId = timeSlotId
  }

  private adjustTime (time : Date) {
    if (this.isVirtual) {
      return hourFilter(time, CultureService.getCulture(), {
        hour: 'numeric',
        minute: 'numeric',
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      })
    }

    return hourFilter(time)
  }
}
