import { BASE_API } from './api-common'
import { AxiosPromise } from 'axios'
import { SignIn, SignInResponse } from '@/authentication/sign-in/SignIn.model'
import { SignUpRequest } from '@/authentication/sign-up/SignUp.model'
import { ForgotPassword } from '@/forgot-password/ForgotPassword.model'
import { ResetPassword } from '@/reset-password/ResetPassword.model'
import { Invitation } from '@/authentication/sign-up/Invitation.model'
import { Contact } from '@/company/contact/Contact.model'

export class AuthenticationService {
  public static signOut () {
    return BASE_API.post('authenticate/signout')
  }

  public static signIn (infos: SignIn) : AxiosPromise<SignInResponse> {
    return BASE_API.post('authenticate/signin', infos)
  }

  public static signUp (request: SignUpRequest, invitation: Invitation, crmInvite: boolean) {
    return BASE_API.post('authenticate/registeremployer', {
      userRequest: request,
      invitation: invitation,
      crmInvite: crmInvite
    })
  }

  public static signUpCandidate (request: SignUpRequest) {
    return BASE_API.post('authenticate/registercandidate', request)
  }

  public static resetPassword (model : ForgotPassword) {
    return BASE_API.put('authenticate/forgotPassword', model)
  }

  public static resendConfirmationEmail (userEmail : string, culture: string) {
    return BASE_API.get('authenticate/resendConfirmationEmail', {
      params: {
        userEmail: userEmail,
        culture: culture
      }
    })
  }

  public static changePassword (model : ResetPassword) {
    return BASE_API.post('authenticate/resetPassword', model)
  }

  public static validatePassword (user: number, password: string) : AxiosPromise<boolean> {
    return BASE_API.get(`authenticate/validatePassword/${user}`, {
      params: {
        password: password
      }
    })
  }

  public static getUserInfo (userId: number) : AxiosPromise<Contact> {
    return BASE_API.get('authenticate/userInfo', {
      params: {
        userId: userId
      }
    })
  }

  public static reconfirmAccount (culture : string) {
    return BASE_API.put('authenticate/reconfirm', null, {
      params: {
        culture: culture
      }
    })
  }

  public static updateConsent (candidateId: number, role: string) : AxiosPromise<any> {
    return BASE_API.put(`authenticate/updateConsent/${candidateId}`, null, {
      params: {
        role: role
      }
    })
  }
}
