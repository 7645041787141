

















import { Component, Prop, Vue } from 'vue-property-decorator'
import { AdminDashboardPlatformEmployersCardTranslations } from '../../translations/AdminDashboard.translations'

@Component
export default class AdminEmployersAggregateStatsComponent extends Vue {
  @Prop() public total!: number
  @Prop() public translations!: AdminDashboardPlatformEmployersCardTranslations
}
