







































import { Component, Prop, Vue } from 'vue-property-decorator'
import MITMInputComponent from '@/generic/components/MITMInput.vue'
import AdminAuthenticateTranslations from '../translations/AdminAuthenticate.translations'
import AdminAuthenticateModel from '../models/AdminAuthenticate.model'
import AdminConsoleService from '../services/AdminConsoleService'
import { RequiredRule } from '@/generic/rules/Required'
import { getUrlParameter, loadWhile } from '@/generic/helpers'
import { CultureService } from '@/services/CultureService'

@Component({
  components: {
    'mitm-input': MITMInputComponent
  }
})
export default class AdminAuthenticateComponent extends Vue {
  @Prop() translations!: AdminAuthenticateTranslations

  private model: AdminAuthenticateModel = { admin: '', password: '', culture: '', returnUrl: '' }
  private hasError: boolean = false
  private errorMessage: string = ''
  private rules: { [key: string]: Array<(value: any) => boolean | string> } = {
    required: [new RequiredRule().getValidator(this.translations.field.required)]
  }

  private login () {
    this.model.culture = CultureService.getCultureCode()
    this.hasError = false
    this.model.returnUrl = getUrlParameter('ReturnUrl')
    loadWhile(this, this.translations.message.loading, () => AdminConsoleService.Login(this.model)
      .then(response => {
        window.location.href = response.data
      })
      .catch(error => {
        switch (error.response.status) {
          case 401:
            this.errorMessage = this.translations.error.unauthorized
            break
          case 403:
            this.errorMessage = this.translations.error.forbidden
            break
          case 404:
            this.errorMessage = this.translations.error.notFound
            break
          default:
            this.errorMessage = this.translations.error.unknown
            break
        }
        this.hasError = true
      })
    )
  }
}
