





















































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import MediaObject, { MediaType } from '../models/MediaObject'
import TriangleAnimation from './TriangleAnimation'
import MITMCopyrightComponent from '@/generic/components/MITMCopyright.vue'

@Component({
  components: {
    'mitm-copyright': MITMCopyrightComponent
  }
})
export default class MediaBlockComponent extends Vue {
  @Prop() media!: MediaObject
  @Prop() titleColor!: string
  @Prop() title!: string
  @Prop() link!: string
  @Prop({ default: false }) private displayAnimation!: boolean
  @Prop({ default: false }) private heroBanner!: boolean
  @Prop({ default: false }) private autoplay!: boolean
  @Prop({ default: 0 }) private maxSize!: number
  private animation: TriangleAnimation | null = null
  private imageHeight = 0
  private imageWidth = 0
  private originalHeight = 0
  private originalWidth = 0
  private img: any = null

  @Watch('$q.screen.gt.sm')
  private onScreenChange (): void {
    if (this.displayAnimation) {
      if (!this.$q.screen.gt.sm) {
        if (this.animation) {
          this.animation.destroy()
        }
      } else {
        if (!this.animation) {
          this.animation = new TriangleAnimation(this.$refs.mediaBlock as HTMLElement, this.originalWidth, this.originalHeight)
        }
        this.animation.init()
        this.animation.playOpen()
      }
    }
  }

  private get hasUrl() {
    return this.link !== null || this.link !== ''
  }

  private onCanPlay () {
    (this.$refs.video as HTMLVideoElement).play()
  }

  private get isVideo () {
    return this.media && this.media.type === MediaType.Video
  }

  private get imageUrl () {
    if (this.maxSize > 0) {
      return this.media.url + '?maxsidesize=' + this.maxSize
    }

    return this.media.url
  }

  private setupAnimation (event: Event): void {
    if (this.displayAnimation) {
      let image = (event.target as HTMLImageElement)
      this.imageHeight = image.height
      this.imageWidth = image.width
      this.originalHeight = image.naturalHeight
      this.originalWidth = image.naturalWidth
      this.$nextTick(() => {
        this.img = this.$refs.mediaImage
        if (this.$q.screen.gt.sm) {
          this.animation = new TriangleAnimation(this.$refs.mediaBlock as HTMLElement, this.originalWidth, this.originalHeight)
          this.animation.playOpen()
        }
      })
    }
  }

  private get isController () {
    if (this.autoplay) {
      return {
        '--isController': `0`
      }
    } else {
      return {
        '--isController': `70px`
      }
    }
  }

  private triggerPlayPause () {
    const video = this.$refs.video as HTMLVideoElement
    if (video.paused === true) {
      return video.play()
    } else {
      return video.pause()
    }
  }
}
