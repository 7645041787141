import { BASE_API } from './api-common'
import { AxiosPromise } from 'axios'
import { ChangeAccountModel } from '@/authentication/account/ChangeAccount.model'

export class AccountService {
  public static updateAccount (model: ChangeAccountModel) : AxiosPromise<ChangeAccountModel> {
    return BASE_API.put(`account/update/${model.id}`, model)
  }

  public static updateAccountFromAdmin (model: ChangeAccountModel) : AxiosPromise<ChangeAccountModel> {
    return BASE_API.put(`account/AdminUpdate/${model.id}`, model)
  }

  public static disableRecruiter (companyId: number, id: number, deleteRecruiter: boolean) : AxiosPromise<any> {
    return BASE_API.delete(`account/disable/${id}`, { params: {
      companyId: companyId,
      deleteRecruiter: deleteRecruiter
    } })
  }

  public static deleteAccount (password: string): AxiosPromise<void> {
    return BASE_API.delete('account/delete', {
      params: {
        password: password
      }
    })
  }
}
