
















import { Component, Prop, Vue } from 'vue-property-decorator'
import { MinimumRule } from '@/generic/rules/Minimum'
import MITMInput from '@/components/MITMInput.vue'
import { IsNumber } from '@/generic/rules/IsNumber'
import { RequiredRule } from '@/generic/rules/Required'
import { JobInfoTranslations } from '@/offer/info/JobInfo.translations'
import { CommonErrorTranslations } from '@/generic/models/CommonError.translations'

@Component
export default class JobCountComponent extends Vue {
  @Prop() private translations!: JobInfoTranslations;
  @Prop() private errorTranslations!: CommonErrorTranslations;
  @Prop() private value!: number;

  private rules: { [key: string]: Array<(value: any) => boolean | string> } = {
    isNumber: [
      new IsNumber().getValidator(this.errorTranslations.validNumber),
      new RequiredRule().getValidator(this.errorTranslations.validNumber)
    ]
  };

  private get localValue (): number {
    return this.value
  }

  private set localValue (value: number) {
    this.$emit('input', value)
  }
}
