






















































































import { Component, Model, Prop, Vue } from 'vue-property-decorator'
import { CompanyBasicInfoTranslations } from './BasicInfo.translations'
import { BasicCompanyInfo } from './BasicInfo.model'
import { ActivityArea } from '@/activity-area/ActivityArea.model'
import { ActivityAreaInfo } from '@/activity-area/ActivityAreaInfo.model'
import { ActivityAreaTranslations } from '@/activity-area/ActivityArea.translations'
import { RequiredRule } from '@/generic/rules/Required'
import { PostalCodeRule } from '@/generic/rules/PostalCode'
import { SelectOption } from '@/generic/models/SelectOption'
import { IRule } from '@/generic/rules/IRule'
import MITMInput from '@/components/MITMInput.vue'
import { CommonAddressTranslations } from '@/generic/models/CommonAddress.translations'
import { IsPhone } from '@/generic/rules/IsPhone'
import { AdministrativeRegionHelper } from '@/generic/models/AdministrativeRegionHelper.model'

@Component
export default class BasicInfoComponent extends Vue {
  @Prop() private translations!: CompanyBasicInfoTranslations
  @Prop() private commonTranslations!: CommonAddressTranslations
  @Prop() private value!: BasicCompanyInfo
  @Prop() private sectionNumber!: number
  @Prop() private activityAreaModel!: ActivityAreaInfo
  @Prop() private activityAreaTranslations!: ActivityAreaTranslations
  @Prop() private activityAreaList!: ActivityArea[]
  @Prop({ type: Boolean, default: false }) private hideName!: boolean
  private administrativeRegions: Array<SelectOption> = AdministrativeRegionHelper.getRegion()

  private rules: { [key: string]: Array<(value: any) => boolean | string> } = {
    name: [new RequiredRule().getValidator(this.translations.message.nameRequired)],
    address: [
      new RequiredRule().getValidator(this.commonTranslations.message.addressRequired)
    ],
    postalCode: [
      new RequiredRule().getValidator(this.commonTranslations.message.postalCodeRequired),
      new PostalCodeRule().getValidator(this.commonTranslations.message.invalidPostalCode)
    ],
    city: [new RequiredRule().getValidator(this.commonTranslations.message.cityRequired)],
    phone: [
      new IsPhone().getValidator(this.commonTranslations.message.validPhone),
      new RequiredRule().getValidator(this.translations.message.phoneNumberRequired)
    ],
    region: [new RequiredRule().getValidator(this.translations.message.regionRequired)]
  }
}
