
























































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { fullURL, getUrlParameter, loadWhile } from '@/generic/helpers'
import AdminConsoleProductService from '../../services/AdminConsoleProductService'
import ProductsListTranslations from '../../models/products/ProductsList.translations'
import Product, { ProductTabEnum } from '../../models/products/Product.models'
import Cohort from '../../models/products/Cohort.models'
import { ProductType } from '@/dashboard/employer-package/models/ProductType'

@Component
export default class AdminProductsListComponent extends Vue {
  @Prop() translations!: ProductsListTranslations
  tab: number = ProductTabEnum.Cohort
  tabs = ProductTabEnum
  recProducts: Array<Product> = []
  missionProducts: Array<Product> = []
  cohortProducts: Array<Cohort> = []
  hasError: boolean = false
  errorMessage!: string
  loaded: boolean = false
  showDialog: boolean = false
  showAssociationDialog: boolean = false
  skuGuid: string = ''
  productName: string = ''
  private needReloading: boolean = false
  productTypeEnum: any = ProductType
  productType: ProductType = ProductType.JobOffer

  mounted () {
    const requestedTab = getUrlParameter('tab') || this.tabs.All
    if ([this.tabs.All, this.tabs.Rec, this.tabs.Mission, this.tabs.Cohort].includes(+requestedTab)) {
      this.tab = +requestedTab
    } else {
      this.updateUrl()
    }

    this.loadProducts()
  }

  loadProducts() {
    this.recProducts = []
    this.missionProducts = []
    this.cohortProducts = []
    this.loaded = false
    loadWhile(this, this.translations.common.message.loading, () => Promise.all([
      this.getProducts()
    ]).then(() => {
      this.$forceUpdate()
      this.loaded = true
    }).catch(error => {
      this.handleError(error)
    }))
  }

  private getProducts(): Promise<void> {
    return AdminConsoleProductService.getProducts()
      .then((response) => {
        if (response.data.recs.length > 0) {
          Array.prototype.push.apply(this.recProducts, response.data.recs)
        }

        if (response.data.missions.length > 0) {
          Array.prototype.push.apply(this.missionProducts, response.data.missions)
        }

        if (response.data.cohorts.length > 0) {
          Array.prototype.push.apply(this.cohortProducts, response.data.cohorts)
        }
      })
      .catch((error) => {
        if (error) {
          this.handleError(error)
        }
      })
  }

  toogleDialogFromCard(skuGuid: string) {
    this.skuGuid = skuGuid
    this.toogleDialog()
  }

  toogleAssociationDialogFromCard({ skuGuid, productName, productType } : { skuGuid: string, productName: string, productType: ProductType }): void {
    this.skuGuid = skuGuid
    this.productType = productType
    this.productName = productName
    this.showAssociationDialog = !this.showAssociationDialog
  }

  toogleDialog () {
    this.showDialog = !this.showDialog
  }

  showNoProductMessage(tab: number) : boolean {
    var show = false
    if ([this.tabs.All, this.tabs.Rec].includes(tab)) {
      show = this.recProducts.length === 0 || show
    } else if ([this.tabs.All, this.tabs.Mission].includes(tab)) {
      show = this.missionProducts.length === 0 || show
    } else if ([this.tabs.All, this.tabs.Cohort].includes(tab)) {
      show = this.cohortProducts.length === 0 || show
    }

    return show
  }

  onTabChange (newVal: number) {
    this.tab = newVal
    this.updateUrl()
  }

  private updateUrl () {
    const url = fullURL()
    url.searchParams.set('tab', this.tab + '')
    window.history.pushState(null, '', url.href)
  }

  private updateFollowUpNeededAfterSave () : void {
    this.needReloading = true
  }

  hide () {
    this.skuGuid = ''
    this.productName = ''
    if (this.needReloading) {
      this.loadProducts()
    }
  }

  private handleError (error: any): void {
    this.hasError = true
    if (error && error.response.status === 404) {
      this.errorMessage = 'error'
    } else {
      this.errorMessage = error.message
    }
  }
}
