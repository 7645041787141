

















































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { loadWhile, notify } from '@/generic/helpers'
import MissionService from '@/services/Mission.service'
import { CompanyService } from '@/services/CompanyService'
import { Recruiter } from './Mission.model'
import { EmployerMissionTranslations } from './EmployerMission.translations'
import { SelectionEvent, SelectedIds } from '@/generic/models/MITMAvailabilityExpansion.models'
import MITMAvailabilityExpansionComponent from '@/generic/components/MITMAvailabilityExpansion.vue'

@Component
export default class EmployerMissionComponent extends Vue {
  @Prop() private title!: string
  @Prop() private startDate!: string
  @Prop() private endDate!: string
  @Prop() private translations!: EmployerMissionTranslations
  @Prop() private missionId!: number
  @Prop() private companyId!: number
  @Prop() private schedulesUrl!: string

  private recruiters!: Recruiter[]
  private loaded = false
  private selected: SelectedIds = {}
  private conflicts: { startTime: Date, endTime: Date, candidateName: string}[][] = []

  private mounted () {
    loadWhile(this, this.translations.employerMission.loading,
      () => CompanyService.getRecruitersMission(this.companyId, this.missionId)
        .then(result => {
          this.recruiters = result.data.recruiters
          this.selected = result.data.selected
        })
        .finally(() => { this.loaded = true })
    )
  }

  private selectionChange (event: SelectionEvent) {
    this.selected[event.id] = event.selected
  }

  private confirmChange (id: number, value: boolean) {
    if (!value) {
      this.selected[id] = []
    }
  }

  private saveAvailability () {
    this.conflicts = []
    loadWhile(this, this.translations.employerMission.loading,
      () => MissionService.saveAvailability(this.companyId, this.missionId, this.selected)
        .then(result => {
          if (result.data) {
            // There are conflicts
            this.conflicts = result.data
            for (const prop in this.conflicts) {
              (this.$refs[prop] as any)[0].show()
            }
          } else {
            // It worked
            notify(this, this.translations.employerMission.saved)
          }
        }))
  }

  private get oneDayMission () : boolean {
    const endDate = new Date(this.endDate)
    const startDate = new Date(this.startDate)
    return startDate.getDate() === endDate.getDate() &&
      startDate.getMonth() === endDate.getMonth() &&
      startDate.getFullYear() === endDate.getFullYear()
  }
}
