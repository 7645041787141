






































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { CreateProfileTranslations } from '@/profile/CreateProfile.translations'
import { CreateProfileModel } from '@/profile/CreateProfile.model'
import { SelectOption } from '@/generic/models/SelectOption'
import { PublicationLanguage } from '@/generic/models/PublicationLanguage'
import { AxiosResponse } from 'axios'
import { getUrlParameter, loadWhile, notify, notifyNegative } from '@/generic/helpers'
import { LanguageProficiency } from '@/generic/models/LanguageProficiency.model'
import AdminConsoleCandidateService from '../../services/AdminConsoleCandidateService'
import { AccountTranslations } from '@/authentication/account/Account.translations'
import { ChangeAccountModel } from '@/authentication/account/ChangeAccount.model'
import { CandidateLanguageProficiency } from '@/generic/models/CandidateLanguageProficiency.model'

@Component
export default class AdminEditCandidateComponent extends Vue {
  @Prop() private translations!: CreateProfileTranslations
  @Prop() private experienceData !: Array<SelectOption>
  @Prop() private immigrationProcess !: Array<SelectOption>
  @Prop() private countriesList !: Array<SelectOption>
  @Prop() private candidateId!: number
  @Prop() private languageProficienciesListFr !: Array<LanguageProficiency>
  @Prop() private languageProficienciesListEn !: Array<LanguageProficiency>
  // Account Form
  @Prop() private accountTranslations!: AccountTranslations
  private accountModel!: ChangeAccountModel
  @Prop() private culture !: string
  @Prop() private signInUrl !: string
  @Prop() private emailNotificationField !: Array<SelectOption>
  @Prop() private confirmed!: boolean
  @Prop() private adminId!: number

  private model = new CreateProfileModel()
  private hasError = false
  private errorMessage = ''
  private isModelLoaded = false
  private isAccountLoaded = false
  private isInterestAreaLoaded = true

  private loadModel (dto: CreateProfileModel) {
    this.model = dto
    this.model.language = {
      language: PublicationLanguage.FrenchAndEnglish
    }
    if (this.model.languageProficiencyFrId <= 0 || this.model.languageProficiencyEnId <= 0) {
      notify(this, this.translations.proficiency.message)
    }
    this.isModelLoaded = true
  }

  private mounted (): void {
    loadWhile(this, this.translations.common.message.loading, () => Promise.all([
      AdminConsoleCandidateService.getProfile(this.candidateId)
        .then(response => {
          this.loadModel(response.data)
        }),
      AdminConsoleCandidateService.getAccount(this.candidateId)
        .then(response => {
          this.accountModel = response.data
          this.isAccountLoaded = true
        })
    ]))
  }

  private validate (): Promise<boolean> {
    const form = this.$refs['candidateForm'] as any
    return form.validate()
  }

  private save (): void {
    this.validate().then(isValid => {
      if (isValid) {
        if (!this.validateSkills() || !this.validateLanguageProficiencies()) {
          this.hasError = true
          notifyNegative(this, this.translations.common.message.allRequired)
        } else {
          const returnUrl = getUrlParameter('ReturnUrl')
          loadWhile(this, this.translations.common.message.loading, () => {
            this.hasError = false
            return AdminConsoleCandidateService.saveProfile(this.model)
              .then((response: AxiosResponse<CreateProfileModel>) => {
                notify(this, this.translations.common.notifications.modified)
                this.loadModel(response.data)
              })
              .catch((error: any) => {
                this.hasError = true
                if (error.response.status === 404) {
                  this.errorMessage = this.translations.errors.common.notFound
                } else if (error.response.status === 403) {
                  this.errorMessage = this.translations.errors.common.forbidden
                } else {
                  this.errorMessage = this.translations.profileCreate.errors[error.response.message]
                }
              })
          })
        }
      }
    })
  }
  private validateLanguageProficiencies () {
    if (this.model.languageProficiencyFrId === -1 || this.model.languageProficiencyEnId === -1) {
      return false
    } else {
      return true
    }
  }

  private validateSkills (): boolean {
    if (this.model.skills.length < 2) {
      return false
    }

    let isEmptyRow = false
    this.model.skills.forEach(element => {
      if (this.isEmptyOrSpaces(element.fr) && this.isEmptyOrSpaces(element.en)) {
        isEmptyRow = true
      }
    })

    return isEmptyRow === false
  }

  private isEmptyOrSpaces (str: string): boolean {
    return str === null || str.match(/^ *$/) !== null
  }

  private selectedLanguageProficiencyFr (id: number) {
    this.model.languageProficiencyFrId = id
  }

  private selectedLanguageProficiencyEn (id: number) {
    this.model.languageProficiencyEnId = id
  }

  private selectedLanguageProficiency (value: Array<CandidateLanguageProficiency>) {
    this.model.languageProficiencies = value
  }
}
