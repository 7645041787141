






















import { Component, Prop, Vue } from 'vue-property-decorator'
import { BreadcrumbLink } from './BreadcrumbLink.model'

@Component
export default class BreadcrumbComponent extends Vue {
  @Prop() private links!: Array<BreadcrumbLink>

  private goTo (link: string) {
    if (link !== '') {
      window.location.href = link
    }
  }
}
