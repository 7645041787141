
























































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { CalendarEvent, SelectedEvent } from '@/event/Event.model'
import EventCalendarComponent from '@/event/EventCalendar.vue'
import { CandidateAvailabilityPreferences } from '@/candidate/CandidateAvailabilityPreferences.model'
import { fullURL, notifyNegative } from '@/generic/helpers'
import { MeetingService } from '@/services/MeetingService'
import CandidateCardTranslations from '@/candidate/candidates-list/CandidateCard.translations'
import MITMPromptComponent from '@/generic/components/MITMPrompt.vue'
import { CultureService } from '@/services/CultureService'

@Component({
  components: {
    'mitm-event-calendar': EventCalendarComponent
  }
})
export default class MeetingDetailsRecComponent extends Vue {
  @Prop() private availabilityPreferences!: CandidateAvailabilityPreferences;
  @Prop() private candidateId!: number;
  @Prop() private candidatureTimezone!: string;
  @Prop() private companyId!: number;
  @Prop() private jobId!: number;
  @Prop() private editEvent!: SelectedEvent
  @Prop() private translations !: CandidateCardTranslations

  private events: CalendarEvent[] = []
  private selectedEvent: SelectedEvent = new SelectedEvent()
  private selectedDate: Date = new Date()
  private cityColors: Map<string, string> = new Map<string, string>()
  private colors: string[] = ['azure']
  private recruiterTimezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone
  private recruiterTimezoneHours: number = 0
  private candidateTimezoneHours: number = 0
  private timeInRecruiterTimezoneMessage = ''
  private timeInCandidateTimezoneMessage = ''
  private errorMessage = ''
  private monthsEn: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
  private monthsFr: string[] = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']
  private cultureCode: string = CultureService.getCulture()

  private created() {
    if (this.candidatureTimezone === null) {
      this.candidatureTimezone = this.recruiterTimezone
    }
    this.recruiterTimezoneHours = new Date(new Date().toLocaleString('en-US', { timeZone: this.recruiterTimezone })).getHours()
    this.candidateTimezoneHours = new Date(new Date().toLocaleString('en-US', { timeZone: this.candidatureTimezone })).getHours()
    // Calendar and candidat preferences vues start the week with monday, but day 0 is sunday so we put it first when calculating calendar events
    let sunday = this.availabilityPreferences.days.splice(this.availabilityPreferences.days.length - 1)
    this.availabilityPreferences.days.splice(0, 0, sunday[0])
    this.mapAvailabilityToEvents()
    this.mapCityToColor(this.events.map(event => event.city))
  }

  private mounted () {
    this.selectedDate = new Date(this.editEvent.date)
    this.selectedEvent = { id: null, date: new Date(this.editEvent.date), periodIndex: this.editEvent.periodIndex }
    this.onSelectDate(this.selectedDate)
    if (this.selectedEvent.periodIndex !== null) {
      this.selectPeriod(this.selectedEvent.periodIndex)
    }
    this.errorMessage = ''
  }

  private mapAvailabilityToEvents () {
    let day = new Date()
    let dayOfWeek = day.getDay()
    day.setDate(day.getDate() - dayOfWeek + 1)
    for (let i = 0; i < 84; i++) {
      let periods = this.getAvailablePeriodFromTimezone(new Date(day))
      if (periods.length > 0) {
        this.events.push({ date: new Date(day), city: this.translations.candidateCard.rec.availabilityPreferences })
      }
      day.setDate(day.getDate() + 1)
    }
  }

  private mapCityToColor (cities: string[]) {
    let index = 0
    cities.forEach((city) => {
      if (!this.cityColors.has(city)) {
        this.cityColors.set(city, this.colors[index])
        index = (index % this.colors.length) + 1
      }
    })
  }

  private getAvailablePeriodFromTimezone(date: Date) {
    let availableHours: number[] = []
    let timezoneDiff = this.candidateTimezoneHours - this.recruiterTimezoneHours
    date.setHours(0)
    let timeInCandidateTimezone = new Date(date)
    timeInCandidateTimezone.setHours(timeInCandidateTimezone.getHours() + timezoneDiff)
    for (let i = 0; i < 24; i++) {
      if (this.availabilityPreferences.days[timeInCandidateTimezone.getDay()].periods.period.some(function(e) { return e === true })) {
        let periods = this.availabilityPreferences.days[timeInCandidateTimezone.getDay()].periods.period
        if (timeInCandidateTimezone.getHours() < 6 && periods[0] === true) {
          availableHours.push(i)
        } else if (timeInCandidateTimezone.getHours() >= 6 && timeInCandidateTimezone.getHours() < 12 && periods[1] === true) {
          availableHours.push(i)
        } else if (timeInCandidateTimezone.getHours() >= 12 && timeInCandidateTimezone.getHours() < 18 && periods[2] === true) {
          availableHours.push(i)
        } else if (timeInCandidateTimezone.getHours() >= 18 && periods[3] === true) {
          availableHours.push(i)
        }
      }
      date.setHours(date.getHours() + 1)
      timeInCandidateTimezone.setHours(timeInCandidateTimezone.getHours() + 1)
    }
    return availableHours
  }

  private onSelectDate (date: Date) {
    this.resetPeriodStyle()
    this.selectedDate = date
    let periods = this.getAvailablePeriodFromTimezone(new Date(date))
    let periodElements = document.getElementsByClassName('period')
    let labelElements = document.getElementsByClassName('label')
    periods.forEach(period => {
      periodElements[period].classList.add('candidat-preferences')
      labelElements[period].classList.add('candidat-preferences-label')
    })
    if (this.selectedDate.toLocaleDateString('en-US') === this.selectedEvent.date.toLocaleDateString('en-US')) {
      if (this.selectedEvent.periodIndex !== null) {
        this.selectPeriod(this.selectedEvent.periodIndex)
      }
    }
    this.errorMessage = ''
  }

  private resetPeriodStyle () {
    let elements = document.getElementsByClassName('period')
    for (let el of elements) {
      el.classList.remove('candidat-preferences')
    }
    elements = document.getElementsByClassName('label')
    for (let el of elements) {
      el.classList.remove('candidat-preferences-label')
    }
    elements = document.getElementsByClassName('period-selected')
    for (let el of elements) {
      el.classList.remove('period-selected')
    }
    elements = document.getElementsByClassName('icon-container')
    for (let el of elements) {
      el.classList.add('hide')
    }
  }

  private generateCustomClass (index: number, element: string) {
    if (element === 'label') {
      return 'label-' + index
    }
    return 'period-' + index
  }

  private selectPeriod (index: number) {
    let elements = document.getElementsByClassName('period-selected')
    for (let el of elements) {
      el.classList.remove('period-selected')
    }
    elements = document.getElementsByClassName('period')
    elements[index].classList.add('period-selected')
    elements = document.getElementsByClassName('icon-container')
    for (let el of elements) {
      el.classList.add('hide')
    }
    elements[index].classList.remove('hide')
    this.selectedEvent = { id: null, date: new Date(this.selectedDate), periodIndex: index }

    let date = new Date(this.selectedDate)
    date.setHours(index)
    let dateForRecruiter = new Date(date.toLocaleString('en-US', { timeZone: this.recruiterTimezone }))
    let dateForCandidate = new Date(date.toLocaleString('en-US', { timeZone: this.candidatureTimezone }))
    this.errorMessage = ''

    if (this.cultureCode === 'en-US') {
      this.timeInRecruiterTimezoneMessage = this.monthsEn[dateForRecruiter.getMonth()] + ' ' + dateForRecruiter.getDate() + ' ' + dateForRecruiter.getFullYear() + ' - ' + dateForRecruiter.getHours().toString().padStart(2, '0') + ':00'
      this.timeInCandidateTimezoneMessage = this.monthsEn[dateForCandidate.getMonth()] + ' ' + dateForCandidate.getDate() + ' ' + dateForCandidate.getFullYear() + ' - ' + dateForCandidate.getHours().toString().padStart(2, '0') + ':00'
    } else {
      this.timeInRecruiterTimezoneMessage = dateForRecruiter.getDate() + ' ' + this.monthsFr[dateForRecruiter.getMonth()] + ' ' + dateForRecruiter.getFullYear() + ' - ' + dateForRecruiter.getHours().toString().padStart(2, '0') + ':00'
      this.timeInCandidateTimezoneMessage = dateForCandidate.getDate() + ' ' + this.monthsFr[dateForCandidate.getMonth()] + ' ' + dateForCandidate.getFullYear() + ' - ' + dateForCandidate.getHours().toString().padStart(2, '0') + ':00'
    }
  }

  private createConvocation () {
    if (this.selectedEvent.periodIndex === null) {
      this.errorMessage = this.translations.candidateCard.rec.periodError
    }
    let yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)
    if (new Date(Date.UTC(this.selectedEvent.date.getFullYear(), this.selectedEvent.date.getMonth(), this.selectedEvent.date.getDate())) < yesterday) {
      this.errorMessage += this.translations.candidateCard.rec.dateError
    }
    if (this.errorMessage !== '') { return }

    if (this.editEvent.id == null) {
      MeetingService.setupRecMeeting(
        this.companyId,
        this.candidateId,
        this.jobId,
        new Date(Date.UTC(this.selectedEvent.date.getFullYear(), this.selectedEvent.date.getMonth(), this.selectedEvent.date.getDate())),
        this.selectedEvent.periodIndex!,
        this.candidateTimezoneHours - this.recruiterTimezoneHours
      ).then(() => {
        this.redirect()
      }).catch(() => {
        notifyNegative(this, 'Error has occured')
      })
    } else {
      let oldDate = new Date(this.editEvent.date)
      let newDate = new Date(this.selectedEvent.date)
      if (oldDate.toDateString() === newDate.toDateString() &&
        this.editEvent.periodIndex === this.selectedEvent.periodIndex) {
        // Nothing changed we redirect to list
        this.redirect()
      } else {
        MeetingService.rebookRecMeeting(
          this.editEvent.id,
          this.companyId,
          this.jobId,
          new Date(Date.UTC(this.selectedEvent.date.getFullYear(), this.selectedEvent.date.getMonth(), this.selectedEvent.date.getDate())),
          this.selectedEvent.periodIndex!,
          this.candidateTimezoneHours - this.recruiterTimezoneHours
        ).then(() => {
          this.redirect()
        }).catch(() => {
          notifyNegative(this, 'Error has occured')
        })
      }
    }
  }

  private deleteConfirmation () {
    (this.$refs.confirmPrompt as MITMPromptComponent).open()
  }

  private deleteConvocation() {
    MeetingService.removeRecMeeting(
      this.editEvent.id!,
      this.companyId,
      new Date(Date.UTC(this.selectedEvent.date.getFullYear(), this.selectedEvent.date.getMonth(), this.selectedEvent.date.getDate())),
      this.selectedEvent.periodIndex!
    ).then(() => {
      this.redirect()
    }).catch(() => {
      notifyNegative(this, 'Error has occured')
    })
  }

  private redirect() {
    const url = fullURL()
    if (!url.searchParams.has('op')) {
      url.searchParams.append('op', `${this.candidateId}`)
    }
    window.location.href = url.href
  }
}
