












import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { SVGInjector } from '@tanem/svg-injector'

@Component
export default class AnimatedBackgroundComponent extends Vue {
  @Prop() name!: string
  @Prop() animation!: boolean
  @Prop() index!: number
  private publicPath: string = process.env.VUE_APP_ASSET_URL
  private ready: boolean = false
  private svgElements: HTMLCollection = document.getElementsByClassName('inject-me')

  @Watch('animation')
  private onLinkHover ():void {
    let svgs = Object.keys(this.svgElements).map((i: any) => this.svgElements[i])
    svgs[this.index].classList.remove('in')
    if (this.animation) {
      svgs[this.index].classList.add('in')
    }
  }

  mounted () {
    SVGInjector(this.svgElements, {
      afterAll: (elementsLoaded) => {
        this.ready = true
      },
      afterEach: (err, svg) => {
        if (err) {
          throw err
        }
        if (svg) {
          svg.setAttribute('style', 'width: 100%; height: 100%;')
        }
      }
    })
  }
}
