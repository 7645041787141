import { IRule } from './IRule'

export default class MatchField implements IRule {
  private fieldToMatch : string
  private valueCallback : (prop: string) => any
  private fieldCallback ?: (prop: string) => any

  constructor (field : string, callback : (prop : string) => any, fieldCallback ?: (prop : string) => any) {
    this.fieldToMatch = field
    this.valueCallback = callback
    this.fieldCallback = fieldCallback
  }

  getValidator (errorMessage: string, activate?: () => boolean): (value: any) => boolean|string {
    return (value: any): boolean|string => {
      var compare = this.valueCallback(this.fieldToMatch)
      if (value === compare || !(value && compare)) {
        if (this.fieldCallback) {
          this.fieldCallback(this.fieldToMatch).resetValidation()
        }
        return true
      }
      return errorMessage
    }
  }
}
