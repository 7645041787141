




















































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { OfferPrerequisiteTranslations } from './translations/OfferPrerequisite.translations'
import { OfferPrerequisiteResponse } from './models/OfferPrerequisiteDto.model'
import { OfferPrerequisiteData } from './models/OfferPrerequisiteData.model'
import { SelectOption } from '@/generic/models/SelectOption'
import { PublicationLanguage } from '@/generic/models/PublicationLanguage'
import { CommonTranslations } from '@/generic/models/Common.translations'
import { QuestionType } from '@/generic/models/QuestionType'
import { RequiredRule } from '@/generic/rules/Required'

@Component
export default class OfferPrerequisiteQuestionComponent extends Vue {
  @Prop() private titleQuestion!: string
  @Prop() private translations!: OfferPrerequisiteTranslations
  @Prop() private commonTranslations!: CommonTranslations
  @Prop() private value!: OfferPrerequisiteResponse
  @Prop() private prerequisiteData !: OfferPrerequisiteData
  @Prop() private language!: PublicationLanguage
  @Prop({ default: false }) private wasPublished!: boolean
  @Prop({ default: '' }) private dataTest!: string
  private rules: { [key: string]: Array<(value: string) => boolean | string> } = {
    typeFr: [new RequiredRule().getValidator('', () => {
      return (this.publicationLanguageFrenchOnly(this.language) && !!this.value.questionFr) ||
        (this.publicationLanguageFrench(this.language) && (!!this.value.questionFr || !!this.value.questionEn))
    })],
    typeEn: [new RequiredRule().getValidator('', () => {
      return this.publicationLanguageEnglishOnly(this.language) && !!this.value.questionEn
    })]
  }

  private PublicationLanguage = PublicationLanguage

  private onChangeResponseType (value : string) {
    this.value.viewOnlyResponse = 0
  }

  private get isScale () {
    return this.value.responseType === QuestionType.Scale
  }

  private get isYesNo () {
    return this.value.responseType === QuestionType.YesOrNoType
  }

  private publicationLanguageFrenchOnly (language: PublicationLanguage) {
    return language === PublicationLanguage.French
  }

  private publicationLanguageFrench (language: PublicationLanguage) {
    return language === PublicationLanguage.FrenchAndEnglish || language === PublicationLanguage.French
  }

  private publicationLanguageEnglish (language: PublicationLanguage) {
    return language === PublicationLanguage.FrenchAndEnglish || language === PublicationLanguage.English
  }

  private publicationLanguageEnglishOnly (language: PublicationLanguage) {
    return language === PublicationLanguage.English
  }
}
