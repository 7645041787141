


















































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { OfferService } from '@/services/OfferService'
import { JobOffer } from '@/offer/JobOffer.model'
import { Culture } from '@/generic/models/Culture'
import { CultureService } from '@/services/CultureService'
import MissionService from '@/services/Mission.service'
import IJobCardTranslations from '@/offer/summary/JobCard.translations'
import OfferMenuTabTranslations from '@/dashboard/offer-list/OfferMenuTab.translations'
import { getUrlParameter, loadWhile, fullURL } from '@/generic/helpers'
import { SelectOption } from '@/generic/models/SelectOption'
import { Status } from '@/generic/models/Status'
import { ProductService } from '@/services/ProductService'
import { ProductType } from '../employer-package/models/ProductType'

@Component
export default class OfferMenuTab extends Vue {
  @Prop() companyId !: number
  @Prop() currentCulture !: string
  @Prop() cardTranslation !: IJobCardTranslations
  @Prop() offerMenuTabTranslation !: OfferMenuTabTranslations
  private events !: SelectOption[]
  private selectedEvent: SelectOption | undefined = undefined
  private selectedEventId: number = 0
  private selectedEventType: number = 0
  private jobsList: JobOffer[] = []
  private hasError = false
  allOfferCount: number = 0
  activeOfferCount: number = 0
  draftedOfferCount: number = 0
  awaitingReviewOfferCount: number = 0
  readyToPublishOfferCount: number = 0
  closedOfferCount: number = 0
  archivedOfferCount: number = 0
  private errorMessage = ''
  private tab = '0'
  private loaded = false

  private handleError (error: any) {
    this.hasError = true
    if (error.response.status === 404) {
      this.errorMessage = this.offerMenuTabTranslation.error.notFound
    } else if (error.response.status === 403) {
      this.errorMessage = this.offerMenuTabTranslation.error.forbidden
    } else {
      this.errorMessage = this.offerMenuTabTranslation.error.unknown
    }
  }

  private missionSelected (missionSelected: SelectOption) {
    if (missionSelected) {
      this.selectedEvent = missionSelected
      let splitId = missionSelected.value.toString().split('-')
      this.selectedEventId = +splitId[0]
      this.selectedEventType = +splitId[1]
    } else {
      this.selectedEvent = undefined
      this.selectedEventId = 0
      this.selectedEventType = 0
    }
    this.updateCounts()
  }

  private filteredByMission (jobs : JobOffer[]) : JobOffer[] {
    if (this.selectedEventId !== 0 && this.selectedEventType === ProductType.Mission) {
      return jobs.filter(jo => jo.missionIds.includes(this.selectedEventId))
    } else if (this.selectedEventId !== 0 && this.selectedEventType === ProductType.Cohort) {
      return jobs.filter(jo => jo.cohortId === this.selectedEventId)
    } else {
      return jobs
    }
  }

  private get filteredJobs () : JobOffer[] {
    return this.filteredByMission(this.jobsList)
  }

  private filteredByStatusAndAlphabetical (jobsList : JobOffer[]) : JobOffer[] {
    var jobs = jobsList.sort((a, b) => a.titleInfo !== b.titleInfo ? a.titleInfo < b.titleInfo ? -1 : 1 : 0)
    jobs = jobs.sort((a, b) => a.status === Status.Published && b.status !== Status.Published ? -1 : 1)
    return jobs
  }

  private loadMissions (): Promise<void> {
    return MissionService.missionCompany(this.companyId, CultureService.getCultureCode(), false, true).then(result => {
      this.events = result.data
    })
  }

  private updateCounts () : Promise<void> {
    return OfferService.getCountOfferByStatus(this.companyId, this.selectedEventId, this.selectedEventType).then(result => {
      this.allOfferCount = result.data.countAll
      this.activeOfferCount = result.data.countActive
      this.draftedOfferCount = result.data.countDraft
      this.awaitingReviewOfferCount = result.data.countAwaitingReview
      this.readyToPublishOfferCount = result.data.countReadyToPublish
      this.closedOfferCount = result.data.countClosed
      this.archivedOfferCount = result.data.countArchived
    })
  }

  mounted () {
    const requestedTab = getUrlParameter('tab') || '0'
    if (['0', '1', '2', '3'].includes(requestedTab)) {
      this.tab = requestedTab
    } else {
      this.updateUrl()
    }
    loadWhile(this, '', () => Promise.all([
      this.updateCounts(),
      this.loadMissions(),
      [OfferService.getAllMyJobs, OfferService.getAllMyActiveJobs, OfferService.getAllMyDraftJobs, OfferService.getAllMyAwaitingReviewJobs, OfferService.getAllMyReadyToPublishJobs, OfferService.getAllMyClosedJobs, OfferService.getAllMyArchivedJobs][+this.tab](this.companyId, this.currentCulture).then(result => {
        this.jobsList = this.filteredByStatusAndAlphabetical(result.data)
      })
    ]).then(() => {
      this.loaded = true
    }).catch(error => {
      this.handleError(error)
    }))
  }

  showAllJobs () {
    loadWhile(this, ' ', () => OfferService.getAllMyJobs(this.companyId, this.currentCulture)
      .then(response => {
        this.jobsList = this.filteredByStatusAndAlphabetical(response.data)
        this.allOfferCount = this.filteredByMission(this.jobsList).length
      })
      .catch(error => {
        this.handleError(error)
      })
    )
  }

  showActiveJobs () {
    loadWhile(this, ' ', () => OfferService.getAllMyActiveJobs(this.companyId, this.currentCulture)
      .then(response => {
        this.jobsList = response.data
        this.activeOfferCount = this.filteredByMission(this.jobsList).length
      })
      .catch(error => {
        this.handleError(error)
      })
    )
  }

  showDraftJobs () {
    loadWhile(this, ' ', () => OfferService.getAllMyDraftJobs(this.companyId, this.currentCulture)
      .then(response => {
        this.jobsList = response.data
        this.draftedOfferCount = this.filteredByMission(this.jobsList).length
      })
      .catch(error => {
        this.handleError(error)
      })
    )
  }

  showAwaitingReviewJobs() {
    loadWhile(this, ' ', () => OfferService.getAllMyAwaitingReviewJobs(this.companyId, this.currentCulture)
      .then(response => {
        this.jobsList = response.data
        this.awaitingReviewOfferCount = this.filteredByMission(this.jobsList).length
      })
      .catch(error => {
        this.handleError(error)
      })
    )
  }

  showReadyToPublishJobs() {
    loadWhile(this, ' ', () => OfferService.getAllMyReadyToPublishJobs(this.companyId, this.currentCulture)
      .then(response => {
        this.jobsList = response.data
        this.readyToPublishOfferCount = this.filteredByMission(this.jobsList).length
      })
      .catch(error => {
        this.handleError(error)
      })
    )
  }

  showClosedJobs () {
    loadWhile(this, ' ', () => OfferService.getAllMyClosedJobs(this.companyId, this.currentCulture)
      .then(response => {
        this.jobsList = response.data
        this.closedOfferCount = this.filteredByMission(this.jobsList).length
      })
      .catch(error => {
        this.handleError(error)
      })
    )
  }

  showArchivedJobs () {
    loadWhile(this, ' ', () => OfferService.getAllMyArchivedJobs(this.companyId, this.currentCulture)
      .then(response => {
        this.jobsList = response.data
        this.archivedOfferCount = this.filteredByMission(this.jobsList).length
      })
      .catch(error => {
        this.handleError(error)
      })
    )
  }

  private addNewOffer () {
    const culture = CultureService.getCulture()
    if (culture === Culture.English) {
      window.location.href = '/en/employer/new-offer'
    } else {
      window.location.href = '/fr/employeur/nouvelle-offre'
    }
  }

  private updateUrl () {
    const url = fullURL()
    url.searchParams.set('tab', this.tab)
    window.history.pushState(null, '', url.href)
  }

  private onTabChange (newVal: string) {
    this.tab = newVal
    this.updateUrl()
  }
}
