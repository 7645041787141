













































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { CreateProfileTranslations } from './CreateProfile.translations'
import { CreateProfileModel } from './CreateProfile.model'
import { SelectOption } from '@/generic/models/SelectOption'
import { RequiredRule } from '@/generic/rules/Required'
import HelpIconComponent from '../generic/components/MITMHelpIcon.vue'
import SkillHelpComponent from '../offer/skill/SkillHelp.vue'
import { ActivityArea } from '@/activity-area/ActivityArea.model'
import MITMAutocompleteComponent from '@/generic/components/MITMAutocomplete.vue'
import { CultureService } from '@/services/CultureService'
import InterestAreasService from '@/services/InterestAreas.service'
import { InterestArea, InterestAreaGroup } from '@/generic/models/InterestArea.model'
import { Culture } from '@/generic/models/Culture'
import { notifyNegative } from '@/generic/helpers'
import { getActivityAreasMifi } from '@/generic/activity-area-helper'
import { IsAscii } from '@/generic/rules/IsAscii'

@Component({
  components: {
    'mitm-skill-help': SkillHelpComponent,
    'mitm-autocomplete': MITMAutocompleteComponent
  }
})
export default class TitleAndSkillsComponent extends Vue {
  @Prop() private translations!: CreateProfileTranslations
  @Prop() private experienceData !: Array<SelectOption>
  @Prop() private value !: CreateProfileModel
  @Prop() private countriesList !: Array<SelectOption>
  @Prop() private IsCreateProfile !: boolean
  @Prop() private levelsOfStudy !: Array<SelectOption>

  private selectedSoftSkills : Array<SelectOption> = []
  private optionIsDisabled : Array<boolean> = [false, false]
  private isFirstLoad : boolean = true
  private hasSoftSkillError : boolean = false
  private interestAreas!: Array<InterestArea>;
  private autoCompleteOptions : Array<InterestArea> = []
  private interestAreaOptions : Array<string> = []
  private interestArea : any
  private culture !: string
  private rules: { [key: string]: Array<(value: any) => boolean | string> } = {
    required: [
      new RequiredRule().getValidator(this.translations.common.message.required)
    ],
    ascii: [
      new IsAscii().getValidator(this.translations.common.message.invalidCharacter)
    ]
  };
  private fieldsOfStudy : Array<SelectOption> = [
    { value: 'Arts', label: this.translations.profileCreate.education.fieldOfStudy.arts },
    { value: 'Law', label: this.translations.profileCreate.education.fieldOfStudy.law },
    { value: 'LanguagesAndLitterature', label: this.translations.profileCreate.education.fieldOfStudy.languagesAndLitterature },
    { value: 'Engineering', label: this.translations.profileCreate.education.fieldOfStudy.engineering },
    { value: 'HealthSciences', label: this.translations.profileCreate.education.fieldOfStudy.healthSciences },
    { value: 'BusinessAdmistrationAndCommerce', label: this.translations.profileCreate.education.fieldOfStudy.businessAdmistrationAndCommerce },
    { value: 'AppliedSciences', label: this.translations.profileCreate.education.fieldOfStudy.appliedSciences },
    { value: 'EducationalSciences', label: this.translations.profileCreate.education.fieldOfStudy.educationalSciences },
    { value: 'HumanSciences', label: this.translations.profileCreate.education.fieldOfStudy.humanSciences },
    { value: 'PureSciences', label: this.translations.profileCreate.education.fieldOfStudy.pureSciences },
    { value: 'InformationTechnology', label: this.translations.profileCreate.education.fieldOfStudy.informationTechnology },
    { value: 'Other', label: this.translations.profileCreate.education.fieldOfStudy.other }
  ]

  private industries: Array<SelectOption> = getActivityAreasMifi(this.translations.profileCreate.workExperiences.industry)
  private softSkillsList : Array<SelectOption> = [
    { value: '', label: this.translations.profileCreate.skills.softSkill.choose },
    { value: 'Autonomy', label: this.translations.profileCreate.skills.softSkill.autonomy },
    { value: 'AbilityToFederate', label: this.translations.profileCreate.skills.softSkill.abilityToFederate },
    { value: 'Adaptability', label: this.translations.profileCreate.skills.softSkill.adaptability },
    { value: 'CommunicationSkills', label: this.translations.profileCreate.skills.softSkill.communicationSkills },
    { value: 'Curiosity', label: this.translations.profileCreate.skills.softSkill.curiosity },
    { value: 'DecisionMakingSkills', label: this.translations.profileCreate.skills.softSkill.decisionMakingSkills },
    { value: 'OrganizationalSkills', label: this.translations.profileCreate.skills.softSkill.organizationalSkills },
    { value: 'Perseverance', label: this.translations.profileCreate.skills.softSkill.perseverance },
    { value: 'Reactivity', label: this.translations.profileCreate.skills.softSkill.reactivity },
    { value: 'Rigor', label: this.translations.profileCreate.skills.softSkill.rigor },
    { value: 'StepBackSkills', label: this.translations.profileCreate.skills.softSkill.stepBackSkills },
    { value: 'StrengthOfProposal', label: this.translations.profileCreate.skills.softSkill.strengthOfProposal },
    { value: 'StressManagement', label: this.translations.profileCreate.skills.softSkill.stressManagement },
    { value: 'Teamwork', label: this.translations.profileCreate.skills.softSkill.teamwork }
  ]

  mounted () {
    this.culture = CultureService.getCultureCode()
    if (this.IsCreateProfile) {
      this.onValueSoftSkillChange(this.value)
    }
    this.validateSoftSkill()
  }

  @Watch('value.softSkills')
  private onValueSoftSkillChange(value: CreateProfileModel) {
    if (this.isFirstLoad) {
      for (let i = 0; i < this.value.softSkills.length; i++) {
        let skill = this.softSkillsList.find(s => s.value === this.value.softSkills[i])
        if (skill !== null) {
          this.selectedSoftSkills.push(skill as SelectOption)
          this.optionIsDisabled[i] = true
        }
      }

      if (this.value.softSkills.length === 0) {
        this.selectedSoftSkills.push(this.softSkillsList[0])
        this.value.softSkills.push(this.softSkillsList[0].value.toString())
      }
      this.isFirstLoad = false
    }
  }

  private updateOptions (value: string) {
    let options: Array<string> = this.interestAreaOptions
    if (value.length >= 1) {
      let selectedInterestAreas = this.value.interestAreaGroups !== undefined && this.value.interestAreaGroups !== null && this.value.interestAreaGroups.length > 0
        ? this.value.interestAreaGroups.map((item) => item.id)
        : []
      if (!isNaN(+value)) {
        this.getInterestAreasByCnpCode(+value, options, this.culture, selectedInterestAreas)
      } else if (value.length > 2) {
        this.getInterestAreasByTitle(value, options, this.culture, selectedInterestAreas)
      }
    }
  }

  private getInterestAreasByTitle (value: string, options: Array<string>, culture: string, selectedInterestAreas: number []) {
    InterestAreasService.getInterestAreas(value, culture, selectedInterestAreas)
      .then(response => {
        if (response.data.length !== 0) {
          this.interestAreas = response.data
          this.autoCompleteOptions = this.interestAreas
          options.splice(0)
          options.push(...this.interestAreas.map((item) => item.title))
        }
      })
      .catch(() => {
        this.interestAreas.splice(0)
      })
  }

  private getInterestAreasByCnpCode (value: number, options: Array<string>, culture: string, selectedInterestAreas: number []) {
    InterestAreasService.getInterestAreasByCnpCode(value, culture, selectedInterestAreas)
      .then(response => {
        if (response.data.length !== 0) {
          this.interestAreas = response.data
          this.autoCompleteOptions = this.interestAreas
          options.splice(0)
          options.push(...this.interestAreas.map((item) => item.title))
        }
      })
      .catch(() => {
        this.interestAreas.splice(0)
      })
  }

  private inputChanged (value: string) {
    if (this.autoCompleteOptions.length > 0) {
      let selectedInterestArea = this.autoCompleteOptions.find(x => x.title === value)
      if (selectedInterestArea !== undefined) {
        this.addInterestAreaGroup(selectedInterestArea)
      }
    }
  }

  private addInterestAreaGroup (selectedInterestArea : InterestArea) {
    if (this.value.interestAreaGroups.some(i => i.id === selectedInterestArea.interestAreaGroup.id)) {
      notifyNegative(this, this.translations.profileCreate.interestArea.error.familyNameExist)
      return
    }
    if (this.value.interestAreaGroups.length === 2) {
      notifyNegative(this, this.translations.profileCreate.interestArea.error.maximumValues)
      return
    }

    this.value.interestAreaGroups.push(selectedInterestArea.interestAreaGroup)
  }

  private getInterestAreaGroupNameTranslated (selectedGroup : InterestAreaGroup) : string {
    let culture : string = CultureService.getCultureCode()
    let translatedValue : string = selectedGroup.translations.find(t => t.cultureName === culture)?.name!

    return translatedValue
  }

  private deleteAreaGroup (selectedGroup : InterestAreaGroup) {
    let position = this.value.interestAreaGroups.indexOf(selectedGroup)
    this.value.interestAreaGroups.splice(position, 1)
  }

  private addRow() {
    if (this.selectedSoftSkills.length === 1) {
      this.selectedSoftSkills.push(this.softSkillsList[0])
      this.value.softSkills.push(this.softSkillsList[0].value.toString())
    }
  }

  private deleteRow() {
    if (this.selectedSoftSkills.length === 2) {
      this.optionIsDisabled[1] = false
      this.selectedSoftSkills.pop()
      this.value.softSkills.pop()
      this.validateSoftSkill()
    }
  }

  private setModelSoftSkill(index: number) {
    let selectInput = (document.getElementsByClassName('soft-skill-select')[index] as HTMLSelectElement)
    let selectedOption = this.softSkillsList.find(s => s.value === selectInput.value) as SelectOption
    this.selectedSoftSkills[index] = selectedOption
    this.value.softSkills[index] = selectedOption.value.toString()
    this.disableFirstOption(index)
    this.validateSoftSkill()
  }

  private softSkillLostFocus(index: number) {
    let selectInput = (document.getElementsByClassName('soft-skill-select')[index] as HTMLSelectElement)
    if (index === 0 && selectInput !== undefined) {
      if (this.hasSoftSkillError) {
        selectInput.classList.add('soft-skill-error')
      } else {
        selectInput.classList.remove('soft-skill-error')
      }
    }
  }

  private disableFirstOption(index: number) {
    if (!this.optionIsDisabled[index]) {
      let selectInput = (document.getElementsByClassName('soft-skill-select')[index] as HTMLSelectElement)
      selectInput.options[0].disabled = true
      this.optionIsDisabled[index] = true
    }
  }

  private validateSoftSkill () {
    var temp = this.selectedSoftSkills.filter(function(e) { return e.value !== '' })
    if (temp.length > 0) {
      this.hasSoftSkillError = false
    } else {
      this.hasSoftSkillError = true
    }
    this.softSkillLostFocus(0)
  }
}
