


























import { Component, Prop, Vue } from 'vue-property-decorator'
import { AuthenticationService } from '../../services/Authentication.service'
import { loadWhile } from '../../generic/helpers'
import { CultureService } from '../../services/CultureService'
import { CommonErrorTranslations } from '../../generic/models/CommonError.translations'
import { AuthentcationErrorTranslations } from '../AuthentcationError.translations'

@Component
export default class ReconfirmComponent extends Vue {
@Prop() private title !: string
@Prop() private message !: string
@Prop() private button !: string
@Prop() private redirectUrl !: string
@Prop() private translations !: AuthentcationErrorTranslations

private hasError: boolean = false
private errorMessage: string = ''

private onConfirm () {
  this.hasError = false
  loadWhile(this, '', () => AuthenticationService.reconfirmAccount(CultureService.getCultureCode())
    .then(() => {
      if (this.redirectUrl) {
        window.location.href = this.redirectUrl
      }
    })
    .catch((error) => {
      this.hasError = true
      this.errorMessage = this.translations[error.response.data.message]
    })
  )
}
}
