import { DurationOptionType } from '@/dashboard/employer-package/models/DurationOptionType'
import { ProductJobOfferType } from '@/dashboard/employer-package/models/ProductJobOfferType'

export default class Product {
  id: number
  skuGuid: string
  name:string
  crmId: string
  description: string
  shortDescription: string
  departmentId: number
  enabled: boolean
  publishTo: Date
  price: number
  discountValue: number
  variants: Array<Product>
  isJobOfferDepartment: boolean
  isMissionDepartment: boolean
  isCohortDepartment: boolean
  isPermitDepartment: boolean
  productDurationType: DurationOptionType
  productJobOfferType: ProductJobOfferType
  isDeletable: boolean

  constructor () {
    this.id = 0
    this.skuGuid = ''
    this.name = ''
    this.crmId = ''
    this.description = ''
    this.shortDescription = ''
    this.departmentId = 0
    this.enabled = false
    this.publishTo = new Date()
    this.price = 0
    this.discountValue = 0
    this.variants = []
    this.isJobOfferDepartment = false
    this.isMissionDepartment = false
    this.isCohortDepartment = false
    this.isPermitDepartment = false
    this.productDurationType = DurationOptionType.oneMonth
    this.productJobOfferType = ProductJobOfferType.OneOffer
    this.isDeletable = true
  }
}

export enum ProductTabEnum {
  All,
  Rec,
  Mission,
  Cohort,
}
