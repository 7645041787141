










































import { Component, Prop, Vue } from 'vue-property-decorator'
import { SkillTranslations } from '@/offer/skill/Skill.translations'
import HelpIconComponent from '@/generic/components/MITMHelpIcon.vue'

@Component({
  components: {
    'mitm-help-icon': HelpIconComponent
  }
})
export default class SkillHelpComponent extends Vue {
  @Prop() translations!: SkillTranslations
  @Prop({ default: false }) helpForOffer!: boolean

  private showExamples = false

  private showExampleSkills () {
    this.showExamples = !this.showExamples
  }

  private multiline (text: string) : string {
    return text.split('\n').join('<br>')
  }
}
