










import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class PdfPreviewComponent extends Vue {
  @Prop() fileName!: string;
  @Prop() url!: string;
}
