import { IRule } from './IRule'

export class IsFacebookURL implements IRule {
  static regex=/^(?:https?:\/\/)?(?:www\.)?(?:facebook|fb)\.com\/((?![A-z]+\.php)(?!marketplace|gaming|watch|me|messages|help|search|groups)[A-z0-9_\-.]+)\/?$/
  getValidator (errorMessage: string, activate?: () => boolean): (value: string) => boolean|string {
    return (value: string): boolean|string => {
      if (activate === undefined || (activate && activate())) {
        if (value) {
          return !!value.match(IsFacebookURL.regex) || errorMessage
        }
      }
      return true
    }
  }
}
