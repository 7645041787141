















import { Component, Prop, Vue } from 'vue-property-decorator'
import { NewsletterForm } from './NewsletterForm'
import { NewsletterTranslations } from '@/generic/translations/Newsletter.translations'
import NewsletterFormComponent from './NewsletterForm.vue'

@Component({
  components: {
    'mitm-newsletter-form': NewsletterFormComponent
  }
})
export default class NewsletterDialogComponent extends Vue {
  @Prop() private translations!: NewsletterTranslations
  @Prop() private privacyPolicyLink!: string
  private openDialog = false

  private hide () {
    this.openDialog = false
  }

  private onConfirm (result: NewsletterForm) {
    this.hide()
  }

  public open (): void {
    this.openDialog = true
  }
}
