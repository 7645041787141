import { IRule } from './IRule'

export class IsURL implements IRule {
  static regex=/^(https?:\/\/)?([\w-])+\.{1}([a-zA-Z]{2,63})([/\w-]*)*\/?\??([^#\n\r]*)?#?([^\n\r]*)$/i;
  getValidator (errorMessage: string, activate?: () => boolean): (value: string) => boolean|string {
    return (value: string): boolean|string => {
      if (activate === undefined || (activate && activate())) {
        if (value) {
          return !!value.match(IsURL.regex) || errorMessage
        }
      }
      return true
    }
  }
}
