export class ExternalListFilter {
    total: number
    pageNumber: number
    pageSize: number
    searchTerms: string

    constructor () {
      this.total = 0
      this.pageNumber = 1
      this.pageSize = 20
      this.searchTerms = ''
    }
}
