

















































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { loadWhile, notify, notifyNegative } from '@/generic/helpers'
import { CultureService } from '@/services/CultureService'
import { Culture } from '@/generic/models/Culture'
import ProductsListTranslations from '../../models/products/ProductsList.translations'
import MITMInputComponent from '@/generic/components/MITMInput.vue'
import { IsNumber } from '@/generic/rules/IsNumber'
import { RequiredRule } from '@/generic/rules/Required'
import { MinimumRule } from '@/generic/rules/Minimum'
import { SelectOption } from '@/generic/models/SelectOption'
import AdminConsoleService from '../../services/AdminConsoleService'
import AdminConsoleProductService from '../../services/AdminConsoleProductService'
import FileListComponent from '../meetings/external/files/FileList.vue'
import { FileInfo } from '@/generic/models/File.model'
import ProductAssociation from '../../models/products/ProductAssociation.models'
import AdminConsoleRecruiterService from '../../services/AdminConsoleRecruiterService'
import { ProductType } from '@/dashboard/employer-package/models/ProductType'

@Component({
  components: {
    'mitm-input': MITMInputComponent,
    'mitm-file-list': FileListComponent
  }
})
export default class AdminProductForm extends Vue {
  @Prop() private parentSkuGuid!: string
  @Prop({ default: ProductType.JobOffer }) private productType!: ProductType
  @Prop() productName!: string
  @Prop() translations !: ProductsListTranslations
  rules: { [key: string]: Array<(value: any) => boolean | string> } = {
    isNumber: [
      new MinimumRule(0).getValidator(this.translations.error.minimum),
      new IsNumber().getValidator(this.translations.error.validNumber),
      new RequiredRule().getValidator(this.translations.error.validNumber)
    ],
    required: [new RequiredRule().getValidator(this.translations.error.allRequired)]
  };
  productAssociation: ProductAssociation = new ProductAssociation(this.productType)
  variants: SelectOption[] = []
  selectedVariant!: SelectOption | null
  contract: Array<FileInfo> = []
  companies: SelectOption[] = []
  selectedCompany!: SelectOption | null
  recruiters: SelectOption[] = []
  selectedRecruiter!: SelectOption | null
  culture: string = CultureService.getCulture() === Culture.English ? 'en-US' : 'fr-CA'
  loaded: boolean = false
  companiesLoaded: boolean = false
  recruitersLoaded: boolean = false
  isLoading: boolean = false
  isSaving: boolean = false
  hasError: boolean = false
  errorMessage: string = ''

  mounted(): void {
    loadWhile(this, this.translations.common.message.loading, () => Promise.all([
      this.loadCompanies(),
      this.loadVariants()
    ]).then(() => {
      this.loaded = true
      if (this.productType === 4) {
        this.productAssociation.productSkuGuid = this.parentSkuGuid
      } else {
        this.getDefaultTotalDisplay()
      }
    }).catch(error => {
      this.handleError(error)
    }))
  }

  updateInput (value: any): void {
    this.$forceUpdate()
  }

  getDefaultTotalDisplay() : void {
    var productName = this.variants[0].label
    if (this.productType === 0) {
      if (productName.startsWith('1 off')) {
        this.productAssociation.totalDisplay = 1
      } else if (productName.startsWith('5 off')) {
        this.productAssociation.totalDisplay = 5
      } else if (productName.startsWith('10 off')) {
        this.productAssociation.totalDisplay = 10
      } else {
        this.productAssociation.totalDisplay = 25
      }
    } else if (this.productType === 1) {
      this.productAssociation.totalDisplay = 50
    }
  }

  variantSelected (variantSelected: SelectOption) {
    if (variantSelected !== null) {
      this.selectedVariant = variantSelected
      this.productAssociation.productSkuGuid = variantSelected.value.toString()
    } else {
      this.selectedVariant = null
      this.productAssociation.productSkuGuid = ''
    }
    this.$forceUpdate()
  }

  companySelected (companiesSelected: SelectOption) {
    if (companiesSelected !== null) {
      this.selectedCompany = companiesSelected
      loadWhile(this, this.translations.common.message.loading, () => Promise.all([
        this.loadRecruiters(+companiesSelected.value)
      ]).then(() => {
        this.recruitersLoaded = true
      }).catch(error => {
        this.recruitersLoaded = false
        this.handleError(error)
      }))
    } else {
      this.recruiters = []
      this.selectedCompany = null
      this.resetRecruiter()
      this.recruitersLoaded = false
    }
    this.$forceUpdate()
  }

  recruiterSelected (recruiterSelected: SelectOption) {
    if (recruiterSelected !== null) {
      this.selectedRecruiter = recruiterSelected
      this.productAssociation.recruiterId = +recruiterSelected.value
    } else {
      this.resetRecruiter()
    }
    this.$forceUpdate()
  }

  private resetRecruiter(): void {
    this.selectedRecruiter = null
    this.productAssociation.recruiterId = 0
  }

  private loadVariants(): Promise<void> {
    this.hasError = false
    return AdminConsoleProductService.getProductVariants(this.parentSkuGuid)
      .then((result) => {
        this.variants = this.variants.concat(result.data)
      })
      .catch((error) => {
        if (error) {
          this.handleError(error)
        }
      })
  }

  private loadCompanies(): Promise<void> {
    this.hasError = false
    return AdminConsoleService.getAllCompanies()
      .then((result) => {
        this.companies = this.companies.concat(result.data)
        this.companiesLoaded = true
      })
      .catch((error) => {
        if (error) {
          this.handleError(error)
        }
      })
  }

  private loadRecruiters(companyId: number): Promise<void> {
    this.hasError = false
    return AdminConsoleRecruiterService.getAllRecruiterByCompany(companyId)
      .then((result) => {
        this.recruiters = []
        this.recruiters = this.recruiters.concat(result.data)
      })
      .catch((error) => {
        if (error) {
          this.handleError(error)
        }
      })
  }

  save () : void {
    this.productAssociation.contract = this.contract
    let errorMessage = document.getElementsByClassName('error-message')[0]
    errorMessage.classList.add('hide')
    if (this.canSave()) {
      this.isSaving = true
      this.hasError = false
      loadWhile(this, this.translations.common.message.loading, () =>
        this.saveAction((result: boolean | void) => {
          if (result) {
            this.$emit('follow-up-save')
            document.getElementById('close-button')?.click()
          }
        })
      )
      this.isSaving = false
    } else {
      errorMessage.classList.remove('hide')
    }
  }

  private saveAction (done: Function): Promise<void> {
    return AdminConsoleProductService.addProductToCompany(this.productAssociation)
      .then(() => {
        notify(this, this.translations.page.saveNotify)
        done(true)
      })
      .catch((error) => {
        if (error) {
          notifyNegative(this, error.message)
        }
        done(false)
      })
  }

  private canSave() : boolean {
    return this.productAssociation.isValid()
  }

  private handleError (error: any): void {
    this.hasError = true
    if (error.response.status && error.response.status === 404) {
      this.errorMessage = this.translations.error.notFound
    } else if (error.response.status) {
      this.errorMessage = error.message
    } else {
      this.errorMessage = error
    }
  }
}
