
















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { OfferAddressTranslations } from './OfferAddress.translations'
import { CommonAddressTranslations } from '@/generic/models/CommonAddress.translations'
import { AddressInfo } from './AddressInfo.model'
import { RequiredRule } from '@/generic/rules/Required'
import { PostalCodeRule } from '@/generic/rules/PostalCode'
import { IRule } from '@/generic/rules/IRule'
import MITMInput from '@/components/MITMInput.vue'
import { BasicCompanyInfo } from '@/company/basic-info/BasicInfo.model'

@Component
export default class OfferAddressInfoComponent extends Vue {
  @Prop() private translations!: OfferAddressTranslations;
  @Prop() private commonTranslations!: CommonAddressTranslations
  @Prop() private value!: AddressInfo;
  @Prop() private companyInfo!: BasicCompanyInfo;

  @Watch('value.useCompanyAddress')
  private onUseCompanyAddress (val: boolean) {
    if (val) {
      this.value.jobAddress = this.companyInfo.address
      this.value.jobCity = this.companyInfo.city
      this.value.jobPostalCode = this.companyInfo.postalCode
    }
  }

  beforeMount () {
    if (this.value.jobAddress === '' && this.value.jobCity === '' && this.value.jobPostalCode === '') {
      this.value.useCompanyAddress = true
    }
  }

  mounted () {
    this.onUseCompanyAddress(this.value.useCompanyAddress)
  }

  private addressIsDifferentFromCompanyAddress (): boolean {
    return !this.value.useCompanyAddress
  }

  private rules: { [key: string]: Array<(value: any) => boolean | string> } = {
    address: [
      new RequiredRule().getValidator(
        this.commonTranslations.message.addressRequired,
        this.addressIsDifferentFromCompanyAddress
      )
    ],
    postalCode: [
      new RequiredRule().getValidator(
        this.commonTranslations.message.postalCodeRequired,
        this.addressIsDifferentFromCompanyAddress
      ),
      new PostalCodeRule().getValidator(
        this.commonTranslations.message.invalidPostalCode,
        this.addressIsDifferentFromCompanyAddress
      )
    ],
    city: [
      new RequiredRule().getValidator(
        this.commonTranslations.message.cityRequired,
        this.addressIsDifferentFromCompanyAddress
      )
    ]
  };
}
