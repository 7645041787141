

























































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { MenuSection } from '@/navigation/model/SiteMenu.model'
import { SiteMenuTranslations } from '@/navigation/model/SiteMenu.transations'

@Component
export default class DesktopMenuSectionComponent extends Vue {
  @Prop() private section!: MenuSection
  @Prop() private translations!: SiteMenuTranslations
  @Prop() private offset!: Number

  private visible = false
  private timeoutId = 0
  private animations = this.section.articles.map(() => false)

  private onMouseOver (index: number) {
    this.$set(this.animations, index, true)
  }

  private onMouseLeave (index: number) {
    this.$set(this.animations, index, false)
  }

  private get sectionStyle () {
    return {
      '--hover-color': this.section.primaryColor
    }
  }

  private selectedStyle () {
    if (window.location.pathname.startsWith(this.section.link)) {
      return true
    }
  }

  private showMenu () {
    clearTimeout(this.timeoutId)
    this.timeoutId = setTimeout(() => { this.visible = true }, 1000)
  }

  private hideMenu () {
    clearTimeout(this.timeoutId)
    this.timeoutId = setTimeout(() => { this.visible = false }, 50)
  }
}
