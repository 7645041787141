import { IRule } from './IRule'

export class SpecificValueRule implements IRule {
  private value: any

  constructor (value: any) {
    this.value = value
  }

  getValidator (errorMessage: string, activate?: () => boolean): (value: any) => boolean|string {
    return (value: any): boolean|string => {
      if (activate === undefined || (activate && activate())) {
        return value === this.value || errorMessage
      }
      return true
    }
  }
}
