import SkillTag from '@/generic/models/SkillTag.model'
export default interface Candidate {
  id: number
  firstName: string
  lastName: string
  title: string
  industry: string
  new: boolean
  booked: boolean
  hidden: boolean
  consultedByRecruiter: boolean
  tags: Array<SkillTag>
  // percentage: number
  liked: boolean
  rating: number
  email: string
  candidateId: number
  created: Date
  isSelectedForCVDownload:boolean
  languageProficiencyLevelFr: string
  languageProficiencyLevelEn: string
  degree: string
}

export enum CandidatureType {
  Applicant,
  SuggestedProfile
}
