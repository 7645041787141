
















































import { Component, Prop, Vue } from 'vue-property-decorator'

import ArticleListComponent from './articles/ArticleList.vue'
import ActiveOffersSectionComponent from './active-offers/ActiveOffersSection.vue'
import CandidateChartComponent from './candidate-graph/CandidateChart.vue'
import CandidateWheelComponent from './candidate-wheel/CandidateWheel.vue'
import OfferCountComponent from './offer-count/OfferCount.vue'
import ArticleSummary from './articles/ArticleSummary.model'

import { EmployerDashboardTranslations } from './EmployerDashboard.translations'
import { CommonErrorTranslations } from '@/generic/models/CommonError.translations'
import RemainingPackageComponent from '../employer-package/RemainingPackageCard.vue'

@Component({
  components: {
    'mitm-candidate-chart': CandidateChartComponent,
    'mitm-candidate-wheel': CandidateWheelComponent,
    'mitm-offer-count': OfferCountComponent,
    'mitm-article-list': ArticleListComponent,
    'mitm-active-offers': ActiveOffersSectionComponent,
    'mitm-remaining-package-card': RemainingPackageComponent
  }
})
export default class EmployerDashboardComponent extends Vue {
  @Prop() translations!: EmployerDashboardTranslations
  @Prop() errors!: CommonErrorTranslations
  @Prop() companyId!: number
  @Prop() lastLogin!: Date
  @Prop() linkAllOffers!: string
  @Prop() locale!: string
  @Prop() articles!: ArticleSummary[]
  @Prop() linkAllArticles!: string
}
