import { ActivityAreaInfo } from '@/activity-area/ActivityAreaInfo.model'
import { ITranslatable } from '@/generic/models/ITranslatable'
import { AddressInfo } from '@/offer/address/AddressInfo.model'
import { Skill } from '@/offer/skill/Skill.model'
import { OfferPrerequisiteDto } from '@/offer/prerequisite/models/OfferPrerequisiteDto.model'
import { LanguageSelector } from '@/offer/language/LanguageSelector.model'
import { PublicationLanguage } from '@/generic/models/PublicationLanguage'
import { Experience } from '@/generic/models/Experience'
import { Status } from '@/generic/models/Status'
import { InterestAreaGroup } from '@/generic/models/InterestArea.model'
import { JobOfferWorkInfosDto } from '@/offer/jobOfferWorkInfos/JobOfferWorkInfosDto.model'
import { RequirementDto } from '@/offer/jobOfferRequirement/RequirementDto.model'

export class JobOffer {
  public id: number
  public companyId: number
  public expiryDate: Date|null
  public publishedDate: Date|null
  public status: Status
  public addressInfo: AddressInfo
  public jobPosts: { count ?: number }
  public description: ITranslatable
  public taskDescription: ITranslatable
  public asset: ITranslatable
  public titleInfo: ITranslatable
  public skills: Array<Skill>
  public softSkills: Array<string>
  public industry: string | null
  public industryTranslation: string | null
  public prerequisites: OfferPrerequisiteDto
  public jobOfferWorkInfos: JobOfferWorkInfosDto
  public jobOfferRequirements: RequirementDto
  public language: LanguageSelector
  public filledUsingTalentMontreal: boolean|null
  public missionIds: number[]
  public cohortId: number
  interestAreaGroups: InterestAreaGroup[]
  public orderItemId: number|null|undefined
  public isVirtualInterview: boolean
  isPaid: boolean

  constructor () {
    this.id = -1
    this.companyId = -1
    this.expiryDate = null
    this.publishedDate = null
    this.orderItemId = null
    this.isVirtualInterview = false
    this.status = Status.Draft
    this.addressInfo = {
      useCompanyAddress: false,
      jobAddress: '',
      jobCity: '',
      jobPostalCode: ''
    }
    this.jobPosts = {
      count: 1
    }
    this.description = {
      en: '',
      fr: ''
    }
    this.taskDescription = {
      en: '',
      fr: ''
    }
    this.asset = {
      en: '',
      fr: ''
    }
    this.titleInfo = {
      fr: '',
      en: ''
    }
    this.skills = [{
      fr: '',
      en: '',
      experienceYears: Experience.OneToTwo
    }]
    this.softSkills = []
    this.industry = null
    this.industryTranslation = null
    this.jobOfferWorkInfos = {
      hoursPerWeek: undefined,
      salaryIsAnnually: 1,
      unionize: 0,
      hourlywage: undefined,
      annualSalary: undefined,
      offerID: 0
    }
    this.jobOfferRequirements = {
      yearExperience: undefined,
      levelOfStudy: undefined,
      studyDiploma: undefined,
      otherLanguage: undefined,
      languageProficiencyFrId: undefined,
      languageProficiencyEnId: undefined,
      languageProficiencyOtherId: undefined,
      offerID: 0
    }
    this.prerequisites = {
      questions: [
        {
          responseType: undefined,
          questionEn: '',
          questionFr: '',
          viewAllCandidates: true,
          viewOnlyResponse: 0
        },
        {
          responseType: undefined,
          questionEn: '',
          questionFr: '',
          viewAllCandidates: true,
          viewOnlyResponse: 0
        }
      ]
    }
    this.language = {
      language: PublicationLanguage.FrenchAndEnglish
    }
    this.filledUsingTalentMontreal = false
    this.missionIds = []
    this.cohortId = 0
    this.interestAreaGroups = []
    this.isPaid = false
  }
}
