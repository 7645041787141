



















































import { Component, Prop, Vue } from 'vue-property-decorator'
import MediaObject from '@/content/models/MediaObject'

@Component
export default class TestimonialBlockComponent extends Vue {
  @Prop() private title!: string
  @Prop() private link!: string
  @Prop() private image!: MediaObject
  @Prop() private linkText!: string
  @Prop() private videoLink!: string
  private animation = false

  private onMouseOver () {
    this.animation = true
  }

  private onMouseLeave () {
    this.animation = false
  }
}
