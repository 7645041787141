<script>
import { Line } from 'vue-chartjs'

export default {
  extends: Line,

  // eslint-disable-next-line vue/require-prop-types
  props: ['chartData', 'chartOptions'],
  mounted () {
    this.renderChart(this.chartData, this.chartOptions)
  }
}
</script>
