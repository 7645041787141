export class CandidateSearchDto {
  JobId: number
  Culture: string
  PageNumber: number
  PageSize: number
  CityOrCountry: string
  SelectedMissions: Array<number>
  CountryIds: Array<number>
  LanguageProficiencyFrIds: Array<number>
  LanguageProficiencyEnIds: Array<number>
  InterestAreaGroupCode: number | null
  Industries: Array<string>
  LevelsOfStudy: Array<string>
  Sort: string

  constructor (
    JobId: number,
    Culture: string,
    PageNumber: number,
    PageSize: number,
    CityOrCountry: string,
    SelectedMissions: Array<number>,
    CountryIds:Array<number>,
    LanguageProficiencyFrIds: Array<number>,
    LanguageProficiencyEnIds: Array<number>,
    InterestAreaGroupCode: number | null,
    Industries: Array<string>,
    LevelsOfStudy: Array<string>,
    Sort: string) {
    this.JobId = JobId
    this.Culture = Culture
    this.PageNumber = PageNumber
    this.PageSize = PageSize
    this.CityOrCountry = CityOrCountry
    this.SelectedMissions = SelectedMissions
    this.CountryIds = CountryIds
    this.LanguageProficiencyFrIds = LanguageProficiencyFrIds
    this.LanguageProficiencyEnIds = LanguageProficiencyEnIds
    this.InterestAreaGroupCode = InterestAreaGroupCode
    this.Industries = Industries
    this.LevelsOfStudy = LevelsOfStudy
    this.Sort = Sort
  }
}
