























import { Component, Prop, Vue } from 'vue-property-decorator'
import ArticleSummary from './ArticleSummary.model'
import ArticleListTranslations from './ArticleList.translations'
import ImageCardComponent from '../../../content/card/ImageCard.vue'
import MITMCardContainerComponent from '@/generic/components/MITMCardContainer.vue'
import { CallToAction } from '@/generic/models/CallToAction'

@Component({
  components: {
    'mitm-image-card': ImageCardComponent,
    'mitm-card-container': MITMCardContainerComponent
  }
}
)
export default class ArticleListComponent extends Vue {
  @Prop() articles!: ArticleSummary[]
  @Prop() translations!: ArticleListTranslations
  @Prop() callToAction!: CallToAction
}
