import { Filter } from '@/admin/console/models/Filter'

export class MeetingsListFilter {
    total: number
    followUpNeededCount: number
    pageNumber: number
    pageSize: number
    meetingType: string
    selectedEvent: string
    selectedEventType: string
    selectedJobOffer: number
    selectedCompany: number
    searchTerms: string
    cultureName: string
    filterValue: Filter
    onlyUpcoming: boolean
    onlyFollowUpNeeded: boolean
    followUpProcessStatus: FollowUpProcessStatus

    constructor () {
      this.total = 0
      this.followUpNeededCount = 0
      this.pageNumber = 1
      this.pageSize = 20
      this.meetingType = 'Tous'
      this.selectedEvent = ''
      this.selectedEventType = ''
      this.selectedJobOffer = 0
      this.selectedCompany = 0
      this.searchTerms = ''
      this.cultureName = ''
      this.filterValue = 0
      this.onlyUpcoming = false
      this.onlyFollowUpNeeded = false
      this.followUpProcessStatus = 1
    }
}

export enum FollowUpProcessStatus {
  All,
  Ongoing,
  Ended
}
