






















































































































































































import { loadWhile, notify } from '@/generic/helpers'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Company } from '@/company/Company.model'
import { ActivityArea } from '@/activity-area/ActivityArea.model'
import { ActivityAreaInfo } from '@/activity-area/ActivityAreaInfo.model'
import { AccountTranslations } from '@/authentication/account/Account.translations'
import ValidatePasswordDialog from '@/authentication/password/ValidatePassword.vue'
import { ValidatePasswordModel } from '@/authentication/password/ValidatePassword.model'
import { ChangeAccountModel } from '@/authentication/account/ChangeAccount.model'
import { EditCompanyTranslations } from '@/company/edit/EditCompany.translations'
import AdminConsoleCompanyService from '../../services/AdminConsoleCompanyService'
import { SelectOption } from '@/generic/models/SelectOption'
import { JobOffer } from '@/offer/JobOffer.model'
import JobCardTranslations from '@/offer/summary/JobCard.translations'

@Component
export default class AdminEditCompanyComponent extends Vue {
  @Prop() private translations!: EditCompanyTranslations
  @Prop() private jobCardTranslations !: JobCardTranslations
  @Prop() private activityAreas !: ActivityArea[]
  @Prop() private company !: Company
  @Prop() private culture !: string
  @Prop() private account!: ChangeAccountModel
  @Prop() private emailNotificationField !: Array<SelectOption>
  @Prop() private jobOffers !: JobOffer[]
  private currentUser: number = -1
  private model = new Company()
  private hasError: boolean = false
  private errorMessage: string = ''
  private loaded : boolean = false
  private sectionPadding : string = 'U--mt--30'
  private seeRecruiterProfile : boolean = false
  private selectedRecruiterId: number = -1

  private handleError (error: any) {
    this.hasError = true
    if (error.response.status === 404) {
      this.errorMessage = this.translations.errors.common.notFound
    } else if (error.response.status === 403) {
      this.errorMessage = this.translations.errors.common.forbidden
    } else {
      this.errorMessage = this.translations.errors.common.unknown
    }
  }

  private mounted () {
    if (this.company !== null) {
      this.model = this.company
      this.adjustActivities(this.model.activityArea, this.activityAreas)
      this.loaded = true
    }
  }

  private get validatePasswordTranslations (): AccountTranslations {
    return {
      auth: this.translations.auth,
      common: this.translations.common,
      error: this.translations.errors.auth,
      companyDeleted: this.translations.company.edit.notify.deleted
    }
  }

  private adjustActivities (activites : ActivityAreaInfo, areas: ActivityArea[]) {
    this.adjustActivityName(activites.primaryActivity, areas)
    this.adjustActivityName(activites.secondaryActivity, areas)
  }

  private adjustActivityName (activity : ActivityArea | undefined, areas: ActivityArea[]) {
    if (activity && areas) {
      const found = areas.find(function (area) {
        if (activity) {
          return area.id === activity.id
        }
        return false
      })

      activity.name = found ? found.name : ''
    }
  }

  private saveCompany () {
    this.hasError = false
    this.validate()
      .then(isValid => {
        if (isValid) {
          this.model.culture = this.culture
          loadWhile(this, this.translations.company.edit.loading.saving, () => AdminConsoleCompanyService.saveCompany(this.model)
            .then(response => {
              this.model = response.data
              this.adjustActivities(this.model.activityArea, this.activityAreas)
              notify(this, this.translations.company.edit.notify.savedAndRecruiterModified)
            })
            .catch((error) => {
              if (error.response.data.message === undefined) {
                this.handleError(error)
              } else {
                this.hasError = true
                this.errorMessage = this.translations.errors.colleague[error.response.data.message]
              }
            })
          )
        }
      })
  }

  private deleteCompany () {
    (this.$refs.validatePasswordDialog as ValidatePasswordDialog).open().then((event: ValidatePasswordModel | undefined) => {
      if (event) {
        loadWhile(this, this.translations.common.message.loading, () => AdminConsoleCompanyService.deleteCompany(this.model.id, event.password, this.culture).then(response => {
          notify(this, this.translations.company.edit.notify.deleted)
          setTimeout(() => {
            window.location.href = response.data
          }, 3000)
        }))
      }
    })
  }

  private validate (): Promise<boolean> {
    const form = this.$refs['editCompanyForm'] as any
    return form.validate()
  }

  private showRecruiter (id: number) {
    this.selectedRecruiterId = id
    this.seeRecruiterProfile = true
  }

  private get recruiterId () {
    return this.selectedRecruiterId
  }

  private showJobOffer (id: number) {
    if (this.culture === 'en-US') {
      window.location.href = `/en/site-admin/console-edit-job-offer/${id}`
    } else {
      window.location.href = `/fr/admin/console-modification-offre/${id}`
    }
  }
}
