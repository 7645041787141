import { AxiosPromise } from 'axios'
import { BASE_API } from './api-common'
import { Colleague } from '@/company/colleagues/Colleague.model'

export class RecruiterService {
  public static inviteColleagues (id : number, culture : string, model : Colleague) : AxiosPromise<any> {
    return BASE_API.post(`recruiter/invite/${id}`, model, {
      params: {
        culture: culture
      }
    })
  }

  public static reInviteColleagues (id : number, culture : string, email : string) : AxiosPromise<any> {
    return BASE_API.post(`recruiter/reinvite/${id}`, {}, {
      params: {
        culture: culture,
        email: email
      }
    })
  }

  public static requestMoreAllowedJobOffers (id : number, culture : string) : AxiosPromise<any> {
    return BASE_API.post(`recruiter/requestMoreAllowedJobOffers/${id}`, {}, {
      params: {
        culture: culture
      }
    })
  }
}
