




















































































































































































































































































































import { Component, Prop, Vue, Mixins } from 'vue-property-decorator'
import { FooterTranslations } from '../translations/Footer.translations'
import { CultureService } from '../../services/CultureService'
import { Culture } from '../models/Culture'
import NewsletterFormComponent from '@/newsletter/NewsletterForm.vue'
import { notify } from '../helpers'
import { NewsletterService } from '@/services/Newsletter.service'
import { NewsletterForm } from '@/newsletter/NewsletterForm'
import NewsletterDialogComponent from '@/newsletter/NewsletterDialog.vue'

@Component({
  components: {
    'mitm-newsletter-dlg': NewsletterDialogComponent
  }
})
export default class FooterComponent extends Vue {
  @Prop() private translations !: FooterTranslations
  @Prop() private about !: string
  @Prop() private contactUs !: string
  @Prop() private faq !: string
  @Prop() private newsletterPage !: string

  private openCookiesPopup (): void {
    Optanon.ToggleInfoDisplay()
  }

  private get privacyPolicy () : string {
    if (CultureService.getCulture() === Culture.French) {
      return 'https://www.montrealinternational.com/fr/politique-de-vie-privee/'
    }
    return 'https://www.montrealinternational.com/en/privacy-policy/'
  }

  private get termsConditions () : string {
    if (CultureService.getCulture() === Culture.French) {
      return 'https://www.montrealinternational.com/fr/conditions-dutilisation/'
    }
    return 'https://www.montrealinternational.com/en/terms-and-conditions/'
  }

  private get montrealInternational (): string {
    if (CultureService.getCulture() === Culture.French) {
      return 'https://www.montrealinternational.com/'
    }
    return 'https://www.montrealinternational.com/en/'
  }

  private get jeChoisisMontreal (): string {
    if (CultureService.getCulture() === Culture.French) {
      return 'https://www.jechoisismontreal.com/fr/'
    }
    return 'https://www.jechoisismontreal.com/en/'
  }

  private get quebec (): string {
    if (CultureService.getCulture() === Culture.French) {
      return 'https://www.quebec.ca/'
    }
    return 'https://www.quebec.ca/en/'
  }

  private get homepage (): string {
    if (CultureService.getCulture() === Culture.French) {
      return '/'
    }
    return '/en'
  }

  private openNewsletterSignup (): void {
    // (this.$refs.newsletterPrompt as NewsletterDialogComponent).open()
    location.href = this.newsletterPage
  }
}
