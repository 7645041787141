























































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { LicenseTranslations } from './License.translations'
import { CommonTranslations } from '@/generic/models/Common.translations'
import { License } from './License.model'
import { SelectOption } from '@/generic/models/SelectOption'
import { RequiredRule } from '@/generic/rules/Required'
import { ITranslatable } from '@/generic/models/ITranslatable'

@Component
export default class EducationComponent extends Vue {
  @Prop() private translations!: LicenseTranslations
  @Prop() private commonTranslations!: CommonTranslations
  @Prop() private value!: Array<License>
  @Prop() private sectionNumber!: number
  @Prop({ default: false }) private isCandidateProfil!: boolean
  @Prop() private countriesList !: Array<SelectOption>
  @Prop() private fieldsOfStudy !: Array<SelectOption>

  private rules: { [key: string]: Array<(value: any) => boolean | string> } = {
    required: [
      new RequiredRule().getValidator(this.commonTranslations.message.required)
    ]
  }
  private maxItem : number = 2
  private maxLength : number = 60

  private deleteRow (pos: number) {
    this.value.splice(pos, 1)
  }

  private addRow () {
    this.value.push({
      name: '',
      issuingOrganization: '',
      country: '',
      countryId: null,
      fieldOfStudy: '',
      issueDate: null,
      expirationDate: null
    })
  }

  private onIssueDate (value: any, reason: any, details: any, index: number) {
    if (reason === 'month') {
      (this.$refs.qIssueDateProxy as any)[index].hide()
    }
  }

  private onExpirationDate (value: any, reason: any, details: any, index: number) {
    if (reason === 'month') {
      (this.$refs.qExpirationDateProxy as any)[index].hide()
    }
  }

  private validateMaxItem (): boolean {
    if (this.value?.length > this.maxItem) {
      return false
    }
    return true
  }

  private get hasMaxEntries () : boolean {
    return this.value && this.value.length >= 2
  }

  private isEmptyOrSpaces (str: string): boolean {
    return str === null || str.match(/^ *$/) !== null
  }

  private formatDate (date : Date) : string {
    var month = '' + (date.getMonth() + 1)
    var day = '' + date.getDate()
    var year = date.getFullYear()

    if (month.length < 2) {
      month = '0' + month
    }

    if (day.length < 2) {
      day = '0' + day
    }

    return [year, month, day].join('/')
  }
}
