



















































































































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import moment from 'moment'
import { loadWhile, notify } from '@/generic/helpers'
import { SelectOption } from '@/generic/models/SelectOption'
import { PublicationLanguage } from '@/generic/models/PublicationLanguage'
import { Status } from '@/generic/models/Status'
import { dateFilter } from '@/generic/filters/Date'
import MissionCompanyModel from '@/dashboard/missions/Mission.model'
import { EditOfferTranslations } from '@/offer/edit/EditOffer.translations'
import { EditOffer } from '@/offer/edit/EditOffer.model'
import SkillHelpComponent from '@/offer/skill/SkillHelp.vue'
import AdminConsoleJobOfferService from '../../services/AdminConsoleJobOfferService'
import { CompanyService } from '@/services/CompanyService'
import { JobOffer } from '@/offer/JobOffer.model'
import { ProductType } from '@/dashboard/employer-package/models/ProductType'
import { DurationOptionType } from '@/dashboard/employer-package/models/DurationOptionType'
import { CompanyOrder } from '@/company/models/CompanyOrder'
import { WorkInfoData } from '@/offer/jobOfferWorkInfos/WorkInfoData.model'
import { RequirementData } from '@/offer/jobOfferRequirement/RequirementData.model'

@Component({
  components: {
    'mitm-skill-help': SkillHelpComponent
  }
})
export default class AdminEditJobOfferComponent extends Vue {
  @Prop() translations!: EditOfferTranslations
  @Prop() experienceData!: Array<SelectOption>
  @Prop() workInfoData!: WorkInfoData
  @Prop() requirementData!: RequirementData
  @Prop() offer!: JobOffer
  @Prop() availableOrders!: CompanyOrder[]
  @Prop({ default: false }) private companyCanPublish!: boolean
  @Prop() private culture !: string
  @Prop() statusData!: Array<SelectOption>

  private selectedSoftSkills : Array<SelectOption> = []
  private maxSoftSkillsItem : number = 2
  private minSoftSkillsItem : number = 1
  private optionIsDisabled : Array<boolean> = [false, false]
  private isInterestAreaLoaded = true
  private missions: MissionCompanyModel[] = []
  private model = new EditOffer()
  private hasError = false
  private errorMessage = ''
  private loaded = false
  private selectedMissionIds: number[] = []
  private availableOrdersOptions: SelectOption[] = []
  private availableOrdersOptionsShort: SelectOption[] = []
  private optionLabel: string = ''
  private softSkillsList : Array<SelectOption> = [
    { value: '', label: this.translations.offer.skillsAndTitle.choose },
    { value: 'Autonomy', label: this.translations.offer.skillsAndTitle.autonomy },
    { value: 'AbilityToFederate', label: this.translations.offer.skillsAndTitle.abilityToFederate },
    { value: 'Adaptability', label: this.translations.offer.skillsAndTitle.adaptability },
    { value: 'CommunicationSkills', label: this.translations.offer.skillsAndTitle.communicationSkills },
    { value: 'Curiosity', label: this.translations.offer.skillsAndTitle.curiosity },
    { value: 'DecisionMakingSkills', label: this.translations.offer.skillsAndTitle.decisionMakingSkills },
    { value: 'OrganizationalSkills', label: this.translations.offer.skillsAndTitle.organizationalSkills },
    { value: 'Perseverance', label: this.translations.offer.skillsAndTitle.perseverance },
    { value: 'Reactivity', label: this.translations.offer.skillsAndTitle.reactivity },
    { value: 'Rigor', label: this.translations.offer.skillsAndTitle.rigor },
    { value: 'StepBackSkills', label: this.translations.offer.skillsAndTitle.stepBackSkills },
    { value: 'StrengthOfProposal', label: this.translations.offer.skillsAndTitle.strengthOfProposal },
    { value: 'StressManagement', label: this.translations.offer.skillsAndTitle.stressManagement },
    { value: 'Teamwork', label: this.translations.offer.skillsAndTitle.teamwork }
  ]
  calendarMinDate!: Date

  get expiryDate () {
    if (this.model.offer.expiryDate) {
      return this.model.offer.expiryDate
    }
    const date = new Date()
    date.setMonth(date.getMonth() + 1)
    return date
  }

  get canExtend () {
    return this.companyCanPublish
  }

  get canPublish () {
    return this.companyCanPublish
  }

  get canRepublish () {
    return this.companyCanPublish
  }

  get expiryMessage () {
    if (this.isExpired) {
      return this.translations.offer.edit.expired
    }
    if (this.isClosed) {
      return this.translations.offer.edit.closed
    }
    if (this.isPublished) {
      return this.translations.offer.edit.expires
    }
    const nowRegex = /{{now}}/gi
    return this.translations.offer.edit.prompt.replace(nowRegex, dateFilter(new Date()))
  }

  get isDraft () : boolean {
    return this.model.offer.status === Status.Draft
  }

  get isExpired () : boolean {
    return this.model.offer.status === Status.Expired
  }

  get isPublished () : boolean {
    return this.model.offer.status === Status.Published
  }

  get isReviewed () : boolean {
    return this.model.offer.status === Status.Reviewed
  }

  get isReadyToBePublish () : boolean {
    return this.model.offer.status === Status.ReadyToBePublished
  }

  get isClosed () : boolean {
    return this.model.offer.status === Status.Closed ||
           this.model.offer.status === Status.Expired
  }

  private handleError (error: any) {
    this.hasError = true
    if (error.response.status === 400 && error.response.data.message === 'accountNotConfirmed') {
      this.errorMessage = this.translations.errors.auth.accountNotConfirmed
    } else if (error.response.status === 404) {
      this.errorMessage = this.translations.errors.company.notFound
    } else if (error.response.status === 403) {
      this.errorMessage = this.translations.errors.common.forbidden
    } else if (error.response.status === 409) {
      this.errorMessage = this.translations.errors.offer.cannotRepublish
    } else {
      this.errorMessage = this.translations.errors.offer[error.response.data.message]
    }
  }

  mounted () {
    this.ordersProcessing()

    this.model.offer = this.offer
    this.model.offer.isPaid = this.model.offer.isPaid !== undefined ? this.model.offer.isPaid : false
    this.calendarMinDate = this.model.offer.expiryDate ? this.model.offer.expiryDate : new Date()

    for (let i = 0; i < this.model.offer.softSkills.length; i++) {
      let skill = this.softSkillsList.find(s => s.value === this.model.offer.softSkills[i])
      if (skill !== null) {
        this.selectedSoftSkills.push(skill as SelectOption)
        this.optionIsDisabled[i] = true
      }
    }
    if (this.model.offer.softSkills.length === 0) {
      this.selectedSoftSkills.push(this.softSkillsList[0])
      this.model.offer.softSkills.push(this.softSkillsList[0].value.toString())
    }

    this.ajustMissionSelect()
    this.ajustStatus(this.offer.status)

    loadWhile(this, this.translations.common.message.loading, () => Promise.all([
      CompanyService.getCompany(this.model.offer.companyId)
        .then(response => {
          this.model.company.id = this.model.offer.companyId
          this.model.company.companyInfo = response.data.basicInfo
          this.model.offer.addressInfo.jobCity = this.model.company.companyInfo.city
          this.model.offer.addressInfo.jobAddress = this.model.company.companyInfo.address
          this.model.offer.addressInfo.jobPostalCode = this.model.company.companyInfo.postalCode
        }),
      AdminConsoleJobOfferService.activeMissionCompany(this.model.offer.companyId)
        .then(response => {
          this.missions = response.data
          this.ajustMissionSelect()
          this.adjustJobOfferWorkInfos()
          this.adjustJobOfferRequirements()
        })
    ]))
      .catch(error => {
        if (error.response.data.message === undefined) {
          this.handleError(error)
        } else {
          this.hasError = true
          this.errorMessage = this.translations.errors.company[error.response.data.message]
        }
      })
      .finally(() => {
        this.loaded = true
      })
  }
  ordersProcessing() {
    this.availableOrders.forEach(o => {
      if (o.productType === ProductType.JobOffer) {
        let name = o.name.split('(')[0]
        let duration = o.productDurationType === DurationOptionType.oneMonth
          ? this.translations.orderDurations.one
          : o.productDurationType === DurationOptionType.oneYear
            ? this.translations.orderDurations.two
            : ''
        o.name = name + ' (' + duration + ')'
      } else {
        o.name = o.name.split('(')[0]
      }
    })
    this.createAvailableOrdersOptionsShort(this.availableOrders)
    this.createAvailableOrdersOptions(this.availableOrders)
  }

  private createAvailableOrdersOptionsShort (availableOrders: CompanyOrder[]) {
    this.availableOrdersOptionsShort = []
    this.extractLabel(availableOrders, this.availableOrdersOptionsShort, true)
  }

  private createAvailableOrdersOptions (availableOrders: CompanyOrder[]) {
    this.availableOrdersOptions = []
    this.extractLabel(availableOrders, this.availableOrdersOptions)
  }

  private extractLabel(availableOrders: CompanyOrder[], extractedArray: SelectOption[], isShort = false) {
    availableOrders.forEach(o => {
      let label = o.isCrmOrder ? o.name
        : o.productType === ProductType.Mission ? this.getAvailableOrdersMissionName(o, isShort)
          : this.getAvailableOrdersName(o, isShort)
      let option: SelectOption = {
        label: label,
        value: o.orderItemId
      }
      return extractedArray.push(option)
    })
  }

  private getAvailableOrdersName(order: CompanyOrder, isShort: boolean): string {
    if (isShort) {
      return order.name
    } else {
      return order.name + ' - ' +
      order.remainingQuantity + ' ' +
      this.translations.offer.select.remainingOrders + ' - ' + this.getFormattedDate(order.expiry)
    }
  }

  private getAvailableOrdersMissionName(order: CompanyOrder, isShort: boolean): string {
    if (isShort) {
      return order.name.split('(')[0]
    } else {
      return order.name.split('(')[0] + ' - ' + this.getFormattedDate(order.expiry)
    }
  }

  private getFormattedDate(d: Date): string {
    let date = new Date(d)
    return [
      date.getFullYear(),
      ('0' + (date.getMonth() + 1)).slice(-2),
      ('0' + date.getDate()).slice(-2)
    ].join('-')
  }

  displayOfferName(selectedId: number, availableOptions: SelectOption[]) {
    let index = 0
    availableOptions.forEach(o => {
      if (o.value !== -900) {
        if (o.value === selectedId) {
          let splittedOption = o.label.split('-')
          let order = this.availableOrders.find(o => o.orderItemId === selectedId && o.productType === ProductType.Mission) || this.availableOrders.find(o => o.orderItemId === selectedId && o.productType === ProductType.Cohort)
          if (order !== undefined) {
            this.optionLabel = splittedOption[0] + '- ' + this.translations.offer.order.cohort.options.part.one + ' ' +
            moment(order.candidateStartDate).format('DD/MM/YYYY') + ' ' + this.translations.offer.order.cohort.options.part.two +
            ' ' + moment(order.candidateEndDate).format('DD/MM/YYYY')
          } else {
            this.optionLabel = splittedOption[0] + '- ' + splittedOption[1] + '- ' +
            this.translations.offer.select.expiry + ' ' + splittedOption[2] + '-' + splittedOption[3] + '-' + splittedOption[4]
          }
        } else if (selectedId === null) {
          this.optionLabel = ''
        }
      }
      index++
    })
    return this.optionLabel
  }

  private adjustJobOfferWorkInfos() {
    if (this.model.offer.jobOfferWorkInfos == null) {
      this.model.offer.jobOfferWorkInfos = {
        hoursPerWeek: undefined,
        salaryIsAnnually: 1,
        unionize: 0,
        hourlywage: undefined,
        annualSalary: undefined,
        offerID: 0
      }
    }
  }

  private adjustJobOfferRequirements() {
    if (this.model.offer.jobOfferRequirements == null) {
      this.model.offer.jobOfferRequirements = {
        yearExperience: undefined,
        levelOfStudy: undefined,
        studyDiploma: undefined,
        otherLanguage: undefined,
        languageProficiencyFrId: undefined,
        languageProficiencyEnId: undefined,
        languageProficiencyOtherId: undefined,
        offerID: 0
      }
    }
  }

  private saveDraftClick (): void {
    this.saveDraft(false, this.isDraft).catch(() => {})
  }

  private saveDraft (noRedirect: boolean, isDraftEditing: boolean): Promise<any> {
    this.hasError = false
    return this.validate().then(isValid => {
      if (isValid) {
        this.model.offer.missionIds = []
        if (this.isOrderSelectedTypeOfMission()) {
          this.setMissionId()
        }
        return loadWhile(this, this.translations.common.message.loading, () => AdminConsoleJobOfferService.editOffer(this.model.offer.id, this.model, isDraftEditing)
          .then(response => {
            notify(this, this.translations.offer.edit.saved)
          }).catch((error) => {
            if (error.response.data.message === undefined) {
              this.handleError(error)
            } else {
              this.hasError = true
              this.errorMessage = this.translations.errors.offer[error.response.data.message]
            }
          }))
      } else {
        return Promise.reject(isValid)
      }
    })
  }

  private changeLanguage (language : PublicationLanguage): void {
    this.model.offer.language.language = language
  }

  private validate (): Promise<boolean> {
    const form = this.$refs['editOfferForm'] as any
    return form.validate()
  }

  private ajustMissionSelect () {
    this.selectedMissionIds = this.model.offer.missionIds
  }

  private setMissionId () {
    let selectedOrder = this.availableOrders.find(o => o.orderItemId === this.model.offer.orderItemId)
    if (selectedOrder !== undefined) {
      let mission = this.missions.find(m => m.crmId === selectedOrder?.orderItemGuid)
      if (mission !== undefined) {
        this.model.offer.missionIds.push(mission.id)
      }
    }
  }

  private isOrderSelectedTypeOfMission () : boolean {
    return this.availableOrders.find(o => o.orderItemId === this.model.offer.orderItemId)?.productType === ProductType.Mission
  }

  private isRec(orderItemId: number) : boolean {
    return this.availableOrders.find(o => o.orderItemId === orderItemId)?.productType === ProductType.JobOffer
  }

  private addSoftSkillRow() {
    if (this.selectedSoftSkills.length === this.minSoftSkillsItem) {
      this.selectedSoftSkills.push(this.softSkillsList[0])
      this.model.offer.softSkills.push(this.softSkillsList[0].value.toString())
    }
  }

  private deleteSoftSkillRow() {
    if (this.selectedSoftSkills.length === this.maxSoftSkillsItem) {
      this.optionIsDisabled[1] = false
      this.selectedSoftSkills.pop()
      this.model.offer.softSkills.pop()
    }
  }

  private setModelSoftSkill(index: number) {
    let selectInput = (document.getElementsByClassName('soft-skill-select')[index] as HTMLSelectElement)
    let selectedOption = this.softSkillsList.find(s => s.value === selectInput.value) as SelectOption
    this.selectedSoftSkills[index] = selectedOption
    this.model.offer.softSkills[index] = selectedOption.value.toString()
    this.disableFirstOption(index)
  }

  private ajustStatus(offerStatus: Status) {
    let status: SelectOption[] = []
    this.statusData.forEach(item => {
      if (item.value === offerStatus) {
        status.push(item)
      } else if ([Status.Archived, Status.Closed, Status.Expired, Status.Reviewed].includes(+item.value)) {
        status.push(item)
      } else if (this.isRec(this.model.offer.orderItemId || 0) && this.model.offer.status === Status.ReadyToBePublished && +item.value === Status.Published) {
        status.push(item)
      }
    })
    this.statusData = status
  }

  disableFirstOption(index: number) {
    if (!this.optionIsDisabled[index]) {
      let selectInput = (document.getElementsByClassName('soft-skill-select')[index] as HTMLSelectElement)
      selectInput.options[0].disabled = true
      this.optionIsDisabled[index] = true
    }
  }

  getDateForCalendar(date: Date | null) {
    date = date ? new Date(date) : new Date()
    return [
      date.getFullYear(),
      ('0' + (date.getMonth() + 1)).slice(-2),
      ('0' + date.getDate()).slice(-2)
    ].join('/')
  }

  onExpiryDate (value: any, reason: any, details: any) {
    if (reason === 'day' && details.changed) {
      this.model.offer.expiryDate = new Date(value)
    }
  }
}
