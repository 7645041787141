





























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class TitleBlockComponent extends Vue {
  @Prop() private primaryColor!: string
  @Prop() private secondaryColor!: string
  @Prop() private titleClass!: string
  @Prop() private fontSize!: string
  @Prop() private isHeader!: boolean
}
