







































































import { Culture } from '@/generic/models/Culture'
import { CultureService } from '@/services/CultureService'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class AppHeaderComponent extends Vue {
  @Prop() private title!: string
  @Prop() private otherLanguageLabel!: string
  @Prop() private otherLanguageLink!: string
  @Prop() private companyName!: string
  @Prop() private companyLogo!: string
  @Prop() private signOutRedirect!: string
  @Prop() private homepageUrl!: string
  @Prop({ default: true }) private shoppingCartIsEmpty!: boolean

  private headerClass = this.$q.screen.lt.md ? 'header-mobile' : 'header q-px-lg'

  private ShowCartItems () {
    if (this.shoppingCartIsEmpty) {
      this.goToOffersPage()
    } else {
      this.goToCartPage()
    }
  }

  private toggleMenu (): void {
    this.$emit('open-menu')
  }

  private goToOffersPage () {
    if (CultureService.getCulture() === Culture.English) {
      window.location.href = '/en/employer/offers'
    } else {
      window.location.href = '/fr/employeur/offres'
    }
  }

  private goToCartPage () {
    if (CultureService.getCulture() === Culture.English) {
      window.location.href = `/en/employer/cart`
    } else {
      window.location.href = `/fr/employeur/panier`
    }
  }
}
