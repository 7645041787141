import Product from './Product.models'

export default class Cohort extends Product {
  startDate: Date
  endDate: Date
  saleStartDate: Date
  saleEndDate: Date
  industries: Array<string>

  constructor () {
    super()
    this.startDate = new Date()
    this.endDate = new Date()
    this.saleStartDate = new Date()
    this.saleEndDate = new Date()
    this.industries = []
  }
}
