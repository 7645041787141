

































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Culture } from '@/generic/models/Culture'
import { CultureService } from '@/services/CultureService'
import { getUrlParameter, loadWhile, fullURL } from '@/generic/helpers'
import { SelectOption } from '@/generic/models/SelectOption'
import MeetingTranslations from './Meeting.translations'
import { Meeting } from './models/Meeting.model'
import { MeetingsListFilter } from './models/MeetingsListFilter.model'
import { MeetingService } from '@/services/MeetingService'
import { ProductService } from '@/services/ProductService'

@Component
export default class MeetingListComponent extends Vue {
  @Prop() companyId!: number
  @Prop() translations!: MeetingTranslations
  @Prop() statusData!: Array<SelectOption>
  @Prop() processStatusData!: Array<SelectOption>
  @Prop() salaryData!: Array<SelectOption>
  private meetings: Array<Meeting> = []
  private filters = new MeetingsListFilter()
  private events: SelectOption[] = []
  private selectedEvent!: SelectOption | null
  private selectedStatus!: SelectOption | null
  private hideEventFilter: boolean = false
  private eventsLoaded: boolean = false
  private hasError: boolean = false
  private total: number = 0
  private needUpdateCount: number = 0
  private errorMessage: string = ''
  private tab: string = '0'
  private loaded: boolean = false
  private meetingTypes = [
    this.translations.common.option.all,
    'Mission',
    this.translations.meetingsPage.onlyRec
  ]
  private pageSizeOptions = [20, 50, 100]
  private pageSizeOldValue = 20

  private handleError (error: any) {
    this.hasError = true
    if (error.response.status === 404) {
      this.errorMessage = this.translations.meetingError.notFound
    } else if (error.response.status === 403) {
      this.errorMessage = this.translations.meetingError.forbidden
    } else {
      this.errorMessage = this.translations.meetingError.unknown
    }
  }

  private updateCounts (init: boolean = false) : Promise<void> {
    return MeetingService.getFollowUpMeetingCount(this.companyId).then(result => {
      this.needUpdateCount = result.data.followUpNeeded
      this.total = result.data.total
      if (init && this.needUpdateCount > 0) {
        this.tab = '1'
        this.filters.onlyFollowUpNeeded = true
      }
    })
  }

  mounted () {
    const requestedTab = getUrlParameter('tab') || '0'
    if (['0', '1'].includes(requestedTab)) {
      this.tab = requestedTab
    } else {
      this.updateUrl()
    }

    this.setupFilters()
    loadWhile(this, '', () => this.updateCounts(true)).then(() => {
      this.getMeetings()
      this.loadEvents()
    }).then(() => {
      this.loaded = true
    }).catch(error => {
      this.handleError(error)
    })
  }

  private setupFilters () {
    this.filters = new MeetingsListFilter()
    if (CultureService.getCulture() === Culture.English) {
      this.filters.cultureName = 'en-US'
    } else {
      this.filters.cultureName = 'fr-CA'
    }
    this.filters.meetingType = this.translations.common.option.all
    this.filters.selectedCompany = this.companyId
    this.filters.onlyFollowUpNeeded = this.tab === '1'
    this.selectedStatus = this.processStatusData[this.filters.followUpProcessStatus]
  }

  private loadEvents (): Promise<void> {
    this.eventsLoaded = false
    return ProductService.getProductsForDropDownForCompany(this.companyId, this.filters.cultureName)
      .then((result) => {
        this.events = [{ label: this.translations.common.option.all, value: '' }]
        this.events = this.events.concat(result.data)
        this.eventsLoaded = true
      })
      .catch((error) => {
        if (error) {
          this.hasError = true
        }
      })
  }

  private getMeetings () : Promise<void> {
    this.loaded = false
    return loadWhile(this, ' ', () => MeetingService.getMeetings(this.filters)
      .then(response => {
        this.filters = response.data.filters
        this.meetings = []
        if (response.data.meetings.length > 0) {
          Array.prototype.push.apply(this.meetings, response.data.meetings)
        }
        this.loaded = true
      })
      .catch(error => {
        this.handleError(error)
      })
    )
  }

  private onStatusChange () {
    if (this.selectedStatus === null) {
      this.filters.followUpProcessStatus = 0
      this.getMeetings()
    } else if (this.filters.followUpProcessStatus !== this.selectedStatus.value) {
      this.filters.followUpProcessStatus = +this.selectedStatus.value
      this.getMeetings()
    }
  }

  private onMeetingTypeChange (meetingType: string) : void {
    if (meetingType === this.translations.meetingsPage.onlyRec) {
      this.hideEventFilter = true
      this.selectedEvent = null
      this.filters.selectedEvent = ''
    } else {
      this.hideEventFilter = false
    }
    this.getMeetings()
  }

  private onSearchTermsChange (newSearchTerms: string): void {
    this.filters.searchTerms = newSearchTerms
    if (this.filters.searchTerms.length >= 4 || this.filters.searchTerms.length === 0) {
      this.resetPageNumber()
    }
  }

  private eventSelected (eventSelected: SelectOption) {
    if (eventSelected && eventSelected.value !== '') {
      this.selectedEvent = eventSelected
      this.filters.selectedEvent = eventSelected.value.toString()
    } else {
      this.filters.selectedEvent = ''
      this.selectedEvent = null
    }
    this.$forceUpdate()
    this.getMeetings()
  }

  private updateUrl () {
    const url = fullURL()
    url.searchParams.set('tab', this.tab)
    window.history.pushState(null, '', url.href)
  }

  private onTabChange (newVal: string) {
    this.tab = newVal
    this.filters.onlyFollowUpNeeded = newVal === '1'
    this.updateUrl()
  }

  private pageSizeChanged () {
    if (this.filters.pageSize !== this.pageSizeOldValue) {
      this.pageSizeOldValue = this.filters.pageSize
      this.resetPageNumber()
    }
  }

  private onPageChange (newPage: number) {
    if (newPage !== this.filters.pageNumber) {
      this.filters.pageNumber = newPage
      this.getMeetings()
    }
  }

  private resetPageNumber () {
    this.filters.pageNumber = 1
    this.getMeetings()
  }

  private hide (needReloading: boolean) {
    if (needReloading) {
      this.loaded = false
      loadWhile(this, '', () => Promise.all([
        this.updateCounts(true),
        this.getMeetings()
      ]).then(() => {
        this.loaded = true
      }).catch(error => {
        this.handleError(error)
      }))
    }
  }
}
