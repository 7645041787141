































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { JobOfferSummary } from '@/offer/summary/JobOfferSummary.model'
import { loadWhile } from '@/generic/helpers'
import JobOfferCardTranslations from '@/offer/job-offer-list/jobOfferCard.translations'
import { OfferService } from '@/services/OfferService'

@Component
export default class JobBlockComponent extends Vue {
  @Prop() private title!: string
  @Prop() private activityArea!: string
  @Prop() private primaryColor!: string
  @Prop() private secondaryColor!: string
  @Prop() private allOffers!: string
  @Prop() private linkText!: string
  @Prop() private translations!: JobOfferCardTranslations
  @Prop() private currentCulture!: string
  @Prop() private userIsCandidate!: boolean
  @Prop() private maxJobs!: number
  @Prop() private seed!: string

  private jobsList: JobOfferSummary[] = []
  private hasError: boolean = false
  private errorMessage: string = ''
  private model = 1

  private handleError (error: any) {
    this.hasError = true
    if (error.response.status === 404) {
      this.errorMessage = this.translations.error.notFound
    } else if (error.response.status === 403) {
      this.errorMessage = this.translations.error.forbidden
    } else {
      this.errorMessage = this.translations.error.unknown
    }
  }

  mounted () {
    this.getJobOffers()
  }

  private getJobOffers () {
    return OfferService.getAllActiveJobsFromDB(
      this.currentCulture,
      1,
      '',
      this.maxJobs,
      this.seed,
      null,
      0,
      0,
      this.activityArea,
      null,
      null,
      null,
      null,
      null
    )

      .then(result => {
        this.jobsList = result.data.results
      })
      .catch(error => {
        this.handleError(error)
      })
  }
}
