import Vue from 'vue'
import Component from 'vue-class-component'

export enum GtmEvent {
  Employer = 'employer',
  Candidate = 'candidate',
  Newsletter = 'newsletter'
}

@Component
export default class GtmMixin extends Vue {
  protected callGtm (event: string, action: string, label: any, value?: any) {
    this.$gtm.trackEvent({
      event: event,
      eventAction: action,
      eventLabel: label,
      eventValue: value
    })
  }

  protected onLogin (isCandidate : boolean, crmId: string) {
    this.callGtm(isCandidate ? GtmEvent.Candidate : GtmEvent.Employer, 'log in', crmId)
  }
}
