import { JobOffer } from '@/offer/JobOffer.model'
import { ActivityArea } from '@/activity-area/ActivityArea.model'
import { BasicCompanyInfo } from '@/company/basic-info/BasicInfo.model'

export class EditOffer {
  public company: {
    id: number,
    companyInfo: BasicCompanyInfo
  }
  public offer: JobOffer
  public activityAreas: ActivityArea[]

  constructor () {
    this.company = {
      id: 0,
      companyInfo: {
        name: '',
        address: '',
        postalCode: '',
        city: '',
        phoneNumber: '',
        emailNotification: '',
        administrativeRegion: null
      }
    }
    this.offer = new JobOffer()
    this.activityAreas = []
  }
}
