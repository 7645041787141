import { Filter } from '../Filter'

export class JobOffersListFilter {
    total: number
    pageNumber: number
    pageSize: number
    selectedEvent: string
    selectedEventType: string
    selectedStatus!: Array<number>
    selectedCompanies!: Array<number>
    selectedLevelOfStudy!: number
    selectedAnnualSalary!: number
    selectedAdministrativeRegions!: Array<string>
    searchTerms: string
    cultureName: string
    filterValue: Filter

    constructor () {
      this.total = 0
      this.pageNumber = 1
      this.pageSize = 20
      this.selectedEvent = ''
      this.selectedEventType = ''
      this.selectedStatus = [1]
      this.selectedAdministrativeRegions = []
      this.selectedCompanies = []
      this.searchTerms = ''
      this.cultureName = ''
      this.filterValue = 0
    }
}
