





























import { Component, Prop, Vue } from 'vue-property-decorator'
import { CompanyCoordinate } from '@/company/models/CompanyCoordinate'

@Component
export default class EmployerPopUpComponent extends Vue {
  @Prop() company!: CompanyCoordinate
}
