









































































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { SiteMenu } from '@/navigation/model/SiteMenu.model'
import SignOutService from '@/services/SignOutService'
import { getInitials } from '@/generic/helpers'
import { MenuItem } from '../../model/Menu.model'

@Component
export default class DesktopHeaderComponent extends Vue {
  @Prop() private switchLanguageLabel!: string
  @Prop() private switchLanguageLink!: string
  @Prop() private menu!: SiteMenu

  private showUserMenu : boolean = false
  private initials = getInitials(this.menu.name)

  private signOut () {
    SignOutService.signOut()
      .then(() => {
        location.reload()
      })
  }

  private pageStyle (page: MenuItem) {
    return {
      '--hover-color': page.primaryColor
    }
  }

  private selectedStyle (page: MenuItem) {
    return window.location.pathname.startsWith(page.link)
  }
}
