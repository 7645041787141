import { render, staticRenderFns } from "./LanguageProficiency.vue?vue&type=template&id=32065644&scoped=true&"
import script from "./LanguageProficiency.vue?vue&type=script&lang=ts&"
export * from "./LanguageProficiency.vue?vue&type=script&lang=ts&"
import style0 from "./LanguageProficiency.vue?vue&type=style&index=0&id=32065644&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32065644",
  null
  
)

export default component.exports