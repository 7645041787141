











import { Component, Prop, Vue } from 'vue-property-decorator'
import { CommonErrorTranslations } from '@/generic/models/CommonError.translations'
import PaymentConfirmationTranslations from '../models/PaymentConfirmation.translations'

@Component
export default class PaymentConfirmationComponent extends Vue {
  @Prop() translations!: PaymentConfirmationTranslations
  @Prop() errors!: CommonErrorTranslations
  @Prop() orderNumber!: number
}
