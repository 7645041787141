

































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import MITMPromptComponent from '@/generic/components/MITMPrompt.vue'
import { DialogResult } from '@/generic/models/DialogResult'
import { notify, notifyNegative } from '@/generic/helpers'
import Product from '../../models/products/Product.models'
import ProductsListTranslations from '../../models/products/ProductsList.translations'
import AdminConsoleProductService from '../../services/AdminConsoleProductService'
import Cohort from '../../models/products/Cohort.models'
import { CultureService } from '@/services/CultureService'
import { getActivityAreasMifi } from '@/generic/activity-area-helper'
import { SelectOption } from '@/generic/models/SelectOption'
import { ProductType } from '@/dashboard/employer-package/models/ProductType'

@Component
export default class AdminProductCardComponent extends Vue {
  @Prop() product !: Product
  @Prop() productType !: ProductType
  @Prop() cohort !: Cohort
  @Prop() translations !: ProductsListTranslations
  private industriesList: Array<SelectOption> = getActivityAreasMifi(this.translations.industry)
  productTypeEnum: any = ProductType
  culture: string = CultureService.getCulture()
  seeDialog: boolean = false
  loading: boolean = false
  isCohort: boolean = this.productType === this.productTypeEnum.Cohort

  getDateRangeFormatted (dateStart: string, dateEnd: string) : string {
    if (dateStart === '0001-01-01T00:00:00' || dateEnd === '9999-12-31T23:59:59.9999999') {
      return 'N/A'
    }
    return new Date(dateStart).toLocaleDateString(this.culture + '-CA', this.getDateOptions) + ' - ' + new Date(dateEnd).toLocaleDateString(this.culture + '-CA', this.getDateOptions)
  }

  get getProduct () : any {
    if (this.isCohort) {
      return this.cohort
    } else {
      return this.product
    }
  }

  get getDateOptions () : object {
    return { year: 'numeric', month: '2-digit', day: '2-digit' }
  }

  get isActive () : boolean {
    if (this.isCohort) {
      var now = new Date()
      return new Date(this.getProduct['startDate']) <= now && new Date(this.getProduct['endDate']) >= now
    }
    return this.getProduct['enabled']
  }

  getCohortIndutry() {
    let industries: string[] = []
    this.cohort.industries.forEach((x) => {
      let industry = this.industriesList.find(industry => industry.value === x)
      if (industry !== undefined) {
        industries.push(industry.label)
      }
    })
    return industries
  }

  toggleDialog () : void {
    this.seeDialog = !this.seeDialog
  }

  deleteConfirmation () {
    (this.$refs.confirmPrompt as MITMPromptComponent).open()
  }

  deleteProduct (result: DialogResult) {
    if (result === DialogResult.Ok) {
      this.loading = true
      let id = this.isCohort ? this.cohort.skuGuid : this.product.skuGuid
      return AdminConsoleProductService.deleteProduct(id)
        .then((response) => {
          this.loading = false
          notify(this, this.translations.page.successNotify)
          this.$emit('hide')
          location.reload()
        })
        .catch((error) => {
          if (error) {
            notifyNegative(this, error.message)
          }
          this.loading = false
        })
    }
  }
}

