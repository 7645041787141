






















































































import { Component, Prop, Vue, Watch, Mixins } from 'vue-property-decorator'
import { SignIn, SignInRequest } from './SignIn.model'
import { SignInTranslations } from './SignIn.translations'
import { AuthenticationService } from '@/services/Authentication.service'
import VueRecaptcha from 'vue-recaptcha'
import { RequiredRule } from '@/generic/rules/Required'
import { getUrlParameter, loadWhile } from '@/generic/helpers'
import ForgotPasswordTranslations from '@/forgot-password/ForgotPassword.translations'
import { AuthentcationErrorTranslations } from '@/authentication/AuthentcationError.translations'
import { CultureService } from '../../services/CultureService'
import GtmEmployerMixin from '@/gtm/GtmEmployer'
import GtmCandidateMixin from '@/gtm/GtmCandidate'

@Component
export default class SignInComponent extends Mixins(GtmEmployerMixin, GtmCandidateMixin) {
  @Prop() private translations!: any;
  @Prop() private culture!: string;
  @Prop() private isCandidate! : boolean;
  @Prop() initModel!: SignInRequest;
  @Prop() passwordResetTranslations!: ForgotPasswordTranslations;
  @Prop() errors!: AuthentcationErrorTranslations

  private model: SignInRequest = {
    email: this.initModel.email,
    password: this.initModel.password,
    returnUrl: '',
    capchaRequest: '',
    preferredCulture: this.culture
  };
  private errorHappened:boolean = false;
  private errorMessage: string = ''
  private rules: { [key: string]: Array<(value: any) => boolean | string> } = {
    email: [new RequiredRule().getValidator(this.translations.email.required)],
    password: [
      new RequiredRule().getValidator(this.translations.password.required)
    ]
  };
  private showSendNewEmailButton: boolean = false
  private showEmailSent: boolean = false

  private onVerifyRecaptcha (response: any): void {
    this.model.capchaRequest = response
  }

  private onExpiredRecaptcha (): void {
    this.model.capchaRequest = ''
  }

  private submit (): void {
    this.model.preferredCulture = this.culture
    this.model.returnUrl = getUrlParameter('ReturnUrl')
    loadWhile(this, this.translations.loading, () => AuthenticationService.signIn(this.model)
      .then(response => {
        this.onLogin(this.isCandidate, response.data.crmId)
        window.location.href = response.data.redirectUrl
      })
      .catch(error => {
        if (error.response.data.message === 'notConfirmed') {
          this.showSendNewEmailButton = true
          this.showEmailSent = false
        }
        this.errorMessage = this.errors[error.response.data.message]
        this.errorHappened = true
      }))
  }

  private sendNewEmail (): void {
    loadWhile(this, this.translations.loading, () => AuthenticationService.resendConfirmationEmail(this.model.email, CultureService.getCultureCode())
      .then(() => {
        this.showSendNewEmailButton = false
        this.showEmailSent = true
      }))
  }
}
