





































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { downloadFileCsv, loadWhile } from '@/generic/helpers'
import MeetingCardTranslations from '../../models/meetings/MeetingCard.translations'
import { CultureService } from '@/services/CultureService'
import { Culture } from '@/generic/models/Culture'
import { SelectOption } from '@/generic/models/SelectOption'
import AdminConsoleService from '../../services/AdminConsoleService'
import { MeetingsListFilter } from '../../models/meetings/MeetingsListFilter.model'
import { Meeting } from '@/dashboard/meeting-list/models/Meeting.model'
import AdminConsoleMeetingService from '../../services/AdminConsoleMeetingService'

@Component
export default class AdminMeetingsListComponent extends Vue {
  @Prop() translations!: MeetingCardTranslations
  @Prop() filterData!: Array<SelectOption>
  @Prop() statusData!: Array<SelectOption>
  @Prop() processStatusData!: Array<SelectOption>
  @Prop() salaryData!: Array<SelectOption>
  needReloading: boolean = false
  filters = new MeetingsListFilter(this.translations.common.option.all || 'All')
  events: SelectOption[] = []
  jobsList: SelectOption[] = []
  companies: SelectOption[] = []
  selectedEvent!: SelectOption | null
  selectedJobOffer!: SelectOption | null
  selectedCompany!: SelectOption | null
  selectedStatus!: SelectOption | null
  meetings: Array<Meeting> = []
  meeting?: Meeting
  hasError = false
  hideEventFilter = false
  loaded = true
  eventsLoaded = false
  jobsOffersLoaded = false
  companiesLoaded = false
  searchTerms = ''
  animation = false
  pageSizeOptions = [20, 50, 100]
  pageSizeOldValue = 20
  meetingTypes = [
    this.translations.common.option.all,
    'Mission',
    this.translations.meetingsPage.onlyRec,
    this.translations.meetingsPage.onlyCohort
  ]
  staticEventDropdownOptions: SelectOption[] = []
  selectedMeetingType = this.translations.common.option.all
  selectedFilterValue: SelectOption = { value: 0, label: '' }
  showDialog: boolean = false

  mounted (): void {
    this.filters.meetingType = this.translations.common.option.all
    this.selectedStatus = this.processStatusData[this.filters.followUpProcessStatus]
    if (CultureService.getCulture() === Culture.English) {
      this.filters.cultureName = 'en-US'
    } else {
      this.filters.cultureName = 'fr-CA'
    }

    this.loadEvents((a: boolean | void) => {})
    this.loadJobs((a: boolean | void) => {})
    this.loadCompanies((a: boolean | void) => {})
    this.loadMeetings()
  }

  get animationOutClass () {
    return 'animated ' + (this.$q.screen.lt.md ? 'slideOutRight' : 'fadeOut')
  }

  loadMeetings (): void {
    this.loaded = false
    loadWhile(this, this.translations.common.message.loading, () =>
      this.getMeetings((a: boolean | void) => {}).then(() => {
        this.loaded = true
      })
    )
  }

  loadEvents (done: Function): Promise<void> {
    this.eventsLoaded = false
    this.hasError = false
    return AdminConsoleService.getDropdownEvents(this.filters.cultureName)
      .then((result) => {
        this.events = [{ label: this.translations.common.option.all, value: '' }]
        this.events = this.events.concat(result.data)
        this.staticEventDropdownOptions = this.events
        this.eventsLoaded = true
        done(true)
      })
      .catch((error) => {
        if (error) {
          this.hasError = true
        }
        done(true)
      })
  }

  loadJobs (done: Function): Promise<void> {
    this.jobsOffersLoaded = false
    this.hasError = false
    return AdminConsoleService.getAllJobs(this.filters.cultureName, this.filters.selectedEvent)
      .then((result) => {
        this.jobsList = [{ label: this.translations.common.option.all, value: '' }]
        this.jobsList = this.jobsList.concat(result.data)
        this.jobsOffersLoaded = true
        done(true)
      })
      .catch((error) => {
        if (error) {
          this.hasError = true
        }
        this.jobsOffersLoaded = true
        done(true)
      })
  }

  loadCompanies(done: Function): Promise<void> {
    this.companiesLoaded = false
    this.hasError = false
    return AdminConsoleService.getAllCompanies()
      .then((result) => {
        this.companies = this.companies.concat(result.data)
        this.companiesLoaded = true
        done(true)
      })
      .catch((error) => {
        if (error) {
          this.hasError = true
        }
        done(true)
      })
  }

  getMeetings (done: Function): Promise<void> {
    this.hasError = false
    return AdminConsoleMeetingService.getMeetings(this.filters)
      .then((response) => {
        this.filters = response.data.filters
        this.meetings = []
        if (response.data.meetings.length > 0) {
          Array.prototype.push.apply(this.meetings, response.data.meetings)
          done()
        } else {
          done(true)
        }
      })
      .catch((error) => {
        if (error) {
          this.hasError = true
        }
        done(true)
      })
  }

  onPageChange (newPage: number) {
    if (newPage !== this.filters.pageNumber) {
      this.filters.pageNumber = newPage
      this.onSearch()
    }
  }

  onSearch () {
    this.loadMeetings()
  }

  resetFilters () {
    this.filters = new MeetingsListFilter(this.translations.common.option.all)
    this.selectedEvent = null
    this.selectedJobOffer = null
    this.selectedCompany = null
    this.selectedFilterValue = { value: 0, label: '' }
    this.searchTerms = ''
    this.hideEventFilter = false
    this.loadMeetings()
  }

  advancedSearch () {
    this.resetPageNumber()
    this.loadMeetings()
  }

  exportSearch () {
    this.loaded = false

    loadWhile(this, this.translations.common.message.loading, async () => {
      this.hasError = false

      try {
        const response = await AdminConsoleMeetingService.exportMeetings(this.filters)

        downloadFileCsv(response)
      } catch (error) {
        if (error) {
          this.hasError = true
        }
      }

      this.loaded = true
    })
  }

  onSearchTermsChange (newSearchTerms: string): void {
    this.filters.searchTerms = newSearchTerms
    if (this.filters.searchTerms.length > 4) {
      this.resetPageNumber()
    }
  }

  onMeetingTypeChange (meetingType: string) : void {
    this.events = this.staticEventDropdownOptions
    if (meetingType === this.translations.meetingsPage.onlyRec) {
      this.hideEventFilter = true
      this.selectedEvent = null
    } else {
      this.hideEventFilter = false
      if (meetingType === 'Mission') {
        this.events = this.events.filter((event) => {
          return event.value.toString().split('-')[1] === '1'
        })
      } else if (meetingType === this.translations.meetingsPage.onlyCohort) {
        this.events = this.events.filter((event) => {
          return event.value.toString().split('-')[1] === '4'
        })
      }
    }
  }

  eventSelected (eventSelected: SelectOption) {
    if (eventSelected && eventSelected.value !== '') {
      this.selectedEvent = eventSelected
      this.filters.selectedEvent = eventSelected.value.toString()
    } else {
      this.filters.selectedEvent = ''
      this.selectedEvent = null
    }
    this.$forceUpdate()
  }

  jobOfferSelected (jobSelected: SelectOption) {
    if (jobSelected && jobSelected.value !== '') {
      this.selectedJobOffer = jobSelected
      this.filters.selectedJobOffer = +jobSelected.value
    } else {
      this.filters.selectedJobOffer = 0
      this.selectedJobOffer = null
    }
    this.$forceUpdate()
  }

  companySelected (companySelected: SelectOption) {
    if (companySelected && companySelected.value !== '') {
      this.selectedCompany = companySelected
      this.filters.selectedCompany = +companySelected.value
    } else {
      this.filters.selectedCompany = 0
      this.selectedCompany = null
    }
    this.$forceUpdate()
  }

  onStatusChange (statusSelected: SelectOption) {
    if (statusSelected && statusSelected.value !== '') {
      this.selectedStatus = statusSelected
      this.filters.followUpProcessStatus = +statusSelected.value
    } else {
      this.filters.followUpProcessStatus = 0
      this.selectedStatus = null
    }
    this.$forceUpdate()
  }

  pageSizeChanged () {
    if (this.filters.pageSize !== this.pageSizeOldValue) {
      this.pageSizeOldValue = this.filters.pageSize
      this.resetPageNumber()
    }
  }

  filterDataChanged () {
    if (this.filters.filterValue !== this.selectedFilterValue.value) {
      this.filters.filterValue = +this.selectedFilterValue.value
      this.resetPageNumber()
    }
  }

  resetPageNumber () {
    this.filters.pageNumber = 1
  }

  toogleEditDialog (data: Meeting) {
    this.meeting = data
    this.toogleDialog()
  }

  toogleDialog () {
    this.showDialog = !this.showDialog
  }

  updateFollowUpNeededAfterSave () : void {
    this.needReloading = true
  }

  hide () {
    this.meeting = undefined
    if (this.needReloading) {
      this.loadMeetings()
      this.needReloading = false
    }
  }
}
