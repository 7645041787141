














































































































































































import { loadWhile, notify } from '@/generic/helpers'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ChangeAccountModel } from '@/authentication/account/ChangeAccount.model'
import { RequiredRule } from '@/generic/rules/Required'
import MatchField from '@/generic/rules/Match'
import ValidatePasswordDialog from '@/authentication/password/ValidatePassword.vue'
import { ValidatePasswordModel } from '@/authentication/password/ValidatePassword.model'
import { SelectOption } from '@/generic/models/SelectOption'
import { DialogResult } from '@/generic/models/DialogResult'
import { EditCompanyTranslations } from '@/company/edit/EditCompany.translations'
import AdminConsoleRecruiterService from '../../services/AdminConsoleRecruiterService'
import AdminConsoleCompanyService from '../../services/AdminConsoleCompanyService'
import { CultureService } from '@/services/CultureService'
import { Culture } from '@/generic/models/Culture'

@Component
export default class AdminConsoleEditRecruiterComponent extends Vue {
  @Prop() private translations!: EditCompanyTranslations
  @Prop() private model !: ChangeAccountModel
  @Prop() private recruiterId !: number
  @Prop() private companyId !: number
  @Prop() private adminId !: number
  @Prop() private signInUrl !: string
  @Prop() private emailNotificationField !: Array<SelectOption>
  private culture !: string
  private allowChangePassword : boolean = false
  private allowChangeEmail : boolean = true
  private hasError : boolean = false
  private errorMessage : string = ''
  private loaded : boolean = false
  private rules: { [key: string]: Array<(value: any) => boolean | string> } = {
    email: [new RequiredRule().getValidator(this.translations.common.message.required)],
    firstName: [new RequiredRule().getValidator(this.translations.common.message.required)],
    lastName: [new RequiredRule().getValidator(this.translations.common.message.required)],
    confirmPassword: [new RequiredRule().getValidator(this.translations.common.message.required), new MatchField('newPassword', this.get, this.getField).getValidator(this.translations.auth.passwords.message.mustMatch)],
    password: [new RequiredRule().getValidator(this.translations.common.message.required), new MatchField('confirmPassword', this.get, this.getField).getValidator(this.translations.auth.passwords.message.mustMatch)]
  }
  get (prop: string): any {
    return this.model[prop]
  }

  getField (prop: string) : any {
    return this.$refs[prop]
  }

  mounted () {
    const preferredCulture = CultureService.getCulture()
    if (preferredCulture === Culture.English) {
      this.culture = 'en-US'
    } else {
      this.culture = 'fr-CA'
    }
    this.loadRecruiterProfile(this.recruiterId)
  }

  private loadRecruiterProfile (recruiterId: number) {
    this.hasError = false
    loadWhile(this, this.translations.common.message.loading, () => AdminConsoleRecruiterService.getRecruiter(this.recruiterId)
      .then((response) => {
        this.model = {
          changePassword: false,
          preferredCulture: this.culture,
          currentPassword: '',
          newPassword: '',
          confirmPassword: '',
          relogin: false,
          username: response.data.username,
          email: response.data.email,
          emailAddressBeforeChange: response.data.email,
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          isRecruiter: true,
          emailNotificationFrequency: response.data.emailNotificationFrequency,
          id: response.data.id,
          isAdmin: false
        }
      })
      .then(() => {
        this.loaded = true
      })
      .catch((error) => {
        this.hasError = true
        this.errorMessage = this.translations.errors.company[error.response.data.message]
      })
    )
  }

  private get promptMessage () {
    return this.translations.auth.account.prompt.changeEmail
  }

  private enableEmail () {
    this.validatePassword()
      .then(result => {
        if (result) {
          this.allowChangeEmail = result.valid
          this.$nextTick(() => {
            (this.$refs['emailField'] as any).focus()
          })
        }
      })
  }

  private validatePassword () : Promise<ValidatePasswordModel | undefined> {
    return (this.$refs.validatePasswordDialog as ValidatePasswordDialog).open()
  }

  private confirmPassword () {
    this.validatePassword()
      .then(result => {
        if (result) {
          this.allowChangePassword = result.valid
          this.model.changePassword = true
          this.model.currentPassword = result.password
          this.$nextTick(() => {
            (this.$refs.newPassword as any).focus()
          })
        }
      })
  }

  private saveAccount () {
    this.hasError = false
    this.model.isAdmin = true
    loadWhile(this, this.translations.common.message.loading, () => AdminConsoleRecruiterService.updateAccount(this.model)
      .then(response => {
        this.model = response.data
        notify(this, this.translations.auth.account.message.saved)
        if (this.model.relogin) {
          window.location.href = this.signInUrl
        }
        this.reloadCompanyPage()
      })
      .catch(error => {
        this.hasError = true
        this.errorMessage = this.translations.errors.company[error.response.message]
      })
      .finally(() => {
        this.model.newPassword = ''
        this.model.confirmPassword = ''
        this.allowChangePassword = false
      })
    )
  }

  private deleteAccount (): void {
    this.validatePassword().then((event: ValidatePasswordModel | undefined) => {
      if (event) {
        loadWhile(this, this.translations.common.message.loading, () => AdminConsoleRecruiterService.deleteAccount(this.model.email, event.password).then((response) => {
          notify(this, this.translations.auth.account.message.deleted)
          setTimeout(() => {
            this.reloadCompanyPage()
          }, 3000)
        }))
      }
    })
  }

  private reloadCompanyPage () : void {
    if (this.culture === 'en-US') {
      window.location.href = `/en/site-admin/console-company-modification/${this.companyId}`
    } else {
      window.location.href = `/fr/admin/console-modification-entreprise/${this.companyId}`
    }
  }

  private deleteCompany (result: DialogResult): void {
    if (result === DialogResult.Ok) {
      this.validatePassword().then((event: ValidatePasswordModel | undefined) => {
        if (event) {
          loadWhile(this, this.translations.common.message.loading, () => AdminConsoleCompanyService.deleteCompany(this.companyId, event.password, this.culture)).then(response => {
            notify(this, this.translations.company.edit.notify.deleted)
            setTimeout(() => {
              window.location.href = response.data
            }, 3000)
          })
        }
      })
    }
  }
}
