




















































import { Component, Prop, Vue } from 'vue-property-decorator'
import { RequiredRule } from '@/generic/rules/Required'
import { ITranslatable } from '@/generic/models/ITranslatable'
import { OfferBasicInfoTranslations } from './OfferBasicInfo.translations'
import { CommonTranslations } from '@/generic/models/Common.translations'
import { LanguageSelectorTranslations } from '../language/LanguageSelector.translations'
import { LanguageSelector } from '../language/LanguageSelector.model'
import { PublicationLanguage } from '@/generic/models/PublicationLanguage'

@Component
export default class OfferTitleComponent extends Vue {
  @Prop() private translations!: OfferBasicInfoTranslations;
  @Prop() private commonTranslations!: CommonTranslations
  @Prop() private value!: ITranslatable;
  @Prop() private sectionNumber!: number;
  @Prop() private languageSelectorModel!: LanguageSelector
  @Prop() private languageSelectorTranslations!: LanguageSelectorTranslations
  @Prop({ default: false }) private isPublished!: boolean
  @Prop() private language!: PublicationLanguage

  private rules: { [key: string]: Array<(value: any) => boolean | string> } = {
    posteTitleFr: [
      new RequiredRule().getValidator(this.translations.message.titleRequired, () => this.publicationLanguageFrench(this.language))
    ],
    posteTitleEn: [
      new RequiredRule().getValidator(this.translations.message.titleRequired, () => this.publicationLanguageEnglish(this.language))
    ]
  };

  private publicationLanguageFrench (language: PublicationLanguage) {
    return language === PublicationLanguage.FrenchAndEnglish || language === PublicationLanguage.French
  }

  private publicationLanguageEnglish (language: PublicationLanguage) {
    return language === PublicationLanguage.FrenchAndEnglish || language === PublicationLanguage.English
  }

  private publicationLanguageEnglishOnly (language: PublicationLanguage) {
    return language === PublicationLanguage.English
  }

  private getLanguage (lang: PublicationLanguage) {
    this.$emit('change-language', lang)
  }
}
