











































































































































































































import { Component, Prop, Vue, Mixins } from 'vue-property-decorator'
import { PreviewOfferTranslations } from '@/offer/preview-offer/PreviewOffer.translations'
import { OfferService } from '@/services/OfferService'
import { PublicationLanguage } from '@/generic/models/PublicationLanguage'
import { JobOffer } from '@/offer/JobOffer.model'
import { Company } from '@/company/Company.model'
import { SelectOption } from '@/generic/models/SelectOption'
import { CultureService } from '@/services/CultureService'
import { FavoriteService } from '@/services/FavoriteService'
import { Culture } from '@/generic/models/Culture'
import { loadWhile } from '@/generic/helpers'
import GtmCandidateMixin from '@/gtm/GtmCandidate'

@Component
export default class JobOfferComponent extends Mixins(GtmCandidateMixin) {
  @Prop() private translations!: PreviewOfferTranslations
  @Prop() private offerId!: number
  @Prop() private experienceTexts!: { fr: Array<SelectOption>, en: Array<SelectOption> }
  @Prop() private model !: JobOffer
  @Prop() private company !: Company
  @Prop() private isFavorited!: boolean
  @Prop() private isCandidate!: boolean
  @Prop() private isEmployer!: boolean
  @Prop() private isConfirmed!: boolean
  @Prop() private hasAlreadyApplied !: boolean
  @Prop({ default: '' }) private missionId !: string
  @Prop() private missionPage!: string

  private animation = false
  private iconAnimation = false

  private onIconMouseOver () {
    this.iconAnimation = true
  }

  private onIconMouseLeave () {
    this.iconAnimation = false
  }

  private onMouseOver () {
    this.animation = true
  }

  private onMouseLeave () {
    this.animation = false
  }

  private loaded = false
  private favorited = false

  private offerIsLoaded () {
    this.loaded = true
  }

  mounted (): void {
    this.favorited = this.isFavorited
    OfferService.getUserApplicationStatus(this.offerId)
      .then(result => {
        this.hasAlreadyApplied = result.data
      })
  }

  applyOffer () {
    let title = CultureService.getCulture() === Culture.English ? this.model.titleInfo.en : this.model.titleInfo.fr
    this.OnApplyToOffer(title)
    setTimeout(() => {
      let href = ''
      if (CultureService.getCulture() === Culture.English) {
        href = `/en/jobs/job/apply/${this.offerId}`
      } else {
        href = `/fr/emplois/emploi/postuler/${this.offerId}`
      }
      if (this.missionId !== '') {
        href += `?mission=${this.missionId}`
      }

      window.location.href = href
    }, 500)
  }

  private toggleFavorite (): void {
    if (this.favorited) {
      loadWhile(this, this.translations.common.message.loading, () => FavoriteService.deleteOfferFromFavorite(this.offerId).then(() => {
        this.favorited = false
      }))
    } else {
      loadWhile(this, this.translations.common.message.loading, () => FavoriteService.addOfferToFavorite(this.offerId).then(() => {
        this.favorited = true
      }))
    }
  }
}
