
































































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class MITMAutocompleteComponent extends Vue {
  @Prop() private options!: any[]
  @Prop() private label!: string
  @Prop() private value!: any
  @Prop() private rules!: Array<(value: string) => boolean | string>
  @Prop() private prefix!: string
  @Prop() private maxLength!: number
  @Prop() private name!: string
  @Prop() private prefilled!: boolean
  @Prop() private trimEnd!: boolean
  @Prop() private serverSearch!: boolean
  @Prop({ default: 500 }) private inputDebounce!: number

  private filteredOptions: any[] = this.options

  private inputTimeout: any = null
  private searchTimer: any
  public get length () {
    return this.value ? this.value.length : 0
  }

  public showPopup () {
    (this.$refs['autocomplete'] as any).showPopup()
  }

  public refresh () {
    (this.$refs['autocomplete'] as any).refresh()
  }

  get hasValue () {
    return this.value !== null && this.value !== undefined && this.value !== ''
  }

  get floatingLabel () {
    return this.hasValue ? this.label : ''
  }

  get placeholder () {
    return this.hasValue ? '' : this.label
  }

  private onInput (value: string) {
  }

  private inputChanged (value: string) {
    if (this.maxLength) {
      if (value.length > this.maxLength) {
        value = value.substring(0, value.length - 1);
        (this.$refs['autocomplete'] as any).updateInputValue(value, true)
      }
    }
    this.$emit('input-changed', value)
    if (value.length < 1) {
      return
    }

    if (this.serverSearch) {
      clearTimeout(this.searchTimer)
      this.searchTimer = setTimeout(() => {
        let that = this
        if (this.trimEnd) {
          value = value.trimEnd()
        }
        that.$emit('update-options', value)
      }, 1000)
    } else {
      if (!this.inputTimeout) {
        let that = this
        this.inputTimeout = setTimeout(() => {
          if (this.trimEnd) {
            value = value.trimEnd()
          }
          that.$emit('update-options', value)
          clearTimeout(that.inputTimeout)
          that.inputTimeout = 0
        }, 250)
      }
    }
  }

  private filter (val : string, update : any) {
    if (val === '') {
      if (this.prefilled) {
        this.filteredOptions = this.options
      } else {
        this.filteredOptions = []
        this.options = []
      }
    }
    update(() => {
      var needle = this.removeAccent(val.toLocaleLowerCase())
      if (this.trimEnd) {
        needle = needle.trimEnd()
      }
      this.filteredOptions = this.options.filter(v => this.removeAccent(v.toLocaleLowerCase()).indexOf(needle) > -1)
    })
  }

  private removeAccent (str: string) : string {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  }

  private hasError (): boolean {
    if (this.$refs.qselect) {
      return (this.$refs.qselect as any).hasError
    }
    return false
  }
}
