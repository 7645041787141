




























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class TitleIconComponent extends Vue {
  @Prop() private sectionNumber!: number;
  private table: string[][] = [
    ['triangle-hidden', 'triangle border-bottom-pink', 'triangle border-bottom-pink', 'triangle border-bottom-pink', 'triangle-hidden'],
    ['triangle border-bottom-pink', 'triangle border-bottom-purple', 'triangle border-bottom-purple', 'triangle border-bottom-purple', 'triangle border-bottom-purple'],
    ['triangle-hidden', 'triangle-hidden', 'triangle border-bottom-teal', 'triangle border-bottom-blue', 'triangle border-bottom-blue'],
    ['triangle-hidden', 'triangle-hidden', 'triangle-hidden', 'triangle border-bottom-teal', 'triangle border-bottom-teal']
  ]

  private getTriangle (value: number): string {
    return this.table[value - 1][this.sectionNumber - 1]
  }
}
