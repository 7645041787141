

































import { Component, Prop, Vue } from 'vue-property-decorator'
import { FileInputTranslations } from '../models/FileInput.translations'
import { RequiredRule } from '@/generic/rules/Required'

@Component
export default class MITMFileInputComponent extends Vue {
  @Prop() private filename !: string
  @Prop() private translations !: FileInputTranslations
  @Prop({ default: false }) private required !: boolean

  chooseFile (): void {
    this.$emit('choose-file')
  }

  clear (): void {
    this.$emit('clear')
  }

  dropHandler (event: DragEvent): void {
    event.preventDefault()
    if (event.dataTransfer) {
      this.$emit('drag-and-drop', event.dataTransfer.files.item(0))
    }
  }

  dragOverHandler (event: DragEvent): void {
    event.preventDefault()
  }
  private rules: { [key: string]: Array<(value: any) => boolean | string> } = {
    required: [
      new RequiredRule().getValidator(this.translations.required, () => { return this.required })
    ]
  };
}
