import GtmMixin, { GtmEvent } from './GtmMixin'
import Component from 'vue-class-component'

@Component
export default class GtmCandidateMixin extends GtmMixin {
  protected onCompleteProfileStep (step : number, label : string) {
    this.callGtm(GtmEvent.Candidate, 'complete profile step ' + step, label)
  }

  protected OnConfirmCandidate (label: string) {
    this.callGtm(GtmEvent.Candidate, 'confirm candidate account', label)
  }

  protected OnApplyToOffer (title: string) {
    this.callGtm(GtmEvent.Candidate, 'application intention', title)
  }

  protected OnApplicationConfirmed (title: string) {
    this.callGtm(GtmEvent.Candidate, 'application confirmation', title)
  }

  protected OnApplicationSubmitted (title: string) {
    this.callGtm(GtmEvent.Candidate, 'application submitted', title)
  }
}
