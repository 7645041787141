import { FileInfo } from '@/generic/models/File.model'
import { ProductType } from '@/dashboard/employer-package/models/ProductType'

export default class ProductAssociation {
  productSkuGuid: string
  recruiterId: number
  productType: ProductType
  price: number
  totalDisplay: number
  contract: Array<FileInfo>

  constructor (productType: ProductType) {
    this.productSkuGuid = ''
    this.recruiterId = 0
    this.price = 0
    this.totalDisplay = 1
    this.contract = []
    this.productType = productType
  }

  isValid () : boolean {
    return this.productSkuGuid !== '' && this.productSkuGuid !== undefined && this.recruiterId > 0 && this.price >= 0 && this.totalDisplay >= 1 && this.contract.length > 0
  }
}
