import { IRule } from './IRule'

export class IsEmail implements IRule {
  static regex=/^.+@.+\..+$/i;
  getValidator (errorMessage: string, activate?: () => boolean): (value: string) => boolean|string {
    return (value: string): boolean|string => {
      if (activate === undefined || (activate && activate())) {
        if (value) {
          return !!value.match(IsEmail.regex) || errorMessage
        }
      }
      return true
    }
  }
}
