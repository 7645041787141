



































import { Component, Prop, Vue } from 'vue-property-decorator'
import MediaObject from '../models/MediaObject'
import MITMCopyrightComponent from '@/generic/components/MITMCopyright.vue'

@Component({
  components: {
    'mitm-copyright': MITMCopyrightComponent
  }
})
export default class CarouselSlideColumnImageComponent extends Vue {
  @Prop() private title!: string
  @Prop() private link!: string
  @Prop() private image!: MediaObject
  private animation = false

  private onMouseOver () {
    this.animation = true
  }

  private onMouseLeave () {
    this.animation = false
  }

  private handleSlideClick () {
    window.location.href = this.link
  }
}
