import { Skill } from '@/offer/skill/Skill.model'
import { LanguageSelector } from '@/offer/language/LanguageSelector.model'
import { PublicationLanguage } from '@/generic/models/PublicationLanguage'
import { FileInfo } from '@/generic/models/File.model'
import { Experience } from '@/generic/models/Experience'
import { ImmigrationProcess } from '@/generic/models/ImmigrationProcess'
import { InterestArea, InterestAreaGroup } from '@/generic/models/InterestArea.model'
import { WorkExperience } from '@/profile/work-experience/WorkExperience.model'
import { Education } from '@/profile/education/Education.model'
import { License } from '@/profile/license/License.model'
import { CandidateLanguageProficiency } from '@/generic/models/CandidateLanguageProficiency.model'
import { CandidateAvailabilityPreferences } from '@/candidate/CandidateAvailabilityPreferences.model'

export class CreateProfileModel {
  candidateId: number
  title: string
  industry: string
  skills: Array<Skill>
  softSkills: Array<string>
  workExperiences: Array<WorkExperience>
  educationEntries: Array<Education>
  licenses: Array<License>
  files: Array<FileInfo>
  filesToDelete: Array<FileInfo>
  urlSite: string
  urlLinkedin: string
  city: string
  country: string
  countryId: number|null
  countryOfCitizenship1: string
  countryOfCitizenship1Id: number|null
  countryOfCitizenship2: string
  countryOfCitizenship2Id: number|null
  countrySubdivision: string
  immigrationProcess: ImmigrationProcess|null
  language: LanguageSelector
  languageProficiencyFrId: number
  languageProficiencyEnId: number
  interestAreaGroups: InterestAreaGroup[]
  languageProficiencies: Array<CandidateLanguageProficiency>
  availabilityPreferences: CandidateAvailabilityPreferences

  constructor () {
    this.candidateId = 0
    this.title = ''
    this.industry = ''
    this.skills = [{
      fr: '',
      en: '',
      experienceYears: Experience.OneToTwo
    },
    {
      fr: '',
      en: '',
      experienceYears: Experience.OneToTwo
    }]
    this.softSkills = []
    this.workExperiences = [{
      title: '',
      companyName: '',
      country: '',
      countryId: null,
      industry: '',
      isCurrentJob: false,
      startDate: null,
      endDate: null
    }]
    this.educationEntries = [{
      school: '',
      country: '',
      countryId: null,
      degree: '',
      fieldOfStudy: '',
      levelOfStudy: '',
      startDate: null,
      endDate: null
    }]
    this.licenses = []
    this.language = {
      language: PublicationLanguage.FrenchAndEnglish
    }
    this.immigrationProcess = null
    this.urlSite = ''
    this.urlLinkedin = ''
    this.city = ''
    this.country = ''
    this.countryId = null
    this.countryOfCitizenship1 = ''
    this.countryOfCitizenship1Id = null
    this.countryOfCitizenship2 = ''
    this.countryOfCitizenship2Id = null
    this.countrySubdivision = ''
    this.files = []
    this.filesToDelete = []
    this.languageProficiencyFrId = -1
    this.languageProficiencyEnId = -1
    this.interestAreaGroups = []
    this.languageProficiencies = []
    this.availabilityPreferences = {
      days: []
    }
  }
}
