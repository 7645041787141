








































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { PreviewOfferTranslations } from './PreviewOffer.translations'
import { OfferService } from '@/services/OfferService'
import { JobOffer } from '../JobOffer.model'
import { loadWhile } from '@/generic/helpers'
import { PublicationLanguage } from '@/generic/models/PublicationLanguage'
import { ITranslatable } from '@/generic/models/ITranslatable'
import { SelectOption } from '@/generic/models/SelectOption'
import { Experience } from '@/generic/models/Experience'
import { dateFilter } from '@/generic/filters/Date'
import { Culture } from '@/generic/models/Culture'
import { Status } from '@/generic/models/Status'
import { CultureService } from '@/services/CultureService'
import { Company } from '@/company/Company.model'

@Component
export default class PreviewOfferApplyComponent extends Vue {
  @Prop() private translations!: PreviewOfferTranslations
  @Prop() private offerId!: number
  @Prop() private company!: Company
  @Prop() private experienceTexts!: { fr: Array<SelectOption>, en: Array<SelectOption> }
  private model = new JobOffer()
  private displayEnglish = false;
  private loaded = false
  private hasWorkInfo = false
  private hasRequirements = false
  private hasLanguageRequirements = false
  private selectedTab = 'description'

  mounted (): void {
    loadWhile(this, this.translations.common.message.loading, () => {
      return OfferService.getOffer(this.offerId, CultureService.getCultureCode()).then((response) => {
        this.model = response.data
        this.displayEnglish = this.decideDisplayLanguage()
        this.hasRequirements = this.verifyHasRequirements()
        this.hasLanguageRequirements = this.verifyHasLanguageRequirements()
        this.hasWorkInfo = this.verifyHasWorkInfo()
      }).finally(() => {
        this.loaded = true
        this.$emit('loaded')
      })
    })
  }

  // true is english false is french
  private decideDisplayLanguage (): boolean {
    if (CultureService.getCulture() === Culture.English) {
      return this.model.language.language === PublicationLanguage.FrenchAndEnglish || this.model.language.language === PublicationLanguage.English
    } else {
      return !(this.model.language.language === PublicationLanguage.FrenchAndEnglish || this.model.language.language === PublicationLanguage.French)
    }
  }

  private translate (translatable: ITranslatable, uiCulture = false): string {
    if (uiCulture) {
      return CultureService.getCulture() === Culture.English ? translatable.en : translatable.fr
    }
    return this.displayEnglish ? translatable.en : translatable.fr
  }

  private getExperienceText (experience: Experience): string {
    const found = (this.displayEnglish ? this.experienceTexts.en : this.experienceTexts.fr).find((v) => v.value === experience)
    if (found) {
      return found.label
    }
    return ''
  }

  private getLanguageProficiencyText(level: number): string {
    const found = this.translations.languageProficiencies.find((v) => v.id === level)
    if (found) {
      return found.label
    }
    return ''
  }

  private getLevelOfStudyText(level: number): string {
    const found = this.translations.levelOfStudy.find((v) => v.value === level)
    if (found) {
      return found.label
    }
    return ''
  }

  private getSalaryText(salary: number, isAnnual: boolean): string {
    if (isAnnual) {
      const found = this.translations.annualSalary.find((v) => v.value === salary)
      if (found) {
        return found.label
      }
    } else {
      const found = this.translations.hourlywage.find((v) => v.value === salary)
      if (found) {
        return found.label
      }
    }
    return ''
  }

  private getYearText(year: number): string {
    let label = ''
    if (this.displayEnglish) {
      label = year > 1 ? ' years' : ' year'
    } else {
      label = year > 1 ? ' ans' : ' an'
    }
    return year + label
  }

  private getNoYesText(value: number): string {
    if (this.displayEnglish) {
      return value ? 'Yes' : 'No'
    } else {
      return value ? 'Oui' : 'Non'
    }
  }

  private getOtherLanguageText(otherLang: number): string {
    if (this.displayEnglish) {
      return otherLang + ' language level required'
    } else {
      return 'Niveau de langue en ' + otherLang + ' requis'
    }
  }

  private getTranslation (key: string): string {
    const [key1, key2] = key.split('.')
    return (this.displayEnglish ? this.translations.offerEn : this.translations.offerFr)[key1][key2]
  }

  private getTranslationSoftSkill (key: string): string {
    const [key1, key2] = ['offer', key.toLowerCase()]
    return (this.displayEnglish ? this.translations.offerEn : this.translations.offerFr)[key1][key2]
  }

  private getPublishedDate (): string {
    return dateFilter(this.model.publishedDate || new Date(), CultureService.getCulture())
  }

  private verifyHasRequirements(): boolean {
    return (this.model.jobOfferRequirements !== null && this.model.jobOfferRequirements.offerID !== 0 && ((this.model.jobOfferRequirements.studyDiploma !== null && this.model.jobOfferRequirements.studyDiploma !== '') ||
      this.model.jobOfferRequirements.levelOfStudy !== null || this.model.jobOfferRequirements.yearExperience !== null))
  }

  private verifyHasLanguageRequirements(): boolean {
    return (this.model.jobOfferRequirements !== null && this.model.jobOfferRequirements.offerID !== 0 && (this.model.jobOfferRequirements.languageProficiencyFrId !== null || this.model.jobOfferRequirements.languageProficiencyEnId !== null ||
      ((this.model.jobOfferRequirements.otherLanguage !== null && this.model.jobOfferRequirements.otherLanguage !== '') && this.model.jobOfferRequirements.languageProficiencyOtherId !== null)))
  }

  private verifyHasWorkInfo(): boolean {
    return this.model.jobOfferWorkInfos !== null && this.model.jobOfferWorkInfos.offerID !== 0 && (this.model.jobOfferWorkInfos.hoursPerWeek !== null || this.model.jobOfferWorkInfos.unionize !== null ||
      (this.model.jobOfferWorkInfos.annualSalary !== null && this.model.jobOfferWorkInfos.salaryIsAnnually === 1) || (this.model.jobOfferWorkInfos.hourlywage !== null && this.model.jobOfferWorkInfos.salaryIsAnnually === 0))
  }

  private get tab() {
    return this.selectedTab
  }

  private set tab(tab: string) {
    this.selectedTab = tab
  }
}
