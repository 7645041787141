





































































































































































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { loadWhile, notify, notifyNegative } from '@/generic/helpers'
import { CultureService } from '@/services/CultureService'
import { Culture } from '@/generic/models/Culture'
import ProductsListTranslations from '../../models/products/ProductsList.translations'
import ProductDetails from '../../models/products/ProductsDetails.models'
import MITMInputComponent from '@/generic/components/MITMInput.vue'
import { IsNumber } from '@/generic/rules/IsNumber'
import { RequiredRule } from '@/generic/rules/Required'
import { MinimumRule } from '@/generic/rules/Minimum'
import { SelectOption } from '@/generic/models/SelectOption'
import { getActivityAreasMifi } from '@/generic/activity-area-helper'
import AdminConsoleService from '../../services/AdminConsoleService'
import AdminConsoleCompanyService from '../../services/AdminConsoleCompanyService'
import AdminConsoleProductService from '../../services/AdminConsoleProductService'
import FileListComponent from '../meetings/external/files/FileList.vue'
import { FileInfo } from '@/generic/models/File.model'
import { data } from 'cypress/types/jquery'
import ConsentComponent from '@/authentication/consent/Consent.vue'

@Component({
  components: {
    'mitm-input': MITMInputComponent,
    'mitm-file-list': FileListComponent
  }
})
export default class AdminProductForm extends Vue {
  @Prop() private skuGuid!: string
  @Prop() translations !: ProductsListTranslations
  private industriesList: Array<SelectOption> = getActivityAreasMifi(this.translations.industry)
  rules: { [key: string]: Array<(value: any) => boolean | string> } = {
    isNumber: [
      new MinimumRule(0).getValidator(this.translations.error.minimum),
      new IsNumber().getValidator(this.translations.error.validNumber),
      new RequiredRule().getValidator(this.translations.error.validNumber)
    ],
    required: [new RequiredRule().getValidator(this.translations.error.allRequired)]
  };
  contractFr: Array<FileInfo> = []
  contractEn: Array<FileInfo> = []
  companies: SelectOption[] = []
  selectedCompanies: SelectOption[] = []
  selectedActivityAreaCompanies: SelectOption[] = []
  allSelectedCompagnies: SelectOption[] = []
  activityAreas: SelectOption[] = []
  selectedActivityAreas: SelectOption[] = []
  selectedIndustries: SelectOption[] = []
  product!: ProductDetails
  startDate!: string
  endDate!: string
  saleStartDate!: string
  saleEndDate!: string
  culture: string = CultureService.getCulture() === Culture.English ? 'en-US' : 'fr-CA'
  loaded: boolean = false
  companiesLoaded: boolean = false
  activityAreasLoaded: boolean = false
  isLoading: boolean = false
  isSaving: boolean = false
  isDeleting: boolean = false
  hasError: boolean = false
  errorMessage: string = ''
  isEditing: boolean = this.skuGuid !== ''

  created (): void {
    this.product = new ProductDetails()
    if (!this.isEditing) {
      this.startDate = this.formatDate(this.product.startDate, true)
      this.endDate = this.formatDate(this.product.endDate, true)
      this.saleStartDate = this.formatDate(this.product.saleStartDate, true)
      this.saleEndDate = this.formatDate(this.product.saleEndDate, true)
    } else {
      loadWhile(this, this.translations.common.message.loading, () => Promise.all([
        this.getProduct()
      ]).then(() => {
        this.contractFr = this.product.contractFr || []
        this.contractEn = this.product.contractEn || []
        this.startDate = this.formatDate(this.product.startDate, true)
        this.endDate = this.formatDate(this.product.endDate, true)
        this.saleStartDate = this.formatDate(this.product.saleStartDate, true)
        this.saleEndDate = this.formatDate(this.product.saleEndDate, true)
        this.$forceUpdate()
      }))
    }
  }

  mounted(): void {
    loadWhile(this, this.translations.common.message.loading, () => Promise.all([
      this.loadCompanies(),
      this.loadActivityAreas()
    ]).then(() => {
      this.loaded = true
    }).catch(error => {
      this.handleError(error)
    }))
  }

  updateInput (value: any): void {
    this.$forceUpdate()
  }

  private getProduct(): Promise<void> {
    this.hasError = false
    return AdminConsoleProductService.getProduct(this.skuGuid)
      .then((result) => {
        this.product = result.data
        result.data.companies.forEach(element => {
          this.selectedCompanies.push({
            value: element.id,
            label: element.name
          })
        })
        result.data.activityAreas.forEach(element => {
          this.selectedActivityAreas.push({
            value: element.id,
            label: this.culture === 'fr-CA' ? element.fr : element.en
          })
        })
        this.activityAreaSelected(this.selectedActivityAreas)
        result.data.industries.forEach(element => {
          let industry = this.industriesList.find(x => x.value === element)
          if (industry !== undefined) {
            this.selectedIndustries.push({
              value: element,
              label: industry.label
            })
          }
        })
      }).catch((error) => {
        if (error) {
          this.handleError(error)
        }
      })
  }

  updateInputDate (value: any, reason: any, details: any, input: string) {
    if (reason === 'day' && details.changed) {
      if (input === 'startDate') {
        this.product.startDate = new Date(value)
        this.startDate = this.formatDate(value, true)
      } else if (input === 'endDate') {
        this.product.endDate = new Date(value)
        this.endDate = this.formatDate(value, true)
      } else if (input === 'saleStartDate') {
        this.product.saleStartDate = new Date(value)
        this.saleStartDate = this.formatDate(value, true)
      } else if (input === 'saleEndDate') {
        this.product.saleEndDate = new Date(value)
        this.saleEndDate = this.formatDate(value, true)
      }
    }
    this.$forceUpdate()
  }

  industriesSelected (industriesSelected: SelectOption[]) {
    if (industriesSelected !== null && industriesSelected.length > 0) {
      this.selectedIndustries = industriesSelected
    } else {
      this.selectedIndustries = []
    }
  }

  companySelected (companiesSelected: SelectOption[]) {
    if (companiesSelected !== null && companiesSelected.length > 0) {
      this.selectedCompanies = companiesSelected
    } else {
      this.selectedCompanies = []
    }
    this.getAllSelectedCompagnies()
  }

  activityAreaSelected (activityAreaSelected: SelectOption[]) {
    if (activityAreaSelected !== null && activityAreaSelected.length > 0) {
      this.isLoading = true
      this.selectedActivityAreas = activityAreaSelected
      loadWhile(this, this.translations.common.message.loading, () => Promise.all([
        this.loadActivityAreaCompanies(activityAreaSelected)
      ]).then(() => {
        this.isLoading = false
      }).catch(error => {
        this.handleError(error)
      }))
    } else {
      this.selectedActivityAreas = []
      this.selectedActivityAreaCompanies = []
      this.getAllSelectedCompagnies()
    }
  }

  getAllSelectedCompagnies() : void {
    this.allSelectedCompagnies = []
    this.allSelectedCompagnies = this.allSelectedCompagnies.concat(this.selectedCompanies)
    var exist: boolean
    this.selectedActivityAreaCompanies.forEach((companyArea) => {
      exist = false
      this.allSelectedCompagnies.forEach((company) => {
        if (company.value === companyArea.value) {
          exist = true
        }
      })
      if (!exist) {
        this.allSelectedCompagnies.push(companyArea)
      }
    })
    this.allSelectedCompagnies.sort((a, b) => a.label.charCodeAt(0) - b.label.charCodeAt(0))
    this.$forceUpdate()
  }

  private loadCompanies(): Promise<void> {
    this.hasError = false
    return AdminConsoleService.getAllCompanies()
      .then((result) => {
        this.companies = this.companies.concat(result.data)
        this.companiesLoaded = true
      })
      .catch((error) => {
        if (error) {
          this.handleError(error)
        }
      })
  }

  private loadActivityAreas(): Promise<void> {
    this.activityAreasLoaded = false
    return AdminConsoleService.GetActivityAreas(this.culture)
      .then((result) => {
        this.activityAreas = result.data
        this.activityAreasLoaded = true
      })
      .catch((error) => {
        if (error) {
          this.handleError(error)
        }
      })
  }

  private loadActivityAreaCompanies(activityAreaSelected: SelectOption[]): Promise<void> {
    this.selectedActivityAreaCompanies = []
    let ids: number[] = []
    activityAreaSelected.forEach((x) => {
      ids.push(+x.value)
    })
    return AdminConsoleCompanyService.getCompaniesByActivityAreas(ids)
      .then((result) => {
        this.selectedActivityAreaCompanies = result.data
        this.getAllSelectedCompagnies()
      })
      .catch((error) => {
        if (error) {
          this.handleError(error)
        }
      })
  }

  save () : void {
    let errorMessage = document.getElementsByClassName('error-message')[0]
    errorMessage.classList.add('hide')
    if (this.canSave()) {
      this.isSaving = true
      this.hasError = false
      loadWhile(this, this.translations.common.message.loading, () =>
        this.saveAction((result: boolean | void) => {
          if (result) {
            this.$emit('follow-up-save')
            document.getElementById('close-button')?.click()
          }
        })
      )
      this.isSaving = false
    } else {
      errorMessage.classList.remove('hide')
    }
  }

  private saveAction (done: Function): Promise<void> {
    let activityAreaIds: number[] = []
    this.selectedActivityAreas.forEach((x) => {
      activityAreaIds.push(+x.value)
    })
    this.product.activityAreaIds = activityAreaIds
    let companyIds: number[] = []
    this.selectedCompanies.forEach((x) => {
      companyIds.push(+x.value)
    })
    this.product.companieIds = companyIds
    let industries: string[] = []
    this.selectedIndustries.forEach((x) => {
      industries.push(x.value.toString())
    })
    this.product.industries = industries
    this.product.startDate = new Date(this.startDate)
    this.product.endDate = new Date(this.endDate)
    this.product.saleStartDate = new Date(this.saleStartDate)
    this.product.saleEndDate = new Date(this.saleEndDate)
    this.product.contractFr = this.contractFr
    this.product.contractEn = this.contractEn
    if (!this.isEditing) {
      return AdminConsoleProductService.createProduct(this.product)
        .then(() => {
          notify(this, this.translations.page.saveNotify)
          done(true)
        })
        .catch((error) => {
          if (error) {
            notifyNegative(this, error.message)
          }
          done(false)
        })
    } else {
      return AdminConsoleProductService.editProduct(this.product)
        .then(() => {
          notify(this, this.translations.page.saveNotify)
          done(true)
        })
        .catch((error) => {
          if (error) {
            notifyNegative(this, error.message)
          }
          done(false)
        })
    }
  }

  private canSave() : boolean {
    return this.product.nameFr !== '' && this.product.nameEn !== '' && this.product.startDate !== undefined &&
      this.product.endDate !== undefined && this.product.saleStartDate !== undefined && this.product.saleEndDate !== undefined &&
      this.contractFr.length !== 0 && this.contractEn.length !== 0
  }

  public formatDate(date: Date | string, forCalendar : boolean = false) {
    date = date instanceof Date ? date : new Date(date)
    if (forCalendar) {
      return [
        date.getFullYear(),
        ('0' + (date.getMonth() + 1)).slice(-2),
        ('0' + date.getDate()).slice(-2)
      ].join('/')
    }

    return date.toLocaleDateString(this.culture, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour12: false
    })
  }

  getPriceRangeMinimum(index: number): number {
    if (index > 0) {
      return (+this.product.prices[index - 1].max + 1)
    }
    return 1
  }

  validatePriceRangeMaximum(index: number) {
    if (this.product.prices[index].max < this.getPriceRangeMinimum(index)) {
      this.product.prices[index].max = +this.product.prices[index].max + 1
      notifyNegative(this, this.translations.page.priceRangeMaxNotify)
    }
    this.updateInput(this.product.prices[index].max)
  }

  private handleError (error: any): void {
    this.hasError = true
    if (error.response.status && error.response.status === 404) {
      this.errorMessage = this.translations.error.notFound
    } else if (error.response.status) {
      this.errorMessage = error.message
    } else {
      this.errorMessage = error
    }
  }
}
