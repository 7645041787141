import { IndustryTranslations } from '@/profile/Industry.translations'
import { SelectOption } from '@/generic/models/SelectOption'

// This list corresponds with the backend enum ActivityAreaMifi.
export function getActivityAreasMifi (industries: IndustryTranslations): Array<SelectOption> {
  return [
    { value: 'PublicAdministration', label: industries.publicAdministration },
    { value: 'AgricultureAndforestry', label: industries.agricultureAndforestry },
    { value: 'ArtsAndEntertainment', label: industries.artsAndEntertainment },
    { value: 'OtherServices', label: industries.otherServices },
    { value: 'RetailTrade', label: industries.retailTrade },
    { value: 'WholesaleTrade', label: industries.wholesaleTrade },
    { value: 'Construction', label: industries.construction },
    { value: 'MiningQuarryingAndOil', label: industries.miningQuarryingAndOil },
    { value: 'Manufacturing', label: industries.manufacturing },
    { value: 'FinanceAndInsurance', label: industries.financeAndInsurance },
    { value: 'CivilEngineering', label: industries.civilEngineering },
    { value: 'MechanicalOrIndustrialEngineering', label: industries.mechanicalOrIndustrialEngineering },
    { value: 'ManagementOfCompanies', label: industries.managementOfCompanies },
    { value: 'EducationalServices', label: industries.educationalServices },
    { value: 'AccommodationAndFoodServices', label: industries.accommodationAndFoodServices },
    { value: 'RealEstateAndRental', label: industries.realEstateAndRental },
    { value: 'ProfessionalAndTechnicalServices', label: industries.professionalAndTechnicalServices },
    { value: 'GovernmentServices', label: industries.governmentServices },
    { value: 'HealthCareAndSocialAssistance', label: industries.healthCareAndSocialAssistance },
    { value: 'InformationTechnology', label: industries.informationTechnology },
    { value: 'TransportationAndWarehousing', label: industries.transportationAndWarehousing }
  ]
}
