






















































































































































































































































































































import { Component, Prop, Vue, Mixins } from 'vue-property-decorator'
import JobCardTranslations from './JobCard.translations'
import { Status } from '@/generic/models/Status'
import { OfferService } from '@/services/OfferService'
import { loadWhile, notifyNegative } from '@/generic/helpers'
import { CultureService } from '@/services/CultureService'
import { Culture } from '@/generic/models/Culture'
import { CompanyService } from '@/services/CompanyService'
import GtmEmployerMixin from '@/gtm/GtmEmployer'
import moment from 'moment'

@Component
export default class JobCardComponent extends Mixins(GtmEmployerMixin) {
  @Prop() private jobId!: number
  @Prop() private status!: Status
  @Prop() private expirationDate!: Date|null
  @Prop() private publishDate!: Date|null
  @Prop() private jobTitle!: string
  @Prop() private filledUsingTm!: boolean|null
  @Prop() private translations!: JobCardTranslations
  @Prop() private companyId!: number
  private candidateNewApplicant = 0
  private candidateNewSuggested = 0
  private errorMessage = ''
  private errorHappened = false
  private deleted = false
  private selectedMission = 0
  private applicantCount : number = 0
  private suggestedProfileCount : number = 0

  private statusColor = {
    'color': 'mitm-light-orange',
    'textColor': 'mitm-orange',
    'label': this.translations.jobCard.status.draft
  }
  private getStatusColor () {
    return this.statusColor
  }

  mounted (): void {
    this.updateStatus(this.status)
    loadWhile(this, this.translations.jobCard.loading.message, () => Promise.all([
      CompanyService.getNumberCandidateForEachJob(this.jobId)
        .then(result => {
          this.applicantCount = result.data
        }),
      CompanyService.getNumberSuggestedCandidateForEachJob(this.jobId)
        .then(result => {
          this.suggestedProfileCount = result.data
        }),
      CompanyService.getNumberOfNewCandidateForEachJob(this.jobId)
        .then(result => {
          this.candidateNewApplicant = result.data
        }),
      CompanyService.getNumberOfNewSuggestedCandidateForEachJob(this.jobId).then(result => {
        this.candidateNewSuggested = result.data
      })
    ]).catch(e => {
      this.errorMessage = this.translations.error[e.response.data.message]
      this.errorHappened = true
    }))
  }

  private removeLastCharacter (text: string) {
    return text.slice(0, -1)
  }

  private publishedStatus () {
    return this.status === Status.Published
  }

  private draftStatus () {
    return this.status === Status.Draft
  }

  private inReviewProcessStatus(): boolean {
    return this.status === Status.AwaitingReview || this.status === Status.Reviewed || this.status === Status.ReadyToBePublished
  }

  private expiredStatus () {
    return this.status === Status.Expired
  }

  private closedStatus () {
    return this.status === Status.Closed
  }

  private archivedStatus () {
    return this.status === Status.Archived
  }

  private modifyJob () {
    // TODO : Update this to use the Menu object from MITM-237
    const culture = CultureService.getCulture()
    if (culture === Culture.English) {
      window.location.href = `/en/employer/edit-offer/${this.jobId}`
    } else {
      window.location.href = `/fr/employeur/modifier-offre/${this.jobId}`
    }
  }

  private ArchiveJob () {
    loadWhile(this, this.translations.jobCard.loading.message,
      () =>
        OfferService.archiveOffer(this.companyId, this.jobId)
          .then((jo) => {
            this.status = jo.data.status
            this.updateStatus(this.status)
          })
          .catch(e => {
            this.errorMessage = this.translations.error[e.response.data.message]
            this.errorHappened = true
          })
    )
  }

  private close (withTm: boolean) {
    loadWhile(this, this.translations.jobCard.loading.message,
      () =>
        OfferService.fill(this.companyId, this.jobId, withTm, CultureService.getCulture())
          .then((jo) => {
            this.onFillOffer(withTm, jo.data.publishedDate)
            this.status = jo.data.status
            this.filledUsingTm = jo.data.filledUsingTalentMontreal
            this.updateStatus(this.status)
          })
          .catch(e => {
            this.errorMessage = this.translations.error[e.response.data.message]
            this.errorHappened = true
          })
    )
  }

  private updateStatus (status : Status) {
    if (this.status === Status.Published) {
      this.statusColor.color = 'mitm-light-green'
      this.statusColor.textColor = 'mitm-green'
      this.statusColor.label = this.translations.jobCard.status.published
      if (this.publishDate !== null) {
        var pDate = moment(this.publishDate)
        if (pDate.isAfter(moment.now())) {
          this.statusColor.label = this.translations.jobCard.status.publishOnHold + ' (' + pDate.format('yyyy-MM-DD') + ')'
        }
      }
    } else if (this.status === Status.Closed || this.status === Status.Expired) {
      this.statusColor.color = 'mitm-light-red'
      this.statusColor.textColor = 'mitm-red'
      this.statusColor.label = this.translations.jobCard.status.closed
    } else if (this.status === Status.Archived) {
      this.statusColor.color = 'mitm-light-red'
      this.statusColor.textColor = 'mitm-red'
      this.statusColor.label = this.translations.jobCard.status.archived
    } else if (this.status === Status.AwaitingReview) {
      this.statusColor.color = 'mitm-light-orange'
      this.statusColor.textColor = 'mitm-orange'
      this.statusColor.label = this.translations.jobCard.status.awaitingReview
    } else if (this.status === Status.Reviewed) {
      this.statusColor.color = 'mitm-light-orange'
      this.statusColor.textColor = 'mitm-orange'
      this.statusColor.label = this.translations.jobCard.status.reviewed
    } else if (this.status === Status.ReadyToBePublished) {
      this.statusColor.color = 'mitm-light-orange'
      this.statusColor.textColor = 'mitm-orange'
      this.statusColor.label = this.translations.jobCard.status.readyToBePublished
    } else {
      this.statusColor.color = 'mitm-light-orange'
      this.statusColor.textColor = 'mitm-orange'
      this.statusColor.label = this.translations.jobCard.status.draft
    }
  }

  private goToApplicationsList (tab: number = 0) {
    const culture = CultureService.getCulture()
    if (culture === Culture.English) {
      window.location.href = `/en/employer/candidates-list/${this.jobId}?tab=${tab}`
    } else {
      window.location.href = `/fr/employeur/liste-candidats/${this.jobId}?tab=${tab}`
    }
  }

  private deleteJob (): void {
    loadWhile(this, this.translations.jobCard.loading.message, () => {
      return OfferService.deleteOffer(this.jobId).then(() => {
        this.deleted = true
      }).catch((error) => {
        this.errorMessage = this.translations.error[error.response.data.message]
        this.errorHappened = true
      })
    })
  }

  private unpublishJob (): void {
    loadWhile(this, this.translations.jobCard.loading.message, () => {
      return OfferService.unpublishOffer(this.jobId).then((response) => {
        this.onUnpublishJob(response.data.publishedDate, response.data.expiryDate)
        this.status = Status.Closed
        this.updateStatus(this.status)
      }).catch((error) => {
        this.errorMessage = this.translations.error[error.response.data.message]
        this.errorHappened = true
      })
    })
  }
}

