






























































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { MissionPageTranslations } from './translations/MissionPage.translations'
import { CultureService } from '@/services/CultureService'
import { oneDayMission, missionLocation } from '@/generic/helpers'

@Component
export default class MissionPageHeaderComponent extends Vue {
  @Prop() private mission!: any
  @Prop() private translations!: { missionPage: MissionPageTranslations }
  @Prop() private allowCandidatesToApply!: boolean

  private options = { month: 'short' }
  private startDate = new Date(this.mission.startDate)
  private startDay = this.startDate.getDate()
  private startMonth = new Intl.DateTimeFormat(this.culture, this.options).format(this.startDate)
  private endDate = new Date(this.mission.endDate)
  private endDay = this.endDate.getDate()
  private endMonth = new Intl.DateTimeFormat(this.culture, this.options).format(this.endDate)
  private animation = false

  private onMouseOver () {
    this.animation = true
  }

  private onMouseLeave () {
    this.animation = false
  }

  private get month () {
    const month = (this.startMonth === this.endMonth) ? this.startMonth : `${this.startMonth} - ${this.endMonth}`
    return month
  }

  private get oneDayMission (): boolean {
    return oneDayMission(this.startDate, this.endDate)
  }

  private get location (): string {
    return missionLocation(this.mission.multipleCity, this.mission.city, this.mission.multipleCountry, this.mission.country, this.translations.missionPage.multipleCity, this.translations.missionPage.multipleCountry)
  }

  private get culture () {
    return CultureService.getCultureCode()
  }
}
