



















import { Component, Prop, Vue } from 'vue-property-decorator'
import { CreateProfileTranslations } from '../CreateProfile.translations'
import { CreateProfileModel } from '../CreateProfile.model'
import FileListComponent from '../files/FileList.vue'
import { FileInfo } from '@/generic/models/File.model'

@Component({
  components: {
    'mitm-file-list': FileListComponent
  }
})
export default class CvSectionComponent extends Vue {
  @Prop() private translations!: CreateProfileTranslations
  @Prop() private value !: CreateProfileModel
  @Prop() private isNewProfile!: boolean

  private removeFile (file : FileInfo) {
    this.value.filesToDelete.push(file)
  }
}
