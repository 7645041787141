












































import { Component, Prop, Vue } from 'vue-property-decorator'
import { OfferPrerequisiteTranslations } from './translations/OfferPrerequisite.translations'
import { OfferPrerequisiteDto } from './models/OfferPrerequisiteDto.model'
import { OfferPrerequisiteData } from './models/OfferPrerequisiteData.model'
import { CommonTranslations } from '@/generic/models/Common.translations'
import { PublicationLanguage } from '@/generic/models/PublicationLanguage'
import { QuestionType } from '@/generic/models/QuestionType'

@Component
export default class OfferPrerequisiteQuestionsComponent extends Vue {
  @Prop() private translations!: OfferPrerequisiteTranslations
  @Prop() private commonTranslations!: CommonTranslations
  @Prop() private value!: OfferPrerequisiteDto
  @Prop() private PrerequisiteData !: OfferPrerequisiteData
  @Prop() private language!: PublicationLanguage
  @Prop({ default: false }) private isPublished!: boolean

  private PublicationLanguage = PublicationLanguage
  private published = [false, false]

  created () {
    let i = 0
    while (i < 2) {
      if (this.value.questions[i].questionEn === '' && this.value.questions[i].questionFr === '') {
        this.published[i] = false
      } else {
        this.published[i] = this.isPublished
      }
      i = i + 1
    }
  }
}

