


















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class AnimatedArrowIconComponent extends Vue {
  @Prop() private animation!: boolean
  @Prop() private arrowColor!: string
  @Prop({ default: false }) private fullColored!: boolean
  @Prop({ default: '' }) private size!: string

  private get cssVariables () {
    return {
      '--arrowColor': this.arrowColor
    }
  }
}
