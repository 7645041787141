











































import { Component, Prop, Vue } from 'vue-property-decorator'
import MatchField from '@/generic/rules/Match'
import ResetPasswordTranslations from '@/reset-password/ResetPassword.translations'
import { RequiredRule } from '@/generic/rules/Required'
import { AuthenticationService } from '../services/Authentication.service'
import { ResetPassword } from './ResetPassword.model'

@Component
export default class ResetPasswordComponent extends Vue {
  @Prop() translations !: ResetPasswordTranslations
  @Prop() resetUrl !: string
  @Prop() userId !: number
  @Prop() token !: string

  private password : string = ''
  private passwordConfirmation : string = ''
  private hasErrors : boolean = false
  private loading : boolean = false

  private rules : { [key: string]: Array<(value: any) => boolean | string> } = {
    matchPassword: [
      new MatchField('password', this.getProperty).getValidator(
        this.translations.confirm.validation.match),
      new RequiredRule().getValidator(
        this.translations.confirm.validation.required)
    ],
    matchConfirmPassword: [
      new MatchField('passwordConfirmation', this.getProperty).getValidator(
        this.translations.password.validation.match),
      new RequiredRule().getValidator(
        this.translations.confirm.validation.required)
    ]
  }

  private getProperty (prop : string) : any {
    return this[prop as keyof ResetPasswordComponent]
  }

  get disabled () {
    return !(this.password && this.passwordConfirmation && this.password === this.passwordConfirmation)
  }

  changePassword () {
    this.loading = true
    AuthenticationService.changePassword({
      userId: this.userId,
      password: this.password,
      confirmPassword: this.passwordConfirmation,
      token: this.token
    })
      .then(() => {
        this.loading = false
        window.location.href = this.resetUrl
      })
      .catch(() => {
        this.loading = false
        this.hasErrors = true
      })
  }
}
