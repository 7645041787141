import { AxiosPromise } from 'axios'
import { BASE_API } from '@/services/api-common'
import { CultureService } from '@/services/CultureService'
import ProductsList from '../models/products/ProductsList.models'
import Product from '../models/products/Product.models'
import ProductDetails from '../models/products/ProductsDetails.models'
import ProductAssociation from '../models/products/ProductAssociation.models'
import { SelectOption } from '@/generic/models/SelectOption'

export default class AdminConsoleProductService {
  public static getProducts(): AxiosPromise<ProductsList> {
    return BASE_API.get('adminconsoleproduct/getproducts', {
      params: {
        cultureCode: CultureService.getCultureCode()
      }
    })
  }

  public static getProduct(skuGuid: string): AxiosPromise<ProductDetails> {
    return BASE_API.get('adminconsoleproduct/getproduct', {
      params: {
        cultureCode: CultureService.getCultureCode(),
        skuGuid: skuGuid
      }
    })
  }

  public static getProductVariants(skuGuid: string): AxiosPromise<SelectOption> {
    return BASE_API.get('adminconsoleproduct/getProductVariants', {
      params: {
        parentSkuGuid: skuGuid
      }
    })
  }

  public static createProduct(product: Product): AxiosPromise<void> {
    return BASE_API.post('adminconsoleproduct/createProduct', product)
  }

  public static editProduct(product: Product): AxiosPromise<void> {
    return BASE_API.post('adminconsoleproduct/editProduct', product)
  }

  public static deleteProduct(skuGuid: string): AxiosPromise<void> {
    return BASE_API.delete('adminconsoleproduct/deleteProduct', {
      params: {
        cultureCode: CultureService.getCultureCode(),
        skuGuid: skuGuid
      }
    })
  }

  public static addProductToCompany(productAssociation: ProductAssociation): AxiosPromise<void> {
    return BASE_API.post('adminconsoleproduct/assignProductToCompany', productAssociation)
  }
}
