




























































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import CandidateCardTranslations from '@/candidate/candidates-list/CandidateCard.translations'
import { FavoriteService } from '@/services/FavoriteService'
import { FavoriteCandidates } from './FavoriteCandidates.model'
import { loadWhile, notify, downloadFile, debounce, downloadFileCsv } from '@/generic/helpers'
import { SelectOption } from '@/generic/models/SelectOption'
import { ProductService } from '@/services/ProductService'
import { CultureService } from '../../services/CultureService'
import { Status } from '@/generic/models/Status'

@Component
export default class FavoritesComponent extends Vue {
  @Prop() private translations!: CandidateCardTranslations
  @Prop() companyId !: number

  private showHidden: boolean = false
  private loaded : boolean = false
  private events !: SelectOption[]
  private selectedEvent!: SelectOption | null
  private favorites: FavoriteCandidates|null = null
  private searchTerm: string = ''
  private selectedFavoriteCandidatesIds: string= ''
  private loadingFiles: boolean = false
  private loadingFilesCSV: boolean = false
  private loadingInvitation: boolean = false
  private jobOfferStatus!: SelectOption[]
  private selectedStatus!: SelectOption | null | undefined
  private oldSelectedStatus!: SelectOption | null | undefined
  private debounceLoad = debounce(() => {
    loadWhile(this, this.translations.common.message.loading, () => this.loadFavorites())
  })

  private get showExportTools () {
    return this.favorites && this.favorites.favoritesPerJobOffer.length > 0
  }

  mounted () {
    this.jobOfferStatus = this.translations.jobOfferStatus.filter(s => s.value === Status.Published || s.value === Status.Closed)
    this.selectedStatus = this.jobOfferStatus.find(s => s.value === Status.Published)
    this.oldSelectedStatus = this.selectedStatus
    loadWhile(this, this.translations.common.message.loading, () => Promise.all([this.loadFavorites(), this.loadEvents()]))
      .then(() => {
        this.loaded = true
      })
  }

  private loadFavorites () : Promise<void> {
    return FavoriteService.getMyCompanysFavoriteCandidates(this.searchTerm,
      this.selectedEvent ? this.selectedEvent.value.toString() : '',
      this.selectedStatus ? this.selectedStatus.value as number : 1).then((response) => {
      this.favorites = response.data
    })
  }

  private onSearchTermsChange (newSearchTerms: string): void {
    this.searchTerm = newSearchTerms
    this.debounceLoad()
  }

  private eventSelected (eventSelected: SelectOption) {
    if (eventSelected) {
      this.selectedEvent = eventSelected
    } else {
      this.selectedEvent = null
    }

    loadWhile(this, this.translations.common.message.loading, () => this.loadFavorites())
  }

  private statusSelected (statusSelected: SelectOption) {
    if (statusSelected && statusSelected.value !== this.oldSelectedStatus?.value) {
      this.oldSelectedStatus = this.selectedStatus
      this.selectedStatus = statusSelected
      loadWhile(this, this.translations.common.message.loading, () => this.loadFavorites())
    }
  }

  private loadEvents (): Promise<void> {
    return ProductService.getProductsForDropDownForCompany(this.companyId, CultureService.getCultureCode()).then(result => {
      this.events = [{ label: this.translations.common.option.rec, value: '0-0' }]
      this.events = this.events.concat(result.data)
    })
  }

  private downloadCV () {
    this.loadingFiles = true
    this.selectedFavoriteCandidatesIds = ''
    this.favorites?.favoritesPerJobOffer.forEach(favorite =>
      favorite.favorites.forEach(candidate => { candidate.isSelectedForCVDownload ? this.selectedFavoriteCandidatesIds += candidate.candidateId + '|' : this.selectedFavoriteCandidatesIds += '' })
    )
    let culture = CultureService.getCultureCode()
    FavoriteService.downloadCvs(culture, this.showHidden, this.searchTerm,
      this.selectedEvent ? this.selectedEvent.value.toString() : '',
      this.selectedStatus ? this.selectedStatus.value as number : 1,
      this.selectedFavoriteCandidatesIds)
      .then(response => {
        if (response.status === 204) {
          notify(this, this.translations.candidateCard.noCvAvailable)
        } else {
          downloadFile(response)
        }
      })
      .catch(() => {
        notify(this, this.translations.candidateCard.noCvAvailable)
      })
      // eslint-disable-next-line no-return-assign
      .finally(() => this.loadingFiles = false)
  }

  private csvUrl () {
    this.loadingFilesCSV = true
    this.selectedFavoriteCandidatesIds = ''
    this.favorites?.favoritesPerJobOffer.forEach(favorite =>
      favorite.favorites.forEach(candidate => { candidate.isSelectedForCVDownload ? this.selectedFavoriteCandidatesIds += candidate.candidateId + '|' : this.selectedFavoriteCandidatesIds += '' })
    )
    let culture = CultureService.getCultureCode()
    FavoriteService.downloadFavoriteCvs(culture, this.showHidden, this.searchTerm,
      this.selectedEvent ? this.selectedEvent.value.toString() : '',
      this.selectedStatus ? this.selectedStatus.value as number : 1,
      this.selectedFavoriteCandidatesIds)
      .then(response => {
        if (response.status === 204) {
          notify(this, this.translations.candidateCard.noCvAvailable)
        } else {
          downloadFileCsv(response)
        }
      })
      .catch(() => {
        notify(this, this.translations.candidateCard.noCvAvailable)
      })
      // eslint-disable-next-line no-return-assign
      .finally(() => this.loadingFilesCSV = false)
  }

  private invitationUrl() {
    this.loadingInvitation = true
    this.selectedFavoriteCandidatesIds = ''
    this.favorites?.favoritesPerJobOffer.forEach(favorite =>
      favorite.favorites.forEach(candidate => { candidate.isSelectedForCVDownload ? this.selectedFavoriteCandidatesIds += candidate.id + '|' : this.selectedFavoriteCandidatesIds += '' })
    )
    let culture = CultureService.getCultureCode()
    FavoriteService.sendFavoriteInvitation(culture, this.selectedFavoriteCandidatesIds)
      .then(response => {
        notify(this, this.translations.candidateCard.invitationsSented)
      })
      .catch(() => {
        notify(this, this.translations.candidateCard.invitationsNotSented)
      })
      // eslint-disable-next-line no-return-assign
      .finally(() => {
        this.loadFavorites()
        this.loadingInvitation = false
      })
  }
}
