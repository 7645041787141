







































import { Component, Prop, Vue } from 'vue-property-decorator'
import Doughnut from '@/generic/components/charts/Doughnut.vue'
import { DoughnutChart, DoughnutDataSet } from '@/generic/components/charts/DoughnutChart.model'
import CandidateService from '@/services/CandidateService'
import CandidateInfo from '@/dashboard/employer/models/CandidateInfo.model'
import CandidateWheelTranslations from './CandidateWheel.translations'

@Component({
  components: {
    'doughnut-chart': Doughnut
  }
})
export default class CandidateWheelComponent extends Vue {
  @Prop() companyId !: number
  @Prop() since !: Date
  @Prop() translations !: CandidateWheelTranslations

  private loaded : boolean = false
  private error : boolean = false
  private chartData?: CandidateInfo
  private data : DoughnutChart = new DoughnutChart()
  private iconData !: {
    [key: string] : DoughnutChart
    suggestedCandidates: DoughnutChart
    newCandidates: DoughnutChart
    applicants: DoughnutChart
  }
  private keys : string[] = [
    'newCandidates',
    'applicants',
    'suggestedCandidates'
  ]

  private dataPoints : number[] = []
  private color = {
    suggestedCandidates: '#da1b66',
    newCandidates: '#093c6f',
    applicants: '#00b7ce',
    transparent: 'rgba(0, 0, 0, 0)'
  }
  private chartIcon : {
      [key:string] : any
    } = {
      suggestedCandidates: {
        colors: [this.color.suggestedCandidates, this.color.transparent, this.color.transparent]
      },
      newCandidates: {
        colors: [this.color.transparent, this.color.newCandidates, this.color.transparent]
      },
      applicants: {
        colors: [this.color.transparent, this.color.transparent, this.color.applicants]
      }
    }

  private options : any = {
    responsive: true,
    maintainAspectRatio: true,
    // cutoutPercentage: 60,
    tooltips: {
      enabled: false
    },
    legend: {
      display: false
    },
    elements: {
      center: {
        text: this.translations.subTitle
      }
    }
  }

  get legend () {
    let legend: any[] = []
    this.keys.forEach((key, index) => {
      if (this.dataPoints[index] > 0) {
        legend.push({
          key: key,
          iconData: this.iconData[key],
          label: this.translations.label[key],
          value: this.dataPoints[index]
        })
      }
    })
    return legend
  }

  get totalCandidates () : number {
    return (this.chartData) ? this.chartData.suggestedCandidates + this.chartData.applicants : 0
  }

  private colorList () {
    return [this.color.suggestedCandidates, this.color.newCandidates, this.color.applicants]
  }

  private buildOuterChart (index : number) : DoughnutDataSet {
    let colorList = this.colorList()
    for (var _x = 0; _x < colorList.length; _x++) {
      if (_x !== index) {
        colorList[_x] = this.color.transparent
      }
    }
    return this.buildChartDataSet(colorList, 1)
  }

  private buildInnerChart (index : number) : DoughnutDataSet {
    let colorList = this.colorList()
    return this.buildChartDataSet(colorList, 5)
  }

  private buildChartDataSet (colorList : string[], weight : number) : DoughnutDataSet {
    return {
      backgroundColor: colorList,
      borderColor: colorList,
      borderWidth: -1,
      hoverBackgroundColor: colorList,
      hoverBorderColor: colorList,
      hoverBorderWidth: 0,
      weight: weight,
      data: this.dataPoints
    }
  }

  private buildIcons () {
    this.iconData = {
      suggestedCandidates: new DoughnutChart(),
      newCandidates: new DoughnutChart(),
      applicants: new DoughnutChart()

    }
    for (let icon of Object.keys(this.chartIcon)) {
      this.iconData[icon] = {
        labels: [],
        datasets: [this.buildChartDataSet(this.chartIcon[icon].colors, 1)]
      }
    }
  }
  async mounted () {
    CandidateService.getAllCandidates(this.companyId, this.since)
      .then(results => {
        this.chartData = results.data
        this.keys = Object.keys(results.data)
        this.dataPoints = this.keys.map(key => results.data[key])
        const index = this.keys.indexOf('suggestedCandidates')

        let outerRing = this.buildOuterChart(index)
        let innerRing = this.buildInnerChart(index)

        this.data.datasets.push(outerRing, innerRing)

        this.buildIcons()

        this.loaded = true
      })
      .catch(() => {
        this.error = true
      })
  }
}
