



































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { InteractiveMapTranslations } from './translations/InteractiveMap.translations'
import { MapRegion } from './MapRegion'
import MITMImageComponent from '@/generic/components/MITMImage.vue'

@Component({
  components: {
    'mitm-img': MITMImageComponent
  }
})
export default class InteractiveMapInfoCardComponent extends Vue {
  @Prop() private region!: MapRegion
  @Prop() private translations!: InteractiveMapTranslations
  private icon: boolean = false
  private animation = false

  private onMouseOver () {
    this.animation = true
  }

  private onMouseLeave () {
    this.animation = false
  }
}
