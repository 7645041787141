


























































































import { Component, Prop, Vue } from 'vue-property-decorator'
import JobCardTranslations from '@/offer/summary/JobCard.translations'
import ActiveOffersSectionTranslation from '@/dashboard/employer/active-offers/ActiveOffersSection.translations'
import { CallToAction } from '@/generic/models/CallToAction'
import MITMCarouselComponent from './MITMCarousel.vue'

@Component({
  components: {
    'mitm-carousel': MITMCarouselComponent
  }
})
export default class MITMCardContainerComponent extends Vue {
  @Prop() content!: any []
  @Prop() headerIcon!: string
  @Prop() callToAction!: CallToAction

  private get hasError () : boolean {
    return !!this.$slots.error &&
    !!(this.$slots.error[0] as any).children
  }

  private slide: number = 0

  get hasContent () : boolean {
    return this.content.length > 0
  }
}

