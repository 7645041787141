import { BASE_API } from './api-common'
import { AxiosPromise } from 'axios'
import { CreateProfileModel } from '@/profile/CreateProfile.model'

export class ProfileCandidateService {
  public static createProfile (model: CreateProfileModel): AxiosPromise<any> {
    return BASE_API.post('profileCandidate/createProfile', model)
  }

  public static getProfile (candidateId : number): AxiosPromise<CreateProfileModel> {
    return BASE_API.get(`profileCandidate/getProfile/${candidateId}`)
  }

  public static saveProfile (model: CreateProfileModel): AxiosPromise<CreateProfileModel> {
    return BASE_API.put(`profileCandidate/save/${model.candidateId}`, model)
  }
}
