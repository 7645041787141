

















































import { Component, Prop, Vue } from 'vue-property-decorator'
import { LanguageProficiency } from '@/generic/models/LanguageProficiency.model'

@Component
export default class LanguageProficiencyCardComponent extends Vue {
  @Prop() private model!: LanguageProficiency
  @Prop() private selected!: boolean
  @Prop() private otherLanguageIndex !: number

  private hasError = false
  private errorMessage: string = ''
  private href:string = ''

  private handleError (error: any) {
    this.hasError = true
    if (error.response.status === 404) {
      this.errorMessage = 'this.translations.error.notFound'
    } else if (error.response.status === 403) {
      this.errorMessage = 'this.translations.error.forbidden'
    } else {
      this.errorMessage = 'this.translations.error.unknown'
    }
  }

  private selectLanguageProficiency () {
    this.selected = !this.selected
    this.model.selected = this.selected
    if (this.selected) {
      this.$emit('selected-language-proficiency', this.model.id, this.otherLanguageIndex)
    } else {
      this.$emit('selected-language-proficiency', -1, this.otherLanguageIndex)
    }
  }
}

