























































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import ExternalDocument from '../../../models/meetings/external/ExternalDocument.models'
import ExternalMeetingTranslations from '../../../models/meetings/external/ExternalMeeting.translations'
import { ExternalListFilter } from '../../../models/meetings/external/ExternalListFilter.model'
import AdminConsoleMeetingService from '../../../services/AdminConsoleMeetingService'
import { FileInfo } from '@/generic/models/File.model'
import FileListComponent from './files/FileList.vue'
import { loadWhile } from '@/generic/helpers'

@Component({
  components: {
    'mitm-file-list': FileListComponent
  }
})
export default class AdminExternalMeetingsListComponent extends Vue {
  @Prop() translations!: ExternalMeetingTranslations
  private filters: ExternalListFilter = new ExternalListFilter()
  private documents: Array<ExternalDocument> = []
  private hasError: boolean = false
  private errorMessage!: string
  private loaded: boolean = true
  private isSaving: boolean = false
  private searchTerms: string = ''
  private pageSizeOptions: Array<Number> = [20, 50, 100]
  private pageSizeOldValue: Number = 20
  private showDialog: boolean = false
  private name: string = ''
  private files: Array<FileInfo> = []
  private isEditing: boolean = false

  mounted (): void {
    this.resetFilters()
  }

  private get animationOutClass () {
    return 'animated ' + (this.$q.screen.lt.md ? 'slideOutRight' : 'fadeOut')
  }

  private loadDocuments (): void {
    this.loaded = false
    this.documents = []
    loadWhile(this, this.translations.common.message.loading, () =>
      this.getDocuments((a: boolean | void) => {}).then(() => {
        this.loaded = true
      })
    )
  }

  private getDocuments(done: Function): Promise<void> {
    this.hasError = false
    return AdminConsoleMeetingService.getExternalDocuments(this.filters)
      .then((response) => {
        this.documents = []
        this.filters.total = response.data.total
        if (response.data.documents.length > 0) {
          Array.prototype.push.apply(this.documents, response.data.documents)
          done()
        } else {
          done(true)
        }
      })
      .catch((error) => {
        if (error) {
          this.handleError(error)
        }
        done(true)
      })
  }

  private uploadDocuments(done: Function): Promise<void> {
    this.hasError = false
    return AdminConsoleMeetingService.uploadExternalDocuments(this.files[0], this.name)
      .then((response) => {
        done(true)
      })
      .catch((error) => {
        if (error) {
          this.handleError(error)
        }
        done(true)
      })
  }

  private onPageChange (newPage: number) {
    if (newPage !== this.filters.pageNumber) {
      this.filters.pageNumber = newPage
      this.loadDocuments()
    }
  }

  private resetFilters () {
    this.filters = new ExternalListFilter()
    this.searchTerms = ''
    this.loadDocuments()
  }

  private advancedSearch () {
    this.resetPageNumber()
    this.loadDocuments()
  }

  private onSearchTermsChange (newSearchTerms: string): void {
    this.filters.searchTerms = newSearchTerms
    if (this.filters.searchTerms.length > 4) {
      this.resetPageNumber()
    }
  }

  private pageSizeChanged () {
    if (this.filters.pageSize !== this.pageSizeOldValue) {
      this.pageSizeOldValue = this.filters.pageSize
      this.resetPageNumber()
    }
  }

  private resetPageNumber () {
    this.filters.pageNumber = 1
  }

  private toogleDialogFromCard(name: string) {
    this.isEditing = true
    this.name = name
    this.toogleDialog()
  }

  private toogleDialog () {
    this.files = []
    this.showDialog = !this.showDialog
  }

  private get canSave() : boolean {
    return (this.files.length !== 0 && this.name !== '')
  }

  private save () : void {
    if (this.canSave) {
      this.isSaving = true
      loadWhile(this, this.translations.common.message.loading, () =>
        this.uploadDocuments((a: boolean | void) => {}).then(() => {
          this.isSaving = false
          this.toogleDialog()
          this.loadDocuments()
        })
      )
    }
  }

  private hide () {
    this.name = ''
    this.isEditing = false
    this.loadDocuments()
  }

  private handleError (error: any): void {
    this.hasError = true
    if (error && error.response.status === 404) {
      this.errorMessage = 'error'
    } else {
      this.errorMessage = error.message
    }
  }
}
