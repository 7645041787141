




































import { Component, Prop, Vue } from 'vue-property-decorator'
import { CommonTranslations } from '@/generic/models/Common.translations'
import { DialogResult } from '@/generic/models/DialogResult'

@Component
export default class MITMPromptComponent extends Vue {
  @Prop() private translations !: CommonTranslations
  @Prop() private prompt !: string
  @Prop({ default: '' }) private customClass !: string
  private openDialog : boolean = false
  private result : DialogResult = DialogResult.Cancel

  public mounted () {
    this.$on('hide', () => {
      this.$emit('close-dialog', this.result)
    })
  }

  private onCancel () {
    this.result = DialogResult.Cancel;
    (this.$refs.promptDialog as any).hide()
  }

  private onOk () {
    this.result = DialogResult.Ok;
    (this.$refs.promptDialog as any).hide()
  }

  public open () {
    this.openDialog = true
  }
}
