










import { Component, Prop, Vue } from 'vue-property-decorator'
import { SVGInjector } from '@tanem/svg-injector'

@Component
export default class SVGIconComponent extends Vue {
  @Prop() name!: string
  @Prop() svgFill!: string
  private publicPath: string = process.env.VUE_APP_ASSET_URL
  private ready = false

  mounted () {
    SVGInjector(this.$el, {
      afterAll: (elementsLoaded) => {
        this.ready = true
      },
      afterEach: (err, svg) => {
        if (err) {
          throw err
        }
        if (svg) {
          // we need to add possible events here
          svg.addEventListener('click', this.click)
          svg.addEventListener('enter', this.enter)
          svg.classList.remove('hidden')
          if (this.svgFill) {
            svg.setAttribute('style', 'fill:' + this.svgFill)
          }
        }
      },
      beforeEach (svg) {
      }
    })
  }

  click () {
    this.$emit('click')
  }

  enter () {
    this.$emit('enter')
  }
}
