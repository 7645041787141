





















































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { JobApplicationMission, TimeSlotsPerDay, TimeSlot } from '@/job-application/JobApplication.model'
import { CultureService } from '../services/CultureService'
import { Culture } from '@/generic/models/Culture'
import MissionService from '../services/Mission.service'
import EventDateComponent from './EventDate.vue'
import EventCalendarComponent from './EventCalendar.vue'
import { CalendarEvent } from './Event.model'
import { EventTranslations } from './Event.transations'
import moment from 'moment'

@Component({
  components: {
    'mitm-event-date': EventDateComponent,
    'mitm-event-calendar': EventCalendarComponent
  }
})
export default class EventParticipationComponent extends Vue {
  @Prop() mission !: JobApplicationMission
  @Prop() availabilities!: Array<number>
  @Prop() translations!: EventTranslations
  @Prop() selectMission!: boolean
  @Prop() participating!: boolean
  @Prop() timeSlots!: Array<TimeSlot>

  private events: CalendarEvent[] = []
  private hasErrors: boolean = false
  private ready: boolean = false
  private animation: boolean = false
  private selectedTimeslots!: TimeSlotsPerDay | undefined
  private selectedDate: Date = new Date()
  private color :string = 'azure'
  private timeSlotsPerDay!: TimeSlotsPerDay[]
  private dayTimeslots: Map<number, TimeSlotsPerDay> = new Map<number, TimeSlotsPerDay>()
  private doNotInitialize: boolean = false
  private cityColors: Map<string, string> = new Map<string, string>()
  private colors: string[] = ['azure', 'aubergine', 'framboise', 'marine']

  private get allTimeslots () : Array<number> {
    return this.timeSlotsPerDay.map(ts => ts.timeSlots).flat().map(ts => ts.id)
  }

  private get dropDownEvents () {
    return this.events.map(event => {
      return {
        label: this.formatDate(event.date) + ' | ' + event.city,
        value: event.date
      }
    })
  }

  private dropDownDisplay (date: Date) {
    let event = this.events.find(event => event.date.valueOf() === date.valueOf())
    if (event) {
      return this.formatDate(event.date) + ' | ' + event.city
    }
    return ''
  }

  private onDropDownChange (value: any) {
    this.onSelectDate(value as Date)
  }

  private formatDate (date: Date) {
    let str = this.dateString(date, CultureService.getCultureCode(), { weekday: 'long', month: 'short', day: 'numeric' })
    return str[0].toUpperCase() + str.slice(1)
  }

  mounted () {
    var result = this.parseTimeSlots()
    if (this.participating) {
      this.doNotInitialize = this.availabilities?.length !== 0
    }

    this.timeSlotsPerDay = result
    this.timeSlotsPerDay.forEach((ts) => {
      if (!this.dayTimeslots.has((ts.date as Date).valueOf())) {
        this.dayTimeslots.set((ts.date as Date).valueOf(), ts)
      }
      this.events.push({
        date: ts.date as Date,
        city: ts.city
      })
    })
    this.mapCityToColor(this.events.map(event => event.city))
    this.ready = true
    if (this.timeSlotsPerDay.length > 0) {
      this.onSelectDate(this.timeSlotsPerDay[0].date as Date)
    }
  }

  private mapCityToColor (cities: string[]) {
    let index = 0
    cities.forEach((city) => {
      if (!this.cityColors.has(city)) {
        this.cityColors.set(city, this.colors[index])
        index = (index % this.colors.length) + 1
      }
    })
  }

  private onSelectDate (date: Date) {
    this.selectedDate = date
    this.selectedTimeslots = this.dayTimeslots.get(date.valueOf())
    this.color = this.colors[0]
    if (this.selectedTimeslots) {
      let city = this.selectedTimeslots.city
      if (city) {
        this.color = this.cityColors.get(city as string) as string
      }
    }
    this.$forceUpdate()
  }

  private get missionDates () {
    const culture = CultureService.getCultureCode()
    const start = this.dateString(this.mission.startDate, culture, { month: 'short', day: 'numeric' })
    const end = this.dateString(this.mission.endDate, culture, { year: 'numeric', month: 'short', day: 'numeric' })
    const link = culture === 'en-US' ? ' to ' : ' au '
    return start + link + end
  }

  private get location () : string {
    if (this.mission.isVirtual) {
      return CultureService.getCulture() === Culture.English ? 'Virtual' : 'Virtuel'
    }
    const city = this.mission.multipleCity && !this.mission.multipleCountry ? '' : this.mission.city
    const country = this.mission.multipleCountry ? '' : this.mission.country
    const location = (city || '') + ((city && country) ? ', ' : '') + (country || '')
    return location
  }

  private dateString (date: any, locale: string, options: Intl.DateTimeFormatOptions) : string {
    if (!(date instanceof Date)) {
      date = new Date(date)
    }
    return date.toLocaleDateString(locale, options)
  }

  private parseTimeSlots () : Array<TimeSlotsPerDay> {
    const timeSlots = this.timeSlots
    const map = new Map<number, Array<TimeSlot>>()
    timeSlots.forEach(ts => {
      if (this.mission.isVirtual) {
        ts.startTime = this.changeTimeZone(ts.startTime)
        ts.endTime = this.changeTimeZone(ts.endTime)
        ts.cityName = CultureService.getCulture() === Culture.English ? 'Virtual' : 'Virtuel'
      } else {
        ts.startTime = new Date(ts.startTime)
        ts.endTime = new Date(ts.endTime)
      }
      const date = new Date(ts.startTime.toDateString()).valueOf()
      if (map.has(date)) {
        const dateTimeSlots = map.get(date)
        if (dateTimeSlots) {
          dateTimeSlots.push(ts)
        }
      } else {
        map.set(date, [ts])
      }
    })
    const timeSlotsPerDay:TimeSlotsPerDay[] = []
    for (let date of map.keys()) {
      let timeslots = map.get(date) || []
      timeSlotsPerDay.push({
        date: new Date(date),
        city: timeslots.length > 0 ? timeslots[0].cityName : '',
        timeSlots: timeslots
      })
    }
    return timeSlotsPerDay
  }

  private changeTimeZone (date: Date): Date {
    return moment.utc(date).local().toDate()
  }

  private onUpdate (timeslots: Array<number>) {
    this.$emit('add-availabilities', timeslots)
  }

  public validate(): boolean {
    const isValid = this.timeSlotsPerDay.length === 0 || (this.timeSlotsPerDay.length > 0 && this.availabilities.length > 0)
    this.hasErrors = !isValid
    return isValid
  }
}
