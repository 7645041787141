import { BasicCompanyInfo } from '@/company/basic-info/BasicInfo.model'
import { ActivityAreaInfo } from '@/activity-area/ActivityAreaInfo.model'
import { AdditionalInformation } from '@/company/additionnal-info/AdditionalInformation.model'
import { ITranslatable } from '@/generic/models/ITranslatable'
import { Logo } from './logo/Logo.model'
import { FileType } from '@/generic/models/FileType'
import { Colleague } from '@/company/colleagues/Colleague.model'

export class Company {
  id: number
  basicInfo: BasicCompanyInfo
  activityArea: ActivityAreaInfo
  additionalInfo: AdditionalInformation
  description: ITranslatable
  logo: Logo
  colleagues: Colleague[]
  culture: string

  constructor () {
    this.id = -1
    this.basicInfo = {
      name: '',
      address: '',
      postalCode: '',
      city: '',
      phoneNumber: '',
      emailNotification: '',
      administrativeRegion: null
    }
    this.activityArea = {
      title: '',
      primaryActivity: undefined,
      secondaryActivity: undefined,
      requiredActivity: ''
    }
    this.additionalInfo = {
      employeeCount: undefined,
      facebookUrl: '',
      linkedInUrl: '',
      twitterUrl: '',
      webSiteUrl: ''
    }
    this.description = {
      en: '',
      fr: ''
    }
    this.logo = {
      fileContent: '',
      fileType: FileType.Unknown
    }
    this.colleagues = [
      {
        email: '',
        new: true
      }
    ]
    this.culture = ''
  }
}
