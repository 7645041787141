



























































import { Component, Prop, Vue } from 'vue-property-decorator'
import { EmployerPackageTranslations } from '../models/EmployerPackage.translations'

@Component
export default class StaticProductCardComponent extends Vue {
  @Prop() translations!: EmployerPackageTranslations

  getBackgroundColor (): string {
    return 'background-color: #ffffff'
  }
}
