


























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class AuthentificationTitleComponent extends Vue {
  @Prop() private title!: string;
  @Prop() private subtitle!: string;
  @Prop() private buttonLabel!: string;

  toggleConnectionType () {
    this.$emit('display-change')
  }
}
