














































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { EducationTranslations } from './Education.translations'
import { CommonTranslations } from '@/generic/models/Common.translations'
import { Education } from './Education.model'
import { SelectOption } from '@/generic/models/SelectOption'
import { RequiredRule } from '@/generic/rules/Required'
import { ITranslatable } from '@/generic/models/ITranslatable'

@Component
export default class EducationComponent extends Vue {
  @Prop() private translations!: EducationTranslations
  @Prop() private commonTranslations!: CommonTranslations
  @Prop() private value!: Array<Education>
  @Prop() private sectionNumber!: number
  @Prop({ default: false }) private isCandidateProfil!: boolean
  @Prop() private countriesList !: Array<SelectOption>
  @Prop() private fieldsOfStudy !: Array<SelectOption>
  @Prop() private levelsOfStudy !: Array<SelectOption>
  @Prop() private educationHelp !: Array<string>

  private rules: { [key: string]: Array<(value: any) => boolean | string> } = {
    required: [
      new RequiredRule().getValidator(this.commonTranslations.message.required)
    ]
  }
  private maxLength : number = 60
  private maxItem : number = 2
  private minItem : number = 1

  private deleteRow (pos: number) {
    this.value.splice(pos, 1)
  }

  private addRow () {
    this.value.push({
      school: '',
      country: '',
      countryId: null,
      fieldOfStudy: '',
      levelOfStudy: '',
      degree: '',
      startDate: null,
      endDate: null
    })
  }

  private onStartDate (value: any, reason: any, details: any, index: number) {
    if (reason === 'month') {
      (this.$refs.qStartDateProxy as any)[index].hide()
    }
  }

  private onEndDate (value: any, reason: any, details: any, index: number) {
    if (reason === 'month') {
      (this.$refs.qEndDateProxy as any)[index].hide()
    }
  }

  private validateMaxItem (): boolean {
    if (this.value?.length < this.minItem) {
      return false
    }

    if (this.value?.length > this.maxItem) {
      return false
    }
    return true
  }

  private get hasMaxEntries () : boolean {
    return this.value && this.value.length >= 2
  }

  private isEmptyOrSpaces (str: string): boolean {
    return str === null || str.match(/^ *$/) !== null
  }
}
