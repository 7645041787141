import { render, staticRenderFns } from "./AdminEditJobOffer.vue?vue&type=template&id=2542a7e2&scoped=true&"
import script from "./AdminEditJobOffer.vue?vue&type=script&lang=ts&"
export * from "./AdminEditJobOffer.vue?vue&type=script&lang=ts&"
import style0 from "./AdminEditJobOffer.vue?vue&type=style&index=0&id=2542a7e2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2542a7e2",
  null
  
)

export default component.exports