

































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { AuthenticationService } from '../../services/Authentication.service'
import { loadWhile } from '@/generic/helpers'
import { ConsentTranslations } from './Consent.translations'
import SignOutService from '@/services/SignOutService'
@Component
export default class ConsentComponent extends Vue {
  @Prop() private userId!: number
  @Prop() private role!: string
  @Prop() private message!: string
  @Prop() private signature!: string
  @Prop() private privacyMessage!: string
  @Prop() private returnUrl!: string
  @Prop() private homeUrl!: string
  @Prop() private privacyPolicyLink!: string
  @Prop() private translations!: ConsentTranslations
  private isChecked = false
  private errorHappened = false
  private errorMessage : string = ''
  private onPrivacyPolicyClick (e: Event): void {
    e.stopPropagation()
  }
  private signOut () {
    SignOutService.signOut()
      .then(() => { window.location.href = this.homeUrl })
  }
  private submit (): void {
    loadWhile(this, this.translations.loading, () => {
      return AuthenticationService.updateConsent(this.userId, this.role)
        .then(() => {
          window.location.href = this.returnUrl
        })
        .catch(error => {
          this.errorMessage = error.response.data.message
          this.errorHappened = true
        })
    })
  }
}
