


















































import { Component, Prop, Vue } from 'vue-property-decorator'
import { FooterTranslations } from '@/generic/translations/Footer.translations'
@Component
export default class AuthLayoutComponent extends Vue {
  @Prop() private otherLanguageLabel!: string
  @Prop() private otherLanguageLink!: string
  @Prop({ default: false }) private rightSideBar!: boolean
  @Prop() private sideBarImage!: string
  @Prop() private footerTranslations!: FooterTranslations
  @Prop() private about!: string
  @Prop() private contactUs!: string
  @Prop() private faq!: string
  @Prop() private newsletterPage!: string
}
