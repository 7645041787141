import { render, staticRenderFns } from "./TextEditor.vue?vue&type=template&id=97be68f2&scoped=true&"
import script from "./TextEditor.vue?vue&type=script&lang=ts&"
export * from "./TextEditor.vue?vue&type=script&lang=ts&"
import style0 from "./TextEditor.vue?vue&type=style&index=0&id=97be68f2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97be68f2",
  null
  
)

export default component.exports