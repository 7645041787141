import $ from 'jquery'
import { TimelineMax } from 'gsap/TimelineMax'
import { Circ, Power4, Power2, Power1 } from 'gsap/EasePack'

class TriangleAnimation {
  constructor ($el, width, height) {
    // Define global variables
    this.cache = {}
    this.canvas = {}
    this.triangles = []

    // Define scroll related variables
    this.winHeight = 0
    this.lastScrollPos = 0
    this.scrollPos = 0
    this.scrollDownTriggerOffset = 0.75
    this.scrollDownTrigger = 0
    this.scrollUpTriggerOffset = 0.25
    this.scrollUpTrigger = 0
    this.isScrollDown = false
    this.isScrollAnimating = false
    this.scrollStartValues = false

    this.originalWidth = width
    this.originalHeight = height
    this.imageWidth = 0
    this.imageHeight = 0
    // Define timelines
    this.tlOpen = false
    this.tlDownScroll = false
    this.tlUpScroll = false

    // Set cache object
    this.cache.$main = $($el)
    this.initCache()

    // Trigger init fn
    this.init()
  }

  /******************************/
  /* Destroy function              */
  /******************************/
  destroy () {
    // Clear canvas
    this.canvas.ctx.clearRect(0, 0, this.canvas.baseWidth, this.canvas.baseHeight)
    this.canvas.ctx.save()

    // Unbind event
    this.unregisterEvents()

    // Clear global variables
    this.canvas = {}
    this.triangles = []

    // Clear scroll variables
    this.winHeight = 0
    this.lastScrollPos = 0
    this.scrollPos = 0
    this.scrollDownTriggerOffset = 0.75
    this.scrollDownTrigger = 0
    this.scrollUpTriggerOffset = 0.25
    this.scrollUpTrigger = 0
    this.isScrollDown = false
    this.isScrollAnimating = false
    this.scrollStartValues = false

    // Removes callbacks
    this.tlOpen.eventCallback('onUpdate', null)
    this.tlDownScroll.eventCallback('onUpdate', null)
    this.tlUpScroll.eventCallback('onUpdate', null)

    // Kill timelines
    this.tlOpen.kill()
    this.tlDownScroll.kill()
    this.tlUpScroll.kill()

    // Clear timelines
    this.tlOpen = false
    this.tlDownScroll = false
    this.tlUpScroll = false

    // Hide canvas
    this.cache.$canvasWrapper.addClass('is-hidden')
  }

  /******************************/
  /* Init function              */
  /******************************/
  init () {
    // Show canvas
    this.cache.$canvasWrapper.removeClass('is-hidden')

    // Init needed variables
    this.initVariables()
    this.updateCanvasValues()

    this.calcTriangleVertices()

    // Store timelines
    this.tlOpen = this.openTimeline()
    this.tlDownScroll = this.scrollDownTimeline()
    this.tlUpScroll = this.scrollUpTimeline()

    this.tlOpen.play()
  }

  /******************************/
  /* Init Cache                 */
  /******************************/
  initCache () {
    this.cache.$window = $(window)

    this.cache.$imgWrapper = this.cache.$main.find('.MediaBlock_Hero')
    this.cache.$img = this.cache.$imgWrapper.find('img')

    this.cache.$canvasWrapper = this.cache.$main.find('.MediaBlock_Canvas')
    this.cache.$canvas = this.cache.$canvasWrapper.find('canvas')
  }

  /******************************/
  /* Init Variables                 */
  /******************************/
  initVariables () {
    this.winHeight = this.cache.$window.height()
    this.lastScrollPos = 0
    this.scrollPos = this.cache.$main.offset().top

    var scrollHeight = (this.scrollPos + this.cache.$main.height())
    this.scrollDownTrigger = scrollHeight - (this.winHeight * this.scrollDownTriggerOffset)
    this.scrollUpTrigger = scrollHeight - (this.winHeight * this.scrollUpTriggerOffset)
  }

  /******************************/
  /* Update Canvas variables    */
  /******************************/
  updateCanvasValues () {
    this.canvas.ctx = this.cache.$canvas[0].getContext('2d')

    this.canvas.width = this.canvas.ctx.canvas.width
    this.canvas.height = this.canvas.ctx.canvas.height

    this.canvas.baseWidth = this.canvas.ctx.canvas.width
    this.canvas.baseHeight = this.canvas.ctx.canvas.height

    this.imageWidth = this.canvas.baseWidth
    this.imageHeight = (this.canvas.baseWidth / this.originalWidth) * this.originalHeight
  }

  /******************************/
  /* Register Events            */
  /******************************/
  registerEvents () {
    this.cache.$window.on('scroll', null, { self: this }, this.onScroll)
    this.cache.$window.on('resize', null, { self: this }, this.onResize)
  }

  /******************************/
  /* Unregister Events          */
  /******************************/
  unregisterEvents () {
    this.cache.$window.off('scroll', this.onScroll)
    this.cache.$window.off('resize', this.onResize)
  }

  /******************************/
  /* Event handler resize       */
  /******************************/
  onResize (event) {
    var self = event.data.self
    self.initVariables()
  }

  /******************************/
  /* Event handler scroll       */
  /******************************/
  onScroll (event) {
    var self = event.data.self
    var scrollPos = window.pageYOffset

    if (scrollPos >= self.lastScrollPos) {
      if (scrollPos > self.scrollDownTrigger) {
        if (!self.isScrollAnimating || (self.isScrollAnimating && !self.isScrollDown)) {
          self.playScrollDown()
        }
      }
    } else {
      if (scrollPos < self.scrollUpTrigger) {
        if (!self.isScrollAnimating || (self.isScrollAnimating && self.isScrollDown)) {
          self.playScrollUp()
        }
      }
    }

    self.lastScrollPos = scrollPos
  }

  /******************************/
  /* Play Open Animation        */
  /******************************/
  playOpen () {
    if (!this.tlOpen) return false
    this.tlOpen.seek(0).play()
  }

  /******************************/
  /* Play Scroll Down Animation */
  /******************************/
  playScrollDown () {
    if (!this.tlDownScroll) return false
    this.tlUpScroll.kill()

    this.tlDownScroll = this.scrollDownTimeline()
    this.tlDownScroll.play()
  }

  /******************************/
  /* Play Scroll Up Animation   */
  /******************************/
  playScrollUp () {
    if (!this.tlUpScroll) return false
    this.tlDownScroll.kill()

    this.tlUpScroll = this.scrollUpTimeline()
    this.tlUpScroll.play()
  }

  /******************************/
  /* Re-order Array             */
  /******************************/
  swapArrayRow (array, indexA, indexB) {
    var tmp = array[indexA]
    array[indexA] = array[indexB]
    array[indexB] = tmp
  }

  /******************************/
  /* Calc Triangle              */
  /******************************/
  calcTriangleVertices () {
    // First
    var width = 224
    var x0 = Math.round((this.canvas.baseWidth / 100) * 20)
    var y0 = Math.round(this.canvas.baseHeight - width - 10)
    var x1 = x0// Rectangle
    var y1 = y0 + width // Isocèle
    var x2 = x0 + width // Isocèle
    var y2 = y0// Rectangle

    var offsetX = 10
    var offsetY = (this.canvas.baseHeight - this.imageHeight) / 2 - 20

    var x0end = Math.round((this.canvas.baseWidth / 100) * 45)
    var x0scroll = Math.round((this.canvas.baseWidth / 100) * 65)

    this.triangles.push({
      coords: [
        { x: x0, y: y0 },
        { x: x1, y: y1 },
        { x: x2, y: y2 }
      ],
      offset: { x: offsetX, y: offsetY },
      animation: {
        x: x0end,
        xscroll: x0scroll,
        width: width
      }
    }
    )

    // Second
    width = Math.round(((this.canvas.baseHeight / 100) * 45))
    x0 = Math.round((this.canvas.baseWidth / 100) * 40)
    y0 = Math.round((this.canvas.baseHeight / 100) * 50)
    x1 = x0// Rectangle
    y1 = y0 + width// Isocèle
    x2 = x0 + width// Isocèle
    y2 = y0// Rectangle

    x0end = Math.round(this.canvas.baseWidth - width - 20)
    x0scroll = Math.round((this.canvas.baseWidth / 100) * 95)

    this.triangles.push({
      coords: [
        { x: x0, y: y0 },
        { x: x1, y: y1 },
        { x: x2, y: y2 }
      ],
      offset: { x: offsetX, y: offsetY },
      animation: {
        x: x0end,
        xscroll: x0scroll,
        width: width
      }
    }
    )

    // Third
    x0 = Math.round((this.canvas.baseWidth / 100) * 30)
    y0 = Math.round((this.canvas.baseHeight / 100) * 50)
    x1 = x0// Rectangle
    y1 = y0 - width// Isocèle
    x2 = x0 + width// Isocèle
    y2 = y0// Rectangle

    this.triangles.push({
      coords: [
        { x: x0, y: y0 },
        { x: x1, y: y1 },
        { x: x2, y: y2 }
      ],
      offset: { x: offsetX, y: offsetY },
      animation: {
        x: x0end,
        xscroll: x0scroll,
        width: width
      }
    }
    )
  }

  /******************************/
  /* Scroll Down Timeline       */
  /******************************/
  scrollDownTimeline () {
    if (!this.scrollStartValues) {
      this.scrollStartValues = {
        triangles: [
          { x: this.triangles[0].animation.x, border: 1 },
          { x: this.triangles[1].animation.x, border: 1 },
          { x: this.triangles[2].animation.x, border: 1 }
        ]
      }
    }

    var tl = new TimelineMax({ paused: true })

    tl.eventCallback('onStart', this.onScrollTimelineStart, [true], this)
    tl.eventCallback('onUpdate', this.onTimelineUpdate, [this.scrollStartValues], this)

    // Triangle 1
    tl.to(this.scrollStartValues.triangles[0], 1.6, { x: this.triangles[0].animation.xscroll, ease: Power1.easeOut }, 0)

    // Triangle 2
    tl.to(this.scrollStartValues.triangles[1], 1.6, { x: this.triangles[1].animation.xscroll, ease: Power4.easeOut }, 0)

    // Triangle 3
    tl.to(this.scrollStartValues.triangles[2], 1, { x: this.triangles[2].animation.xscroll, ease: Power4.easeOut }, 0)

    return tl
  }

  /******************************/
  /* Scroll Up Timeline       */
  /******************************/
  scrollUpTimeline () {
    if (!this.scrollStartValues) {
      this.scrollStartValues = {
        triangles: [
          { x: this.triangles[0].animation.x, border: 1 },
          { x: this.triangles[1].animation.x, border: 1 },
          { x: this.triangles[2].animation.x, border: 1 }
        ]
      }
    }

    var tl = new TimelineMax({ paused: true })

    tl.eventCallback('onStart', this.onScrollTimelineStart, [false], this)
    tl.eventCallback('onUpdate', this.onTimelineUpdate, [this.scrollStartValues], this)

    // Triangle 1
    tl.to(this.scrollStartValues.triangles[0], 1.6, { x: this.triangles[0].animation.x, ease: Power1.easeOut }, 0)

    // Triangle 2
    tl.to(this.scrollStartValues.triangles[1], 1.6, { x: this.triangles[1].animation.x, ease: Power4.easeOut }, 0)

    // Triangle 3
    tl.to(this.scrollStartValues.triangles[2], 1, { x: this.triangles[2].animation.x, ease: Power4.easeOut }, 0)

    return tl
  }

  /******************************/
  /* Open Timeline Start        */
  /******************************/
  onScrollTimelineStart (scrollDown) {
    var self = this
    self.isScrollDown = scrollDown
    self.isScrollAnimating = true
  }

  /******************************/
  /* Open Timeline              */
  /******************************/
  openTimeline () {
    var animateValues = {
      opacity: 0,
      triangles: [
        { x: this.triangles[0].coords[0].x, border: 0 },
        { x: this.triangles[1].coords[0].x, border: 0 },
        { x: this.triangles[2].coords[0].x, border: 0 }
      ]
    }

    var tl = new TimelineMax({ paused: true })

    tl.eventCallback('onComplete', this.onOpenTimelineComplete, null, this)
    tl.eventCallback('onUpdate', this.onTimelineUpdate, [animateValues], this)

    // Triangle 1
    tl.to(animateValues.triangles[0], 1.8, { x: this.triangles[0].animation.x, ease: Power2.easeOut }, 0)
    tl.to(animateValues.triangles[0], 1.6, { border: 1, ease: Power4.easeOut }, 0.1)

    // Triangle 2
    tl.to(animateValues.triangles[1], 2, { x: this.triangles[1].animation.x, ease: Power4.easeOut }, 0)
    tl.to(animateValues.triangles[1], 2.6, { border: 1, ease: Circ.easeOut }, 0.7)

    // Triangle 3
    tl.to(animateValues.triangles[2], 2, { x: this.triangles[2].animation.x, ease: Power4.easeOut }, 0)
    tl.to(animateValues.triangles[2], 1.6, { border: 1, ease: Power2.easeOut }, 0.5)

    // Triangle opacity
    tl.to(animateValues, 0.8, { opacity: 1, ease: Power1.easeOut }, 0)

    return tl
  }

  /******************************/
  /* Open Timeline Complete     */
  /******************************/
  onOpenTimelineComplete () {
    var self = this

    // Event handling
    self.unregisterEvents()
    self.registerEvents()
  }

  /******************************/
  /* Open Timeline Updates      */
  /******************************/
  onTimelineUpdate (values) {
    // Clear canvas
    var self = this
    self.canvas.ctx.clearRect(0, 0, self.canvas.baseWidth, self.canvas.baseHeight)

    // Draw triangles
    $.each(values.triangles, function (index, value) {
      var coords = [
        { x: value.x, y: self.triangles[index].coords[0].y },
        { x: value.x, y: self.triangles[index].coords[1].y },
        { x: value.x + self.triangles[index].animation.width, y: self.triangles[index].coords[2].y }
      ]

      // Draw triangle to new pos
      self.clippingPath(coords, self.cache.$img[0], self.triangles[index].offset.x, self.triangles[index].offset.y, values.opacity)

      // Reorder for line drawing
      if (index === 1) {
        self.swapArrayRow(coords, 0, 2)
      } else if (index === 2) {
        self.swapArrayRow(coords, 0, 2)
        self.swapArrayRow(coords, 1, 2)
      }

      // Draw triangle border
      self.borderPath(coords, value.border, 1)
    })
  }

  /******************************/
  /* Clipping Path              */
  /******************************/
  clippingPath (coord, img, x, y, alpha) {
    if (alpha === undefined) alpha = 1

    this.canvas.ctx.save()

    // Set alpha
    this.canvas.ctx.globalAlpha = alpha

    // Draw triangle
    this.canvas.ctx.beginPath()
    this.canvas.ctx.moveTo(coord[0].x, coord[0].y)
    this.canvas.ctx.lineTo(coord[1].x, coord[1].y)
    this.canvas.ctx.lineTo(coord[2].x, coord[2].y)
    this.canvas.ctx.closePath()

    // Create clip mask for image
    this.canvas.ctx.clip()

    // Draw image
    this.canvas.ctx.drawImage(img, x, y, this.imageWidth, this.imageHeight)

    this.canvas.ctx.restore()
  }

  /******************************/
  /* Border Path                */
  /******************************/
  borderPath (coord, position, alpha) {
    if (alpha === undefined) alpha = 1

    this.canvas.ctx.save()

    // Set alpha
    this.canvas.ctx.globalAlpha = alpha

    this.canvas.ctx.beginPath()
    this.canvas.ctx.moveTo(coord[0].x, coord[0].y)

    // Set style
    this.canvas.ctx.lineWidth = 1
    this.canvas.ctx.strokeStyle = '#ffffff'

    // Draw line
    var section = 1 / 3
    var t1 = (position) * 3
    t1 = Math.min(t1, 1)

    var t2 = (position - section) * 3
    t2 = Math.min(Math.max(t2, 0), 1)

    var t3 = (position - (section * 2)) * 3
    t3 = Math.min(Math.max(t3, 0), 1)

    this.canvas.ctx.lineTo(coord[0].x + (coord[1].x - coord[0].x) * t1, coord[0].y + (coord[1].y - coord[0].y) * t1)
    if (t1 === 1) this.canvas.ctx.lineTo(coord[1].x + (coord[2].x - coord[1].x) * t2, coord[1].y + (coord[2].y - coord[1].y) * t2)
    if (t2 === 1) this.canvas.ctx.lineTo(coord[2].x + (coord[0].x - coord[2].x) * t3, coord[2].y + (coord[0].y - coord[2].y) * t3)

    this.canvas.ctx.stroke()

    this.canvas.ctx.restore()
  }
}

export default TriangleAnimation
