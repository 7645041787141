import { Vue } from 'vue-property-decorator'
import { AxiosResponse } from 'axios'

declare var Quasar: any

export function deepCopy (from: any, to: any): void {
  for (const prop in from) {
    deepCopy2(from, to, prop)
  }
}

export function deepCopy2 (from: any, to: any, prop: string): void {
  if (from[prop] !== undefined) {
    if (to.hasOwnProperty(prop)) {
      if (to[prop] !== null && typeof to[prop] === 'object' && !Array.isArray(to[prop])) {
        for (const p in from[prop]) {
          deepCopy2(from[prop], to[prop], p)
        }
      } else {
        to[prop] = from[prop]
      }
    }
  }
}

export function getUrlParameter (name: string) {
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
  var results = regex.exec(location.search)
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

export function getUrlParameterAsInt (name : string) : number {
  var result = getUrlParameter(name)
  if (result !== '') {
    return Number.parseInt(result)
  }

  return 0
}

export function setUrlParameters (newParams : Map<string, string | null>) {
  var queryString = window.location.search

  const urlParams = new URLSearchParams(queryString)

  for (let [key, value] of newParams) {
    if (value === null) {
      urlParams.delete(key)
    } else {
      urlParams.set(key, value)
    }
  }

  queryString = urlParams.toString()
  var url = window.location.origin + window.location.pathname
  if (queryString === '?' || queryString === '') {
    history.pushState({}, '', url)
  } else {
    history.pushState({}, '', url + '?' + urlParams.toString())
  }
}

export function loadWhile (vue: Vue, message: string, task: () => Promise<any>): Promise<any> {
  vue.$q.loading.show({
    message: message,
    spinner: Quasar.components.QSpinnerGears,
    delay: 500
  })
  return task().finally(() => {
    vue.$q.loading.hide()
  })
}

export function notify (vue: Vue, message: string) {
  vue.$q.notify({
    message: message,
    position: 'top',
    classes: 'tm-notify',
    timeout: 3000
  })
}

export function notifyNegative (vue: Vue, message: string) {
  vue.$q.notify({
    type: 'negative',
    message: message,
    position: 'top',
    classes: 'tm-notify',
    timeout: 3000
  })
}

export function fullURL () {
  return new URL(window.location.href)
}

export function getInitials (menuName: String) {
  if (menuName) {
    const name = menuName.split(' ')
    if (name.length > 1) {
      return name[0].charAt(0) + '-' + name[name.length - 1].charAt(0)
    } else if (name.length === 1) {
      return name[0].charAt(0)
    }
  }
  return ''
}

export function debounce (callback: Function, wait: number = 500) {
  let timeout: number
  return function (this: any) {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      callback.apply(this)
    }, wait)
  }
}

export function oneDayMission (startDate: Date, endDate: Date) {
  const end = new Date(endDate)
  const start = new Date(startDate)
  return start.getDate() === end.getDate() &&
  start.getMonth() === end.getMonth() &&
  start.getFullYear() === end.getFullYear()
}

export function missionLocation (multipleCity: boolean, MissionCity: string, multipleCountry: boolean, MissionCountry: string, translationsCity: string, translationsCountry: string) {
  const city = multipleCity && !multipleCountry ? translationsCity : MissionCity
  const country = multipleCountry ? translationsCountry : MissionCountry
  return (city || '') +
  ((city && country) ? ', ' : '') +
  (country || '')
}

export function downloadFile (response : AxiosResponse<any>) {
  const fileNameHeader = 'x-suggested-filename'
  const suggestedFileName = response.headers[fileNameHeader]
  download(response, suggestedFileName)
}

export function downloadFileCsv (response : AxiosResponse<any>) {
  const fileNameHeader = 'content-disposition'
  const suggestedFileName = response.headers[fileNameHeader].split('=')[1]
  download(response, suggestedFileName)
}

function download (response : AxiosResponse<any>, suggestedFileName: string) {
  const url = window.URL.createObjectURL(new Blob([response.data], { type: response.data.type }))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('target', '_blank')
  link.setAttribute('download', suggestedFileName)
  document.body.appendChild(link)
  link.click()
  link.remove()
  setTimeout(() => window.URL.revokeObjectURL(url), 100)
}

export function arrayContains (a: any, b: any) {
  return Array.isArray(a) &&
    Array.isArray(b) &&
    b.every(val => a.indexOf(val) >= 0)
}

export function arrayEquals (a: any, b: any) {
  let first = Array.from(a).sort()
  let second = Array.from(b).sort()
  return Array.isArray(first) &&
    Array.isArray(second) &&
    first.length === second.length &&
    first.every((val, index) => val === second[index])
}
