































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { JobOffer } from '@/offer/JobOffer.model'
import { downloadFileCsv, loadWhile } from '@/generic/helpers'
import JobOfferListTranslations from '../../models/jobOffers/JobOfferList.translations'
import { CultureService } from '@/services/CultureService'
import { Culture } from '@/generic/models/Culture'
import { SelectOption } from '@/generic/models/SelectOption'
import AdminConsoleService from '../../services/AdminConsoleService'
import { JobOffersListFilter } from '../../models/jobOffers/JobOffersListFilter.model'
import { AdministrativeRegionHelper } from '@/generic/models/AdministrativeRegionHelper.model'

@Component
export default class AdminJobOffersListComponent extends Vue {
  @Prop() translations!: JobOfferListTranslations
  @Prop() private filterData!: Array<SelectOption>
  @Prop() private annualSalaryData!: Array<SelectOption>
  @Prop() private statusData!: Array<SelectOption>
  @Prop() private levelOfStudyData!: Array<SelectOption>
  private filters = new JobOffersListFilter()
  private events: SelectOption[] = []
  private companies: SelectOption[] = []
  private selectedEvent!: SelectOption | null
  private selectedCompanies: Array<SelectOption> = []
  private selectedLevelOfStudy!: SelectOption | null
  private selectedAnnualSalary!: SelectOption | null
  private selectedStatus: Array<SelectOption> = []
  private selectedAdministrativeRegions: Array<SelectOption> = []
  private jobOffers: Array<JobOffer> = []
  private administrativeRegions: Array<SelectOption> = AdministrativeRegionHelper.getRegion()
  private hasError = false
  private loaded = true
  private eventsLoaded = false
  private companiesLoaded = false
  private searchTerms = ''
  private animation = false
  private pageSizeOptions = [20, 50, 100]
  private pageSizeOldValue = 20
  private selectedFilterValue: SelectOption = { value: 0, label: '' }

  private mounted (): void {
    const preferredCulture = CultureService.getCulture()
    this.selectedStatus.push(this.statusData[1])
    if (preferredCulture === Culture.English) {
      this.filters.cultureName = 'en-US'
    } else {
      this.filters.cultureName = 'fr-CA'
    }
    this.loadEvents((a: boolean | void) => {})
    this.loadCompanies((a: boolean | void) => {})
    this.loadJobs()
  }

  private get animationOutClass () {
    return 'animated ' + (this.$q.screen.lt.md ? 'slideOutRight' : 'fadeOut')
  }

  private loadEvents (done: Function): Promise<void> {
    this.eventsLoaded = false
    this.hasError = false
    return AdminConsoleService.getDropdownEvents(this.filters.cultureName)
      .then((result) => {
        this.events = [{ label: this.translations.common.option.all, value: '' }, { label: this.translations.common.option.rec, value: '0-0' }]
        this.events = this.events.concat(result.data)
        this.eventsLoaded = true
        done(true)
      })
      .catch((error) => {
        if (error) {
          this.hasError = true
        }
        done(true)
      })
  }

  private loadCompanies (done: Function): Promise<void> {
    this.companiesLoaded = false
    this.hasError = false
    return AdminConsoleService.getAllCompanies()
      .then((result) => {
        this.companies = this.companies.concat(result.data)
        this.companiesLoaded = true
        done(true)
      })
      .catch((error) => {
        if (error) {
          this.hasError = true
        }
        done(true)
      })
  }

  private loadJobs (): void {
    this.loaded = false
    loadWhile(this, this.translations.common.message.loading, () =>
      this.loadJobOffers((a: boolean | void) => {}).then(() => {
        this.loaded = true
      })
    )
  }

  private loadJobOffers (done: Function): Promise<void> {
    this.hasError = false
    return AdminConsoleService.getJobOffers(this.filters)
      .then((response) => {
        this.jobOffers = []
        if (response.data.results.length > 0) {
          this.filters.total = response.data.totalCount
          Array.prototype.push.apply(this.jobOffers, response.data.results)
          done()
        } else {
          this.filters.total = 0
          done(true)
        }
      })
      .catch((error) => {
        if (error) {
          this.hasError = true
        }
        done(true)
      })
  }

  private onPageChange (newPage: number) {
    if (newPage !== this.filters.pageNumber) {
      this.filters.pageNumber = newPage
      this.onSearch()
    }
  }

  private onSearch () {
    this.loadJobs()
  }

  private resetFilters () {
    this.filters = new JobOffersListFilter()
    this.selectedEvent = null
    this.selectedStatus = []
    this.selectedLevelOfStudy = null
    this.selectedAnnualSalary = null
    this.selectedAdministrativeRegions = []
  }

  private advancedSearch () {
    this.resetPageNumber()
    this.loadJobs()
  }

  private onSearchTermsChange (newSearchTerms: string): void {
    this.filters.searchTerms = newSearchTerms
    if (this.filters.searchTerms.length > 4) {
      this.resetPageNumber()
    }
  }

  private eventSelected (eventSelected: SelectOption) {
    if (eventSelected && eventSelected.value !== '') {
      this.selectedEvent = eventSelected
      this.filters.selectedEvent = eventSelected.value.toString()
    } else {
      this.filters.selectedEvent = ''
      this.selectedEvent = null
    }
    this.$forceUpdate()
  }

  private levelOfStudySelected (levelOfStudy: SelectOption) {
    if (levelOfStudy && levelOfStudy.value !== '') {
      this.selectedLevelOfStudy = levelOfStudy
      this.filters.selectedLevelOfStudy = +levelOfStudy.value
    } else {
      this.filters.selectedLevelOfStudy = -1
      this.selectedLevelOfStudy = null
    }
    this.$forceUpdate()
  }

  private annualSalarySelected (annualSalary: SelectOption) {
    if (annualSalary && annualSalary.value !== '') {
      this.selectedAnnualSalary = annualSalary
      this.filters.selectedAnnualSalary = +annualSalary.value
    } else {
      this.filters.selectedAnnualSalary = -1
      this.selectedAnnualSalary = null
    }
    this.$forceUpdate()
  }

  private administrativeRegionsSelected (administrativeRegions: Array<SelectOption>) {
    this.filters.selectedAdministrativeRegions = []
    this.selectedAdministrativeRegions = []
    if (administrativeRegions.length > 0) {
      this.selectedAdministrativeRegions = administrativeRegions
      administrativeRegions.forEach(element => {
        if (element.value !== '') {
          this.filters.selectedAdministrativeRegions.push(element.value + '')
        }
      })
    }
    this.$forceUpdate()
  }

  private statusSelected (status: Array<SelectOption>) {
    this.filters.selectedStatus = []
    this.selectedStatus = []
    if (status !== null && status.length > 0) {
      this.selectedStatus = status
      status.forEach(element => {
        if (element.value !== '') {
          this.filters.selectedStatus.push(+element.value)
        }
      })
    }
    this.$forceUpdate()
  }

  private companiesSelected (compagnies: Array<SelectOption>) {
    this.filters.selectedCompanies = []
    this.selectedCompanies = []
    if (compagnies.length > 0) {
      this.selectedCompanies = compagnies
      compagnies.forEach(element => {
        if (element.value !== '') {
          this.filters.selectedCompanies.push(+element.value)
        }
      })
    }
    this.$forceUpdate()
  }

  private pageSizeChanged () {
    if (this.filters.pageSize !== this.pageSizeOldValue) {
      this.pageSizeOldValue = this.filters.pageSize
      this.resetPageNumber()
    }
  }

  private filterDataChanged () {
    if (this.filters.filterValue !== this.selectedFilterValue.value) {
      this.filters.filterValue = +this.selectedFilterValue.value
      this.resetPageNumber()
    }
  }

  private resetPageNumber () {
    this.filters.pageNumber = 1
  }

  private exportSearch () {
    this.loaded = false

    loadWhile(this, this.translations.common.message.loading, async () => {
      this.hasError = false

      try {
        const response = await AdminConsoleService.exportJobOffers(this.filters)
        downloadFileCsv(response)
      } catch (error) {
        if (error) {
          this.hasError = true
        }
      }

      this.loaded = true
    })
  }
}
