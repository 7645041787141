

































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { DailyAvailibility, TimeSlotsPerDay, TimeSlot } from '@/job-application/JobApplication.model'
import { CultureService } from '../services/CultureService'
import { SelectedIds } from '../generic/models/MITMAvailabilityExpansion.models'
import { arrayEquals, arrayContains } from '../generic/helpers'
import { EventTranslations } from './Event.transations'

@Component
export default class EventDateComponent extends Vue {
  @Prop() timeslots!: TimeSlotsPerDay
  @Prop() date!: Date
  @Prop() availabilities!: Array<number>
  @Prop() translations!: EventTranslations
  @Prop() color!: string
  private allDay : boolean | null = false

  public update (all: boolean) {
    this.allDay = all
    this.$forceUpdate()
  }

  public selectColor (timeslotId: number) : string {
    if (this.selected(timeslotId)) {
      return this.bgColor
    } else {
      return 'bg-mitm-light-grey'
    }
  }

  private mounted () {
    this.checkAllAvailabilities(this.availabilities)
  }

  private get timeslotIds () : Array<number> {
    if (this.timeslots) {
      return this.timeslots.timeSlots.map((ts) => ts.id)
    }

    return []
  }

  private get showTimeslots () : boolean {
    if (this.timeslots && this.timeslots.timeSlots) {
      return this.timeslots.timeSlots.length >= 0
    }

    return false
  }

  private get bgColor () {
    return 'bg-' + this.color
  }

  private getTimeslots () : TimeSlot[] {
    if (this.timeslots) {
      return this.timeslots.timeSlots
    }
    return []
  }

  private selected (id : number) : boolean {
    if (this.availabilities) {
      return this.availabilities.indexOf(id) >= 0
    }
    return false
  }

  @Watch('timeslots')
  private onTimeslotsChanged (val: TimeSlotsPerDay, oldVal: TimeSlotsPerDay) {
    this.checkAllAvailabilities(this.availabilities)
  }

  private checkAllAvailabilities (val: Array<number>) {
    if (arrayContains(val, this.timeslotIds)) {
      this.allDay = true
    } else {
      this.allDay = false
    }
  }

  private onAllDay (val: boolean, event: any) {
    if (val) {
      this.timeslotIds.forEach((id) => {
        if (this.availabilities.indexOf(id) === -1) {
          this.availabilities.push(id)
        }
      })
    } else {
      this.timeslotIds.forEach((id) => {
        let index = this.availabilities.indexOf(id)
        if (index >= 0) {
          this.availabilities.splice(index, 1)
        }
      })
    }
    this.updateavailabilities()
  }

  private selectTimeslot (id: number) {
    let index = this.availabilities.indexOf(id)
    if (index === -1) {
      this.availabilities.push(id)
      this.checkAllAvailabilities(this.availabilities)
    } else {
      this.availabilities.splice(index, 1)
      this.allDay = false
    }

    this.updateavailabilities()
  }

  private get currentDate () {
    let date = this.date
    let str = this.dateString(date, CultureService.getCultureCode(), { weekday: 'long', month: 'short', day: 'numeric' })
    return str[0].toUpperCase() + str.slice(1)
  }

  private get currentCity (): string {
    if (this.timeslots && this.timeslots.city) {
      return ' | ' + this.timeslots.city
    }
    return ''
  }

  private formatTime (date: Date) : string {
    return this.timeString(date, CultureService.getCultureCode(), { hour: 'numeric', minute: 'numeric' })
  }

  private dateString (date: any, locale: string, options: Intl.DateTimeFormatOptions) : string {
    if (!(date instanceof Date)) {
      date = new Date(date)
    }
    return date.toLocaleDateString(locale, options)
  }

  private timeString (date: any, locale: string, options: Intl.DateTimeFormatOptions) : string {
    if (!(date instanceof Date)) {
      date = new Date(date)
    }
    return date.toLocaleTimeString(locale, options)
  }

  private updateavailabilities () {
    this.$emit('update', this.availabilities)
    this.$forceUpdate()
  }
}
