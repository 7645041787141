import GtmMixin, { GtmEvent } from './GtmMixin'
import Component from 'vue-class-component'

@Component
export default class GtmEmployerMixin extends GtmMixin {
  private CandidateType (isSuggested: boolean) : string {
    return isSuggested ? 'recommended profile' : 'application'
  }

  protected onConfirmRecruiter (label: string) {
    this.callGtm(GtmEvent.Employer, 'confirm employer account', label)
  }
  protected onOpenProfile (isSuggested: boolean) {
    this.callGtm(GtmEvent.Employer, 'see candidate profile', this.CandidateType(isSuggested))
  }

  protected onAddFavorite (isSuggested: boolean) {
    this.callGtm(GtmEvent.Employer, 'add candidate to favorites', this.CandidateType(isSuggested))
  }

  protected onDownloadCv (isSuggested: boolean) {
    this.callGtm(GtmEvent.Employer, 'download cv candidate', this.CandidateType(isSuggested))
  }

  protected onEmailCandidate (isSuggested: boolean) {
    this.callGtm(GtmEvent.Employer, 'write to candidate', this.CandidateType(isSuggested))
  }

  protected onCreateJobOffer () {
    this.callGtm(GtmEvent.Employer, 'create job offer', 'nouvelle offre')
  }

  protected onPublishFirstOffer (label: string, confirmed : boolean) {
    this.callGtm(GtmEvent.Employer, 'publish first offer', label, confirmed)
  }

  protected onPublishJob (activeJobCount : number) {
    this.callGtm(GtmEvent.Employer, 'publish job offer', 'modifier offre', activeJobCount)
  }

  protected onUnpublishJob (publishDate : Date | null, expiryDate : Date | null) {
    let age = this.calculateAge(publishDate)
    let remaining = this.calculateAge(expiryDate) * -1

    this.callGtm(GtmEvent.Employer, 'unpublish job', remaining, age)
  }

  protected onSaveOffer (label : string, isConfirmed : boolean) {
    this.callGtm(GtmEvent.Employer, 'save offer', label, isConfirmed)
  }

  protected onSaveDraft (label : string, isConfirmed : boolean) {
    this.callGtm(GtmEvent.Employer, 'save as draft', label, isConfirmed)
  }

  protected onPreviewOffer (label : string, isConfirmed : boolean) {
    this.callGtm(GtmEvent.Employer, 'preview offer', label, isConfirmed)
  }

  protected onCompleteOfferStep (step : number, label : string, isConfirmed : boolean) {
    this.callGtm(GtmEvent.Employer, 'first offer complete step ' + step, label, isConfirmed)
  }

  protected onFillOffer (usingPortal : boolean, publishDate : Date | null) {
    let reason = usingPortal ? 'using portal' : 'outside portal'
    this.callGtm(GtmEvent.Employer, 'job is filled', reason, this.calculateAge(publishDate))
  }

  private calculateAge (from : Date | null) {
    if (from) {
      if (typeof from === 'string') {
        from = new Date(from)
      }
      let diff = Date.now().valueOf() - from.valueOf()
      return Math.floor(diff / (1000 * 60 * 60 * 24))
    }

    return 0
  }
}
