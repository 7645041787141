import { IRule } from './IRule'

export class IsLinkedinURL implements IRule {
  static regex=/^(?:https?:)?\/\/(?:[\w]+\.)?linkedin\.com\/(in|company|pub|school)\/([\w\-&_À-ÿ%]+)\/?$/
  getValidator (errorMessage: string, activate?: () => boolean): (value: string) => boolean|string {
    return (value: string): boolean|string => {
      if (activate === undefined || (activate && activate())) {
        if (value) {
          return !!value.match(IsLinkedinURL.regex) || errorMessage
        }
      }
      return true
    }
  }
}
