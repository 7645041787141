

















import { Component, Prop, Vue } from 'vue-property-decorator'
import MissionCompanyModel from '@/dashboard/missions/Mission.model'

@Component
export default class MissionSelectorComponent extends Vue {
    @Prop() value!: number[]
    @Prop() missions!: MissionCompanyModel[]
    @Prop() instructions!: string

    private get selectedMissions () : MissionCompanyModel[] {
      return this.missions.filter(m => this.value.some(v => v === m.id))
    }

    private set selectedMissions (missions : MissionCompanyModel[]) {
      this.value = missions ? missions.map(m => m.id) : []
      this.$emit('input', this.value)
    }
}
