
































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Expertise } from './Expertise'

@Component
export default class ExpertiseComponent extends Vue {
  @Prop() private expertise!: Expertise
  private animation = false

  private onIconMouseOver () {
    this.animation = true
  }

  private onIconMouseLeave () {
    this.animation = false
  }

  private goToExpertisePage (): void {
    window.location.href = this.expertise.url
  }
}
