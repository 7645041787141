




















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class MITMCallToActionComponent extends Vue {
  @Prop() link!: string
  @Prop() linkText!: string
  @Prop() target!: string
  @Prop({ default: '#000000' }) fillColor!: string

  private animation = false

  private onMouseOver () {
    this.animation = true
  }

  private onMouseLeave () {
    this.animation = false
  }
}
