











































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { ColleagueTranslations } from './Colleagues.translations'
import { CommonTranslations } from '@/generic/models/Common.translations'
import { AccountService } from '@/services/Account.service'
import { RecruiterService } from '@/services/RecruiterService'
import { Colleague } from './Colleague.model'
import MITMPromptComponent from '@/generic/components/MITMPrompt.vue'
import { ColleagueErrorTranslations } from '@/company/colleagues/ColleagueError.translations'
import { DialogResult } from '@/generic/models/DialogResult'
import { loadWhile } from '@/generic/helpers'
import { CultureService } from '../../services/CultureService'
import ColleagueLineComponent from './ColleagueLine.vue'
import AdminConsoleCompanyService from '@/admin/console/services/AdminConsoleCompanyService'

@Component({
  components: {
    'mitm-colleague-line': ColleagueLineComponent
  }
})
export default class ColleaguesComponent extends Vue {
  @Prop() translations !: ColleagueTranslations
  @Prop() commonTranslations !: CommonTranslations
  @Prop() errorMessages !: ColleagueErrorTranslations
  @Prop() value!: Colleague[]
  @Prop() companyId!: number
  @Prop(Boolean) edit!: boolean
  @Prop() currentUser!: number
  @Prop({ default: false }) private isAdmin!: boolean

  private hasError : boolean = false
  private errorMessage : string = ''
  private selectedColleagueId : number = -1
  private deleteColleague: boolean = false
  private seeRecruiterProfile: boolean = false

  private deleteRow (index: number) {
    let i = this.colleagues.length + index
    this.value.splice(i, 1)
  }

  private resend (email: string, index: number) {
    loadWhile(this, this.translations.message.invitingColleague, () => {
      return RecruiterService.reInviteColleagues(this.companyId, CultureService.getCultureCode(), email)
        .then((response) => {
          this.colleagues[index].resendComplete = true
          this.$forceUpdate()
        })
        .catch(() => {
          this.hasError = true
          this.errorMessage = this.translations.error.invitationFailed
        })
    })
  }

  private resendByAdmin (email: string, index: number) {
    loadWhile(this, this.translations.message.invitingColleague, () => {
      return AdminConsoleCompanyService.reInviteColleagues(this.companyId, CultureService.getCultureCode(), email)
        .then((response) => {
          this.colleagues[index].resendComplete = true
          this.$forceUpdate()
        })
        .catch(() => {
          this.hasError = true
          this.errorMessage = this.translations.error.invitationFailed
        })
    })
  }

  private disableRecruiterByAdmin () {
    loadWhile(this, this.translations.message.deactivatingAccount, () => {
      return AdminConsoleCompanyService.disableRecruiter(this.companyId, this.selectedColleagueId, this.deleteColleague)
        .then((result) => {
          this.value = result.data.colleagues
        })
        .catch((error) => {
          this.hasError = true
          this.errorMessage = this.errorMessages[error.response.data.message]
        })
    })
  }

  private onConfirm (result: DialogResult) {
    if (result === DialogResult.Ok) {
      this.hasError = false
      if (this.isAdmin) {
        this.disableRecruiterByAdmin()
      } else {
        loadWhile(this, this.translations.message.deactivatingAccount, () => {
          return AccountService.disableRecruiter(this.companyId, this.selectedColleagueId, this.deleteColleague)
            .then((result) => {
              this.value = result.data.colleagues
            })
            .catch((error) => {
              this.hasError = true
              this.errorMessage = this.errorMessages[error.response.data.message]
            })
        })
      }
    }
  }

  private revokeAccess (id: number, isInvited: boolean) {
    this.selectedColleagueId = id
    this.deleteColleague = isInvited;
    (this.$refs.confirmPrompt as MITMPromptComponent).open()
  }

  private get colleagues () {
    return this.value.filter((colleague : Colleague) => {
      return !colleague.new
    })
  }

  private get newUsers () {
    return this.value.filter((colleague : Colleague) => {
      return colleague.new
    })
  }

  private addRow () {
    if (this.value === undefined) {
      this.value = []
    }
    this.value.push({
      email: '',
      new: true
    })
  }

  private showRecruiter (id: number, isInvited: boolean) {
    this.$emit('show-recruiter', id)
  }
}
