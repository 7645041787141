






























































import { Component, Prop, Vue } from 'vue-property-decorator'
import { CommonTranslations } from '@/generic/models/Common.translations'
import MITMPromptComponent from '@/generic/components/MITMPrompt.vue'
import { ColleagueErrorTranslations } from '@/company/colleagues/ColleagueError.translations'
import { DialogResult } from '@/generic/models/DialogResult'
import { loadWhile } from '@/generic/helpers'
import { JobOffer } from '@/offer/JobOffer.model'
import AdminConsoleJobOfferService from '../../services/AdminConsoleJobOfferService'
import { Status } from '@/generic/models/Status'
import JobCardTranslations from '@/offer/summary/JobCard.translations'

@Component
export default class AdminJobOffersComponent extends Vue {
  @Prop() translations !: JobCardTranslations
  @Prop() commonTranslations !: CommonTranslations
  @Prop() errorMessages !: ColleagueErrorTranslations
  @Prop() confirmRemoveAccess !: string
  @Prop() jobOffers !: JobOffer[]

  private hasError : boolean = false
  private errorMessage : string = ''
  private selectedJobId : number = -1

  private getStatus (status : Status) {
    if (status === Status.Published) {
      return this.translations.jobCard.status.published
    } else if (status === Status.Closed) {
      return this.translations.jobCard.status.closed
    } else if (status === Status.Expired) {
      return this.translations.jobCard.status.expired
    } else if (status === Status.Archived) {
      return this.translations.jobCard.status.archived
    } else if (status === Status.AwaitingReview) {
      return this.translations.jobCard.status.awaitingReview
    } else if (status === Status.Reviewed) {
      return this.translations.jobCard.status.reviewed
    } else if (status === Status.ReadyToBePublished) {
      return this.translations.jobCard.status.readyToBePublished
    } else {
      return this.translations.jobCard.status.draft
    }
  }

  private deleteJobOffer (id: number) {
    this.selectedJobId = id;
    (this.$refs.confirmPrompt as MITMPromptComponent).open()
  }

  private onConfirm (result: DialogResult) {
    if (result === DialogResult.Ok) {
      this.hasError = false
      loadWhile(this, 'Deleting...', () => {
        return AdminConsoleJobOfferService.deleteOffer(this.selectedJobId)
          .then(() => {
            location.reload()
          })
          .catch((error) => {
            this.hasError = true
            this.errorMessage = this.errorMessages[error.response.data.message]
          })
      })
    }
  }

  private showJobOffer (id: number) {
    this.$emit('show-job-offer', id)
  }
}
