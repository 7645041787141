



















































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Menu } from '../model/Menu.model'
import AppMenuComponent from './AppMenu.vue'
import { FooterTranslations } from '@/generic/translations/Footer.translations'
import { ShoppingCartService } from '@/services/ShoppingCartService'

@Component
export default class AppLayoutComponent extends Vue {
  @Prop() private title!: string
  @Prop() private otherLanguageLabel!: string
  @Prop() private otherLanguageLink!: string
  @Prop() private companyName!: string
  @Prop() private companyLogo!: string
  @Prop() private signOutRedirect!: string
  @Prop() private menu!: Menu
  @Prop() private footerTranslations!: FooterTranslations
  @Prop() private about!: string
  @Prop() private contactUs!: string
  @Prop() private faq!: string
  @Prop() private homepageUrl!: string
  @Prop() private newsletterPage!: string

  private shoppingCartIsEmpty!: boolean
  private cartLoaded = false

  mounted () {
    ShoppingCartService.getIsShoppingCartEmpty()
      .then(response => {
        this.shoppingCartIsEmpty = response.data
      }).finally(() => {
        this.cartLoaded = true
      })
  }

  private addExtraPadding = false
  private open = false
  private view = this.$q.screen.lt.md ? 'lHh LpR lff' : 'lhh LpR lff'

  private onExpanded (): void {
    this.addExtraPadding = true
  }

  private onShrinked (): void {
    this.addExtraPadding = false
  }

  private onOpenMenu (): void {
    (this.$refs.menu as AppMenuComponent).openDrawer()
  }

  private onCloseMenu (event: any): void {
    var menu = (this.$refs.menu as AppMenuComponent)

    if (!menu.isMenuClosed() && !menu.$el.contains(event.target)) {
      menu.toggleMini()
    }
  }
}
