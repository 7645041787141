
















































































































import { Component, Prop, Vue, Mixins } from 'vue-property-decorator'
import { OfferPrerequisiteDto } from '@/offer/prerequisite/models/OfferPrerequisiteDto.model'
import { JobApplicationModel, JobApplicationMission, TimeSlot, Availibility } from './JobApplication.model'
import { ITranslatable } from '@/generic/models/ITranslatable'
import { loadWhile } from '@/generic/helpers'
import CandidateService from '@/services/CandidateService'
import { OfferPrerequisiteTranslations } from './JobApplication.translations'
import { OfferPrerequisiteData } from '@/offer/prerequisite/models/OfferPrerequisiteData.model'
import { CultureService } from '../services/CultureService'
import { Culture } from '@/generic/models/Culture'
import { SelectOption } from '@/generic/models/SelectOption'
import { QuestionType } from '@/generic/models/QuestionType'
import { QuestionAnswer } from '@/candidate/candidate-details/DetailResult'
import MITMPromptComponent from '@/generic/components/MITMPrompt.vue'
import { DialogResult } from '@/generic/models/DialogResult'
import { CandidateDetails } from '@/candidate/candidate-details/CandidateDetails.model'
import { dateFilter } from '@/generic/filters/Date'
import { SelectedIds } from '@/generic/models/MITMAvailabilityExpansion.models'
import GtmCandidateMixin from '@/gtm/GtmCandidate'
import MITMAvailabilityExpansionComponent from '../generic/components/MITMAvailabilityExpansion.vue'
import EventParticipationContainer from '@/event/EventParticipationContainer.vue'
import MissionService from '../services/Mission.service'

@Component({
  components: {
    'mitm-events': EventParticipationContainer
  }
})
export default class JobApplicationComponent extends Mixins(GtmCandidateMixin) {
  @Prop() prerequisites !: OfferPrerequisiteDto
  @Prop() title!: ITranslatable
  @Prop() candidate!: JobApplicationModel
  @Prop() translations !: OfferPrerequisiteTranslations
  @Prop() private prerequisiteData !: OfferPrerequisiteData
  @Prop() mission !: JobApplicationMission
  @Prop({ default: 0 }) private selectedMission!: number
  @Prop() candidateDetails !: CandidateDetails
  @Prop() private hasApplied!: boolean
  private hasError : boolean = false
  private errorMessage : string = ''
  private missionsLoaded: boolean = false
  private timeSlotsLoaded: boolean = false
  private details : CandidateDetails = { ...this.candidateDetails, ...this.candidate }
  private participatingMissions: Array<number> = []
  private isScaleType: boolean = false
  private timeSlots: Array<TimeSlot> = []
  private hasPreferences: boolean = false

  private created () {
    if (typeof this.selectedMission === 'string') {
      this.selectedMission = parseInt(this.selectedMission)
    }

    if (this.mission) {
      CandidateService.getAvailabilities(this.candidate.id, this.mission.id)
        .then(response => {
          this.candidate.selectedMissions = response.data
          if (!this.candidate.selectedMissions) {
            this.candidate.selectedMissions = {}
          }

          this.candidateDetails.missionsParticipating.forEach(missionId => {
            if (this.mission.id === missionId) {
              this.participatingMissions.push(missionId)
            }
          })

          if (this.selectedMission > 0 && this.participatingMissions.indexOf(this.selectedMission) === -1) {
            this.participatingMissions.push(this.selectedMission)
            if (this.participatingMissions.indexOf(this.mission.id) === -1) {
              this.participatingMissions.push(this.mission.id)
            }
          }
        })
        .finally(() => {
          this.missionsLoaded = true
        })
      MissionService.getMissionTimeSlots(this.mission.id)
        .then(result => {
          this.timeSlots = result.data
        })
        .finally(() => {
          this.timeSlotsLoaded = true
        })
    }
    CandidateService.getCandidateAvailabilityPreferences(this.candidate.id)
      .then(response => {
        if (response.data.preferences.days.length > 0) {
          response.data.preferences.days.forEach((day) => {
            day.periods.period.forEach((period) => {
              if (period) {
                this.hasPreferences = true
              }
            })
          })
        }
      })
  }

  private beforeMount () {
    if (!this.candidateDetails.questionAnswer || this.candidateDetails.questionAnswer.length === 0) {
      this.candidateDetails.questionAnswer = [{ answer: null } as QuestionAnswer, {} as QuestionAnswer]
    } else if (this.candidateDetails.questionAnswer.length < 2) {
      this.candidateDetails.questionAnswer.push({ answer: null } as QuestionAnswer)
    }
  }

  private onQuestion1ResponseChange (value: number): void {
    this.candidate.responseQuestionOne = value
    if (this.candidateDetails.questionAnswer) {
      this.candidateDetails.questionAnswer[0] = { answer: value } as QuestionAnswer
    }
    this.$forceUpdate()
  }

  private onQuestion2ResponseChange (value: number): void {
    this.candidate.responseQuestionTwo = value
    if (this.candidateDetails.questionAnswer) {
      this.candidateDetails.questionAnswer[1] = { answer: value } as QuestionAnswer
    }
    this.$forceUpdate()
  }

  private responseQuestionOne (): number|null {
    if (this.candidateDetails.questionAnswer && this.candidateDetails.questionAnswer.length > 0) {
      return this.candidateDetails.questionAnswer[0].answer
    }

    return null
  }

  private responseQuestionTwo (): number|null {
    if (this.candidateDetails.questionAnswer && this.candidateDetails.questionAnswer.length > 0) {
      return this.candidateDetails.questionAnswer[1].answer
    }

    return null
  }

  private question(index: number): string | null {
    // Not use anymore
    if (index < this.prerequisites.questions.length) {
      const preferredCulture = CultureService.getCulture()
      if (preferredCulture === Culture.English) {
        return this.prerequisites.questions[index].questionEn ? this.prerequisites.questions[index].questionEn : this.prerequisites.questions[index].questionFr
      }
      return this.prerequisites.questions[index].questionFr ? this.prerequisites.questions[index].questionFr : this.prerequisites.questions[index].questionEn
    }
    return null
  }

  private options(index: number): SelectOption[] | null {
    // Not use anymore
    if (index < this.prerequisites.questions.length) {
      if (this.prerequisites.questions[index].responseType === QuestionType.Scale) {
        this.isScaleType = true
        return this.prerequisiteData.scale
      }
      return this.prerequisiteData.yesNo
    }
    return null
  }

  private async validate () : Promise<boolean> {
    // const form = this.$refs.preselQuestionForm as any
    const mitmEvents = this.$refs.mitmEvents as EventParticipationContainer
    // let isValid = await form.validate()
    if (this.mission && mitmEvents) {
      return mitmEvents.validate()
    }
    return true
  }

  private applyOffer () {
    this.validate().then(isValid => {
      if (isValid) {
        let title = CultureService.getCulture() === Culture.English ? this.title.en : this.title.fr
        this.OnApplicationConfirmed(title);
        (this.$refs.confirmPrompt as MITMPromptComponent).open()
      }
    })
  }

  private applyRedirect (result: DialogResult) {
    if (result === DialogResult.Ok) {
      let title = CultureService.getCulture() === Culture.English ? this.title.en : this.title.fr
      this.OnApplicationSubmitted(title)
      loadWhile(this, this.translations.common.message.loading, () => CandidateService.applyToJobOffer(this.candidate)
        .then(response => {
          if (CultureService.getCulture() === Culture.English) {
            window.location.href = '/en/candidate?displayNotification=1'
          } else {
            window.location.href = '/fr/candidat?displayNotification=1'
          }
        })
        .catch(error => {
          if (error) {
            this.errorMessage = this.translations.errors[error.response.data.message]
            this.hasError = true
          }
        })
      )
    }
  }

  private getIsScaleType () {
    return this.isScaleType === true
  }
}
