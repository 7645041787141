import { Company } from '@/company/Company.model'
import { JobOffer } from '@/offer/JobOffer.model'
import { ActivityArea } from '@/activity-area/ActivityArea.model'
import { Logo } from '@/company/logo/Logo.model'
import { FileType } from '@/generic/models/FileType'

export class FirstOffer {
  public company: Company

  public offer: JobOffer

  public activityAreas: ActivityArea[]

  public culture: string

  public userId: number | null

  public inviteType: string

  public fillLater: boolean

  constructor (culture: string) {
    this.company = new Company()
    this.offer = new JobOffer()
    this.activityAreas = []
    this.culture = culture
    this.inviteType = ''
    this.userId = null
    this.fillLater = false
  }
}
