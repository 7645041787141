












import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class HeaderIconComponent extends Vue {
  @Prop() primaryColor!: string
  @Prop() secondaryColor!: string

  get primaryStyle () {
    return {
      'border-bottom-color': this.primaryColor
    }
  }

  get secondaryStyle () {
    return {
      'border-bottom-color': this.secondaryColor
    }
  }
}
