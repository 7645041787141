



























































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { JobOfferSummary } from '@/offer/summary/JobOfferSummary.model'
import { OfferService } from '@/services/OfferService'
import { getUrlParameter, setUrlParameters, loadWhile } from '@/generic/helpers'
import { WorkInfoData } from '@/offer/jobOfferWorkInfos/WorkInfoData.model'
import { RequirementData } from '@/offer/jobOfferRequirement/RequirementData.model'
import { SelectOption } from '@/generic/models/SelectOption'
import JobOfferCardTranslations from './jobOfferCard.translations'
import SearchTranslations from '@/search/search.translations'
import SearchComponent from '@/search/Search.vue'
import SearchParams from './SearchParams'

@Component
export default class JobOfferListComponent extends Vue {
  @Prop() currentCulture !: string
  @Prop() translations!: JobOfferCardTranslations
  @Prop() searchTranslations!: SearchTranslations
  @Prop() private userIsCandidate !: boolean
  @Prop() private salaryInfo!: WorkInfoData
  @Prop() private jobRequirements!: RequirementData
  @Prop() private languageProficienciesListFr !: Array<SelectOption>
  @Prop() private languageProficienciesListEn !: Array<SelectOption>
  @Prop() private experienceData !: Array<SelectOption>
  @Prop() private events!: {}
  @Prop({ default: false }) private hideTitle!: boolean
  @Prop({ default: false }) private hideJobOffers!: boolean
  @Prop({ default: '' }) private eventGuid!: string
  @Prop({ default: 20 }) private pageSize!: number
  @Prop() private seed!: string

  private jobsList: JobOfferSummary[] = []
  private hasError: boolean = false
  private errorMessage: string = ''
  private pageNumber = 1
  private totalCount = 0
  private memento : {
    value: string,
    selectedEventId: string
    selectedEventType: string
    selectedStudy: string
    selectedExperience: string,
    selectedProficiencyFr: string,
    selectedProficiencyEn: string,
    selectedSalaryRange: string,
    selectedInterviewType: boolean|null
  } = {
    value: '',
    selectedEventId: '',
    selectedEventType: '',
    selectedStudy: '',
    selectedExperience: '',
    selectedProficiencyFr: '',
    selectedProficiencyEn: '',
    selectedSalaryRange: '',
    selectedInterviewType: null
  }
  private selectedIcon: string = 'check_box_outline_blank'
  private showFavoritesJobs: boolean = false
  private localJobsStore: JobOfferSummary[] = []

  private handleError (error: any) {
    this.hasError = true
    if (error.response.status === 404) {
      this.errorMessage = this.translations.error.notFound
    } else if (error.response.status === 403) {
      this.errorMessage = this.translations.error.forbidden
    } else {
      this.errorMessage = this.translations.error.unknown
    }
  }

  private onPageChange (newPage: number) {
    if (newPage !== this.pageNumber) {
      this.pageNumber = newPage
      this.onSearch(this.memento)
    }
  }

  private onSearch ({ value, selectedEventId, selectedEventType, selectedStudy, selectedExperience, selectedProficiencyFr, selectedProficiencyEn, selectedSalaryRange, selectedInterviewType } : { value: string, selectedEventId: string, selectedEventType: string, selectedStudy: string, selectedExperience: string, selectedProficiencyFr: string, selectedProficiencyEn: string, selectedSalaryRange: string, selectedInterviewType: boolean|null }) {
    this.memento = {
      value,
      selectedEventId,
      selectedEventType,
      selectedStudy,
      selectedExperience,
      selectedProficiencyFr,
      selectedProficiencyEn,
      selectedSalaryRange,
      selectedInterviewType
    }
    this.setUrlParams(value, selectedEventId, selectedEventType, selectedStudy, selectedExperience, selectedProficiencyFr, selectedProficiencyEn, selectedSalaryRange, selectedInterviewType)
    loadWhile(this, this.translations.loading, () => {
      this.getNumberOfAllActiveJobsFromDb(selectedEventId, selectedEventType, selectedStudy, selectedExperience, selectedProficiencyFr, selectedProficiencyEn, selectedSalaryRange, selectedInterviewType)
      return this.getJobOffers(value, selectedEventId, selectedEventType, selectedStudy, selectedExperience, selectedProficiencyFr, selectedProficiencyEn, selectedSalaryRange, selectedInterviewType)
    })
  }

  created () {
    var vue = this

    window.addEventListener('popstate', function (event: any) {
      vue.search()
    }, false)
  }

  mounted () {
    if (!this.hideJobOffers) {
      this.search()
    }
  }

  private search () {
    loadWhile(this, this.translations.loading, () => {
      var params = this.getParamsFromUrl();
      (this.$refs['search'] as SearchComponent).setSearch(params.keyword, params.eventId, params.selectedStudy, params.selectedExperience, params.selectedProficiencyFr, params.selectedProficiencyEn, params.selectedSalaryRange, params.isVirtualInterview)
      this.memento = {
        value: params.keyword,
        selectedEventId: params.eventId,
        selectedEventType: params.eventType,
        selectedStudy: params.selectedStudy,
        selectedExperience: params.selectedExperience,
        selectedProficiencyFr: params.selectedProficiencyFr,
        selectedProficiencyEn: params.selectedProficiencyEn,
        selectedSalaryRange: params.selectedSalaryRange,
        selectedInterviewType: params.isVirtualInterview
      }
      this.getNumberOfAllActiveJobsFromDb(params.eventId, params.eventType, params.selectedStudy, params.selectedExperience, params.selectedProficiencyFr, params.selectedProficiencyEn, params.selectedSalaryRange, params.isVirtualInterview)
      return this.getJobOffers(params.keyword, params.eventId, params.eventType, params.selectedStudy, params.selectedExperience, params.selectedProficiencyFr, params.selectedProficiencyEn, params.selectedSalaryRange, params.isVirtualInterview)
    })
  }

  private getJobOffers (request: string, selectedEventId: string, selectedEventType: string, selectedStudy: string, selectedExperience: string, selectedProficiencyFr: string, selectedProficiencyEn: string, selectedSalaryRange: string, isVirtualInterview: boolean|null, maxItems: number = this.pageSize) {
    return OfferService.getAllActiveJobsFromDB(this.currentCulture,
      this.pageNumber,
      request,
      maxItems,
      this.seed,
      isVirtualInterview,
      Number(selectedEventId),
      Number(selectedEventType),
      null,
      selectedStudy === '' || selectedStudy === null ? null : Number(selectedStudy),
      selectedExperience === '' || selectedExperience === null ? null : Number(selectedExperience),
      selectedProficiencyFr === '' || selectedProficiencyFr === null ? null : Number(selectedProficiencyFr),
      selectedProficiencyEn === '' || selectedProficiencyEn === null ? null : Number(selectedProficiencyEn),
      selectedSalaryRange === '' || selectedSalaryRange === null ? null : Number(selectedSalaryRange))
      .then(result => {
        if (this.userIsCandidate) {
          this.initialiseCheckBox()
          this.jobsList = result.data.results.sort((a, b) => a.isFavorite && !b.isFavorite ? -1 : 1)
          this.localJobsStore = this.jobsList.filter((x) => x.isFavorite)
        } else {
          this.jobsList = result.data.results
        }
        if (request !== '') {
          this.totalCount = result.data.totalCount
        }
      })
      .catch(error => {
        this.handleError(error)
      })
  }

  private getNumberOfAllActiveJobsFromDb (eventId: string, eventType: string, selectedStudy: string, selectedExperience: string, selectedProficiencyFr: string, selectedProficiencyEn: string, selectedSalaryRange: string, isVirtualInterview: boolean|null) {
    return OfferService.getNumberOfAllActiveJobsFromDb(this.currentCulture, Number(eventId), Number(eventType), selectedStudy === '' || selectedStudy === null ? null : Number(selectedStudy),
      selectedExperience === '' || selectedExperience === null ? null : Number(selectedExperience),
      selectedProficiencyFr === '' || selectedProficiencyFr === null ? null : Number(selectedProficiencyFr),
      selectedProficiencyEn === '' || selectedProficiencyEn === null ? null : Number(selectedProficiencyEn),
      selectedSalaryRange === '' || selectedSalaryRange === null ? null : Number(selectedSalaryRange),
      isVirtualInterview)
      .then(result => {
        this.totalCount = result.data
      })
      .catch(error => {
        this.handleError(error)
      })
  }

  private getParamsFromUrl () : SearchParams {
    let interviewParam = getUrlParameter('it')
    const params : SearchParams = {
      keyword: getUrlParameter('kw'),
      missionId: '',
      eventId: getUrlParameter('ei'),
      eventType: getUrlParameter('et'),
      selectedStudy: getUrlParameter('st'),
      selectedExperience: getUrlParameter('se'),
      selectedProficiencyFr: getUrlParameter('spf'),
      selectedProficiencyEn: getUrlParameter('spe'),
      selectedSalaryRange: getUrlParameter('ssr'),
      isVirtualInterview: interviewParam === 'true' ? true : interviewParam === 'false' ? false : null
    }

    return params
  }

  private setUrlParams (keyword: string, eventId: string, eventType: string, selectedStudy: string, selectedExperience: string, selectedProficiencyFr: string, selectedProficiencyEn: string, selectedSalaryRange: string, isVirtualInterview: boolean|null) {
    const params = new Map<string, string|null>()
    if (keyword) {
      params.set('kw', keyword)
    } else {
      params.set('kw', null)
    }

    if (eventId) {
      params.set('ei', eventId)
    } else {
      params.set('ei', null)
    }

    if (eventType) {
      params.set('et', eventType)
    } else {
      params.set('et', null)
    }

    if (selectedStudy) {
      params.set('st', selectedStudy)
    } else {
      params.set('st', null)
    }

    if (selectedExperience) {
      params.set('se', selectedExperience)
    } else {
      params.set('se', null)
    }

    if (selectedProficiencyFr) {
      params.set('spf', selectedProficiencyFr)
    } else {
      params.set('spf', null)
    }

    if (selectedProficiencyEn) {
      params.set('spe', selectedProficiencyEn)
    } else {
      params.set('spe', null)
    }

    if (selectedSalaryRange) {
      params.set('ssr', selectedSalaryRange)
    } else {
      params.set('ssr', null)
    }

    if (isVirtualInterview !== null) {
      params.set('it', isVirtualInterview.toString())
    } else {
      params.set('it', null)
    }
    setUrlParameters(params)
  }

  showOnlyFavoritesJobs () {
    this.localJobsStore = this.jobsList.filter((x) => x.isFavorite)
    if (this.showFavoritesJobs) {
      this.initialiseCheckBox()
    } else {
      this.selectedIcon = 'check_box'
      this.showFavoritesJobs = true
    }
  }

  initialiseCheckBox () {
    this.showFavoritesJobs = false
    this.selectedIcon = 'check_box_outline_blank'
  }
}
