<template>
  <q-avatar
    style="background-color: #004A7C"
    text-color="white"
    size="25px"
  >
    ?
  </q-avatar>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class HelpIconComponent extends Vue {

}
</script>
