



















































import { Component, Prop, Vue } from 'vue-property-decorator'
import { SiteMenu, MenuSection } from '@/navigation/model/SiteMenu.model'
import { SiteMenuTranslations } from '@/navigation/model/SiteMenu.transations'

@Component
export default class ContentMobileMenuSectionComponent extends Vue {
  @Prop() private section!: MenuSection
  @Prop() private translations!: SiteMenuTranslations

  private navigate (link: string) {
    window.location.href = link
  }

  private sectionClass (index : number) {
    return {
      'Section_PageLastItem': (index === (this.section.menuPages.length - 1)),
      'Section_PageFirstItem': (index === 0)
    }
  }
}
