



















































import { Component, Prop, Vue } from 'vue-property-decorator'
import { AccountTranslations } from '@/authentication/account/Account.translations'
import { AuthenticationService } from '@/services/Authentication.service'
import { ValidatePasswordModel } from '@/authentication/password/ValidatePassword.model'

@Component
export default class ValidatePasswordComponent extends Vue {
  @Prop() private translations !: AccountTranslations
  @Prop() private user !: number
  private password : string = ''
  private errorMessage: string = ''
  private hasError : boolean = false
  private loading : boolean = false
  private openDialog : boolean = false

  private promise !: Promise<ValidatePasswordModel>

  public open () {
    this.openDialog = true
    this.$nextTick(() => {
      const passwordField = (this.$refs.password as any)
      this.password = ''
      passwordField.focus()
    })

    return new Promise<ValidatePasswordModel | undefined>((resolve, reject) => {
      this.$on('password-valid', (event : ValidatePasswordModel) => {
        resolve(event)
      })
      this.$on('hide', () => {
        resolve(undefined)
      })
    })
  }

  private submit () {
    this.loading = true
    this.hasError = false
    AuthenticationService.validatePassword(this.user, this.password)
      .then(response => {
        if (response.data) {
          this.$emit('password-valid', { password: this.password, valid: true });
          (this.$refs.validatePasswordDialog as any).hide()
        } else {
          this.errorMessage = this.translations.error.invalidPassword
          this.hasError = true
        }
      })
      .catch((error : any) => {
        this.errorMessage = this.translations.error[error.response.message]
        this.hasError = true
      })
      .finally(() => {
        this.loading = false
      })
  }
}
