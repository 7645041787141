











































































import { Component, Prop, Vue } from 'vue-property-decorator'
import MediaObject from '../models/MediaObject'
import MITMImageComponent from '@/generic/components/MITMImage.vue'

@Component({
  components: {
    'mitm-img': MITMImageComponent
  }
})
export default class ImageCardComponent extends Vue {
@Prop() image!: MediaObject
@Prop() titleColor!: string
@Prop() title!: string
@Prop() actionLink!: string
@Prop() actionText!: string
@Prop({ default: false }) linkOpenNewTab!: boolean
private animation = false

private onMouseOver () {
  this.animation = true
}

private onMouseLeave () {
  this.animation = false
}

private get hasImage () {
  return this.image && this.image.url
}
}
