import { IRule } from './IRule'

export class RequiredRule implements IRule {
  getValidator (errorMessage: string, activate?: () => boolean): (value: any) => boolean|string {
    return (value: any): boolean|string => {
      if (activate === undefined || (activate && activate())) {
        return (value !== undefined && value !== null && value !== '') || errorMessage
      }
      return true
    }
  }
}
