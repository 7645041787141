









































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { CompanyAdditionalInformationTranslations } from './AdditionalInformation.translations'
import { AdditionalInformation } from './AdditionalInformation.model'
import { IsNumber } from '@/generic/rules/IsNumber'
import { IsURL } from '@/generic/rules/IsURL'
import { IsFacebookURL } from '@/generic/rules/IsFacebookURL'
import { IsLinkedinURL } from '@/generic/rules/IsLinkedinURL'
import { IsTwitterURL } from '@/generic/rules/IsTwitterURL'
import { CommonErrorTranslations } from '@/generic/models/CommonError.translations'
import { MinimumRule } from '@/generic/rules/Minimum'
import { RequiredRule } from '@/generic/rules/Required'

@Component
export default class AdditionalInformationComponent extends Vue {
    @Prop() translations!: CompanyAdditionalInformationTranslations;
    @Prop() errorTranslations!: CommonErrorTranslations
    @Prop() value!:AdditionalInformation;
    @Prop() sectionNumber!:number;
    @Prop({ default: false }) disableEmployeeCount!:boolean;

    private rules: { [key: string]: Array<(value: any) => boolean | string> } = {
      isNumber: [
        new IsNumber().getValidator(this.errorTranslations.validNumber),
        new MinimumRule(1).getValidator(this.errorTranslations.validNumber),
        new RequiredRule().getValidator(this.errorTranslations.validNumber)
      ],
      validURL: [
        new IsURL().getValidator(this.errorTranslations.urlNotFound)
      ],
      validFacebook: [
        new IsFacebookURL().getValidator(this.errorTranslations.urlNotFound)
      ],
      validLinkedin: [
        new IsLinkedinURL().getValidator(this.errorTranslations.urlNotFound)
      ],
      validTwitter: [
        new IsTwitterURL().getValidator(this.errorTranslations.urlNotFound)
      ]
    }
}
