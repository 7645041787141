
































































import { Component, Prop, Vue } from 'vue-property-decorator'
import MediaObject from '../models/MediaObject'
import { Alignment } from '../models/Alignment'
import MITMImageComponent from '@/generic/components/MITMImage.vue'

@Component({
  components: {
    'mitm-img': MITMImageComponent
  }
})
export default class LargeSharedContentComponent extends Vue {
  @Prop() image!: MediaObject
  @Prop() title!: string
  @Prop() subTitle!: string
  @Prop() actionLink!: string
  @Prop() actionText!: string
  @Prop() textColor!:string
  @Prop() iconColor!:string
  @Prop() alignment!: Alignment

  private animation = false

  private onMouseOver () {
    this.animation = true
  }

  private onMouseLeave () {
    this.animation = false
  }

  private get imageAlignment () {
    if (this.$q.screen.lt.md) {
      return {
        'order-first': true
      }
    }
    if (Number(this.alignment) === Alignment.Right) {
      return {
        'order-last': true
      }
    } else {
      return {
        'order-first': true
      }
    }
  }

  private get titleStyle () {
    return {
      color: this.textColor
    }
  }

  private get hasImage () {
    return this.image && this.image.url
  }
}
