// classList (used in SVGIcon.vue)
import 'classlist-polyfill'

// trunc (used in QSelect)
if (!Math.trunc) {
  Math.trunc = (v) => {
    v = +v
    if (!isFinite(v)) return v
    return (v - v % 1) || (v < 0 ? -0 : v === 0 ? v : 0)
  }
}
