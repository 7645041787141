


























import { Component, Prop, Vue } from 'vue-property-decorator'
import { JobApplicationMission, TimeSlot } from '@/job-application/JobApplication.model'
import EventParticipationComponent from './EventParticipation.vue'
import { SelectedIds } from '../generic/models/MITMAvailabilityExpansion.models'
import { EventTranslations } from './Event.transations'

@Component({
  components: {
    'mitm-event': EventParticipationComponent
  }
})
export default class EventParticipationContainer extends Vue {
  @Prop() mission !: JobApplicationMission
  @Prop() availabilities!: SelectedIds
  @Prop() translations!: EventTranslations
  @Prop() selectedMission!: number
  @Prop() participations!: Array<number>
  @Prop() timeSlots!: Array<TimeSlot>

  private ready: boolean = false

  private mounted () {
    if (this.availabilities[this.mission.id] === undefined) {
      this.availabilities[this.mission.id] = []
    }
    this.ready = true
  }

  private isParticipating (missionId: number) {
    if (this.participations) {
      return this.participations.indexOf(missionId) >= 0
    }

    return false
  }
  private onAddAvailabilities (missionId: number, timeslots: Array<number>) {
    this.$forceUpdate()
  }
  public validate(): boolean {
    const isValid = (this.$refs.evtParticipation as EventParticipationComponent).validate()
    this.$forceUpdate()
    return isValid
  }
}
