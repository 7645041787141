import { BASE_API } from './api-common'
import { FirstOffer } from '@/offer/first/FirstOffer.model'
import { AxiosPromise } from 'axios'
import { JobOffer } from '@/offer/JobOffer.model'
import { JobOfferSummary } from '@/offer/summary/JobOfferSummary.model'
import { EditOffer } from '@/offer/edit/EditOffer.model'
import { CreateOfferResponse } from '@/offer/edit/CreateOfferResponse.model'
import { PublishOfferResult } from '@/offer/edit/PublishOfferResult.model'
import { SearchResults } from '@/offer/job-offer-list/SearchResults.model'
import { BasicCompanyInfo } from '@/company/basic-info/BasicInfo.model'
import { Status } from '@/generic/models/Status'

export class OfferService {
  public static createFirstOffer (model: FirstOffer): AxiosPromise<any> {
    return BASE_API.post('offer/createFirstOffer', model)
  }

  public static createOffer (model: EditOffer, isDraftEditing : boolean): AxiosPromise<CreateOfferResponse> {
    return BASE_API.post('offer/add', model, {
      params: {
        isDraftEditing: isDraftEditing
      }
    })
  }

  public static editOffer (offerId: number, model: EditOffer, isDraftEditing : boolean): AxiosPromise<any> {
    return BASE_API.put(`offer/update/${offerId}`, model, {
      params: {
        isDraftEditing: isDraftEditing
      }
    })
  }

  public static updateStatus (offerId: number, status: Status): AxiosPromise<any> {
    return BASE_API.post('offer/update-status', {}, {
      params: {
        jobOfferId: offerId,
        status: status
      }
    })
  }

  public static publishOffer (offerId: number): AxiosPromise<PublishOfferResult> {
    return BASE_API.put(`offer/publish/${offerId}`)
  }

  public static republishOffer (offerId: number, currentCulture : string): AxiosPromise<JobOffer> {
    return BASE_API.put('offer/republish', {}, {
      params: {
        id: offerId,
        cultureCode: currentCulture
      }
    })
  }

  public static extendOffer (offerId: number): AxiosPromise<JobOffer> {
    return BASE_API.put(`offer/extend/${offerId}`)
  }

  public static getOffer (offerId: number, currentCulture: string): AxiosPromise<JobOffer> {
    return BASE_API.get(`offer/get/${offerId}`, {
      params: {
        currentCulture: currentCulture
      }
    })
  }

  public static getCompanyInfo (userId: number): AxiosPromise<BasicCompanyInfo> {
    return BASE_API.get('offer/companyInfo', {
      params: {
        userId: userId
      }
    })
  }

  public static getActiveJobCount (companyId : number) {
    return BASE_API.get('offer/count', {
      params: {
        companyId: companyId
      }
    })
  }

  public static getLastActiveJob (companyId : number, currentCulture : string):AxiosPromise<Array<JobOfferSummary>> {
    return BASE_API.get('offer/last', {
      params: {
        companyId: companyId,
        currentCulture: currentCulture
      }
    })
  }

  public static getCountOfferByStatus (companyId : number, eventId: number, eventType: number):AxiosPromise<any> {
    return BASE_API.get('offer/countOfferByStatus', {
      params: {
        companyId: companyId,
        eventId: eventId,
        eventType: eventType
      }
    })
  }

  public static getAllMyJobs (companyId : number, currentCulture : string):AxiosPromise<Array<JobOffer>> {
    return BASE_API.get('offer/getAllMyJobs', {
      params: {
        companyId: companyId,
        currentCulture: currentCulture
      }
    })
  }

  public static getAllMyActiveJobs (companyId : number, currentCulture : string):AxiosPromise<Array<JobOffer>> {
    return BASE_API.get('offer/getAllMyActiveJobs', {
      params: {
        companyId: companyId,
        currentCulture: currentCulture
      }
    })
  }

  public static getAllMyDraftJobs (companyId : number, currentCulture : string):AxiosPromise<Array<JobOffer>> {
    return BASE_API.get('offer/getAllMyDraftJobs', {
      params: {
        companyId: companyId,
        currentCulture: currentCulture
      }
    })
  }

  public static getAllMyAwaitingReviewJobs (companyId : number, currentCulture : string):AxiosPromise<Array<JobOffer>> {
    return BASE_API.get('offer/getAllMyAwaitingReviewJobs', {
      params: {
        companyId: companyId,
        currentCulture: currentCulture
      }
    })
  }

  public static getAllMyReadyToPublishJobs (companyId : number, currentCulture : string):AxiosPromise<Array<JobOffer>> {
    return BASE_API.get('offer/getAllMyReadyToPublishJobs', {
      params: {
        companyId: companyId,
        currentCulture: currentCulture
      }
    })
  }

  public static getAllMyClosedJobs (companyId : number, currentCulture : string):AxiosPromise<Array<JobOffer>> {
    return BASE_API.get('offer/getAllMyClosedJobs', {
      params: {
        companyId: companyId,
        currentCulture: currentCulture
      }
    })
  }

  public static getAllMyArchivedJobs (companyId : number, currentCulture : string):AxiosPromise<Array<JobOffer>> {
    return BASE_API.get('offer/getAllMyArchivedJobs', {
      params: {
        companyId: companyId,
        currentCulture: currentCulture
      }
    })
  }

  public static fill (companyId: number, jobId: number, withTm: boolean, currentCulture: string): AxiosPromise<JobOffer> {
    return BASE_API.put('offer/fillOffer', null, {
      params: {
        companyId: companyId,
        jobId: jobId,
        withTm: withTm,
        currentCulture: currentCulture
      }
    })
  }

  public static getAllActiveJobsFromDB (currentCulture: string,
    pageNumber: number,
    keywords: string,
    pageCount: number,
    seed: string,
    interviewVirtual: boolean|null,
    eventId: number = 0,
    eventType: number = 0,
    selectedActivityArea: string|null,
    selectedStudy: number|null,
    selectedExperience: number|null,
    selectedProficiencyFr: number|null,
    selectedProficiencyEn: number|null,
    selectedSalaryRange: number|null): AxiosPromise<SearchResults> {
    return BASE_API.get('offer/getAllActiveJobOffers', {
      params: {
        currentCulture: currentCulture,
        pageNumber: pageNumber,
        keywords: keywords,
        pageCount: pageCount,
        seed: seed,
        eventId: eventId,
        eventType: eventType,
        selectedActivityArea: selectedActivityArea,
        selectedStudy: selectedStudy,
        selectedExperience: selectedExperience,
        selectedProficiencyFr: selectedProficiencyFr,
        selectedProficiencyEn: selectedProficiencyEn,
        selectedSalaryRange: selectedSalaryRange,
        interviewVirtual: interviewVirtual
      }
    })
  }

  public static getNumberOfAllActiveJobsFromDb (currentCulture: string,
    eventId: number = 0,
    eventType: number = 0,
    selectedStudy: number|null,
    selectedExperience: number|null,
    selectedProficiencyFr: number|null,
    selectedProficiencyEn: number|null,
    selectedSalaryRange: number|null,
    selectedInterviewType: boolean|null): AxiosPromise<number> {
    return BASE_API.get('offer/getNumberOfAllActiveJobsFromDb', {
      params: {
        currentCulture: currentCulture,
        eventId: eventId,
        eventType: eventType,
        selectedStudy: selectedStudy,
        selectedExperience: selectedExperience,
        selectedProficiencyFr: selectedProficiencyFr,
        selectedProficiencyEn: selectedProficiencyEn,
        selectedSalaryRange: selectedSalaryRange,
        interviewVirtual: selectedInterviewType
      }
    })
  }

  public static getMissionOffers (currentCulture: string, pageNumber: number, pageSize: number, missionId: string): AxiosPromise<SearchResults> {
    return BASE_API.get('offer/getMissionOffers', {
      params: {
        currentCulture: currentCulture,
        pageNumber: pageNumber,
        pageSize: pageSize,
        missionId: missionId
      }
    })
  }

  public static deleteOffer (offerId: number): AxiosPromise<void> {
    return BASE_API.delete(`offer/deleteOffer/${offerId}`)
  }

  public static unpublishOffer (offerId: number): AxiosPromise<JobOffer> {
    return BASE_API.delete(`offer/unpublishOffer/${offerId}`)
  }

  public static archiveOffer (companyId: number, offerId: number): AxiosPromise<JobOffer> {
    return BASE_API.put('offer/archiveOffer', null, {
      params: {
        companyId: companyId,
        jobId: offerId
      }
    })
  }

  public static getUserApplicationStatus (offerId: number): AxiosPromise<boolean> {
    return BASE_API.get('offer/getUserApplicationStatus', {
      params: {
        offerId: offerId
      }
    })
  }
}
