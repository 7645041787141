import { IRule } from './IRule'

export class MaxLength implements IRule {
  private max: number

  constructor (max: number) {
    this.max = max
  }

  getValidator (errorMessage: string, activate?: () => boolean): (value: any) => boolean|string {
    return (value: any): boolean|string => {
      if (activate === undefined || (activate && activate())) {
        return value.length <= this.max || errorMessage
      }
      return true
    }
  }
}
