export class AdministrativeRegionHelper {
  public static getRegion () {
    return [
      { value: 'AbitibiTemiscamingue', label: 'Abitibi-Témiscamingue' },
      { value: 'BasSaintLaurent', label: 'Bas-Saint-Laurent' },
      { value: 'CapitaleNationale', label: 'Capitale-Nationale' },
      { value: 'CentreDuQuebec', label: 'Centre-Du-Quebec' },
      { value: 'ChaudiereAppalaches', label: 'Chaudière-Appalaches' },
      { value: 'CoteNord', label: 'Côte-Nord' },
      { value: 'Estrie', label: 'Estrie' },
      { value: 'GaspesieIlesDeLaMadeleine', label: 'Gaspesie-Îles-de-la-Madeleine' },
      { value: 'Lanaudiere', label: 'Lanaudière' },
      { value: 'Laurentides', label: 'Laurentides' },
      { value: 'Laval', label: 'Laval' },
      { value: 'Mauricie', label: 'Mauricie' },
      { value: 'Montreal', label: 'Montréal' },
      { value: 'Monteregie', label: 'Montérégie' },
      { value: 'NordDuQuebec', label: 'Nord-du-Quebec' },
      { value: 'Outaouais', label: 'Outaouais' },
      { value: 'SaguenayLacSaintJean', label: 'Saguenay-Lac-Saint-Jean' }
    ]
  }
}
