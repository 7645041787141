












import { Component, Prop, Vue } from 'vue-property-decorator'
import MediaObject from '@/content/models/MediaObject'
import MITMCopyrightComponent from './MITMCopyright.vue'

@Component({
  components: {
    'mitm-copyright': MITMCopyrightComponent
  }
})
export default class MITMImageComponent extends Vue {
  @Prop() private image!: MediaObject
  @Prop({ default: 0 }) private maxSize!: number

  private get hasCopyright () {
    return !!this.image.copyright
  }

  private get imageUrl () {
    if (this.maxSize > 0) {
      return this.image.url + '?maxsidesize=' + this.maxSize
    }

    return this.image.url
  }
}
