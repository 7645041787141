import { BASE_API } from './api-common'
import { AxiosPromise } from 'axios'

export class HtmlSanitizerService {
  public static sanitize (text: string): AxiosPromise<string> {
    return BASE_API.post('htmlSanitizer/sanitize', {
      text
    })
  }
}
