







































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class MITMInputComponent extends Vue {
  @Prop() private placeholder!: string
  @Prop({ default: true }) private floatPlaceholder!: boolean
  @Prop() private type!: string
  @Prop() private value!: string|number
  @Prop() private rules!: Array<(value: string) => boolean | string>
  @Prop() private prefix!: string
  @Prop() private disable!: boolean
  @Prop() private mask!: string
  @Prop({ default: false }) private onlyFloatPlaceHolder!: boolean
  @Prop({ default: '' }) private dataTest!: string
  @Prop({ default: false }) private noValidation!: boolean
  @Prop({ default: false }) private iconOnRight!: boolean
  @Prop({ default: false }) private square!: boolean
  @Prop({ default: true }) private outlined!: boolean
  private visible = this.type !== 'password'
  private isSvg = this.type === 'password' || this.type === 'email'
  private errorMessage = ''

  @Watch('type')
  typeChanged () {
    // obligatoire dom vue
    this.visible = this.type !== 'password'
    this.isSvg = this.type === 'password' || this.type === 'email'
  }

  private get label () {
    return ((this.localValue && this.floatPlaceholder) ||
             this.onlyFloatPlaceHolder) ? this.placeholder : ''
  }

  private get localValue (): string {
    return typeof this.value === 'number' ? this.value.toString() : this.value
  }

  private set localValue (value: string) {
    this.$emit('input', typeof this.value === 'number' ? +value : value)
  }

  public focus () {
    (this.$refs.qinput as any).focus()
  }

  public resetValidation () {
    (this.$refs.qinput as any).resetValidation()
  }

  private hasError (): boolean {
    if (this.$refs.qinput) {
      return (this.$refs.qinput as any).hasError
    }
    return false
  }

  private onInput (event: any) {
    this.$emit('input', event)
  }

  private onKeyUp (event: any) {
    this.$emit('keyup', event)
  }

  private icon () {
    if (this.type === 'password') {
      return 'ICON_KEY.svg'
    } else if (this.type === 'email') {
      return 'ICON_mail.svg'
    } else if (this.type === 'urlWeb') {
      return 'ICON_link_website.svg'
    } else if (this.type === 'urlLinkedIn') {
      return 'ICON_link_linkedin.svg'
    } else if (this.type === 'urlFacebook') {
      return 'ICON_link_facebook.svg'
    } else if (this.type === 'urlTwitter') {
      return 'ICON_link_twitter.svg'
    } else if (this.type === 'search') {
      return 'Search.svg'
    }
  }

  private usesIcon () {
    return ['email', 'password', 'urlWeb', 'urlLinkedIn', 'urlFacebook', 'urlTwitter', 'search'].includes(this.type)
  }
}
