












































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import MeetingCardTranslations from '../../models/meetings/MeetingCard.translations'
import { CultureService } from '@/services/CultureService'
import { Culture } from '@/generic/models/Culture'
import { FollowUpStatus, Meeting } from '@/dashboard/meeting-list/models/Meeting.model'
import { SelectOption } from '@/generic/models/SelectOption'

@Component
export default class AdminMeetingCardComponent extends Vue {
  @Prop() private meeting !: Meeting
  @Prop() statusData!: Array<SelectOption>
  @Prop() salaryData!: Array<SelectOption>
  @Prop() private translations !: MeetingCardTranslations
  private seeDetails: boolean = false
  private offerHref: string = ''
  private companyHref: string = ''
  private candidateHref: string = ''
  private culture: string = CultureService.getCulture()
  private followUpStatus = {
    'chipColor': 'mitm-light-red',
    'chipTextColor': 'mitm-red',
    'chipLabel': 'close',
    'status': this.statusData[this.meeting.followUpStatus || FollowUpStatus.NotSelected].label,
    'statusColor': 'text-mitm-light-red'
  }
  private needReloading: boolean = false

  mounted () {
    if (this.culture === Culture.English) {
      this.offerHref = `${window.location.origin}/en/admin/console-edit-job-offer/`
      this.companyHref = `${window.location.origin}/en/admin/console-company-modification/`
      this.candidateHref = `${window.location.origin}/en/admin/console-edit-candidate/`
    } else {
      this.offerHref = `${window.location.origin}/fr/admin/console-modification-offre/`
      this.companyHref = `${window.location.origin}/fr/admin/console-modification-entreprise/`
      this.candidateHref = `${window.location.origin}/fr/admin/console-modification-candidat/`
    }

    if (this.meeting.followUpStatus !== undefined && this.meeting.followUpStatus !== null) {
      this.updateFollowUpStatus(this.meeting.followUpStatus)
    }
  }

  private getFollowUpStatus () {
    return this.followUpStatus
  }

  private updateFollowUpStatus (followUpStatus: FollowUpStatus) {
    var openStatus:number[] = [
      FollowUpStatus.AcceptedInPool,
      FollowUpStatus.SelectedForFirstInterview,
      FollowUpStatus.SelectedForSecondInterview,
      FollowUpStatus.WaitingForDocuments,
      FollowUpStatus.InterviewPostponed,
      FollowUpStatus.JobPromise
    ]

    if (openStatus.includes(followUpStatus)) {
      this.followUpStatus = {
        'chipColor': 'brand',
        'chipTextColor': 'white',
        'chipLabel': 'open',
        'status': this.statusData[followUpStatus].label,
        'statusColor': 'text-brand'
      }
    } else if (followUpStatus === FollowUpStatus.OfferAcceptedByCandidate) {
      this.followUpStatus = {
        'chipColor': 'mitm-light-green',
        'chipTextColor': 'mitm-green',
        'chipLabel': 'open',
        'status': this.statusData[followUpStatus].label,
        'statusColor': 'text-brand'
      }
    }
  }

  private get getJobOfferHref () : string {
    return `${this.offerHref}${this.meeting.jobOfferId}`
  }

  private get getCompanyHref () : string {
    return `${this.companyHref}${this.meeting.companyId}`
  }

  private get getCandidateHref () : string {
    return `${this.candidateHref}${this.meeting.candidateId}`
  }

  private toggleDetailsDialog () : void {
    this.seeDetails = !this.seeDetails
  }

  private toggleDialog (meeting : Meeting) : void {
    this.$emit('toogle-edit-dialog', meeting)
  }

  private updateFollowUpNeededAfterSave () : void {
    this.needReloading = true
  }

  private hide () {
    if (this.needReloading) {
      this.$emit('hide')
      this.needReloading = false
    }
  }
}

