
























import { Component, Prop, Vue } from 'vue-property-decorator'
import SignOutService from '@/services/SignOutService'

@Component
export default class UserMenuComponent extends Vue {
  @Prop() companyName !: string
  @Prop() redirectUrl !: string
  @Prop() companyLogo !: string
}
