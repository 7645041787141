












import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class HamburgerBtnComponent extends Vue {
  @Prop() private isOpen!: Boolean

  private toggle (): void {
    this.isOpen = !this.isOpen
    this.$emit('toggle')
  }
}
