




















































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { RequirementTranslations } from './Requirement.translations'
import { CommonTranslations } from '@/generic/models/Common.translations'
import { PublicationLanguage } from '@/generic/models/PublicationLanguage'
import { RequirementDto } from './RequirementDto.model'
import { RequirementData } from './RequirementData.model'
import { Language } from '@/generic/models/Language.model'
import { LanguageHelper } from '@/generic/models/LanguageHelper.model'
import { values } from 'cypress/types/lodash'

@Component
export default class JobOfferRequirementComponent extends Vue {
  @Prop() private translations!: RequirementTranslations
  @Prop() private commonTranslations!: CommonTranslations
  @Prop() private value!: RequirementDto
  @Prop() private requirementData !: RequirementData
  @Prop() private language!: PublicationLanguage

  private languageList: string[] = []

  private mounted (): void {
    this.languageList = LanguageHelper.getAllLanguage(true)
    if (this.value.otherLanguage == null) {
      this.value.otherLanguage = this.languageList[0]
    }
  }

  public translate (language: string) {
    if (language === 'addOtherLanguage') {
      return this.translations.field.addOtherLanguage
    }
    if (language === Language.arabic) {
      return this.translations.field.arabic
    }
    if (language === Language.spanish) {
      return this.translations.field.spanish
    }
    if (language === Language.hindi) {
      return this.translations.field.hindi
    }
    if (language === Language.mandarin) {
      return this.translations.field.mandarin
    }
    if (language === Language.portuguese) {
      return this.translations.field.portuguese
    }
    if (language === Language.other) {
      return this.translations.field.other
    }
  }

  private showTextBox(value: string) {
    let select = document.getElementsByClassName('language-proficiency-select')[0]
    let container = document.getElementsByClassName('language-proficiency-textbox-container')[0]
    if (value === 'other') {
      select?.classList.add('hidden')
      container?.classList.remove('hidden')
      this.value.otherLanguage = ''
    } else {
      select?.classList.remove('hidden')
      container?.classList.add('hidden')
    }
  }

  private removeTextBox() {
    document.getElementsByClassName('language-proficiency-select')[0].classList.remove('hidden')
    document.getElementsByClassName('language-proficiency-textbox-container')[0].classList.add('hidden')
    this.value.otherLanguage = this.languageList[0]
  }
}

