




































































































































































































import { Component, Prop, Vue, Mixins } from 'vue-property-decorator'
import { CreateProfileTranslations } from './CreateProfile.translations'
import { CreateProfileModel } from './CreateProfile.model'
import { SelectOption } from '@/generic/models/SelectOption'
import { ProfileCandidateService } from '@/services/ProfileCandidateService'
import { AxiosResponse } from 'axios'
import { deepCopy, getUrlParameter, loadWhile, notifyNegative } from '@/generic/helpers'
import { CultureService } from '@/services/CultureService'
import { Culture } from '@/generic/models/Culture'
import GtmCandidateMixin from '@/gtm/GtmCandidate'
import { LanguageProficiency } from '@/generic/models/LanguageProficiency.model'
import { ActivityArea } from '@/activity-area/ActivityArea.model'
import { CandidateLanguageProficiency } from '@/generic/models/CandidateLanguageProficiency.model'

@Component
export default class CreateComponent extends Mixins(GtmCandidateMixin) {
  @Prop() private translations!: CreateProfileTranslations
  @Prop() private experienceData !: Array<SelectOption>
  @Prop() private immigrationProcess !: Array<SelectOption>
  @Prop() private countriesList !: Array<SelectOption>
  @Prop() private languageProficienciesListFr !: Array<LanguageProficiency>
  @Prop() private languageProficienciesListEn !: Array<LanguageProficiency>
  @Prop() private activityAreas!: ActivityArea[]
  @Prop() private candidateId!: number

  private currentStep = 1
  private model = new CreateProfileModel()
  private disableNextStepButton = false
  private stepRefs = ['step1', 'step2', 'step3']
  private hasError = false
  private errorMessage = ''
  private localStorageModelKey = 'createProfileModel'

  mounted (): void {
    // Get saved model from the local storage
    const localStorageModel = localStorage.getItem(this.localStorageModelKey)
    if (localStorageModel) {
      const savedModel = JSON.parse(localStorageModel)
      deepCopy(savedModel, this.model)
      this.cleanLocalStorage()
    }
    this.model.candidateId = this.candidateId
    window.addEventListener('beforeunload', () => {
      localStorage.setItem(
        this.localStorageModelKey,
        JSON.stringify(this.model)
      )
    })
    if (this.model.languageProficiencies === null) {
      this.model.languageProficiencies = []
    }
    if (this.model.softSkills === null || this.model.softSkills === undefined) {
      this.model.languageProficiencies = []
    }
  }

  private validate (): Promise<boolean> {
    const form = this.$refs[this.stepRefs[this.currentStep - 1]] as any
    return form.validate()
  }

  private pageButtonLabel (page: number): string | null {
    if (page < this.currentStep) {
      return null
    }
    return page.toString()
  }

  private changePageTo (page: number): void {
    if (!this.validateSoftSkill()) {
      notifyNegative(this, this.translations.profileCreate.errors.personalSkillsRequired)
      return
    }

    if (!this.validateInterestAreas()) {
      notifyNegative(this, this.translations.profileCreate.interestArea.error.empty)
    } else if (this.validateSkills()) {
      if (page > this.currentStep) {
        if (this.currentStep === 2) {
          if (this.model.languageProficiencyFrId !== -1 && this.model.languageProficiencyEnId !== -1) {
            this.onCompleteProfileStep(this.currentStep, this.translations.common.button.nextStep)
            this.currentStep = page
          }
        } else {
          this.validate().then(isValid => {
            if (isValid) {
              this.onCompleteProfileStep(this.currentStep, this.translations.common.button.nextStep)
              this.currentStep = page
              window.scrollTo(0, 0)
            }
          })
        }
      } else {
        this.currentStep = page
      }
    }
  }

  private validateSoftSkill (): boolean {
    var temp = this.model.softSkills.filter(function(e) { return e !== '' })
    if (temp.length > 0) {
      return true
    }
    return false
  }

  private validateSkills (): boolean {
    if (this.model.skills.length < 2) {
      return false
    }

    let isEmptyRow = false
    this.model.skills.forEach(element => {
      if (this.isEmptyOrSpaces(element.fr) && this.isEmptyOrSpaces(element.en)) {
        isEmptyRow = true
      }
    })

    return isEmptyRow === false
  }

  private validateInterestAreas () {
    if (this.model.interestAreaGroups === undefined || this.model.interestAreaGroups === null || this.model.interestAreaGroups.length === 0) {
      return false
    } else {
      return true
    }
  }

  private isEmptyOrSpaces (str: string): boolean {
    return str === null || str.match(/^ *$/) !== null
  }

  private pageButtonIcon (page: number): boolean {
    return page < this.currentStep
  }

  private pageButtonColor (page: number): string {
    if (page === this.currentStep) {
      return 'active-page'
    }
    if (page < this.currentStep) {
      return 'previous-page'
    }
    return 'next-page'
  }

  private goToNextPage (): void {
    this.changePageTo(this.currentStep + 1)
  }

  private goToPreviousPage (): void {
    this.changePageTo(this.currentStep - 1)
  }

  private cleanLocalStorage (): void {
    localStorage.removeItem(this.localStorageModelKey)
  }

  private finish (): void {
    const returnUrl = getUrlParameter('ReturnUrl')
    loadWhile(this, this.translations.common.message.loading, () => {
      this.cleanLocalStorage()
      this.hasError = false
      this.onCompleteProfileStep(this.currentStep, this.translations.profileCreate.field.button.finish)
      return ProfileCandidateService.createProfile(this.model)
        .then((response: AxiosResponse<any>) => {
          const culture = CultureService.getCulture()
          this.model = new CreateProfileModel()
          if (returnUrl) {
            window.location.href = returnUrl
          } else if (culture === Culture.English) {
            window.location.href = '/en/candidate'
          } else {
            window.location.href = '/fr/candidat'
          }
        })
        .catch((error: any) => {
          this.hasError = true
          if (error.response.status === 404) {
            this.errorMessage = this.translations.errors.common.notFound
          } else if (error.response.status === 403) {
            this.errorMessage = this.translations.errors.common.forbidden
          } else if (error.response.status === 400 && error.response.data.message === 'personalSkillsRequired') {
            this.errorMessage = this.translations.profileCreate.errors.personalSkillsRequired
          } else {
            this.errorMessage = this.translations.errors.common.unknown
          }
        })
    })
  }
  private selectedLanguageProficiencyFr (id: number) {
    this.model.languageProficiencyFrId = id
  }

  private selectedLanguageProficiencyEn (id: number) {
    this.model.languageProficiencyEnId = id
  }

  private selectedLanguageProficiency(value: Array<CandidateLanguageProficiency>) {
    this.model.languageProficiencies = value
  }
}
