








































































import { Component, Prop, Vue } from 'vue-property-decorator'
import MediaObject from '../models/MediaObject'
import { Alignment } from '../models/Alignment'
import MITMImageComponent from '@/generic/components/MITMImage.vue'

@Component({
  components: {
    'mitm-img': MITMImageComponent
  }
})
export default class PromoBlockComponent extends Vue {
  @Prop() image!: MediaObject
  @Prop() title!: string
  @Prop() subTitle!: string
  @Prop() actionLink!: string
  @Prop() actionText!: string
  @Prop() primaryColor!: string
  @Prop() secondaryColor!: string
  @Prop() textColor!:string
  @Prop() buttonTextColor!: string
  @Prop() iconColor !: string
  @Prop() alignment!: Alignment
  @Prop() textAlignment!: Alignment

  private animation = false

  private onMouseOver () {
    this.animation = true
  }

  private onMouseLeave () {
    this.animation = false
  }

  private get imageAlignment () {
    if (Number(this.alignment) === Alignment.Right) {
      return {
        'order-last': true
      }
    } else {
      return {
        'order-first': true
      }
    }
  }

  private get theTextAlignement () {
    if (Number(this.textAlignment) === Alignment.Right && !this.$q.screen.lt.md) {
      return {
        'text-right': true
      }
    } else if (Number(this.textAlignment) === Alignment.Left && !this.$q.screen.lt.md) {
      return {
        'text-left': true
      }
    } else {
      return {
        'text-center': true
      }
    }
  }

  private get contentStyle () {
    return {
      backgroundColor: this.primaryColor,
      'text-align': this.$q.screen.lt.md ? 'center' : 'left'
    }
  }

  private get textStyle () {
    return {
      color: this.textColor
    }
  }

  private get subtitleStyle () {
    return {
      color: this.secondaryColor
    }
  }

  private get buttonStyle () {
    return {
      'background-color': this.secondaryColor,
      'color': this.buttonTextColor
    }
  }

  private get hasImage () {
    return this.image && this.image.url
  }
}
