




















import { JobOfferSummary } from '@/offer/summary/JobOfferSummary.model'
import CandidateService from '@/services/CandidateService'
import { Prop, Vue, Component } from 'vue-property-decorator'
import CandidateOffersTranslations from './CandidateOffers.translations'

@Component
export default class CandidateOffersGridComponent extends Vue {
  @Prop() translations!: CandidateOffersTranslations
  @Prop() jobs!: JobOfferSummary[]
}
