

























import { Component, Prop, Vue } from 'vue-property-decorator'
import { SiteMenu } from '@/navigation/model/SiteMenu.model'

@Component
export default class MobileHeaderComponent extends Vue {
  @Prop() homeLink!: string
  @Prop() private isOpen: Boolean = false

  private ToggleMenu (): void {
    this.isOpen = !this.isOpen
    this.$emit('toggle')
  }

  public closeMenu (): void {
    this.isOpen = false
  }

  private get screenWidth () {
    return {
      '--screen-width': this.$q.screen.width + 'px'
    }
  }
}
