






























































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { FileType } from '@/generic/models/FileType'
import { FileInfo } from '@/generic/models/File.model'
import { FileErrorTranslations } from '@/generic/models/FileError.translations'

@Component
export default class FileListComponent extends Vue {
  @Prop() private translationErrors!: FileErrorTranslations
  @Prop() private label!: string
  @Prop() private value !: Array<FileInfo>
  @Prop() private maxNbFiles!: number
  @Prop() private isDragDrop: boolean = false
  @Prop() private acceptedType!: string
  @Prop() private fileSizeLimit!: number
  @Prop() private totalFileLimit!: number

  private displayTotalSizeTooBig = false
  private isFileChanged = false
  private hasError: boolean = false
  private errorMessage!: string

  private dragOverHandler (event: DragEvent): void {
    event.preventDefault()
  }

  dropHandler (event: DragEvent): void {
    event.preventDefault()
    this.isDragDrop = false
    if (!this.hasMaxNbFiles && event.dataTransfer) {
      this.onNewFile(event.dataTransfer.files)
    }
  }

  private get fileInput (): HTMLInputElement {
    return this.$refs.fileInput as HTMLInputElement
  }

  private get hasMaxNbFiles () : boolean {
    return this.value && this.value.length >= this.maxNbFiles
  }

  private chooseFile (): void {
    this.fileInput.click()
  }

  private onChange (): void {
    this.onNewFile(this.fileInput.files && this.fileInput.files)
    this.fileInput.value = ''
  }

  private onNewFile (files: FileList|null): void {
    this.onFileChosen()
    if (files) {
      let totalSize = this.value.reduce((previous, current) => previous + current.size, 0)
      for (var x = 0; x < files.length; x++) {
        let file = files[x]
        totalSize += file.size
        if (totalSize > this.totalFileLimit) {
          this.displayTotalSizeTooBig = true
        }
        if (this.hasSameName(file)) {
          continue
        }
        if (!this.hasValidSize(file)) {
          this.hasError = true
          if (this.displayTotalSizeTooBig) {
            this.errorMessage = this.translationErrors.totalTooBig
          } else {
            this.errorMessage = this.translationErrors.tooBig
          }
          return
        } else if (!this.hasValidType(file)) {
          this.hasError = true
          this.errorMessage = this.translationErrors.invalidFormat
          return
        } else {
          this.onValidFileChosen(file)
        }
      }
    }
  }

  private onFileChosen (): void {
    this.hasError = false
    this.displayTotalSizeTooBig = false
  }

  private hasValidSize (file: File): boolean {
    return file.size < this.fileSizeLimit
  }

  private hasValidType (file: File): boolean {
    return file.type === this.acceptedType
  }

  private hasSameName (newFile: File): boolean {
    return this.value.find((file) => file.fileName === newFile.name) !== undefined
  }

  private onValidFileChosen (file: File): void {
    this.updateValue(file)
  }

  private updateValue (file : File): void {
    const reader = new FileReader()
    reader.addEventListener('loadend', () => {
      const document: FileInfo = {
        id: 0,
        fileContent: (reader.result as string).split(',')[1],
        fileType: this.mimeTypeToFileType(file.type),
        fileName: file.name,
        size: file.size
      }
      this.value.push(document)
      this.isFileChanged = true
    })
    reader.readAsDataURL(file)
  }

  private mimeTypeToFileType (mimeType: string): FileType {
    if (mimeType === 'application/pdf') {
      return FileType.Pdf
    } else if (mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      return FileType.Xlsx
    }
    return FileType.Unknown
  }

  private deleteFile (index: number) {
    let fileInfo = this.value.splice(index, 1)
    let totalSize = this.value.reduce((previous, current) => previous + current.size, 0)
    if (totalSize < this.totalFileLimit) {
      this.displayTotalSizeTooBig = false
    }
    this.$forceUpdate()
  }
}
