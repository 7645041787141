export class CandidateDownloadDto {
  JobId: number
  Culture: string
  ShowHidden: boolean
  City: string
  Missions: Array<number>
  CountryIds: Array<number>
  LanguageProficiencyFrIds: Array<number>
  LanguageProficiencyEnIds: Array<number>
  InterestAreaGroupCode: number | null
  Industries: Array<string>
  LevelsOfStudy: Array<string>
  CandidatesIds: string

  constructor (
    JobId: number,
    Culture: string,
    ShowHidden: boolean,
    City: string,
    Missions: Array<number>,
    CountryIds: Array<number>,
    LanguageProficiencyFrIds: Array<number>,
    LanguageProficiencyEnIds: Array<number>,
    InterestAreaGroupCode: number | null,
    Industries: Array<string>,
    LevelsOfStudy: Array<string>,
    CandidatesIds: string) {
    this.JobId = JobId
    this.Culture = Culture
    this.ShowHidden = ShowHidden
    this.City = City
    this.Missions = Missions
    this.CountryIds = CountryIds
    this.LanguageProficiencyFrIds = LanguageProficiencyFrIds
    this.LanguageProficiencyEnIds = LanguageProficiencyEnIds
    this.InterestAreaGroupCode = InterestAreaGroupCode
    this.Industries = Industries
    this.LevelsOfStudy = LevelsOfStudy
    this.CandidatesIds = CandidatesIds
  }
}
