















import { Component, Prop, Vue } from 'vue-property-decorator'
import HelpIconComponent from '@/generic/components/MITMHelpIcon.vue'
import { PreSelectionHelp } from '@/candidate/candidates-list/CandidateCard.translations'

@Component({
  components: {
    'mitm-help-icon': HelpIconComponent
  }
})
export default class PreSelectionQuestionsHelp extends Vue {
   @Prop() translations!: PreSelectionHelp

   private multiline (text: string) : string {
     return text.split('\n').join('<br>')
   }
}
