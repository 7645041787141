


























































































import { Component, Prop, Vue } from 'vue-property-decorator'
import ImageCardComponent from '@/content/card/ImageCard.vue'
import HeaderBlockComponent from '@/content/block/HeaderBlock.vue'
import ArticleSummary from '@/dashboard/employer/articles/ArticleSummary.model'

@Component({
  components: {
    'mitm-header-text': HeaderBlockComponent,
    'mitm-image-card': ImageCardComponent
  }
}
)
export default class PageCarouselComponent extends Vue {
  @Prop() private title!: string
  @Prop() private titleColor!: string
  @Prop() private primaryColor!: string
  @Prop() private secondaryColor!: string
  @Prop() private pages!: ArticleSummary[]
  @Prop() private linkText!: string
  private model = 1

  private get slideCount () {
    return this.pages.length
  }
}
