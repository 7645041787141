





























































































































import { Component, Prop, Vue, Watch, Mixins } from 'vue-property-decorator'
import GtmNewsletterMixin from '@/gtm/GtmNewsletter'
import GtmEmployerMixin from '@/gtm/GtmEmployer'
import GtmCandidateMixin from '@/gtm/GtmCandidate'
import { SignUp, SignUpRequest } from './SignUp.model'
import { SignUpTranslations } from './SignUp.translations'
import { AuthenticationService } from '@/services/Authentication.service'
import VueRecaptcha from 'vue-recaptcha'
import { RequiredRule } from '@/generic/rules/Required'
import MatchField from '@/generic/rules/Match'
import { getUrlParameter, loadWhile } from '@/generic/helpers'
import ForgotPasswordTranslations from '@/forgot-password/ForgotPassword.translations'
import { AuthentcationErrorTranslations } from '@/authentication/AuthentcationError.translations'
import { Invitation } from './Invitation.model'
import { CultureService } from '@/services/CultureService'
import { Culture } from '@/generic/models/Culture'
import { Contact } from '@/company/contact/Contact.model'

@Component
export default class SignUpComponent extends Mixins(GtmNewsletterMixin, GtmCandidateMixin, GtmEmployerMixin) {
    @Prop() private translations!: SignUpTranslations
    @Prop() private culture!: string
    @Prop() initModel!: SignUp
    @Prop() invitation!: Invitation
    @Prop() passwordResetTranslations!: ForgotPasswordTranslations;
    @Prop() private errors!: AuthentcationErrorTranslations
    @Prop() isCandidat!: boolean
    private model: SignUpRequest = {
      firstName: this.initModel.firstName,
      lastName: this.initModel.lastName,
      email: this.initModel.email,
      emailConfirmation: this.initModel.emailConfirmation,
      password: '',
      passwordConfirmation: '',
      preferredCulture: this.culture,
      returnUrl: '',
      capchaRequest: '',
      newsletterConsent: false
    }
    private errorHappened = false
    private crmInvite = false
    private isUserNotAutorizedToCreateAccount = false
    private showModal = true
    private errorMessage : string = ''
    private emailReadOnly: boolean = false
    private contact: Contact | null = null
    private rules: { [key: string]: Array<(value: any) => boolean | string> } = {
      email: [new RequiredRule().getValidator(this.translations.email.required)],
      confirmEmail: [new RequiredRule().getValidator(this.translations.confirmEmail.required), new MatchField('email', this.get).getValidator(this.translations.emailMustMatch)],
      firstName: [new RequiredRule().getValidator(this.translations.firstName.required)],
      lastName: [new RequiredRule().getValidator(this.translations.lastName.required)],
      confirmPassword: [new RequiredRule().getValidator(this.translations.confirmPassword.required), new MatchField('password', this.get).getValidator(this.translations.passwordMustMatch)],
      password: [new RequiredRule().getValidator(this.translations.password.required), new MatchField('passwordConfirmation', this.get).getValidator(this.translations.passwordMustMatch)]
    }

    get (prop: string): any {
      return (this.model as any)[prop]
    }

    private get privacyPolicyLink (): string {
      if (CultureService.getCulture() === Culture.French) {
        return 'https://www.montrealinternational.com/fr/politique-de-vie-privee/'
      }
      return 'https://www.montrealinternational.com/en/privacy-policy/'
    }

    private getContactInfo () : void {
      AuthenticationService.getUserInfo(Number.parseInt(getUrlParameter('userId'))).then(
        result => {
          this.contact = result.data
        }).finally(() => {
        if (this.contact != null) {
          this.model = {
            firstName: this.contact.firstName ? this.contact.firstName : this.initModel.firstName,
            lastName: this.contact.lastName ? this.contact.lastName : this.initModel.lastName,
            email: this.contact.email ? this.contact.email : this.initModel.email,
            emailConfirmation: this.contact.email ? this.contact.email : this.initModel.email,
            password: '',
            passwordConfirmation: '',
            preferredCulture: this.culture,
            returnUrl: '',
            capchaRequest: '',
            newsletterConsent: false
          }
        }
      })
    }

    private onPrivacyPolicyClick (e: Event): void {
      e.stopPropagation()
    }

    private onVerifyRecaptcha (response: any): void {
      this.model.capchaRequest = response
    }

    private onExpiredRecaptcha (): void {
      this.model.capchaRequest = ''
    }

    private mounted () {
      if (getUrlParameter('inviteType') === 'crm') {
        this.crmInvite = true
        this.getContactInfo()
      }
      if (this.invitation) {
        this.model.email = this.invitation.email
        this.model.emailConfirmation = this.invitation.email
        this.emailReadOnly = true
      }

      this.isUserNotAutorizedToCreateAccount = !this.crmInvite && this.invitation === null
    }

    private submit (): void {
      this.model.returnUrl = getUrlParameter('ReturnUrl')
      this.model.preferredCulture = this.culture
      loadWhile(this, this.translations.loading, () => {
        if (this.isCandidat) {
          return AuthenticationService.signUpCandidate(this.model)
            .then(response => {
              if (this.model.newsletterConsent) {
                this.OnSubscribeNewsletterFromSignup()
              }
              this.OnConfirmCandidate(this.translations.buttonLabel)
              setTimeout(() => {
                window.location.href = response.data.redirectUrl
              }, 1000)
            })
            .catch(error => {
              this.errorMessage = this.errors[error.response.data.message]
              this.errorHappened = true
            })
        } else {
          return AuthenticationService.signUp(this.model, this.invitation, this.crmInvite)
            .then(response => {
              this.onConfirmRecruiter(this.translations.buttonLabel)
              setTimeout(() => {
                window.location.href = response.data.redirectUrl
              }, 1000)
            })
            .catch(error => {
              this.errorMessage = this.errors[error.response.data.message]
              this.errorHappened = true
            })
        }
      })
    }
}
