




















































































































































































































import { Component, Prop, Vue, Mixins } from 'vue-property-decorator'
import CandidateCardTranslations from './CandidateCard.translations'
import SkillTag from '@/generic/models/SkillTag.model'
import Candidate from '../Candidate.model'
import { CreateProfileModel } from '@/profile/CreateProfile.model'
import { FavoriteService } from '@/services/FavoriteService'
import CandidateService from '../../services/CandidateService'
import GtmEmployerMixin from '@/gtm/GtmEmployer'
import { is } from 'cypress/types/bluebird'

@Component
export default class CandidateCardComponent extends Mixins(GtmEmployerMixin) {
  @Prop() private candidate !: Candidate
  @Prop() private showEmail!: boolean
  @Prop() private translations !: CandidateCardTranslations
  @Prop() private profile!: CreateProfileModel
  @Prop() private jobId!: number
  @Prop() private companyId!: number
  @Prop({ default: false, type: Boolean }) private showMission!: boolean
  @Prop({ default: false }) private openDetails!: boolean
  @Prop({ default: false }) private isSuggested!: boolean
  @Prop({ default: false }) private hidden!: boolean
  @Prop({ default: false }) private isNew!: boolean
  private seeProfile = this.openDetails

  private min = 0
  private max = 100
  private loading = false
  private selectedIcon: string = 'check_box_outline_blank'

  private selectedForDownload1 () {
    if (this.candidate.isSelectedForCVDownload) {
      this.selectedIcon = 'check_box_outline_blank'
      // eslint-disable-next-line no-return-assign
      return this.candidate.isSelectedForCVDownload = false
    } else {
      this.selectedIcon = 'check_box'
      // eslint-disable-next-line no-return-assign
      return this.candidate.isSelectedForCVDownload = true
    }
  }

  // private progressionColor () {
  //  if (this.candidate.percentage < 60) {
  //    return 'mitm-red'
  //  }
  //  if (this.candidate.percentage >= 60 && this.candidate.percentage < 85) {
  //    return 'mitm-orange'
  //  }
  //  if (this.candidate.percentage >= 85) {
  //    return 'mitm-dark-green'
  //  }
  // }

  tagsCharacterCount () {
    let count = 0
    this.candidate.tags.forEach(tag => {
      count = count + tag.name.length
    })
    return count
  }

  sortByCharacterCount (tags: any) {
    tags.sort(function (a: any, b: any) {
      return a.name.length - b.name.length
    })
  }

  maxTags (tags: any) {
    let maxTags = [] as any
    for (let i = 0; i < 4; i++) {
      if (tags[i]) {
        maxTags.push(tags[i])
      }
      this.sortByCharacterCount(maxTags)
    }
    if (this.tagsCharacterCount() > 75) {
      maxTags.pop()
    }
    return maxTags
  }

  private toggleFavorite () {
    this.loading = true
    FavoriteService.updateRating(this.jobId, this.candidate.candidateId, this.candidate.rating)
      .then(result => {
        this.candidate.rating = result.data.rating
        if (this.candidate.rating > 0) {
          this.onAddFavorite(this.isSuggested)
        }
      })
      .catch(_ => { this.candidate.rating = 0 })
      .finally(() => {
        this.loading = false
        this.$emit('rating', this.candidate.candidateId, this.candidate.rating)
      })
  }

  private hideCandidate () {
    CandidateService.hideCandidate(this.candidate.id)
      .then(response => {
        this.candidate.hidden = true
        this.$emit('hide-candidate', this.candidate.id)
      })
  }

  private showCandidate () {
    CandidateService.showCandidate(this.candidate.id)
      .then(response => {
        this.candidate.hidden = false
        this.$emit('hide-candidate', this.candidate.id)
      })
  }

  private showProfile () {
    this.onOpenProfile(this.isSuggested)
    this.seeProfile = true
    CandidateService.setCansultedByRecuiter(this.candidate.id)
      .then(response => {
        if (!this.candidate.consultedByRecruiter) {
          if (this.isSuggested) {
            this.$emit('decrease-suggested-numbers', this.candidate.id)
          } else {
            this.$emit('decrease-applicants-numbers', this.candidate.id)
          }
        }
        this.candidate.consultedByRecruiter = true
      })
  }
}

