



























































import { Component, Prop, Vue } from 'vue-property-decorator'
import { ITranslatable } from '@/generic/models/ITranslatable'
import { CommonDescriptionTranslations } from '@/generic/models/CommonDescription.translations'
import { MaxLength } from '@/generic/rules/MaxLength'

@Component
export default class CompanyDescriptionComponent extends Vue {
  @Prop() private translations!: CommonDescriptionTranslations;
  @Prop() private value!: ITranslatable;
  @Prop() private sectionNumber!: number

  private maxLength = 4000;

  private tab: string = 'francais';

  private fieldError (field: string): boolean {
    const ref = this.$refs[field]
    return ref && (ref as any).hasError && (ref as any).innerErrorMessage
  }

  private rules: { [key: string]: Array<(value: any) => boolean | string> } = {
    fr: [
      new MaxLength(this.maxLength).getValidator(this.translations.message.maxLength.fr)
    ],
    en: [
      new MaxLength(this.maxLength).getValidator(this.translations.message.maxLength.en)
    ]
  };
}
