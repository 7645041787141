































































































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { JobApplicationCandidateDetailsTranslations } from '@/candidate/candidates-list/CandidateCard.translations'
import { CandidateDetails } from '@/candidate/candidate-details/CandidateDetails.model'
import CandidateService from '@/services/CandidateService'
import { loadWhile, downloadFile } from '../generic/helpers'
import { dateFilter } from '@/generic/filters/Date'
import { CultureService } from '@/services/CultureService'

@Component
export default class JobApplicationCandidateDetailsComponent extends Vue {
  @Prop() translations!: JobApplicationCandidateDetailsTranslations
  @Prop() candidate!: CandidateDetails
  @Prop() forMission!: boolean
  @Prop({ default: false }) hasPreferences!: boolean
  @Prop() profileLink!: string
  private errorMessage = this.translations.errors.detailsNotFound

  private isColumnEmpty () {
    return this.candidate.skills === null ||
      (this.candidate.city === '' &&
      this.candidate.country === '' &&
      this.candidate.immigrationProcess === null)
  }

  private onDownloadDocuments (id: number) {
    loadWhile(this, '', () => CandidateService.getDocuments(id)
      .then(response => {
        downloadFile(response)
      })
    )
  }

  private formatDateMonthDayOnly (date: string): string {
    const culture = CultureService.getCulture()
    return dateFilter(date || new Date(), culture, { year: 'numeric', month: 'long' })
  }
}
