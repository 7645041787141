import { IRule } from './IRule'

export class PostalCodeRule implements IRule {
  static regex = /^[A-Z][0-9][A-Z] ?[0-9][A-Z][0-9]$/i;
  getValidator (errorMessage: string, activate?: () => boolean): (value: string) => boolean|string {
    return (value: string): boolean|string => {
      if (activate === undefined || (activate && activate())) {
        return !!value.match(PostalCodeRule.regex) || errorMessage
      }
      return true
    }
  }
}
