














































































































































































































































































































































































































import { Component, Prop, Vue, Mixins } from 'vue-property-decorator'
import { notify, notifyNegative } from '@/generic/helpers'
import { SelectOption } from '@/generic/models/SelectOption'
import AdminConsoleService from '../../services/AdminConsoleService'
import AdminConsoleRecruiterService from '../../services/AdminConsoleRecruiterService'
import AdminConsoleCandidateService from '../../services/AdminConsoleCandidateService'
import { CandidateAvailabilityPreferences } from '@/candidate/CandidateAvailabilityPreferences.model'
import { CultureService } from '@/services/CultureService'
import { Culture } from '@/generic/models/Culture'
import MeetingCardTranslations from '../../models/meetings/MeetingCard.translations'
import MITMPromptComponent from '@/generic/components/MITMPrompt.vue'
import { DialogResult } from '@/generic/models/DialogResult'
import { Meeting } from '@/dashboard/meeting-list/models/Meeting.model'
import AdminConsoleMeetingService from '../../services/AdminConsoleMeetingService'

@Component
export default class AdminMeetingForm extends Vue {
  @Prop() meeting!: Meeting
  @Prop() translations !: MeetingCardTranslations

  culture!: string
  loaded = false
  isSaving = false
  isDeleting = false
  jobsOffersLoaded = false
  companiesLoaded = false
  recruitersLoaded = false
  candidatesLoaded = false
  availabilitiesLoaded = false
  hasError = false
  errorMessage = ''
  selectedType: string = this.translations.meetingsPage.onlyRec
  selectedMission!: SelectOption | null
  selectedCompany!: SelectOption | null
  selectedJobOffer!: SelectOption | null
  selectedRecruiter!: SelectOption | null
  selectedCandidate!: SelectOption | null
  companies: SelectOption[] = []
  jobsList: SelectOption[] = []
  recruiters: SelectOption[] = []
  candidates: SelectOption[] = []
  availabilityPreferences!: CandidateAvailabilityPreferences
  candidateTimezone!: string
  adminTimezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone
  timezoneHourDifference: number = 0

  meetingTypes = [
    'Mission',
    this.translations.meetingsPage.onlyRec
  ]

  created () {
    if (!this.meeting || this.meeting.id === 0) {
      this.meeting = new Meeting()
      this.loadCompanies((a: boolean | void) => {})
    } else {
      this.meeting.recruiterDateStart = this.formatDate(this.meeting.recruiterDateStart)
      this.meeting.recruiterDateEnd = this.formatDate(this.meeting.recruiterDateEnd)
      this.meeting.candidateDateStart = this.formatDate(this.meeting.candidateDateStart)
      this.meeting.candidateDateEnd = this.formatDate(this.meeting.candidateDateEnd)
      this.selectedType = this.meeting && this.meeting.missionId > 0 ? 'Mission' : this.translations.meetingsPage.onlyRec // change with mission created added
      this.selectedMission = { value: this.meeting.missionId, label: this.meeting.missionName } // change with mission created added
      this.selectedCompany = { value: this.meeting.companyId, label: this.meeting.companyName }
      this.selectedJobOffer = { value: this.meeting.jobOfferId, label: this.meeting.jobOfferName }
      this.selectedRecruiter = { value: this.meeting.recruiterId, label: this.meeting.recruiterFullName }
      this.selectedCandidate = { value: this.meeting.candidateId, label: this.meeting.candidateFullName }
      this.jobsOffersLoaded = true
      this.companiesLoaded = true
      this.recruitersLoaded = true
      this.candidatesLoaded = true
      this.loadAvailabilities((a: boolean | void) => {})
      this.$forceUpdate()
    }
  }

  mounted (): void {
    if (CultureService.getCulture() === Culture.English) {
      this.culture = 'en-US'
    } else {
      this.culture = 'fr-CA'
    }

    this.loaded = true
  }

  handleError (error: any): void {
    this.hasError = true
    if (error.response.status === 404) {
      this.errorMessage = 'error'
    } else {
      this.errorMessage = error.message
    }
  }

  loadCompanies(done: Function): Promise<void> {
    this.companiesLoaded = false
    this.hasError = false
    return AdminConsoleService.getAllCompanies()
      .then((result) => {
        this.companies = []
        this.companies = this.companies.concat(result.data)
        this.companiesLoaded = true
        done(true)
      })
      .catch((error) => {
        if (error) {
          this.hasError = true
        }
        done(true)
      })
  }

  loadJobs (done: Function): Promise<void> {
    this.jobsOffersLoaded = false
    this.hasError = false
    // For now we can only create meeting for rec in admin therefor we pass and empty id with REC bool
    return AdminConsoleService.getAllJobs(this.culture, '', this.meeting.companyId, this.isRec)
      .then((result) => {
        this.jobsList = []
        this.jobsList = this.jobsList.concat(result.data)
        this.jobsOffersLoaded = true
        done(true)
      })
      .catch((error) => {
        if (error) {
          this.hasError = true
        }
        this.jobsOffersLoaded = true
        done(true)
      })
  }

  loadRecruiters (done: Function): Promise<void> {
    this.recruitersLoaded = false
    this.hasError = false
    return AdminConsoleRecruiterService.getAllRecruiterByCompany(this.meeting.companyId)
      .then((result) => {
        this.recruiters = []
        this.recruiters = this.recruiters.concat(result.data)
        this.recruitersLoaded = true
        done(true)
      })
      .catch((error) => {
        if (error) {
          this.hasError = true
        }
        this.recruitersLoaded = true
        done(true)
      })
  }

  loadCandidates (done: Function): Promise<void> {
    this.candidatesLoaded = false
    this.hasError = false
    return AdminConsoleService.getApplicantsByOffer(this.meeting.jobOfferId)
      .then((result) => {
        this.candidates = []
        this.candidates = this.candidates.concat(result.data)
        this.candidatesLoaded = true
        done(true)
      })
      .catch((error) => {
        if (error) {
          this.hasError = true
        }
        this.candidatesLoaded = true
        done(true)
      })
  }

  loadAvailabilities (done: Function): Promise<void> {
    this.availabilitiesLoaded = false
    this.hasError = false
    return AdminConsoleCandidateService.getCandidateAvailabilityPreferences(this.meeting.candidateId, this.meeting.jobOfferId)
      .then((result) => {
        this.availabilityPreferences = result.data.preferences
        this.availabilitiesLoaded = true
        this.candidateTimezone = result.data.timezone || this.adminTimezone
        done(true)
      })
      .catch((error) => {
        if (error) {
          this.hasError = true
        }
        this.availabilitiesLoaded = true
        done(true)
      })
  }

  companySelected (companySelected: SelectOption) {
    if (companySelected && companySelected.value !== '') {
      this.selectedCompany = companySelected
      this.meeting.companyId = +companySelected.value
    } else {
      this.selectedCompany = null
      this.meeting.companyId = 0
    }
    this.$forceUpdate()
    this.clearJobOffer()
    this.loadJobs((a: boolean | void) => {})
    this.clearRecruiter()
    this.loadRecruiters((a: boolean | void) => {})
  }

  jobOfferSelected (jobSelected: SelectOption) {
    this.loadRecruiters((a: boolean | void) => {})
    if (jobSelected && jobSelected.value !== '') {
      this.selectedJobOffer = jobSelected
      this.meeting.jobOfferId = +jobSelected.value
    } else {
      this.clearJobOffer()
    }
    this.$forceUpdate()
    this.clearCandidate()
    this.loadCandidates((a: boolean | void) => {})
  }

  recruiterSelected (recruiterSelected: SelectOption) {
    if (recruiterSelected && recruiterSelected.value !== '') {
      this.selectedRecruiter = recruiterSelected
      this.meeting.recruiterId = +recruiterSelected.value
    } else {
      this.clearRecruiter()
    }
    this.$forceUpdate()
  }

  candidateSelected (candidateSelected: SelectOption) {
    if (candidateSelected && candidateSelected.value !== '') {
      this.selectedCandidate = candidateSelected
      this.meeting.candidateId = +candidateSelected.value
    } else {
      this.clearCandidate()
    }
    this.$forceUpdate()
    this.loadAvailabilities((a: boolean | void) => {})
  }

  save () : void {
    if (this.canSave) {
      this.saveAction((a: boolean | void) => {})
    }
  }

  saveAction (done: Function): Promise<void> {
    this.hasError = false
    this.isSaving = true
    return AdminConsoleMeetingService.saveMeeting(this.meeting)
      .then((response) => {
        this.isSaving = false
        this.$emit('follow-up-save')
        notify(this, this.translations.meetingsPage.saveNotify || 'Sauvegarder')
        done(true)
      })
      .catch((error) => {
        if (error) {
          if (error.response.data.message) {
            notifyNegative(this, error.response.data.message)
          } else {
            notifyNegative(this, error.message)
          }
        }
        this.isSaving = false
        done(true)
      })
  }

  deleteConfirmation () {
    if (this.canDelete) {
      (this.$refs.confirmPrompt as MITMPromptComponent).open()
    }
  }

  deleteMeeting (result: DialogResult) {
    if (result === DialogResult.Ok) {
      this.hasError = false
      this.isDeleting = true
      return AdminConsoleMeetingService.deleteMeeting(this.meeting.id, this.meeting.companyId, new Date(this.meeting.recruiterDateStart), 0)
        .then((response) => {
          this.isDeleting = false
          notify(this, this.translations.meetingsPage.deleteNotify)
        })
        .catch((error) => {
          if (error) {
            notifyNegative(this, error.message)
          }
          this.isDeleting = false
        })
    }
  }

  get isMission () : boolean {
    return this.selectedType === 'Mission'
  }

  get isRec () : boolean {
    return this.selectedType === this.translations.meetingsPage.onlyRec
  }

  get canSave () : boolean {
    return this.canDelete || (this.meeting?.recruiterId > 0 && this.meeting.candidateId > 0 && this.meeting.jobOfferId > 0 && this.meeting.companyId > 0)
  }

  get canDelete () : boolean {
    return this.isRec && (this.meeting != null && this.meeting.id > 0)
  }

  get timeZoneMessage () : string {
    if (this.candidateTimezone !== null) {
      var adminTimezoneHours = new Date(new Date().toLocaleString('en-US', { timeZone: this.adminTimezone })).getTime()
      var candidateTimezoneHours = new Date(new Date().toLocaleString('en-US', { timeZone: this.candidateTimezone })).getTime()
      var difference = adminTimezoneHours - candidateTimezoneHours
      var hours = (difference / 3600000)
      var message = (hours > 0) ? this.translations.meetingsPage.timezoneBehind : this.translations.meetingsPage.timezoneAhead
      return '(' + Math.abs(hours) + message + ')'
    }
    return ''
  }

  clearRecruiter () : void {
    this.meeting.recruiterId = 0
    this.selectedRecruiter = null
  }

  clearJobOffer () : void {
    this.meeting.jobOfferId = 0
    this.selectedJobOffer = null
    this.clearCandidate()
  }

  clearCandidate () : void {
    this.meeting.candidateId = 0
    this.selectedCandidate = null
  }

  getWeekDayTranslation (day: string) {
    if (day === 'Sunday') {
      return this.translations.availability.sunday || 'Dimanche'
    } else if (day === 'Monday') {
      return this.translations.availability.monday || 'Lundi'
    } else if (day === 'Tuesday') {
      return this.translations.availability.tuesday || 'Mardi'
    } else if (day === 'Wednesday') {
      return this.translations.availability.wednesday || 'Mercredi'
    } else if (day === 'Thursday') {
      return this.translations.availability.thursday || 'Jeudi'
    } else if (day === 'Friday') {
      return this.translations.availability.friday || 'Vendredi'
    } else {
      return this.translations.availability.saturday || 'Samedi'
    }
  }

  selectColor (selectedDay: string, index: number) {
    if (this.availabilityPreferences.days.find(x => x.day === selectedDay)?.periods.period[index] === true) {
      return 'bg-azure'
    }
    return 'bg-mitm-light-grey'
  }

  getPeriodTitleTranslation (index: number) {
    if (index === 0) {
      return this.translations.availability.night || '0h - 6h'
    } else if (index === 1) {
      return this.translations.availability.morning || '6h - 12h'
    } else if (index === 2) {
      return this.translations.availability.afternoon || '12h - 18h'
    } else {
      return this.translations.availability.evening || '18h - 24h'
    }
  }

  public formatDate (value: string, timezone?: string) : string {
    if (timezone) {
      return new Date(value).toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
        timeZone: timezone || this.adminTimezone
      })
    } else {
      return new Date(value).toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      })
    }
  }

  onSelectDateStart (value: string, reason: string, details: {year: number, month: number, day: number, changed: boolean}) {
    if (reason === 'day' && details.changed) {
      this.meeting.recruiterDateStart = this.formatDate(value)
      this.meeting.candidateDateStart = this.formatDate(value, this.candidateTimezone)
      this.$forceUpdate()
    }
  }

  onSelectTimeStart (value: string, details: {minute: number, month: number, changed: boolean}) {
    if (details.changed) {
      this.meeting.recruiterDateStart = this.formatDate(value)
      this.meeting.candidateDateStart = this.formatDate(value, this.candidateTimezone)
      this.$forceUpdate()
    }
  }

  onSelectDateEnd (value: string, reason: string, details: {year: number, month: number, day: number, changed: boolean}) {
    if (reason === 'day' && details.changed) {
      this.meeting.recruiterDateEnd = this.formatDate(value)
      this.meeting.candidateDateEnd = this.formatDate(value, this.candidateTimezone)
      this.$forceUpdate()
    }
  }

  onSelectTimeEnd (value: string, details: {minute: number, month: number, changed: boolean}) {
    if (details.changed) {
      this.meeting.recruiterDateEnd = this.formatDate(value)
      this.meeting.candidateDateEnd = this.formatDate(value, this.candidateTimezone)
      this.$forceUpdate()
    }
  }
}
