




























import { Component, Prop, Vue } from 'vue-property-decorator'
import { OfferService } from '@/services/OfferService'
import { JobOfferSummary } from '@/offer/summary/JobOfferSummary.model'
import { Culture } from '@/generic/models/Culture'
import JobCardTranslations from '@/offer/summary/JobCard.translations'
import ActiveOffersSectionTranslation from './ActiveOffersSection.translations'
import { CultureService } from '@/services/CultureService'
import MITMCardContainerComponent from '@/generic/components/MITMCardContainer.vue'
import { CallToAction } from '@/generic/models/CallToAction'

@Component({
  components: {
    'mitm-card-container': MITMCardContainerComponent
  }
})
export default class ActiveOffersSectionComponent extends Vue {
  @Prop() companyId !: number
  @Prop() currentCulture !: string
  @Prop() cardTranslation !: JobCardTranslations
  @Prop() sectionsTranslations !: ActiveOffersSectionTranslation
  private activeOffers: JobOfferSummary[] = []
  private slide: number = 0
  private publicPath: string = process.env.VUE_APP_ASSET_URL
  private hasError: boolean = false

  get hasJobs () : boolean {
    return this.activeOffers.length > 0
  }

  get showAllOffers () : CallToAction {
    let link = ''
    if (CultureService.getCulture() === Culture.English) {
      link = '/en/employer/my-offers'
    } else {
      link = '/fr/employeur/mes-offres'
    }

    return {
      text: this.sectionsTranslations.seeAll,
      link: link
    }
  }

  mounted () {
    OfferService.getLastActiveJob(this.companyId, this.currentCulture)
      .then(result => {
        this.activeOffers = result.data
      })
      .catch(() => {
        this.hasError = true
      })
  }
}

