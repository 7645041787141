import { Culture } from '../models/Culture'
import { CultureService } from '@/services/CultureService'

const dateFormatOptions: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  year: 'numeric',
  month: 'long',
  timeZone: 'UTC'
}

const hourFormatOptions: Intl.DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
  timeZone: 'UTC'
}

export function dateFilter (value: Date|string, culture?: Culture, options: Intl.DateTimeFormatOptions = dateFormatOptions) {
  if (typeof value === 'string') {
    value = new Date(value)
  }
  if (!culture) {
    culture = CultureService.getCulture()
  }
  return value.toLocaleDateString(culture, options || dateFormatOptions)
}

export function hourFilter (value: Date|string, culture?: Culture, options: Intl.DateTimeFormatOptions = hourFormatOptions) {
  if (typeof value === 'string') {
    value = new Date(value + 'Z')
  }
  if (!culture) {
    culture = CultureService.getCulture()
  }
  return value.toLocaleTimeString(culture, options || hourFormatOptions)
}

export function dateTimeFilter (value: Date|string) {
  if (typeof value === 'string') {
    value = new Date(value)
  }
  return new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate(), value.getHours(), value.getMinutes()))
}
