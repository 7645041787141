import { AxiosPromise } from 'axios'
import { BASE_API } from './api-common'
import { FavoriteCandidates } from '@/dashboard/favorites/FavoriteCandidates.model'
import { CultureService } from './CultureService'
import Rating from '@/candidate/rating/Rating.model'

export class FavoriteService {
  public static updateRating (offerId : number, candidateId : number, rating : number) : AxiosPromise<Rating> {
    return BASE_API.post('favorite/rating', {}, { params: { offerId: offerId, candidateId: candidateId, rating: rating } })
  }

  public static addOfferToFavorite (offerId: number): AxiosPromise<any> {
    return BASE_API.post('favorite/addOfferToFavorite', { offerId: offerId })
  }

  public static deleteOfferFromFavorite (offerId: number): AxiosPromise<any> {
    return BASE_API.post('favorite/deleteOfferFromFavorite', { offerId: offerId })
  }

  public static getMyCompanysFavoriteCandidates (search: string, eventId : string, jobOfferStatus: number): AxiosPromise<FavoriteCandidates> {
    let data = []
    data = eventId.split('-')
    return BASE_API.get('favorite/getMyCompanysFavoriteCandidates', {
      params: {
        cultureCode: CultureService.getCultureCode(),
        search: search,
        eventId: data[0],
        eventType: data[1] === undefined ? '' : data[1],
        jobOfferStatusId: jobOfferStatus
      }
    })
  }

  public static downloadCvs (culture: string, showHidden: boolean, search: string, eventId: string, jobOfferStatus: number, favoriteIds: string) : AxiosPromise<any> {
    let data = []
    data = eventId.split('-')
    return BASE_API.get('favorite/favoriteCvPdf', {
      params: {
        culture: culture,
        showHidden: showHidden,
        search: search,
        eventId: data[0],
        eventType: data[1] === undefined ? '' : data[1],
        jobOfferStatusId: jobOfferStatus,
        favoriteIds: favoriteIds
      },
      responseType: 'blob'
    })
  }

  public static sendFavoriteInvitation(culture: string, favoriteIds: string): AxiosPromise<any> {
    return BASE_API.get('favorite/sendFavoriteInvitation', {
      params: {
        culture: culture,
        favoriteIds: favoriteIds
      },
      responseType: 'blob'
    })
  }

  public static downloadFavoriteCvs (culture: string, showHidden: boolean, search: string, eventId: string, jobOfferStatus: number, favoriteIds: string) : AxiosPromise<any> {
    let data = []
    data = eventId.split('-')
    return BASE_API.get('favorite/csv', {
      params: {
        culture: culture,
        showHidden: showHidden,
        search: search,
        eventId: data[0],
        eventType: data[1] === undefined ? '' : data[1],
        jobOfferStatusId: jobOfferStatus,
        favoriteIds: favoriteIds
      },
      responseType: 'blob'
    })
  }
}
