





































import { Component, Prop, Vue } from 'vue-property-decorator'
import { ActivityArea } from './ActivityArea.model'
import { ActivityAreaInfo } from './ActivityAreaInfo.model'
import { ActivityAreaTranslations } from './ActivityArea.translations'
import { RequiredRule } from '@/generic/rules/Required'
import { IRule } from '@/generic/rules/IRule'

@Component
export default class ActivityFieldsComponent extends Vue {
  @Prop() private translations!: ActivityAreaTranslations
  @Prop() private value!: ActivityAreaInfo
  @Prop() private options!: ActivityArea[]
  @Prop() private isForJobOffer!: boolean
  @Prop() private isForCandidate!: boolean

  private rules: { [key: string]: Array<(value: any) => boolean | string> } = {
    activity: [new RequiredRule().getValidator(this.translations.required)]
  }

  private get localValue (): ActivityAreaInfo {
    return this.value
  }

  private set localValue (value: ActivityAreaInfo) {
    this.$emit('input', value)
  }

  private get filteredOptions (): ActivityArea[] {
    const primaryActivity = this.localValue.primaryActivity
    return primaryActivity ? this.options.filter((option) => option.id !== primaryActivity.id) : this.options
  }
}
