



































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Menu } from '../model/Menu.model'
import AppMenuComponent from './AppMenu.vue'

@Component
export default class AdminConsoleAppLayoutComponent extends Vue {
  @Prop() private title!: string
  @Prop() private otherLanguageLabel!: string
  @Prop() private otherLanguageLink!: string
  @Prop() private signOutRedirect!: string
  @Prop() private menu!: Menu
  @Prop() private homepageUrl!: string

  private addExtraPadding = false
  private open = false
  private view = this.$q.screen.lt.md ? 'lHh LpR lff' : 'lhh LpR lff'

  private onExpanded (): void {
    this.addExtraPadding = true
  }

  private onShrinked (): void {
    this.addExtraPadding = false
  }

  private onOpenMenu (): void {
    (this.$refs.menu as AppMenuComponent).openDrawer()
  }

  private onCloseMenu (event: any): void {
    var menu = (this.$refs.menu as AppMenuComponent)

    if (!menu.isMenuClosed() && !menu.$el.contains(event.target)) {
      menu.toggleMini()
    }
  }
}
