import { Account } from '@/authentication/account/Account.model'
import { ChangeAccountModel } from '@/authentication/account/ChangeAccount.model'
import { BASE_API } from '@/services/api-common'
import { AxiosPromise } from 'axios'
import { SelectOption } from '@/generic/models/SelectOption'

export default class AdminConsoleRecruiterService {
  public static getRecruiter (recruiterId: number) : AxiosPromise<Account> {
    return BASE_API.get('adminconsolerecruiter/getrecruiter', {
      params: {
        id: recruiterId
      }
    })
  }

  public static getAllRecruiterByCompany (companyId: number) : AxiosPromise<SelectOption> {
    return BASE_API.get('adminconsolerecruiter/getallrecruiterbycompany', {
      params: {
        companyId: companyId
      }
    })
  }

  public static deleteAccount (recruiterEmail: string, password: string): AxiosPromise<void> {
    return BASE_API.delete('adminconsolerecruiter/delete', {
      params: {
        recruiterEmail: recruiterEmail,
        password: password
      }
    })
  }

  public static updateAccount (model: ChangeAccountModel) : AxiosPromise<ChangeAccountModel> {
    return BASE_API.put(`adminconsolerecruiter/update`, model)
  }
}
