















































import { Component, Prop, Vue } from 'vue-property-decorator'
import MITMInputComponent from '@/generic/components/MITMInput.vue'
import ImpersonationTranslations from './Impersonation.translations'
import ImpersonationModel from './Impersonation.model'
import { ImpersonationService } from '@/services/Impersonation.service'
import { RequiredRule } from '@/generic/rules/Required'
import { loadWhile } from '@/generic/helpers'
import { CultureService } from '@/services/CultureService'

@Component({
  components: {
    'mitm-input': MITMInputComponent
  }
})
export default class ImpersonationComponent extends Vue {
  @Prop() translations!: ImpersonationTranslations

  private model: ImpersonationModel = { admin: '', password: '', user: '', culture: '' }
  private hasError: boolean = false
  private errorMessage: string = ''
  private rules: { [key: string]: Array<(value: any) => boolean | string> } = {
    required: [new RequiredRule().getValidator(this.translations.field.required)]
  }

  private impersonate () {
    this.model.culture = CultureService.getCultureCode()
    this.hasError = false
    loadWhile(this, this.translations.message.loading, () => ImpersonationService.impersonate(this.model)
      .then(response => {
        window.location.href = response.data
      })
      .catch(error => {
        switch (error.response.status) {
          case 401:
            this.errorMessage = this.translations.error.unauthorized
            break
          case 403:
            this.errorMessage = this.translations.error.forbidden
            break
          case 404:
            this.errorMessage = this.translations.error.notFound
            break
          default:
            this.errorMessage = this.translations.error.unknown
            break
        }
        this.hasError = true
      })
    )
  }
}
