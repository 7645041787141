





















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class TextBlockComponent extends Vue {
  @Prop() primaryColor!: string
  @Prop() secondaryColor!: string
  @Prop() actionTarget!: string
  @Prop() actionLink!: string
  @Prop() actionText!: string

  private get hasLink () : boolean {
    return !!this.actionLink
  }
}
