
























































































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class MITMSelectnputComponent extends Vue {
  @Prop() private options!: any[]
  @Prop() private label!: string
  @Prop() private value!: any
  @Prop() private optionLabel!: string
  @Prop() private optionValue!: string
  @Prop({ default: false }) private emitValue!: boolean
  @Prop() private rules!: Array<(value: string) => boolean | string>;
  @Prop({ default: true }) private floatPlaceholder!: boolean;
  @Prop({ default: true }) private clearable!: boolean
  @Prop({ default: true }) private useInput!: boolean
  @Prop({ default: false }) private readonly!: boolean
  @Prop({ default: '' }) private dataTest!: string
  @Prop({ default: false }) private multiple!: boolean
  @Prop({ default: false }) private useChips!: boolean
  @Prop({ default: false }) private square!: boolean
  @Prop({ default: false, type: Boolean }) private removeMargins!: boolean
  @Prop({ default: false }) private loading!: boolean
  @Prop({ default: false }) private staticLabel!: boolean
  @Prop({ default: '' }) private popupContentClass!: string
  @Prop({ default: false }) private noBackground!: boolean
  @Prop({ default: false }) private useLabel!: boolean

  private filteredOptions: any[] = this.options

  get floatingLabel () {
    return (this.hasValue() && this.floatPlaceholder) || this.$q.screen.lt.md || this.staticLabel ? this.label : ''
  }

  get placeholder () {
    return this.hasValue() ? '' : this.label
  }

  hasValue (): boolean {
    return this.localValue !== null && this.localValue !== undefined && (!this.multiple || (this.multiple && this.localValue.length !== 0))
  }

  get localValue () {
    return this.value
  }

  set localValue (val) {
    this.$emit('input', val)
  }

  public selectIndex (index : number) {
    this.localValue = [this.options[index]]
  }

  public selectIndexForSelectOption (index: number) {
    this.localValue = [this.options[index].label]
  }

  public clear () {
    this.localValue = null
  }

  public getLabelByIndex(index: number): string {
    return this.options[index].label
  }

  private inputChanged (value: string) {
    this.$emit('input-changed', value)
  }

  private filter (val : string, update : any) {
    update(() => {
      if (val === '') {
        this.filteredOptions = this.options
      } else {
        const needle = val.toLowerCase()
        this.filteredOptions = this.options.filter(
          v => v[this.optionLabel].toLowerCase().indexOf(needle) > -1
        )
      }
    })
  }

  private hasError (): boolean {
    if (this.$refs.qselect) {
      return (this.$refs.qselect as any).hasError
    }
    return false
  }
}
