
































































































































































































import { Component, Prop, Vue, Mixins } from 'vue-property-decorator'
import { FirstOffer } from './FirstOffer.model'
import { FirstOfferTranslations } from './FirstOffer.translations'
import { OfferService } from '@/services/OfferService'
import { AxiosResponse } from 'axios'
import { deepCopy, loadWhile, getUrlParameter, notifyNegative } from '@/generic/helpers'
import { OfferPrerequisiteData } from '../prerequisite/models/OfferPrerequisiteData.model'
import { SelectOption } from '@/generic/models/SelectOption'
import { PublicationLanguage } from '@/generic/models/PublicationLanguage'
import GtmEmployerMixin from '@/gtm/GtmEmployer'
import SkillHelpComponent from '../skill/SkillHelp.vue'

@Component({
  components: {
    'mitm-skill-help': SkillHelpComponent
  }
})
export default class FirstOfferComponent extends Mixins(GtmEmployerMixin) {
  @Prop() private translations!: FirstOfferTranslations
  @Prop() private activityAreas!: {}
  @Prop() private prerequisiteData!: OfferPrerequisiteData
  @Prop() private experienceData!: Array<SelectOption>
  @Prop() private culture!: string
  private currentStep = 1
  private model = new FirstOffer(this.culture)
  private disableNextStepButton = false
  private localStorageModelKey = 'createFirstOfferModel'
  private stepRefs = ['step1', 'step2']
  private hasError: boolean = false
  private errorMessage: string = ''
  private userId: number | null = null
  private inviteType: string = ''

  mounted (): void {
    this.inviteType = getUrlParameter('inviteType')
    if (this.inviteType === 'crm') {
      this.userId = Number.parseInt(getUrlParameter('userId'))
      this.companyInfo()
    }
    // Get saved model from the local storage
    const localStorageModel = localStorage.getItem(this.localStorageModelKey)
    if (localStorageModel) {
      const savedModel = JSON.parse(localStorageModel)

      deepCopy(savedModel, this.model)

      this.cleanLocalStorage()
    }
    window.addEventListener('beforeunload', () => {
      localStorage.setItem(
        this.localStorageModelKey,
        JSON.stringify(this.model)
      )
    })
  }

  private validate (): Promise<boolean> {
    const form = this.$refs[this.stepRefs[this.currentStep - 1]] as any
    return form.validate()
  }

  private pageButtonLabel (page: number): string | null {
    if (page < this.currentStep) {
      return null
    }
    return page.toString()
  }

  private changePageTo (page: number): void {
    if (page > this.currentStep && ((page - this.currentStep) === 1)) {
      this.validate().then(isValid => {
        if (isValid) {
          this.onCompleteOfferStep(this.currentStep, this.translations.common.button.nextStep, this.$userContext ? this.$userContext.confirmed : false)
          this.currentStep = page
          window.scrollTo(0, 0)
        }
      })
    } else if (page < this.currentStep) {
      this.currentStep = page
    }
  }

  private pageButtonIcon (page: number): boolean {
    return page < this.currentStep
  }

  private pageButtonColor (page: number): string {
    if (page === this.currentStep) {
      return 'active-page'
    }
    if (page < this.currentStep) {
      return 'previous-page'
    }
    return 'next-page'
  }

  private goToNextPage (): void {
    this.changePageTo(this.currentStep + 1)
  }

  private goToPreviousPage (): void {
    this.changePageTo(this.currentStep - 1)
  }

  private fillLater (): void {
    this.previsualize(true)
  }

  private previsualize (fillLater: boolean): void {
    if (this.model.company.additionalInfo.employeeCount === undefined ||
    this.model.company.additionalInfo.employeeCount < 1) {
      notifyNegative(this, this.translations.company.additionalInfo.message.required.field.employeeCount)
    } else {
      loadWhile(this, this.translations.offer.firstOffer.loading, () => {
        this.cleanLocalStorage()
        this.hasError = false
        this.model.fillLater = fillLater
        return OfferService.createFirstOffer(this.model)
          .then((response: AxiosResponse<any>) => {
            let confirmed = this.$userContext ? this.$userContext.confirmed : false
            this.onCompleteOfferStep(this.currentStep, this.translations.offer.button.previsualize, confirmed)
            this.onPreviewOffer(this.translations.offer.button.previsualize, confirmed)

            setTimeout(() => {
              window.location.href = response.data
            }, 1000)
          })
          .catch((error: any) => {
            this.hasError = true
            if (error.response.status === 400) {
              switch (error.response.data) {
                case 'company': {
                  this.errorMessage = this.translations.errors.company.cannotCreate
                  break
                }
                case 'offer': {
                  this.errorMessage = this.translations.errors.jobOffer.cannotCreate
                  break
                }
                case 'admin': {
                  this.errorMessage = this.translations.errors.company[error.response.data.message]
                  break
                }
              }
            } else {
              this.errorMessage = this.translations.errors.common.unknown
            }
          })
      })
    }
  }

  private cleanLocalStorage (): void {
    localStorage.removeItem(this.localStorageModelKey)
  }

  private changeLanguage (language : PublicationLanguage): void {
    this.model.offer.language.language = language
  }

  private companyInfo () : void {
    if (this.userId != null) {
      OfferService.getCompanyInfo(this.userId).then(result => {
        this.model.company.basicInfo.name = result.data.name ? result.data.name : ''
        this.model.company.basicInfo.address = result.data.address ? result.data.address : ''
        this.model.company.basicInfo.postalCode = result.data.postalCode ? result.data.postalCode : ''
        this.model.company.basicInfo.city = result.data.city ? result.data.city : ''
        this.model.company.basicInfo.phoneNumber = result.data.phoneNumber ? result.data.phoneNumber : ''
        this.model.userId = this.userId
        this.model.inviteType = this.inviteType
      })
    }
  }
}
