



































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { AuthentificationTitleTranslations } from '@/authentication/title/AuthentificationTitle.translations'
import { AuthentcationErrorTranslations } from '@/authentication/AuthentcationError.translations'
import ForgotPasswordTranslations from '@/forgot-password/ForgotPassword.translations'
import { SignIn } from './sign-in/SignIn.model'
import { SignInTranslations } from './sign-in/SignIn.translations'
import { SignUp } from './sign-up/SignUp.model'
import { SignUpTranslations } from './sign-up/SignUp.translations'
import { fullURL, getUrlParameter } from '@/generic/helpers'
import { Invitation } from '@/authentication/sign-up/Invitation.model'

@Component
export default class AuthentificationEmployerComponent extends Vue {
  @Prop() private signIn!: { initModel: SignIn, translations: SignInTranslations}
  @Prop() private register!: { initModel: SignUp, invitation: Invitation, translations: SignUpTranslations}
  @Prop() private culture!: string
  @Prop() private title!: AuthentificationTitleTranslations
  @Prop() private passwordTranslations!: ForgotPasswordTranslations
  @Prop() private errors!: AuthentcationErrorTranslations
  @Prop() private instructions!: string
  private displaySignUp = true
  private isUserNotAutorizedToCreateAccount = false
  private animation = false

  private onMouseOver () {
    this.animation = true
  }

  private onMouseLeave () {
    this.animation = false
  }

  mounted () {
    this.displaySignUp = getUrlParameter('su') !== 'false'
    let isCrm = getUrlParameter('inviteType') === 'crm'
    this.isUserNotAutorizedToCreateAccount = this.register.invitation === null && !isCrm
  }

  onsignupchange () {
    this.displaySignUp = !this.displaySignUp
    const url = fullURL()
    url.searchParams.set('su', `${this.displaySignUp}`)
    window.history.pushState(null, '', url.href)
  }
}
