















































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { ShoppingCart } from '../models/ShoppingCart'
import { ShoppingCartService } from '@/services/ShoppingCartService'
import { OrderService } from '@/services/OrderService'
import { EmployerCheckoutTranslations } from '../models/EmployerCheckout.translations'
declare var Stripe: any

@Component
export default class PaymentCardComponent extends Vue {
  @Prop() translations!: EmployerCheckoutTranslations
  @Prop() companyId!: number
  @Prop() culture!: string
  @Prop() recruiterEmail!: string
  @Prop() publishKey!: string
  @Prop() shoppingCart!: ShoppingCart
  private isButtonPayClicked = false
  private paymentError = false
  stripe: any
  cardNumber: any
  cardExpiry: any
  cardCvc: any

  mounted () {
    this.loadStripeParams()
  }

  beforeUnmount () {
    this.cardNumber.destroy()
    this.cardExpiry.destroy()
    this.cardCvc.destroy()
  }

  private loadStripeParams () {
    this.stripe = Stripe(this.publishKey)
    const elements = this.stripe.elements()
    this.cardNumber = elements.create('cardNumber')
    this.cardNumber.mount('#card-number')
    this.cardExpiry = elements.create('cardExpiry')
    this.cardExpiry.mount('#card-expiry')
    this.cardCvc = elements.create('cardCvc')
    this.cardCvc.mount('#card-cvc')
  }

  async confirmPayment () {
    this.isButtonPayClicked = true
    this.paymentError = false
    this.createOrder()
  }

  private deleteShoppingCart (cartId: number) {
    ShoppingCartService.deleteShoppingCart(cartId)
      .catch(() => {
        this.paymentError = true
      })
  }

  private createOrder () {
    OrderService.createOrder(this.shoppingCart, this.companyId, this.culture)
      .catch(() => {
        this.paymentError = true
      })
      .finally(async () => {
        if (!this.paymentError) {
          await this.paymentWithStripe()
        } else {
          this.isButtonPayClicked = false
        }
      })
  }

  async paymentWithStripe () {
    const paymentResult = await this.confirmPaymentWithStripe(this.shoppingCart)
    if (paymentResult.paymentIntent) {
      this.deleteShoppingCart(this.shoppingCart.cartId)
      if (this.culture === 'en-US') {
        window.location.href = '/en/employer/payment-confirmation'
      } else {
        window.location.href = '/fr/employeur/confirmation-paiement'
      }
    } else {
      this.paymentError = true
      this.isButtonPayClicked = false
      this.$forceUpdate()
    }
  }

  async confirmPaymentWithStripe (shoppingCart: ShoppingCart) {
    return this.stripe.confirmCardPayment(shoppingCart.shoppingCartToken, {
      payment_method: {
        card: this.cardNumber,
        billing_details: {
          name: this.recruiterEmail
        }
      }
    })
  }
}
