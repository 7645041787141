























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class MITMSection extends Vue {
  @Prop() private sectionTitle!: string
  @Prop() private sectionNumber!: number
  @Prop({ default: '' }) private sectionPadding!: string
  @Prop({ default: true }) private padding!: boolean
}
