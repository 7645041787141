
































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import ExternalDocument from '../../../models/meetings/external/ExternalDocument.models'
import ExternalMeetingTranslations from '../../../models/meetings/external/ExternalMeeting.translations'
import MITMPromptComponent from '@/generic/components/MITMPrompt.vue'
import { DialogResult } from '@/generic/models/DialogResult'
import { notify, notifyNegative } from '@/generic/helpers'
import AdminConsoleMeetingService from '@/admin/console/services/AdminConsoleMeetingService'

@Component
export default class AdminExternalMeetingCardComponent extends Vue {
  @Prop() document !: ExternalDocument
  @Prop() translations !: ExternalMeetingTranslations
  public seeDialog: boolean = false
  private loading: boolean = false

  private toggleDialog () : void {
    this.seeDialog = !this.seeDialog
  }

  private deleteConfirmation () {
    (this.$refs.confirmPrompt as MITMPromptComponent).open()
  }

  private deleteMeeting (result: DialogResult) {
    if (result === DialogResult.Ok) {
      this.loading = true
      return AdminConsoleMeetingService.deleteExternalMeetingDocument(this.document.name)
        .then((response) => {
          this.loading = false
          notify(this, this.translations.page.successNotify)
          this.$emit('hide')
        })
        .catch((error) => {
          if (error) {
            notifyNegative(this, error.message)
          }
          this.loading = false
        })
    }
  }
}

