


























































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { ITranslatable } from '@/generic/models/ITranslatable'
import { CommonDescriptionTranslations } from '@/generic/models/CommonDescription.translations'
import { RequiredRule } from '@/generic/rules/Required'
import { PublicationLanguage } from '@/generic/models/PublicationLanguage'

@Component
export default class OfferDescriptionComponent extends Vue {
  @Prop() private translations!: CommonDescriptionTranslations
  @Prop() private value!: ITranslatable
  @Prop() private language!: PublicationLanguage
  @Prop() private required!: boolean

  private selectedTab : string = 'francais'
  private displayErrors = false

  private rules: { [key: string]: Array<(value: any) => boolean | string> } = {
    fr: [
      new RequiredRule().getValidator('', () => this.publicationLanguageFrench(this.language))
    ],
    en: [
      new RequiredRule().getValidator('', () => this.publicationLanguageEnglish(this.language))
    ]
  };

  mounted () {
    this.displayErrors = true
    this.value.fr = this.value.fr || ''
    this.value.en = this.value.en || ''
  }

  private get tab () {
    if (this.language === PublicationLanguage.FrenchAndEnglish) {
      return this.selectedTab
    }
    return this.publicationLanguageFrench(this.language) ? 'francais' : 'english'
  }

  private set tab (lang : string) {
    this.selectedTab = lang
  }

  private publicationLanguageFrench (language: PublicationLanguage) {
    return language === PublicationLanguage.FrenchAndEnglish || language === PublicationLanguage.French
  }

  private publicationLanguageEnglish (language: PublicationLanguage) {
    return language === PublicationLanguage.FrenchAndEnglish || language === PublicationLanguage.English
  }

  private fieldHasError (field: string): boolean {
    return this.$refs[field] && (this.$refs[field] as any).hasError
  }

  private onFocus (tab: string): void {
    this.tab = tab
  }
}
