






















































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { JobOfferSummary } from '@/offer/summary/JobOfferSummary.model'
import { dateFilter } from '@/generic/filters/Date'
import { Culture } from '@/generic/models/Culture'
import JobOfferListTranslations from '../../models/jobOffers/JobOfferList.translations'
import { CultureService } from '@/services/CultureService'
import { Status } from '@/generic/models/Status'

@Component
export default class jobOfferListCardComponent extends Vue {
  @Prop() private jobId!: number
  @Prop() jobOffer!: JobOfferSummary
  @Prop() translations!: JobOfferListTranslations
  private hasError = false
  private errorMessage: string = ''
  private href:string = ''

  mounted () {
    this.href = window.location.origin + this.getHref()
  }

  private handleError (error: any) {
    this.hasError = true
    if (error.response.status === 404) {
      this.errorMessage = this.translations.error.notFound
    } else if (error.response.status === 403) {
      this.errorMessage = this.translations.error.forbidden
    } else {
      this.errorMessage = this.translations.error.unknown
    }
  }

  private getPublishedDate (): string {
    const culture = CultureService.getCulture()
    if (this.jobOffer.publishedDate === null || this.jobOffer.publishedDate === undefined) {
      return 'N/A'
    }
    return dateFilter(this.jobOffer.publishedDate, culture)
  }

  private getExpiryDate (): string {
    const culture = CultureService.getCulture()
    if (this.jobOffer.expirationDate === null || this.jobOffer.expirationDate === undefined) {
      return 'N/A'
    }
    return dateFilter(this.jobOffer.expirationDate, culture)
  }

  private getHref () : string {
    if (CultureService.getCulture() === Culture.English) {
      return `/en/site-admin/console-edit-job-offer/${this.jobId}`
    } else {
      return `/fr/admin/console-modification-offre/${this.jobId}`
    }
  }

  get isClosed () : boolean {
    return this.jobOffer.status === Status.Closed ||
           this.jobOffer.status === Status.Expired
  }

  get isPublished () : boolean {
    return this.jobOffer.status === Status.Published
  }

  getStatus (status : Status) {
    if (status === Status.Published) {
      return this.translations.status.published
    } else if (status === Status.Closed) {
      return this.translations.status.closed
    } else if (status === Status.Expired) {
      return this.translations.status.expired
    } else if (status === Status.Archived) {
      return this.translations.status.archived
    } else if (status === Status.AwaitingReview) {
      return this.translations.status.awaitingReview
    } else if (status === Status.Reviewed) {
      return this.translations.status.reviewed
    } else if (status === Status.ReadyToBePublished) {
      return this.translations.status.readyToBePublished
    } else {
      return this.translations.status.draft
    }
  }
}

