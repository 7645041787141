















































































































































































































import { Component, Model, Prop, Vue } from 'vue-property-decorator'
import { CreateProfileTranslations } from '@/profile/CreateProfile.translations'
import { CreateProfileModel } from '@/profile/CreateProfile.model'
import { SelectOption } from '@/generic/models/SelectOption'
import { PublicationLanguage } from '@/generic/models/PublicationLanguage'
import { ProfileCandidateService } from '@/services/ProfileCandidateService'
import { AxiosResponse } from 'axios'
import { getUrlParameter, loadWhile, notify, notifyNegative } from '@/generic/helpers'
import { LanguageProficiency } from '@/generic/models/LanguageProficiency.model'
import { CandidateLanguageProficiency } from '@/generic/models/CandidateLanguageProficiency.model'

@Component
export default class CandidateProfileComponent extends Vue {
  @Prop() private translations!: CreateProfileTranslations
  @Prop() private experienceData !: Array<SelectOption>
  @Prop() private immigrationProcess !: Array<SelectOption>
  @Prop() private countriesList !: Array<SelectOption>
  @Prop() private candidateId!: number
  @Prop() private languageProficienciesListFr !: Array<LanguageProficiency>
  @Prop() private languageProficienciesListEn !: Array<LanguageProficiency>

  private model = new CreateProfileModel()
  private hasError = false
  private errorMessage = ''
  private isModelLoaded = false

  private loadModel (dto: CreateProfileModel) {
    this.model = dto
    this.model.language = {
      language: PublicationLanguage.FrenchAndEnglish
    }

    if (this.model.languageProficiencyFrId <= 0 || this.model.languageProficiencyEnId <= 0) {
      notify(this, this.translations.proficiency.message)
    }

    if (this.model.languageProficiencies === null) {
      this.model.languageProficiencies = []
    }
    this.isModelLoaded = true
  }

  private mounted (): void {
    loadWhile(this, this.translations.common.message.loading, () => ProfileCandidateService.getProfile(this.candidateId)
      .then(response => {
        this.loadModel(response.data)
      })
    )
  }

  private validate (): Promise<boolean> {
    const form = this.$refs['candidateForm'] as any
    return form.validate()
  }

  private saveAndReturnToJobApplication (): void {
    this.save()
    if (!this.hasError && this.validateInterestAreas()) {
      this.validate().then(isValid => {
        if (isValid) {
          window.location.href = this.translations.returnToJobApplicationLinkValue
        }
      })
    }
  }

  private save (): void {
    this.validate().then(isValid => {
      if (isValid) {
        if (!this.validateInterestAreas()) {
          notifyNegative(this, this.translations.profileCreate.interestArea.error.empty)
        } else if (!this.validateSkills() || !this.validateLanguageProficiencies()) {
          this.hasError = true
          notifyNegative(this, this.translations.common.message.allRequired)
        } else {
          const returnUrl = getUrlParameter('ReturnUrl')
          loadWhile(this, this.translations.common.message.loading, () => {
            this.hasError = false
            return ProfileCandidateService.saveProfile(this.model)
              .then((response: AxiosResponse<CreateProfileModel>) => {
                notify(this, this.translations.common.notifications.modified)
                this.loadModel(response.data)
              })
              .catch((error: any) => {
                this.hasError = true
                if (error.response.status === 404) {
                  this.errorMessage = this.translations.errors.common.notFound
                } else if (error.response.status === 403) {
                  this.errorMessage = this.translations.errors.common.forbidden
                } else {
                  this.errorMessage = this.translations.profileCreate.errors[error.response.data.message]
                }
              })
          })
        }
      }
    })
  }

  private validateLanguageProficiencies () {
    if (this.model.languageProficiencyFrId === -1 || this.model.languageProficiencyEnId === -1) {
      return false
    } else {
      return true
    }
  }

  private validateInterestAreas () {
    if (this.model.interestAreaGroups === undefined || this.model.interestAreaGroups === null || this.model.interestAreaGroups.length === 0) {
      return false
    } else {
      return true
    }
  }

  private validateSkills (): boolean {
    if (this.model.skills.length < 2) {
      return false
    }

    let isEmptyRow = false
    this.model.skills.forEach(element => {
      if (this.isEmptyOrSpaces(element.fr) && this.isEmptyOrSpaces(element.en)) {
        isEmptyRow = true
      }
    })

    return isEmptyRow === false
  }

  private isEmptyOrSpaces (str: string): boolean {
    return str === null || str.match(/^ *$/) !== null
  }

  private selectedLanguageProficiencyFr (id: number) {
    this.model.languageProficiencyFrId = id
  }

  private selectedLanguageProficiencyEn (id: number) {
    this.model.languageProficiencyEnId = id
  }

  private selectedLanguageProficiency (value: Array<CandidateLanguageProficiency>) {
    this.model.languageProficiencies = value
  }

  private getWeekDayTranslation (day: string) {
    if (day === 'Sunday') {
      return this.translations.profileCreate.title.section.availability.sunday
    } else if (day === 'Monday') {
      return this.translations.profileCreate.title.section.availability.monday
    } else if (day === 'Tuesday') {
      return this.translations.profileCreate.title.section.availability.tuesday
    } else if (day === 'Wednesday') {
      return this.translations.profileCreate.title.section.availability.wednesday
    } else if (day === 'Thursday') {
      return this.translations.profileCreate.title.section.availability.thursday
    } else if (day === 'Friday') {
      return this.translations.profileCreate.title.section.availability.friday
    } else {
      return this.translations.profileCreate.title.section.availability.saturday
    }
  }

  private getPeriodTitleTranslation (index: number) {
    if (index === 0) {
      return this.translations.profileCreate.title.section.availability.night
    } else if (index === 1) {
      return this.translations.profileCreate.title.section.availability.morning
    } else if (index === 2) {
      return this.translations.profileCreate.title.section.availability.afternoon
    } else {
      return this.translations.profileCreate.title.section.availability.evening
    }
  }

  private selectColor (selectedDay: string, index: number) {
    if (this.model.availabilityPreferences.days.find(x => x.day === selectedDay)?.periods.period[index] === true) {
      return 'bg-azure'
    }
    return 'bg-mitm-light-grey'
  }

  private generateId (selectedDay: string, index: number, htmlElement: string) {
    if (htmlElement === 'color') {
      return selectedDay + '-' + index
    }
    return selectedDay + '-' + index + '-icon'
  }

  private selectPeriod (selectedDay: string, index: number) {
    this.model.availabilityPreferences.days.find(x => x.day === selectedDay)!.periods.period[index] = !this.model.availabilityPreferences.days.find(x => x.day === selectedDay)!.periods.period[index]
    let periodComponent = document.getElementById(selectedDay + '-' + index)
    if (periodComponent !== null) {
      periodComponent.classList.toggle('bg-azure')
      periodComponent.classList.toggle('bg-mitm-light-grey')
    }
    let iconComponent = document.getElementById(selectedDay + '-' + index + '-icon')
    if (iconComponent !== null) {
      iconComponent.classList.toggle('hide')
    }
  }
}
