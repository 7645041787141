import { IRule } from './IRule'

export class IsNumber implements IRule {
  static regex = /^\d*$/i;
  getValidator (errorMessage: string, activate?: () => boolean): (value: string) => boolean|string {
    return (value: string): boolean|string => {
      if (activate === undefined || (activate && activate())) {
        if (typeof value === 'string') {
          return !!value.match(IsNumber.regex) || errorMessage
        }
      }
      return true
    }
  }
}
