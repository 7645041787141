
























































































































































import { loadWhile, notify } from '@/generic/helpers'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Company } from '@/company/Company.model'
import { EditCompanyTranslations } from './EditCompany.translations'
import { AxiosResponse } from 'axios'
import { CompanyService } from '@/services/CompanyService'
import { ActivityArea } from '@/activity-area/ActivityArea.model'
import { ActivityAreaInfo } from '@/activity-area/ActivityAreaInfo.model'
import { AccountTranslations } from '@/authentication/account/Account.translations'
import ValidatePasswordDialog from '@/authentication/password/ValidatePassword.vue'
import { ValidatePasswordModel } from '@/authentication/password/ValidatePassword.model'
import { ChangeAccountModel } from '@/authentication/account/ChangeAccount.model'

@Component
export default class EditCompanyComponent extends Vue {
  @Prop() private translations!: EditCompanyTranslations
  @Prop() private activityAreas !: ActivityArea[]
  @Prop() private companyId !: number
  @Prop() private culture !: string
  @Prop() private currentUser !: number
  @Prop() private account!: ChangeAccountModel

  private currentStep = 1
  private model = new Company()
  private hasError: boolean = false
  private errorMessage: string = ''
  private loaded : boolean = false
  private sectionPadding : string = 'U--mt--30'

  private handleError (error: any) {
    this.hasError = true
    if (error.response.status === 404) {
      this.errorMessage = this.translations.errors.common.notFound
    } else if (error.response.status === 403) {
      this.errorMessage = this.translations.errors.common.forbidden
    } else {
      this.errorMessage = this.translations.errors.common.unknown
    }
  }

  private mounted () {
    loadWhile(this, this.translations.common.message.loading, () => CompanyService.getCompany(this.companyId)
      .then(response => {
        this.model = response.data
        this.adjustActivities(this.model.activityArea, this.activityAreas)
        this.loaded = true
      })
      .catch(error => {
        if (error.response.data.message === undefined) {
          this.handleError(error)
        } else {
          this.hasError = true
          this.errorMessage = this.translations.errors.company[error.response.data.message]
        }
      })
    )
  }

  private adjustActivities (activites : ActivityAreaInfo, areas: ActivityArea[]) {
    this.adjustActivityName(activites.primaryActivity, areas)
    this.adjustActivityName(activites.secondaryActivity, areas)
  }

  private adjustActivityName (activity : ActivityArea | undefined, areas: ActivityArea[]) {
    if (activity && areas) {
      const found = areas.find(function (area) {
        if (activity) {
          return area.id === activity.id
        }
        return false
      })

      activity.name = found ? found.name : ''
    }
  }

  private saveCompany () {
    this.hasError = false
    this.validate()
      .then(isValid => {
        if (isValid) {
          this.model.culture = this.culture
          loadWhile(this, this.translations.company.edit.loading.saving, () => CompanyService.saveCompany(this.model)
            .then(response => {
              this.model = response.data
              this.adjustActivities(this.model.activityArea, this.activityAreas)
              notify(this, this.translations.company.edit.notify.savedAndRecruiterModified)
            })
            .catch((error) => {
              if (error.response.data.message === undefined) {
                this.handleError(error)
              } else {
                this.hasError = true
                this.errorMessage = this.translations.errors.colleague[error.response.data.message]
              }
            })
          )
        }
      })
  }

  private deleteCompany () {
    (this.$refs.validatePasswordDialog as ValidatePasswordDialog).open().then((event: ValidatePasswordModel | undefined) => {
      if (event) {
        loadWhile(this, this.translations.common.message.loading, () => CompanyService.deleteCompany(event.password).then(response => {
          notify(this, this.translations.company.edit.notify.deleted)
          setTimeout(() => {
            window.location.href = '/'
          }, 3000)
        }))
      }
    })
  }

  private get validatePasswordTranslations (): AccountTranslations {
    return {
      auth: this.translations.auth,
      common: this.translations.common,
      error: this.translations.errors.auth,
      companyDeleted: this.translations.company.edit.notify.deleted
    }
  }

  private validate (): Promise<boolean> {
    const form = this.$refs['editCompanyForm'] as any
    return form.validate()
  }
}
