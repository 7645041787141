















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class TextEditorComponent extends Vue {
  @Prop() value !: string
  @Prop() propertyName !: string
  @Prop() textarea !: boolean

  private get text (): string {
    return this.value
  }

  private set text (value: string) {
    this.$emit('dispatch', value)
  }
}
