import { AxiosPromise } from 'axios'
import { BASE_API } from './api-common'
import { CultureService } from './CultureService'
import { SelectOption } from '@/generic/models/SelectOption'
import Product from '@/dashboard/employer-package/models/Product'

export class ProductService {
  public static getMissionsProducts () : AxiosPromise<Array<Product>> {
    return BASE_API.get('product/getMissionsProducts', {
      params: {
        cultureCode: CultureService.getCultureCode()
      }
    })
  }

  public static getCohortsProducts () : AxiosPromise<Array<Product>> {
    return BASE_API.get('product/getCohortsProducts', {
      params: {
        cultureCode: CultureService.getCultureCode()
      }
    })
  }

  public static getJobOffersProducts () : AxiosPromise<Array<Product>> {
    return BASE_API.get('product/getJobOffersProducts', {
      params: {
        cultureCode: CultureService.getCultureCode()
      }
    })
  }

  public static getPermitsProducts () : AxiosPromise<Array<Product>> {
    return BASE_API.get('product/getPermitsProducts', {
      params: {
        cultureCode: CultureService.getCultureCode()
      }
    })
  }

  public static getEmployeesNumber (companyId: number) : AxiosPromise<number> {
    return BASE_API.get('product/getEmployeesNumber', {
      params: {
        companyId: companyId,
        cultureCode: CultureService.getCultureCode()
      }
    })
  }

  public static getProductsForDropDownForCompany(companyId: number, cultureCode: string) : AxiosPromise<SelectOption[]> {
    return BASE_API.get<SelectOption[]>('product/getDropdownEventsForCompany', {
      params: {
        companyId: companyId,
        cultureCode: cultureCode
      }
    })
  }
}
