
























import { Component, Prop, Vue } from 'vue-property-decorator'
import MediaObject from '../models/MediaObject'

@Component
export default class SmallSharedContentComponent extends Vue {
  @Prop() image!: MediaObject
  @Prop() textColor!:string
  @Prop() link!: string

  private get textStyle () {
    return {
      color: this.textColor,
      'text-align': (this.$q.screen.lt.md ? 'center' : 'left'),
      'padding-bottom': (this.$q.screen.lt.md ? '40px' : '0')
    }
  }

  private get height () {
    if (this.$q.screen.gt.md) {
      return {
        'height': '140px'
      }
    }
    return {}
  }
  private get imageStyle () {
    if (this.$q.screen.lt.md) {
      return {
        'max-width': '100%'
      }
    }
    return {}
  }
}
