
















































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { getUrlParameter, loadWhile, notify } from '@/generic/helpers'
import CandidateOffersTranslations from './CandidateOffers.translations'
import { JobOfferSummary } from '@/offer/summary/JobOfferSummary.model'
import CandidateService from '@/services/CandidateService'

@Component
export default class CandidateOffersTabMenuComponent extends Vue {
  @Prop() candidateId !: number
  @Prop() translations !: CandidateOffersTranslations
  private jobs: JobOfferSummary[] = []
  private localJobsStore: JobOfferSummary[] = []
  private hasError: boolean = false
  private showFavoritesJobs: boolean = false
  private errorMessage = ''
  private tab: string = '1'
  private selectedIcon: string = 'check_box_outline_blank'

  private handleError (error: any) {
    this.hasError = true
    if (error.response.status === 404) {
      this.errorMessage = this.translations.error.unknown
    }
  }

  mounted () {
    this.loadAppliedOffers()
    if (getUrlParameter('displayNotification')) {
      notify(this, this.translations.candidateOffers.applied)
    }
  }

  loadAppliedOffers () {
    this.initialiseCheckBox()
    loadWhile(this, this.translations.loading, () => CandidateService.getMyAppliedOffers(this.candidateId)
      .then(list => (
        this.jobs = list.data.sort((a, b) => a.isFavorite && !b.isFavorite ? -1 : 1)
      ))
      .catch(error => this.handleError(error))
    )
  }

  loadSuggestedOffers () {
    this.initialiseCheckBox()
    loadWhile(this, this.translations.loading, () => CandidateService.getSuggestedJobByCandidate(this.candidateId)
      .then(list => (
        this.jobs = list.data.sort((a, b) => a.isFavorite && !b.isFavorite ? -1 : 1)
      ))
      .catch(error => this.handleError(error))
    )
  }

  showOnlyFavoritesJobs () {
    this.localJobsStore = this.jobs.filter((x) => x.isFavorite)
    if (this.showFavoritesJobs) {
      this.initialiseCheckBox()
    } else {
      this.selectedIcon = 'check_box'
      this.showFavoritesJobs = true
    }
  }

  initialiseCheckBox () {
    this.showFavoritesJobs = false
    this.selectedIcon = 'check_box_outline_blank'
  }
}
