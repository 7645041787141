














































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { JobOfferWorkInfosTranslations } from './JobOfferWorkInfos.translations'
import { CommonTranslations } from '@/generic/models/Common.translations'
import { PublicationLanguage } from '@/generic/models/PublicationLanguage'
import { JobOfferWorkInfosDto } from './JobOfferWorkInfosDto.model'
import { WorkInfoData } from '@/offer/jobOfferWorkInfos/WorkInfoData.model'

@Component
export default class JobOfferWorkInfosComponent extends Vue {
  @Prop() private translations!: JobOfferWorkInfosTranslations
  @Prop() private commonTranslations!: CommonTranslations
  @Prop() private value!: JobOfferWorkInfosDto
  @Prop() private WorkInfoData !: WorkInfoData
  @Prop() private language!: PublicationLanguage

  mounted() {
    this.value.unionize = this.value.unionize ? 1 : 0
    this.value.salaryIsAnnually = this.value.salaryIsAnnually ? 1 : 0
  }
}
