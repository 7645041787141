



































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { ColleagueTranslations } from '@/company/colleagues/Colleagues.translations'
import { CommonTranslations } from '@/generic/models/Common.translations'
import { Colleague } from './Colleague.model'
import { RecruiterService } from '@/services/RecruiterService'
import { IsEmail } from '@/generic/rules/IsEmail'
import { loadWhile } from '@/generic/helpers'
import { CultureService } from '../../services/CultureService'
import { RequiredRule } from '../../generic/rules/Required'
import AdminConsoleCompanyService from '@/admin/console/services/AdminConsoleCompanyService'

@Component
export default class ColleagueLineComponent extends Vue {
  @Prop() translations !: ColleagueTranslations
  @Prop() commonTranslations !: CommonTranslations
  @Prop() colleague!: Colleague
  @Prop() allowInvite!: boolean
  @Prop() index!: number
  @Prop() companyId!: number
  @Prop() invited: boolean = false
  @Prop({ default: false }) private isAdmin!: boolean

  private errorMessage: string = ''
  private hasError: boolean = false

  private rules: { [key: string]: Array<(value: any) => boolean | string> } = {
    email: [
      new IsEmail().getValidator(this.commonTranslations.field.email.format),
      new RequiredRule().getValidator(this.commonTranslations.field.email.format)]
  }

  private invite (id : number) {
    this.hasError = false;
    (this.$refs.newColleagueForm as any).validate().then((success: boolean) => {
      if (success) {
        loadWhile(this, this.translations.message.invitingColleague, () => {
          return RecruiterService.inviteColleagues(this.companyId, CultureService.getCultureCode(), this.colleague)
            .then((response) => {
              this.invited = true
              this.colleague.invited = true
              this.$emit('invited', this.index)
            })
            .catch(() => {
              this.hasError = true
              this.errorMessage = this.translations.error.userExists
            })
        })
      } else {
        (this.$refs.colleagues as any).focus()
      }
    })
  }

  private inviteByAdmin (id : number) {
    this.hasError = false;
    (this.$refs.newColleagueForm as any).validate().then((success: boolean) => {
      if (success) {
        loadWhile(this, this.translations.message.invitingColleague, () => {
          return AdminConsoleCompanyService.inviteColleagues(this.companyId, CultureService.getCultureCode(), this.colleague)
            .then((response) => {
              this.invited = true
              this.colleague.invited = true
              this.$emit('invited', this.index)
            })
            .catch(() => {
              this.hasError = true
              this.errorMessage = this.translations.error.userExists
            })
        })
      } else {
        (this.$refs.colleagues as any).focus()
      }
    })
  }

  private deleteRow () {
    this.$emit('delete-row', this.index)
  }
}
