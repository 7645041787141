

















import { Component, Vue, Prop } from 'vue-property-decorator'
import { OfferService } from '@/services/OfferService'
import { JobOfferSummary } from '@/offer/summary/JobOfferSummary.model'
import JobOfferCardTranslations from '@/offer/job-offer-list/jobOfferCard.translations'
import { loadWhile } from '@/generic/helpers'
import SearchTranslations from '@/search/search.translations'

@Component
export default class MissionOffersComponent extends Vue {
  @Prop() private missionId!: string
  @Prop() private currentCulture!: string
  @Prop() private translations!: JobOfferCardTranslations
  @Prop() private searchTranslations!: SearchTranslations
  @Prop() private userIsCandidate !: boolean
  @Prop() private pageSize !: number
  @Prop() private activityAreas!: {}
  @Prop() private missionFilter!: number
  @Prop() private seed!: string
}

