


















































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { MissionPageTranslations } from '../missions/translations/MissionPage.translations'
import { loadWhile } from '@/generic/helpers'
import MissionService from '@/services/Mission.service'
import { MissionEvent } from '../models/MissionEvent'
import { EventBlockTranslations } from './EventBlock.translations'

@Component
export default class EventBlockComponent extends Vue {
  @Prop() private primaryColor!: string
  @Prop() private secondaryColor!: string
  @Prop() private title!: string
  @Prop() private allEvents!: string
  @Prop() private linkText!: string
  @Prop() private translations!: EventBlockTranslations
  @Prop({ default: 0 }) private ignoreEventId!: number
  private hasError = false
  private errorMessage = ''
  private events: MissionEvent[] = []
  private model = 0

  private mounted (): void {
    loadWhile(this, 'Loading', () => MissionService.getEvents(this.ignoreEventId).then((response) => {
      this.events = response.data
    }).catch((error) => {
      this.handleError(error)
    }))
  }

  private handleError (error: any): void {
    this.hasError = true
    if (error.response.status === 404) {
      this.errorMessage = this.translations.error.notFound
    } else if (error.response.status === 403) {
      this.errorMessage = this.translations.error.forbidden
    } else {
      this.errorMessage = this.translations.error.unknown
    }
  }
}
