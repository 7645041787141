




















































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { loadWhile } from '@/generic/helpers'
import ExternalMeeting from '@/admin/console/models/meetings/external/ExternalMeeting.models'
import ExternalMeetingTranslations from '@/admin/console/models/meetings/external/ExternalMeeting.translations'
import ExternalDocument from '@/admin/console/models/meetings/external/ExternalDocument.models'
import AdminConsoleMeetingService from '@/admin/console/services/AdminConsoleMeetingService'

@Component
export default class AdminExternalMeetingDetailsComponent extends Vue {
  @Prop() document!: ExternalDocument
  @Prop() translations !: ExternalMeetingTranslations

  public culture!: string
  public meetings: Array<ExternalMeeting> = []
  public loaded = false
  public hasError = false
  public errorMessage = ''
  public columns = [
    { name: 'index', label: '#', field: 'index', sortable: true },
    { name: 'companyName', label: this.translations.details.employer, field: 'companyName', sortable: true },
    { name: 'recruiterName', label: this.translations.details.recruiterName, field: 'recruiterName', sortable: true },
    { name: 'follower', label: this.translations.details.follower, field: 'follower', sortable: true },
    { name: 'companyAdministrativeRegion', label: this.translations.details.region, field: 'companyAdministrativeRegion', sortable: true },
    { name: 'missionName', label: this.translations.details.mission, field: 'missionName', sortable: true },
    { name: 'candidatureNumber', label: this.translations.details.candidateNumber, field: 'candidatureNumber', sortable: true },
    { name: 'candidateName', label: this.translations.details.candidate, field: 'candidateName', sortable: true },
    { name: 'candidateEmail', label: this.translations.details.candidateEmail, field: 'candidateEmail', sortable: true },
    { name: 'isEmployed', label: this.translations.details.isCandidate, field: 'isEmployed', sortable: true },
    { name: 'citizenship', label: this.translations.details.citizenship, field: 'citizenship', sortable: true },
    { name: 'country', label: this.translations.details.country, field: 'country', sortable: true },
    { name: 'countrySubdivision', label: this.translations.details.residenceRegion, field: 'countrySubdivision', sortable: true },
    { name: 'city', label: this.translations.details.city, field: 'city', sortable: true },
    { name: 'candidateStartDate', label: this.translations.details.candidateStartDate, field: 'candidateStartDate', sortable: true },
    { name: 'status', label: this.translations.details.status, field: 'status', sortable: true },
    { name: 'offerName', label: this.translations.details.offer, field: 'offerName', sortable: true },
    { name: 'industry', label: this.translations.details.sector, field: 'industry', sortable: true },
    { name: 'cnpCode', label: this.translations.details.cnp, field: 'cnpCode', sortable: true },
    { name: 'followUp', label: this.translations.details.followUp, field: 'followUp' },
    { name: 'followUpThreeMonth', label: this.translations.details.followUp3, field: 'followUpThreeMonth' },
    { name: 'followUpFiveMonth', label: this.translations.details.followUp5, field: 'followUpFiveMonth', sortable: true },
    { name: 'salary', label: this.translations.details.salary, field: 'salary', sortable: true }
  ]
  public searchTerms: string = ''
  public visibleColumns = [
    'index',
    'companyName',
    'recruiterName',
    'follower',
    'companyAdministrativeRegion',
    'missionName',
    'candidatureNumber',
    'candidateName',
    'candidateEmail',
    'isEmployed',
    'citizenship',
    'country',
    'countrySubdivision',
    'city',
    'candidateStartDate',
    'status',
    'offerName',
    'industry',
    'cnpCode',
    'followUp',
    'followUpThreeMonth',
    'followUpFiveMonth',
    'salary'
  ]
  public pagination = { rowsPerPage: 50 }

  mounted (): void {
    this.meetings = []
    loadWhile(this, '', () => Promise.all([
      this.loadMeetings()
    ]).then(() => {
      this.loaded = true
    }).catch(error => {
      this.handleError(error)
    }))
  }

  private handleError (error: any): void {
    this.hasError = true
    if (error && error.response.status === 404) {
      this.errorMessage = 'error'
    } else {
      this.errorMessage = error.message
    }
  }

  private loadMeetings(): Promise<void> {
    this.hasError = false
    return AdminConsoleMeetingService.getExternalDocumentMeetings(this.document.name)
      .then((result) => {
        this.meetings = result.data.entries
        this.meetings.forEach((row, index) => {
          row.index = index + 1
        })
      })
      .catch((error) => {
        this.handleError(error)
      })
  }
}
