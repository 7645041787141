



























import { Component, Prop, Vue } from 'vue-property-decorator'
import MediaObject from '../models/MediaObject'

@Component
export default class ButtonImageComponent extends Vue {
  @Prop() private text!: string
  @Prop() private link!: string
  @Prop() private media!: MediaObject
  private animation = false

  private onMouseOver () {
    this.animation = true
  }

  private onMouseLeave () {
    this.animation = false
  }
}
