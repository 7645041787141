<script>
import { Doughnut } from 'vue-chartjs'

export default {
  extends: Doughnut,
  // eslint-disable-next-line vue/require-prop-types
  props: ['chartData', 'chartOptions', 'plugin'],
  mounted () {
    if (this.plugin) {
      this.addPlugin(this.plugin)
    }
    this.renderChart(this.chartData, this.chartOptions)
  }
}
</script>
