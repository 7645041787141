







































import { Component, Prop, Vue } from 'vue-property-decorator'
import { FooterTranslations } from '../translations/Footer.translations'

@Component
export default class BlockTimeComponent extends Vue {
  @Prop() private translations !: FooterTranslations

  private static readonly degreesPerMinute = 6 // 360 / 60
  private static readonly degreesPerHour = 30 // 360 / 12
  private static readonly minuteDefaultPosition = -90
  private static readonly hourDefaultPosition = -90
  private static readonly secondsPerHour = 3600
  private static readonly minutesPerHour = 60
  private static readonly secondsPerMinute = 60
  private time = { hours: 0, minutes: 0, seconds: 0 }
  private currentDegrees = { hours: '', minutes: '' }

  mounted () {
    this.calculateTime()
    setInterval(() => this.calculateTime(), 10000)
  }

  private calculateTime () {
    const date = new Date(new Date().toLocaleString('en-US', { timeZone: 'America/Montreal' }))
    this.time.hours = date.getHours()
    this.time.minutes = date.getMinutes()
    this.time.seconds = date.getSeconds()
    this.currentDegrees = {
      hours: `rotate(${BlockTimeComponent.hourDefaultPosition + this.time.hours * BlockTimeComponent.degreesPerHour + BlockTimeComponent.degreesPerHour / BlockTimeComponent.minutesPerHour * this.time.minutes + BlockTimeComponent.degreesPerHour / BlockTimeComponent.secondsPerHour * this.time.seconds}deg)`,
      minutes: `rotate(${BlockTimeComponent.minuteDefaultPosition + this.time.minutes * BlockTimeComponent.degreesPerMinute + (this.time.seconds * BlockTimeComponent.degreesPerMinute / BlockTimeComponent.secondsPerMinute)}deg)`
    }
  }
}
