































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { AdminDashboardPlatformUserCardTranslations } from '../../translations/AdminDashboard.translations'

@Component
export default class AdminCandidatesAggregateStatsComponent extends Vue {
  @Prop() public total!: number
  @Prop() public active!: number
  @Prop() public unvalidated!: number
  @Prop() public completed!: number
  @Prop() public incomplete!: number
  @Prop() public inactiveCandidates!: number
  @Prop() public translations!: AdminDashboardPlatformUserCardTranslations
}
