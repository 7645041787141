




































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import MeetingTranslations from './Meeting.translations'
import { loadWhile, notify } from '@/generic/helpers'
import { MeetingService } from '@/services/MeetingService'
import MeetingFollowUpDetails from './models/MeetingFollowUpDetails.model'
import Candidate from '@/candidate/Candidate.model'
import CandidateService from '@/services/CandidateService'
import { CultureService } from '@/services/CultureService'
import { Culture } from '@/generic/models/Culture'
import { SelectOption } from '@/generic/models/SelectOption'
import { FollowUpStatus } from './models/Meeting.model'
import AddFollowUp from './models/AddFollowUp.model'
import { RequiredRule } from '@/generic/rules/Required'
import { MaxLength } from '@/generic/rules/MaxLength'

@Component
export default class MeetingCardDetailsComponent extends Vue {
  @Prop() meetingId!: number
  @Prop() companyId!: number
  @Prop() candidateId!: number
  @Prop() jobOfferId!: number
  @Prop() missionId!: number
  @Prop() statusData!: Array<SelectOption>
  @Prop() salaryData!: Array<SelectOption>
  @Prop() followUpNeeded!: boolean
  @Prop() translations!: MeetingTranslations
  protected candidate!: Candidate
  protected followUp!: AddFollowUp
  rules: { [key: string]: Array<(value: any) => boolean | string> } = {
    required: [new RequiredRule().getValidator(this.translations.meetingsPage.rules.required)],
    max250: [new MaxLength(250).getValidator(this.translations.meetingsPage.rules.max)]
  }
  protected selectedFollowUpStatus!: SelectOption | null
  protected selectedFollowUpSalary!: SelectOption | null
  protected followUpStatusNote: string = ''
  culture = CultureService.getCulture()
  followUpDetails!: MeetingFollowUpDetails
  loaded: boolean = false
  followUploading: boolean = false
  seeProfile: boolean = false
  hasError: boolean = false
  errorMessage: string = ''

  mounted () {
    if (this.meetingId && this.meetingId > 0 && this.companyId && this.companyId > 0) {
      this.loadMeetingDetails()
    } else {
      this.handleError({
        response: {
          status: 404
        }
      })
    }
  }

  loadMeetingDetails () : void {
    this.loaded = false
    loadWhile(this, '', () => Promise.all([
      this.getMeetingDetails()
    ]).then(() => {
      this.loaded = true
    }).catch(error => {
      this.handleError(error)
    }))
  }

  protected getMeetingDetails() : Promise<void> {
    return loadWhile(this, ' ', () => MeetingService.getMeetingFollowUpDetails(this.companyId, this.meetingId)
      .then(response => {
        this.followUpDetails = response.data
      })
      .catch(error => {
        this.handleError(error)
      })
    )
  }

  private addFollowUp() {
    if (this.canAddFollowUp()) {
      this.followUploading = true
      loadWhile(this, '', () => Promise.all([
        this.saveFollowUp()
      ]).then(() => {
        this.followUploading = false
      }).catch(error => {
        this.followUploading = false
        this.handleError(error)
      }))
    }
  }

  protected saveFollowUp() : Promise<void> {
    return loadWhile(this, ' ', () => MeetingService.saveFollowUp(this.followUp = {
      meetingId: this.meetingId,
      companyId: this.companyId,
      salary: this.selectedFollowUpSalary ? +this.selectedFollowUpSalary.value : 0,
      status: this.selectedFollowUpStatus ? +this.selectedFollowUpStatus.value : 0,
      note: this.followUpStatusNote
    })
      .then(response => {
        notify(this, this.translations.meetingsPage.saveNotify || 'Sauvegarder')
        this.followUpNeeded = false
        this.$emit('follow-up-save')
        this.loadMeetingDetails()
      })
      .catch(error => {
        this.handleError(error)
      })
    )
  }

  private canAddFollowUp() : boolean {
    if (this.selectedFollowUpStatus !== undefined && this.followUpStatusNote.length <= 250) {
      return this.isFollowUpSalaryVisible() ? this.selectedFollowUpSalary !== undefined : true
    }
    return false
  }

  private onInputChange() {
    this.$forceUpdate()
  }

  get getOfferName() : string {
    if (this.culture === Culture.English && this.followUpDetails.offer.titleEn !== '') {
      return this.followUpDetails.offer.titleEn
    } else {
      return this.followUpDetails.offer.titleFr
    }
  }

  getDate(date: string, convertToLocalTimezone: boolean) : string {
    if (convertToLocalTimezone) {
      return new Date(date.replace('T', ' ') + ' UTC').toLocaleDateString(this.culture + '-CA', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: '2-digit' })
    }
    return new Date(date).toLocaleDateString(this.culture + '-CA', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: '2-digit' })
  }

  getIndustry() : any {
    if (this.followUpDetails.offer.industry === null || this.followUpDetails.offer.industry === undefined) {
      return ''
    }
    var industry: string = this.followUpDetails.offer.industry.charAt(0).toLocaleLowerCase() + this.followUpDetails.offer.industry.slice(1)
    return this.translations.candidateCardTranslations.industries[industry]
  }

  getCNP() : any {
    let currentCulture = CultureService.getCultureCode()
    let codeObjects = this.followUpDetails.offer.cnpCodes.filter(x => x.culture === currentCulture)
    let codeStrings = []
    for (let i = 0; i < codeObjects.length; i++) {
      codeStrings.push(codeObjects[i].title)
    }
    return codeStrings.join(', ')
  }

  get getOfferInfos(): string {
    var info = ''
    if (this.followUpDetails.offer.companyName) {
      info += this.followUpDetails.offer.companyName
    }

    if (this.followUpDetails.offer.industry) {
      info += ' - ' + this.getIndustry()
    }

    return info
  }

  followUpTitle (month: number) : string {
    if (this.culture === Culture.English) {
      return `${month} month follow-up`
    } else {
      return `Suivi de ${month} mois`
    }
  }

  getFollowUpStatusColor (followUpStatus: FollowUpStatus) : string {
    if (followUpStatus === FollowUpStatus.OfferAcceptedByCandidate) {
      return 'text-mitm-green'
    }
    var openStatus:number[] = [
      FollowUpStatus.AcceptedInPool,
      FollowUpStatus.SelectedForFirstInterview,
      FollowUpStatus.SelectedForSecondInterview,
      FollowUpStatus.WaitingForDocuments,
      FollowUpStatus.InterviewPostponed,
      FollowUpStatus.JobPromise
    ]

    return openStatus.includes(followUpStatus) ? 'text-mitm-primary-inverse' : 'text-mitm-red'
  }

  protected showProfile () {
    CandidateService.getSimpleDetailsForMeeting(this.candidateId)
      .then(response => {
        this.candidate = {
          id: this.candidateId,
          candidateId: this.candidateId,
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          title: response.data.title,
          industry: this.getIndustry(),
          new: false,
          consultedByRecruiter: true,
          liked: response.data.liked,
          email: response.data.email,
          created: new Date(response.data.created),
          booked: false,
          hidden: false,
          tags: [],
          rating: 0,
          isSelectedForCVDownload: false,
          languageProficiencyLevelFr: response.data.languageProficiencyLevelFr,
          languageProficiencyLevelEn: response.data.languageProficiencyLevelEn,
          degree: ''
        }
        this.seeProfile = true
      })
  }

  isFollowUpSalaryVisible () : boolean {
    return this.statusData !== undefined && this.selectedFollowUpStatus?.value === FollowUpStatus.OfferAcceptedByCandidate
  }

  followUpSectionClass () : string {
    var total = this.followUpDetails.followUps.length
    if (total > 0) {
      if (total > 3) {
        total = 3
      }
      return 'col-12 col-md-' + (12 / total)
    }
    return 'col-12'
  }

  get offerTitle () : string {
    if (this.culture === Culture.French && this.followUpDetails.offer.titleFr !== '' && this.followUpDetails.offer.titleFr !== undefined && this.followUpDetails.offer.titleFr !== null) {
      return this.followUpDetails.offer.titleFr
    } else if (this.culture === Culture.English && this.followUpDetails.offer.titleEn !== '' && this.followUpDetails.offer.titleEn !== undefined && this.followUpDetails.offer.titleEn !== null) {
      return this.followUpDetails.offer.titleEn
    }
    return this.culture === Culture.French ? this.followUpDetails.offer.titleEn : this.followUpDetails.offer.titleFr
  }

  protected handleError (error: any) {
    this.hasError = true
    if (error.response.status === 404) {
      this.errorMessage = this.translations.meetingError.notFound
    } else if (error.response.status === 403) {
      this.errorMessage = this.translations.meetingError.forbidden
    } else {
      this.errorMessage = this.translations.meetingError.unknown
    }
  }

  private getMonthInCulture() {
    if (CultureService.getCultureCode() === 'en-US') {
      return 'months'
    }
    return ' mois'
  }
}
